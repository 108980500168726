import cloneDeep from 'lodash/cloneDeep';

export default class ObjectUtils {
  /**
   * Method will check if the provided object
   * is not empty
   */
  static isNotEmpty(object?: object | null | undefined): boolean {
    return !ObjectUtils.isEmpty(object);
  }

  /**
   * Method will check if the provided object
   * doesn't have any property
   */
  static isEmpty(object?: object | null | undefined): boolean {
    if (object === null || object === undefined) {
      return true;
    }

    return Object.keys(object).length === 0;
  }

  /**
   * Method will check if the object have all
   * properties
   */
  static hasProperties(object: object, properties: string[]): boolean {
    const keys = Object.keys(object);

    for (const property of properties) {
      if (keys.indexOf(property) === -1) {
        return false;
      }
    }

    return true;
  }

  /**
   * Method will check if all properties are
   * - null
   * - undefined
   * - empty string
   */
  static hasAllPropertiesEmpty(object: object): boolean {
    const properties = Object.keys(object);
    for (const property of properties) {
      // eslint-disable-next-line no-prototype-builtins
      if (!object.hasOwnProperty(property)) {
        throw new Error(`Object property doesn't exist: ${property}`);
      }

      // eslint-disable-next-line no-prototype-builtins
      if (object.hasOwnProperty(property) && object[property]) {
        return false;
      }
    }

    return true;
  }

  /**
   * Method will remove all object keys which values are:
   * - null
   * - undefined
   * - empty string
   */
  static removeEmptyKeys(object: object): typeof object {
    const objectCopy = cloneDeep(object);
    const keys = Object.keys(objectCopy);

    for (const key of keys) {
      if (objectCopy[key] === null || objectCopy[key] === undefined || objectCopy[key] === '') {
        delete objectCopy[key];
      }
    }

    return objectCopy;
  }

  /**
   * Method will check if the object has:
   * - null
   * - undefined
   * - empty string
   */

  static hasEmptyProperties(object: object): boolean {
    return Object.values(object)?.filter(value => !value).length > 0;
  }
}
