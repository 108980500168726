import React, { memo, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import withMenuLinks from '../withMenuLinks';
import HamburgerButton from './HamburgerButton';
import styles from './HamburgerMenu.module.scss';
import MobileMenuLink from './MobileMenuLink';
import MobileUserActions from './MobileUserActions';

const propTypes = {
  linksData: PropTypes.arrayOf(PropTypes.object).isRequired
};

const HamburgerMenu = ({ linksData }) => {
  const { t } = useTranslation();

  const [isMenuVisible, setIsMenuVisible] = useState(true);
  const currentLocation = useLocation();

  useEffect(() => setIsMenuVisible(false), [currentLocation]);

  const menuLinks = linksData.map((linkData, index) => (
    <MobileMenuLink
      key={index}
      {...linkData}
      label={linkData.shouldNotTranslateLabel ? linkData.label : t(linkData.label)}
    />
  ));

  return (
    <div>
      <HamburgerButton isActive={isMenuVisible} onClick={() => setIsMenuVisible(!isMenuVisible)} />

      {isMenuVisible && (
        <ul className={styles.menu_container}>
          {menuLinks}
          <MobileUserActions />
        </ul>
      )}
    </div>
  );
};

HamburgerMenu.propTypes = propTypes;

export default withMenuLinks(memo(HamburgerMenu));
