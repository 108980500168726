import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

import fieldNames from 'consts/fieldNames';
import formNames from 'consts/formNames';
import { areEquals, isRequiredArray, isValidPasswordArray } from 'utils/formValidators';
import Button from 'components/Button';
import Form from 'components/Form';
import MyInputPasswordField from 'components/reduxFormFields/MyInputPasswordField';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired
};

const UserPasswordChangeForm = ({ handleSubmit, submitting }) => {
  const { t } = useTranslation();

  return (
    <Form handleSubmit={handleSubmit}>
      <fieldset>
        <legend>{t('UserChangePassword')}</legend>

        <MyInputPasswordField
          label={t('CurrentPassword')}
          placeholder={t('CurrentPassword')}
          name={fieldNames.userPasswordChangeForm.currentPassword}
        />

        <MyInputPasswordField
          label={t('NewPassword')}
          placeholder={t('NewPassword')}
          name={fieldNames.userPasswordChangeForm.newPassword}
        />

        <MyInputPasswordField
          label={t('RepeatPassword')}
          placeholder={t('RepeatPassword')}
          name={fieldNames.userPasswordChangeForm.confirmPassword}
        />

        <Button disabled={submitting}>{t('Update')}</Button>
      </fieldset>
    </Form>
  );
};

UserPasswordChangeForm.propTypes = propTypes;

const validate = values => {
  const errors = {};

  isRequiredArray(
    errors,
    values,
    [
      fieldNames.userPasswordChangeForm.currentPassword,
      fieldNames.userPasswordChangeForm.newPassword,
      fieldNames.userPasswordChangeForm.confirmPassword
    ],
    t('FieldRequired')
  );

  isValidPasswordArray(
    errors,
    values,
    [
      fieldNames.userPasswordChangeForm.newPassword,
      fieldNames.userPasswordChangeForm.confirmPassword
    ],
    t('NotValidPassword')
  );

  areEquals(
    errors,
    values,
    [
      fieldNames.userPasswordChangeForm.newPassword,
      fieldNames.userPasswordChangeForm.confirmPassword
    ],
    t('PasswordsNotEquals')
  );

  return errors;
};

export default reduxForm({
  form: formNames.userPasswordChangeForm,
  validate
})(memo(UserPasswordChangeForm));
