import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Avatar from 'components/Avatar';
import Card from 'components/Card';

import styles from './StyledFormWrapper.module.scss';

const propTypes = {
  children: PropTypes.node.isRequired,
  isVisible: PropTypes.bool
};

// TODO: Vorbert -> zastanowić się nad użyciem hooka 'useFocusOnFirstInput'
const StyledFormWrapper = ({ children, isVisible }) => {
  const formCardClassName = cx(styles.form_card_container, {
    [styles['form_card_container--is-hidden']]: !isVisible
  });

  return (
    <div className={formCardClassName}>
      <Card className={styles.form_card}>
        <Avatar className={styles.form_card_avatar} />
        {children}
      </Card>
    </div>
  );
};

StyledFormWrapper.propTypes = propTypes;

export default memo(StyledFormWrapper);
