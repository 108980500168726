import React, { memo } from 'react';
import { useParams } from 'react-router-dom';

import { usePlayerDataForStatisticsPage } from './data';
import PlayerSeasonStatisticsPage from './PlayerSeasonStatisticsPage';

const PlayerSeasonStatisticsPageContainer = () => {
  const { playerId, seasonId } = useParams();

  const {
    details,
    photo,
    currentTeam,
    debutMatch,
    season,

    getDetailsRequestStatus,
    getPhotoRequestStatus,
    getCurrentTeamRequestStatus,
    getDebutMatchRequestStatus,
    getSeasonRequestStatus
  } = usePlayerDataForStatisticsPage(playerId!, seasonId!);

  const matchesInSeason = season?.matches;

  return (
    <PlayerSeasonStatisticsPage
      details={details}
      photo={photo}
      currentTeam={currentTeam}
      debutMatch={debutMatch}
      season={season}
      matchesInSeason={matchesInSeason}
      getDetailsRequestStatus={getDetailsRequestStatus}
      getPhotoRequestStatus={getPhotoRequestStatus}
      getCurrentTeamRequestStatus={getCurrentTeamRequestStatus}
      getDebutMatchRequestStatus={getDebutMatchRequestStatus}
      getSeasonRequestStatus={getSeasonRequestStatus}
      getMatchesInSeasonRequestStatus={season?.getMatchesRequestStatus}
    />
  );
};

export default memo(PlayerSeasonStatisticsPageContainer);
