import { memo } from 'react';
import styled from 'styled-components';
import { mediaValues } from 'styling';

const StyledWrapper = styled.div`
  display: flex;
  height: 125px;
  background-color: ${({ theme }) => theme.light};
  align-items: center;
  justify-content: center;

  @media screen and (${mediaValues.navbarMobileAndDown}) {
    height: 90px;
  }
`;

export default memo(StyledWrapper);
