import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { matchPropTypesShape } from 'propTypes';
import { useIsDesktop } from 'utils/customHooks';

import MatchesTable from './MatchesTable';
import MobileMatchesTable from './MobileMatchesTable';

const propTypes = {
  matches: PropTypes.arrayOf(matchPropTypesShape), // TODO: player statistics props isCaptain, ageGroupName, goals, etc.
  withStatus: PropTypes.bool,
  withStatusAndPlayerStatistics: PropTypes.bool,
  teamIdContext: PropTypes.string,
  isExtended: PropTypes.bool
};

const MatchesTableResponsive = ({
  matches = [],
  withStatus,
  withStatusAndPlayerStatistics,
  teamIdContext,
  isExtended = true
}) => {
  const isDesktop = useIsDesktop();

  return isDesktop ? (
    <MatchesTable
      matches={matches}
      withStatus={withStatus}
      withStatusAndPlayerStatistics={withStatusAndPlayerStatistics}
      teamIdContext={teamIdContext}
      isExtended={isExtended}
    />
  ) : (
    <MobileMatchesTable
      matches={matches}
      withStatus={withStatus}
      withStatusAndPlayerStatistics={withStatusAndPlayerStatistics}
      teamIdContext={teamIdContext}
      isExtended={isExtended}
    />
  );
};

MatchesTableResponsive.propTypes = propTypes;

export default memo(MatchesTableResponsive);
