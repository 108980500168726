import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// TODO: FootballAssociationActivist default avatar
import defaultAvatar from 'assets/images/BoardMember_default_avatar.svg';
import cx from 'classnames';

import { personWithIdAndPhotoPropTypes } from 'propTypes';
import urls from 'consts/urls';

import Avatar from '../Avatar';
import styles from './FootballAssociationActivistLink.module.scss';
import Link from './Link';

const propTypes = {
  ...personWithIdAndPhotoPropTypes,
  className: PropTypes.string,
  reverse: PropTypes.bool
};

const FootballAssociationActivistLink = ({
  id,
  photo,
  firstName,
  lastName,
  className,
  reverse
}) => {
  const { t } = useTranslation();

  const linkClassName = cx(styles.footballAssociationActivist_link, className, {
    [styles.footballAssociationActivist_link_reverse]: reverse
  });

  return (
    <Link
      to={urls.footballAssociationActivistDetails(id)}
      className={linkClassName}
      title={`${t('GoToTheFootballAssociationActivistSubpage')} ${firstName} ${lastName}`}
    >
      <Avatar src={photo || defaultAvatar} className={styles.footballAssociationActivist_logo} />
      <span className={styles.footballAssociationActivist_name}>
        {firstName} {lastName}
      </span>
    </Link>
  );
};

FootballAssociationActivistLink.propTypes = propTypes;

export default memo(FootballAssociationActivistLink);
