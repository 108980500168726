const actionTypes = {
  STORE_MY_LEAGUE_NAME: 'STORE_MY_LEAGUE_NAME',
  STORE_CURRENT_SEASON_NAME: 'STORE_CURRENT_SEASON_NAME',

  STORE_CURRENT_COMPETITIONS_INFO: 'STORE_CURRENT_COMPETITIONS_INFO',
  STORE_COMPETITIONS_INFO: 'STORE_COMPETITIONS_INFO',

  STORE_CURRENT_COMPETITIONS_TABLE_SEASON_FULL: 'STORE_CURRENT_COMPETITIONS_TABLE_SEASON_FULL',
  STORE_CURRENT_COMPETITIONS_TABLE_SEASON_AS_HOST:
    'STORE_CURRENT_COMPETITIONS_TABLE_SEASON_AS_HOST',
  STORE_CURRENT_COMPETITIONS_TABLE_SEASON_AS_GUEST:
    'STORE_CURRENT_COMPETITIONS_TABLE_SEASON_AS_GUEST',

  STORE_CURRENT_COMPETITIONS_TABLE_AUTUMN_ROUND_FULL:
    'STORE_CURRENT_COMPETITIONS_TABLE_AUTUMN_ROUND_FULL',
  STORE_CURRENT_COMPETITIONS_TABLE_AUTUMN_ROUND_AS_HOST:
    'STORE_CURRENT_COMPETITIONS_TABLE_AUTUMN_ROUND_AS_HOST',
  STORE_CURRENT_COMPETITIONS_TABLE_AUTUMN_ROUND_AS_GUEST:
    'STORE_CURRENT_COMPETITIONS_TABLE_AUTUMN_ROUND_AS_GUEST',

  STORE_CURRENT_COMPETITIONS_TABLE_SPRING_ROUND_FULL:
    'STORE_CURRENT_COMPETITIONS_TABLE_SPRING_ROUND_FULL',
  STORE_CURRENT_COMPETITIONS_TABLE_SPRING_ROUND_AS_HOST:
    'STORE_CURRENT_COMPETITIONS_TABLE_SPRING_ROUND_AS_HOST',
  STORE_CURRENT_COMPETITIONS_TABLE_SPRING_ROUND_AS_GUEST:
    'STORE_CURRENT_COMPETITIONS_TABLE_SPRING_ROUND_AS_GUEST',

  STORE_COMPETITIONS_TABLE_SEASON_FULL: 'STORE_COMPETITIONS_TABLE_SEASON_FULL',
  STORE_COMPETITIONS_TABLE_SEASON_AS_HOST: 'STORE_COMPETITIONS_TABLE_SEASON_AS_HOST',
  STORE_COMPETITIONS_TABLE_SEASON_AS_GUEST: 'STORE_COMPETITIONS_TABLE_SEASON_AS_GUEST',

  STORE_COMPETITIONS_TABLE_AUTUMN_ROUND_FULL: 'STORE_COMPETITIONS_TABLE_AUTUMN_ROUND_FULL',
  STORE_COMPETITIONS_TABLE_AUTUMN_ROUND_AS_HOST: 'STORE_COMPETITIONS_TABLE_AUTUMN_ROUND_AS_HOST',
  STORE_COMPETITIONS_TABLE_AUTUMN_ROUND_AS_GUEST: 'STORE_COMPETITIONS_TABLE_AUTUMN_ROUND_AS_GUEST',

  STORE_COMPETITIONS_TABLE_SPRING_ROUND_FULL: 'STORE_COMPETITIONS_TABLE_SPRING_ROUND_FULL',
  STORE_COMPETITIONS_TABLE_SPRING_ROUND_AS_HOST: 'STORE_COMPETITIONS_TABLE_SPRING_ROUND_AS_HOST',
  STORE_COMPETITIONS_TABLE_SPRING_ROUND_AS_GUEST: 'STORE_COMPETITIONS_TABLE_SPRING_ROUND_AS_GUEST',

  STORE_CURRENT_COMPETITIONS_SCHEDULE: 'STORE_CURRENT_COMPETITIONS_SCHEDULE',
  STORE_COMPETITIONS_SCHEDULE: 'STORE_COMPETITIONS_SCHEDULE'
};

export default actionTypes;
