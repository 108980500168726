import React, { memo } from 'react';
import Link from 'components/Links/Link';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { personWithIdAndPhotoPropTypes } from 'propTypes';
import urls from 'consts/urls';
import Avatar from 'components/Avatar';

import styles from './EventPlayerLink.module.scss';

const propTypes = {
  ...personWithIdAndPhotoPropTypes,
  className: PropTypes.string
  // TODO: assist: PropTypes.bool
};

const EventPlayerLink = ({ id, firstName, lastName, photo, className /* TODO: , assist */ }) => {
  const { t } = useTranslation();

  const getPlayerName = () => `${firstName} ${lastName}`; // TODO: dlaczego tutaj jest arrow function zamiast po prostu zwykła zmienna?
  const playerLinkClassName = cx(styles.link, className);

  return (
    <Link
      className={playerLinkClassName}
      to={urls.playerDetails(id)}
      title={`${t('GoToThePlayerSubpage')} ${getPlayerName()}`}
      data-player-name={getPlayerName()}
    >
      <Avatar src={photo} className={styles.link_logo} />
      <span className={styles.link_name}>
        {getPlayerName()}
        {/* TODO: Vorbert -> zastanowić się nad taką funkcjonalnością */}
        {/* {assist && ' (asysta)'} */}
      </span>
    </Link>
  );
};

EventPlayerLink.propTypes = propTypes;

export default memo(EventPlayerLink);
