import React, { memo } from 'react';

import { useAppSelector } from 'store';
import { useDispatchOnMountWithLocation } from 'utils/customHooks';

import { get } from './actions';
import GalleryPage from './GalleryPage';

const GalleryPageContainer = props => {
  const albums = useAppSelector(state => state.albums);

  useDispatchOnMountWithLocation(get());

  return <GalleryPage {...props} albums={albums} />;
};

export default memo(GalleryPageContainer);
