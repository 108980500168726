import React, { memo, ReactElement } from 'react';
import cx from 'classnames';

import Column from 'components/Column';
import Row from 'components/Row';

import styles from './EventCountColumn.module.scss';

type Props = {
  icon: ReactElement;
  eventCounts: number | string | undefined;
  bigger?: boolean;
};

const EventCountColumn = ({ icon, eventCounts, bigger }: Readonly<Props>) => {
  const eventCountsClassName = cx(styles.event_count_number, {
    [styles.event_count_number_bigger]: bigger
  });

  return (
    <Column className={styles.event_count_column}>
      <Row className={styles.event_count_column_row}>
        <Column>{icon}</Column>

        <Column>
          <span className={eventCountsClassName}>{eventCounts}</span>
        </Column>
      </Row>
    </Column>
  );
};

export default memo(EventCountColumn);
