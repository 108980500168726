import React, { memo } from 'react';

import { CircleInformation } from 'components/CircleInformation';
import { icons } from 'consts/icons';

const NotFoundPage = () => (
  <CircleInformation icon={icons.ban} messageTranslationKey="PageNotFound" />
);

export default memo(NotFoundPage);
