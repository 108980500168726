import React, { memo, useState } from 'react';
import Link from 'components/Links/Link';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import LoginFormReduxForm from './LoginFormReduxForm';
import RegisterFormReduxForm from './RegisterFormReduxForm';

const propTypes = {
  onRegister: PropTypes.func.isRequired,
  onLogin: PropTypes.func.isRequired
};

const AuthPage = ({ onRegister, onLogin }) => {
  const { t } = useTranslation();
  const [activeLoginForm, setActiveLoginForm] = useState(true);

  const showLoginForm = () => setActiveLoginForm(true);
  const showRegisterForm = () => setActiveLoginForm(false);

  return (
    <>
      <LoginFormReduxForm
        isVisible={activeLoginForm}
        onSubmit={onLogin}
        showRegisterForm={showRegisterForm}
      />
      <RegisterFormReduxForm
        isVisible={!activeLoginForm}
        onSubmit={onRegister}
        showLoginForm={showLoginForm}
      />
      <Link to="/"> {t('BackToMainPage')}</Link>
    </>
  );
};

AuthPage.propTypes = propTypes;

export default memo(AuthPage);
