import { RequestStatus } from 'models/common.models';

import { useAppSelector } from 'store';
import { useGetRequestStatusStatus } from 'utils/requests.utils';

import {
  IPlayerCurrentTeamModel,
  IPlayerDebutMatchModel,
  IPlayerDetailsModel,
  IPlayerMatchModel,
  IPlayerModel,
  IPlayerSeasonModel
} from './models';

import {
  selectGetPlayerCurrentTeamRequestStatus,
  selectGetPlayerDebutMatchRequestStatus,
  selectGetPlayerDetailsRequestStatus,
  selectGetPlayerMatchesInSeasonRequestStatus,
  selectGetPlayerPhotoRequestStatus,
  selectGetPlayerSeasonRequestStatus,
  selectGetPlayerSeasonsRequestStatus,
  selectPlayer,
  selectPlayerCurrentTeam,
  selectPlayerDebutMatch,
  selectPlayerDetails,
  selectPlayerMatchesInSeason,
  selectPlayerPhoto,
  selectPlayerSeason,
  selectPlayerSeasons
} from './player.selectors';

export const useSelectPlayer = (id: string | undefined): IPlayerModel | undefined | null =>
  useAppSelector(state => selectPlayer(state, id as string));

export const useSelectGetPlayerDetailsRequestStatus = (id: string | undefined): RequestStatus =>
  useGetRequestStatusStatus(id, selectGetPlayerDetailsRequestStatus);

export const useSelectPlayerDetails = (
  id: string | undefined
): IPlayerDetailsModel | undefined | null =>
  useAppSelector(state => selectPlayerDetails(state, id as string));

export const useSelectGetPlayerPhotoRequestStatus = (id: string | undefined): RequestStatus =>
  useGetRequestStatusStatus(id, selectGetPlayerPhotoRequestStatus);

export const useSelectPlayerPhoto = (id: string | undefined): string | undefined | null =>
  useAppSelector(state => selectPlayerPhoto(state, id as string));

export const useSelectGetPlayerCurrentTeamRequestStatus = (id: string | undefined): RequestStatus =>
  useGetRequestStatusStatus(id, selectGetPlayerCurrentTeamRequestStatus);

export const useSelectPlayerCurrentTeam = (
  id: string | undefined
): IPlayerCurrentTeamModel | undefined | null =>
  useAppSelector(state => selectPlayerCurrentTeam(state, id as string));

export const useSelectGetPlayerDebutMatchRequestStatus = (id: string | undefined): RequestStatus =>
  useGetRequestStatusStatus(id, selectGetPlayerDebutMatchRequestStatus);

export const useSelectPlayerDebutMatch = (
  id: string | undefined
): IPlayerDebutMatchModel | undefined | null =>
  useAppSelector(state => selectPlayerDebutMatch(state, id as string));

export const useSelectGetPlayerSeasonsRequestStatus = (id: string | undefined): RequestStatus =>
  useGetRequestStatusStatus(id, selectGetPlayerSeasonsRequestStatus);

export const useSelectPlayerSeasons = (
  id: string | undefined
): IPlayerSeasonModel[] | undefined | null =>
  useAppSelector(state => selectPlayerSeasons(state, id as string));

export const useSelectGetPlayerSeasonRequestStatus = (
  id: string | undefined,
  seasonId: string | undefined
): RequestStatus =>
  useAppSelector(state =>
    selectGetPlayerSeasonRequestStatus(state, id as string, seasonId as string)
  ) as RequestStatus;

export const useSelectPlayerSeason = (
  id: string | undefined,
  seasonId: string | undefined
): IPlayerSeasonModel | undefined | null =>
  useAppSelector(state => selectPlayerSeason(state, id as string, seasonId as string));

export const useSelectGetPlayerMatchesInSeasonRequestStatus = (
  id: string | undefined,
  seasonId: string | undefined
): RequestStatus =>
  useAppSelector(state =>
    selectGetPlayerMatchesInSeasonRequestStatus(state, id as string, seasonId as string)
  ) as RequestStatus;

export const useSelectPlayerMatchesInSeason = (
  id: string | undefined,
  seasonId: string | undefined
): IPlayerMatchModel[] | undefined | null =>
  useAppSelector(state => selectPlayerMatchesInSeason(state, id as string, seasonId as string));
