import React, { memo, useEffect, useState } from 'react';
import Link from 'components/Links/Link';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';

import { ESortDirections } from 'consts/ESortDirections';
import urls from 'consts/urls';
import ArrayUtils from 'utils/array.utils';
import {
  getCurrentSortingDirection,
  sortNumericValues,
  sortTextValues
} from 'utils/sortingMethods';
import Card from 'components/Card';

import CardTableHeader from './CardTableHeader';
import CardTablePlaceholder from './CardTablePlaceholder';
import CardTableRow from './CardTableRow';
import { columnsToDisplay } from './table.consts';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  teams: any[]; // TODO: type
};

const CardTable = ({ teams }: Readonly<Props>) => {
  const { t } = useTranslation();

  const [hoveredColumnIndex, setHoveredColumnIndex] = useState<number | null>(null);

  const [sortDirection, setSortDirection] = useState<ESortDirections | null>(null);
  const [sortedColumnIndex, setSortedColumnIndex] = useState<number>(0);

  const getTeamsWithGoalsDifference = () =>
    teams.map(team => ({
      ...team,
      goalsDifference: team.goalsScored - team.goalsLost
    }));

  const [teamsData, setTeamsData] = useState(() => getTeamsWithGoalsDifference());

  const resetTeamsData = () =>
    setTeamsData(sortNumericValues([...teamsData], columnsToDisplay[0], true));

  const sortTeamsData = () => {
    const sortFieldName = columnsToDisplay[sortedColumnIndex];
    const sortingType = typeof teamsData[0][sortFieldName] === 'number' ? 'numeric' : 'text';
    const ascending = sortDirection === ESortDirections.ascending;

    setTeamsData(
      sortingType === 'numeric'
        ? sortNumericValues(teamsData, sortFieldName, ascending)
        : sortTextValues(teamsData, sortFieldName, ascending)
    );
  };

  const sortColumn = (columnIndex: number) => {
    const currentSortingDirection = getCurrentSortingDirection(
      sortDirection,
      columnIndex,
      sortedColumnIndex
    );

    setSortDirection(currentSortingDirection);
    setSortedColumnIndex(columnIndex);
  };

  const initializeTeamsData = () =>
    ArrayUtils.isNotEmpty(teams) && setTeamsData(getTeamsWithGoalsDifference());

  useEffect(
    () => {
      initializeTeamsData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [teams]
  );

  useEffect(
    () => {
      if (sortDirection) {
        sortTeamsData();
      } else {
        resetTeamsData();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sortDirection, sortedColumnIndex]
  );

  const lowestPositionNumber = Math.max(...teamsData.map(x => x.position));

  const teamRows = teamsData.map(team => (
    <CardTableRow
      key={team.id}
      team={team}
      hoveredColumnIndex={hoveredColumnIndex}
      lowestPositionNumber={lowestPositionNumber}
    />
  ));

  // TODO: try fix issue after add million
  // const teamRows = (
  //   <For each={teamsData}>
  //     {team => (
  //       <CardTableRow
  //         {...team}
  //         key={team.id}
  //         hoveredColumnIndex={hoveredColumnIndex}
  //         lowestPositionNumber={lowestPositionNumber}
  //       />
  //     )}
  //   </For>
  // );

  return !isEmpty(teams) ? (
    <Card
      renderFooter={() => <Link to={urls.currentCompetitionsTable}>{t('DetailsTable')}</Link>}
      renderTitle={() => t('ResultsTable')}
    >
      <table>
        <CardTableHeader
          sortDirection={sortDirection}
          sortedColumnIndex={sortedColumnIndex}
          setHoveredColumnIndex={setHoveredColumnIndex}
          sortColumn={sortColumn}
        />
        <tbody>{teamRows}</tbody>
      </table>
    </Card>
  ) : (
    <CardTablePlaceholder />
  );
};

export default memo(CardTable);
