import { KeyValuePair } from 'models/common.models';

import ArrayUtils from 'utils/array.utils';

import { ILeagueModel, IPlayerModel, IPlayerSeasonModel, ITeamModel, PlayersState } from './models';

export function getExistingPlayer(
  state: PlayersState,
  playerId: string
): IPlayerModel | undefined | null {
  return state[playerId];
}

export function getExistingSeason(
  state: PlayersState,
  playerId: string,
  seasonId: string
): IPlayerSeasonModel | undefined | null {
  const existingPlayer = getExistingPlayer(state, playerId);

  if (existingPlayer?.seasons?.length) {
    return existingPlayer?.seasons.find(season => season.seasonId === seasonId);
  }
}

export function getExistingLeague(
  state: PlayersState,
  playerId: string,
  seasonId: string,
  leagueId: string
): ILeagueModel | undefined {
  const existingSeason = getExistingSeason(state, playerId, seasonId);

  if (existingSeason) {
    return existingSeason?.leagues.find(league => league.leagueId === leagueId) as ILeagueModel;
  }
}

export function getExistingTeam(
  state: PlayersState,
  playerId: string,
  seasonId: string,
  leagueId: string,
  teamId: string
): ITeamModel | undefined | null {
  const existingLeague = getExistingLeague(state, playerId, seasonId, leagueId);

  if (existingLeague) {
    return existingLeague?.teams.find(team => team.id === teamId) as ITeamModel;
  }
}

export function changePlayerValuesOrAddNewPlayer(
  state: PlayersState,
  action,
  keyValues: KeyValuePair[]
): void {
  const playerId = action.meta.arg;

  const existingPlayer = getExistingPlayer(state, playerId);

  if (existingPlayer) {
    for (const keyValue of keyValues) {
      existingPlayer[keyValue.key] = keyValue.value;
    }
  } else {
    state[playerId] = {} as IPlayerModel;

    for (const keyValue of keyValues) {
      state[playerId][keyValue.key] = keyValue.value;
    }
  }
}

export function changeSeasonValuesOrAddNewSeason(
  state: PlayersState,
  action,
  keyValues: KeyValuePair[]
): void {
  const playerId = action.meta.arg.id;
  const seasonId = action.meta.arg.seasonId;

  const existingSeason = getExistingSeason(state, playerId, seasonId);

  if (existingSeason) {
    for (const keyValue of keyValues) {
      existingSeason[keyValue.key] = keyValue.value;
    }
  } else {
    if (ArrayUtils.isEmpty(state[playerId].seasons!)) {
      state[playerId].seasons = [];
    }

    state[playerId].seasons!.push({ seasonId } as IPlayerSeasonModel);

    const existingSeason = getExistingSeason(state, playerId, seasonId);

    for (const keyValue of keyValues) {
      existingSeason![keyValue.key] = keyValue.value;
    }
  }
}

// TODO: add orAddNewTeam? and logic?
export function changeTeamValues(state: PlayersState, action, keyValues: KeyValuePair[]): void {
  const playerId = action.meta.arg.id;
  const seasonId = action.meta.arg.seasonId;
  const leagueId = action.meta.arg.leagueId;
  const teamId = action.meta.arg.teamId;

  const existingTeam = getExistingTeam(state, playerId, seasonId, leagueId, teamId);

  if (existingTeam) {
    for (const keyValue of keyValues) {
      existingTeam[keyValue.key] = keyValue.value;
    }
  }
}
