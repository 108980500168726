import React, { memo } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { tablePropTypesShape } from 'propTypes';
import InnerContentWrapper from 'components/InnerContentWrapper';
import LoadingPageData from 'components/LoadingPageData';
import PageHeader from 'components/PageHeader';

import Tables from './Tables';

const propTypes = {
  leagueName: PropTypes.string,
  seasonName: PropTypes.string,
  tableSeasonFull: tablePropTypesShape,
  tableSeasonAsHost: tablePropTypesShape,
  tableSeasonAsGuest: tablePropTypesShape,
  tableAutumnRoundFull: tablePropTypesShape,
  tableAutumnRoundAsHost: tablePropTypesShape,
  tableAutumnRoundAsGuest: tablePropTypesShape,
  tableSpringRoundFull: tablePropTypesShape,
  tableSpringRoundAsHost: tablePropTypesShape,
  tableSpringRoundAsGuest: tablePropTypesShape,
  onGetTableData: PropTypes.func
};

const TablePage = ({
  leagueName,
  seasonName,
  tableSeasonFull,
  tableSeasonAsHost,
  tableSeasonAsGuest,
  tableAutumnRoundFull,
  tableAutumnRoundAsHost,
  tableAutumnRoundAsGuest,
  tableSpringRoundFull,
  tableSpringRoundAsHost,
  tableSpringRoundAsGuest,
  onGetTableData
}) => (
  <InnerContentWrapper horizontalOffset>
    {/* TODO: przekierowanie do strony szczegółów ligi-sezonu po kliknięciu na nazwę */}
    {!isEmpty(leagueName) && !isEmpty(seasonName) ? (
      <PageHeader title={`${leagueName} ${seasonName}`} />
    ) : (
      <LoadingPageData />
    )}

    <Tables
      tableSeasonFull={tableSeasonFull}
      tableSeasonAsHost={tableSeasonAsHost}
      tableSeasonAsGuest={tableSeasonAsGuest}
      tableAutumnRoundFull={tableAutumnRoundFull}
      tableAutumnRoundAsHost={tableAutumnRoundAsHost}
      tableAutumnRoundAsGuest={tableAutumnRoundAsGuest}
      tableSpringRoundFull={tableSpringRoundFull}
      tableSpringRoundAsHost={tableSpringRoundAsHost}
      tableSpringRoundAsGuest={tableSpringRoundAsGuest}
      onGetTableData={onGetTableData}
    />
  </InnerContentWrapper>
);

TablePage.propTypes = propTypes;

export default memo(TablePage);
