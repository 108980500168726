import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { matchPropTypesShape } from 'propTypes';

import ContentRevealer from './ContentRevealer';
import styles from './MatchesTable.module.scss';
import MatchRow from './MatchRow';

const propTypes = {
  matches: PropTypes.arrayOf(matchPropTypesShape), // TODO: player statistics props goals, etc.
  withStatus: PropTypes.bool,
  withStatusAndPlayerStatistics: PropTypes.bool,
  teamIdContext: PropTypes.string,
  isExtended: PropTypes.bool
};

const MatchesTable = ({
  matches = [],
  withStatus,
  withStatusAndPlayerStatistics,
  teamIdContext,
  isExtended = true
}) => {
  const teamNameClassName = cx({
    [styles.team_name]: !withStatus && !withStatusAndPlayerStatistics,
    [styles.team_name_withStatus]: withStatus,
    [styles.team_name_withStatusAndPlayerStatistics]: withStatusAndPlayerStatistics
  });

  const goalsClassName = cx({
    [styles.goals]: !withStatus && !withStatusAndPlayerStatistics,
    [styles.goals_withStatus]: withStatus,
    [styles.goals_withStatusAndPlayerStatistics]: withStatusAndPlayerStatistics
  });

  const eventsClassName = cx({
    [styles.events_withStatusAndPlayerStatistics]: withStatusAndPlayerStatistics
  });

  const commentClassName = cx({
    [styles.comment]: !withStatus && !withStatusAndPlayerStatistics,
    [styles.comment_withStatus]: withStatus,
    [styles.comment_withStatusAndPlayerStatistics]: withStatusAndPlayerStatistics
  });

  const dateClassName = cx({
    [styles.date]: !withStatus && !withStatusAndPlayerStatistics,
    [styles.date_withStatus]: withStatus,
    [styles.date_withStatusAndPlayerStatistics]: withStatusAndPlayerStatistics
  });

  const timeClassName = cx({
    [styles.time]: !withStatus && !withStatusAndPlayerStatistics,
    [styles.time_withStatus]: withStatus,
    [styles.time_withStatusAndPlayerStatistics]: withStatusAndPlayerStatistics
  });

  const statusClassName = cx({
    [styles.status_withStatus]: withStatus,
    [styles.status_withStatusAndPlayerStatistics]: withStatusAndPlayerStatistics
  });

  const matchesRows = matches.map(match => (
    <MatchRow
      key={match.id}
      {...match}
      withStatus={withStatus}
      withStatusAndPlayerStatistics={withStatusAndPlayerStatistics}
      teamIdContext={teamIdContext}
    />
  ));

  return (
    <ContentRevealer isVisible={isExtended} className={styles.matches_table_container}>
      {/* TODO: może dorobić tekst w nagłówkach i sortowanie? coś jak w tabelach, na stronie głównej i w szczegółowej tabeli, może jakoś logikę sortowania wydzielić do osobnego, generycznego komponentu? */}
      {/* TODO: zrobić jakiś MatchesTableUiContainer czy coś takiego i tam decydować czy mobileTable czy zwykła */}
      {/* TODO: spróbować scalić wszystkie MatchesTable do jednego komponentu może? */}
      <table className={styles.matches_table}>
        <thead>
          <tr>
            <th className={teamNameClassName} />
            <th className={goalsClassName} />
            <th className={teamNameClassName} />
            {withStatusAndPlayerStatistics && <th className={eventsClassName} />}
            <th className={commentClassName} />
            <th className={dateClassName} />
            <th className={timeClassName} />
            {withStatus && <th className={statusClassName} />}
          </tr>
        </thead>
        <tbody>{matchesRows}</tbody>
      </table>
    </ContentRevealer>
  );
};

MatchesTable.propTypes = propTypes;

export default memo(MatchesTable);
