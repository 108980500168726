import { memo } from 'react';
import styled from 'styled-components';

const StyledFooter = styled.footer`
  padding: 1.25rem 0.75rem;
  background-color: ${({ theme }) => theme.accent};
  color: ${({ theme }) => theme.light};
  font-size: 1.125rem;
  text-align: center;
  line-height: 130%;
`;

export default memo(StyledFooter);
