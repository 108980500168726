import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

// import { useAppDispatch, useAppSelector } from 'store';
import { supportedLanguages } from 'consts/language';
// import urls from 'consts/urls';
import { useCurrentLanguage } from 'utils/customHooks';

// import { logout } from 'modules/auth/actions';
import MobileMenuLink from './MobileMenuLink';

const MobileUserActions = () => {
  // TODO: if auth works, uncomment

  const { i18n: translator, t } = useTranslation();
  // const dispatch = useAppDispatch();
  // const userData = useAppSelector(state => state.auth?.currentUser);
  const currentLanguage = useCurrentLanguage();

  const onLanguageChange = language => translator.changeLanguage(language);
  // const handleLogout = () => dispatch(logout());

  return (
    <>
      <MobileMenuLink
        label={`${t('Language')}: ${currentLanguage.displayName}`}
        subLinks={supportedLanguages.map(language => ({
          label: language.displayName,
          onClick: () => onLanguageChange(language.code),
          address: '#'
        }))}
      />

      {/* {userData ? (
        <MobileMenuLink
          label={`${t('LoggedAs')} ${userData.login || userData.email}!`}
          subLinks={[
            {
              label: t('LogOut'),
              onClick: handleLogout,
              address: '#'
            }
          ]}
        />
      ) : (
        <MobileMenuLink address={urls.login} label={t('Login')} />
      )} */}
    </>
  );
};

export default memo(MobileUserActions);
