import React, { memo } from 'react';
import { useParams } from 'react-router-dom';

import { useAppSelector } from 'store';
import { useDispatchOnMountWithLocationMultiple } from 'utils/customHooks';

import {
  getBoardMembers,
  getContact,
  getDetails,
  getHistory,
  getPartners,
  getSponsors,
  getStadiumDescription,
  getTeams
} from '../actions';
import ClubDetailsPage from './ClubDetailsPage';

const ClubDetailsPageContainer = props => {
  const { id } = useParams();

  const club = useAppSelector(state => state.clubs[id]);

  const details = club?.details;
  const contact = club?.contact;
  const teams = club?.teams;
  const boardMembers = club?.boardMembers;
  const partners = club?.partners;
  const sponsors = club?.sponsors;
  const history = club?.history;
  const stadiumDescription = club?.stadiumDescription;

  useDispatchOnMountWithLocationMultiple([
    getDetails(id),
    getContact(id),
    getTeams(id),
    getBoardMembers(id),
    getPartners(id),
    getSponsors(id),
    getHistory(id),
    getStadiumDescription(id)
  ]);

  return (
    <ClubDetailsPage
      {...props}
      details={details}
      contact={contact}
      teams={teams}
      boardMembers={boardMembers}
      partners={partners}
      sponsors={sponsors}
      history={history}
      stadiumDescription={stadiumDescription}
    />
  );
};

export default memo(ClubDetailsPageContainer);
