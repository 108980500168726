import React, { memo } from 'react';
import cx from 'classnames';

import { EIcons } from 'consts/icons';

import StyledWrapper from './StyledWrapper';

type Props = {
  rotated?: boolean;
  icon: EIcons | string;
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
};

const Icon = ({ icon, disabled, className, onClick }: Readonly<Props>) => {
  const iconClassName = cx(icon, className);

  return <StyledWrapper className={iconClassName} onClick={onClick} disabled={disabled} />;
};

export default memo(Icon);
