import { memo } from 'react';

import PdfViewer from 'components/PdfViewer';
import FileLine from 'components/Files/FileLine';
import { RequestStatus } from 'models/common.models';
import ObjectUtils from 'utils/object.utils';
import { IFileModel } from './data/models';

type Props = {
  url: string;
  file?: IFileModel | null;
  requestStatus?: RequestStatus | null;
};

const DocumentPage = ({ url, file, requestStatus }: Readonly<Props>) => {
  if (requestStatus?.isLoading) {
    return 'loading'; // <ThreeRowsSkeletons />
  }

  if (ObjectUtils.isNotEmpty(file)) {
    return (
      <>
        <FileLine url={url} fileName={file!.fileName} base64Content={file!.base64Content} />
        <PdfViewer file={file!} />
      </>
    );
  }

  return 'No data'; // <StyledMatchesNoDataMessage>{t('NoData')}</StyledMatchesNoDataMessage>
};

export default memo(DocumentPage);
