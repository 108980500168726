import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { ESortDirections } from 'consts/ESortDirections';
import { icons } from 'consts/icons';
import { useIsDesktop } from 'utils/customHooks';

import styles from './TableHeader.module.scss';
import structure from './TableHeaderStructure';

const propTypes = {
  clearActiveIndex: PropTypes.func.isRequired,
  setActiveCellIndex: PropTypes.func.isRequired,
  sortDirection: PropTypes.string,
  sortedColumnIndex: PropTypes.number,
  sortColumn: PropTypes.func.isRequired
};

const TableHeader = ({
  clearActiveIndex,
  setActiveCellIndex,
  sortDirection,
  sortedColumnIndex,
  sortColumn
}) => {
  const isDesktop = useIsDesktop();

  const headerClassName = (className, headerIndex, withoutSort) =>
    cx(className, {
      [styles.active]: sortedColumnIndex === headerIndex && !!sortDirection,
      [styles.without_sort]: withoutSort,
      [styles.mobile]: !isDesktop
    });

  return (
    <tr className={styles.header_row} onMouseLeave={clearActiveIndex}>
      {structure.map((header, headerIndex) => (
        <th
          className={headerClassName(header.className, headerIndex, header.withoutSort)}
          onMouseEnter={() => setActiveCellIndex(headerIndex)}
          onClick={() => !header.withoutSort && sortColumn(headerIndex)}
          title={header.title}
          key={headerIndex}
        >
          <span className={styles.header_text}>
            {headerIndex === sortedColumnIndex && sortDirection && (
              <i
                className={
                  sortDirection === ESortDirections.ascending ? icons.arrowUp : icons.arrowDown
                }
              />
            )}
            {header.content}
          </span>
        </th>
      ))}
    </tr>
  );
};

TableHeader.propTypes = propTypes;

export default memo(TableHeader);
