import { memo } from 'react';
import styled from 'styled-components';

import withColors from 'hoc/withColors';

const Text = styled.p`
  color: var(--color);
`;

export default memo(withColors(Text));
