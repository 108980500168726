import React, { memo } from 'react';
import PropTypes from 'prop-types';

import styles from './CompaniesList.module.scss';
import SingleCompany from './SingleCompany';

const propTypes = {
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      logo: PropTypes.string,
      websiteUrl: PropTypes.string
    }).isRequired
  ).isRequired
};

const CompaniesList = ({ companies }) => (
  <div className={styles.companies_list}>
    {companies.map(company => (
      <SingleCompany {...company} key={company.id} />
    ))}
  </div>
);

CompaniesList.propTypes = propTypes;

export default memo(CompaniesList);
