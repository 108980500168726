import React from 'react';
import { useTranslation } from 'react-i18next';
import { RequestStatus } from 'models/common.models';

import DebutMatch from 'components/DebutMatch';
import PersonDetailsCard from 'components/PersonDetailsCard';
import SingleInformationMultiline from 'components/SingleInformationMultiline';

import { IRefereeDebutMatchModel, IRefereeDetailsModel } from './data/models';

type Props = {
  details?: IRefereeDetailsModel | null;
  photo?: string | null;
  debutMatch?: IRefereeDebutMatchModel | null;

  getDetailsRequestStatus?: RequestStatus | null;
  getPhotoRequestStatus?: RequestStatus | null;
  getDebutMatchRequestStatus?: RequestStatus | null;
};

const RefereeDetailsCard = ({
  details,
  photo,
  debutMatch,
  getDetailsRequestStatus,
  getPhotoRequestStatus,
  getDebutMatchRequestStatus
}: Readonly<Props>) => {
  const { t } = useTranslation();

  return (
    <PersonDetailsCard
      photo={photo}
      firstName={details?.firstName}
      lastName={details?.lastName}
      badgeLabel={t('Referee')}
      isPhotoLoading={getPhotoRequestStatus?.isLoading}
      isDetailsLoading={getDetailsRequestStatus?.isLoading}
    >
      <SingleInformationMultiline
        title={t('SeniorMatchDebut')}
        value={debutMatch ? <DebutMatch debutMatch={debutMatch} /> : <div>{t('NoData')}</div>}
        isLoading={getDebutMatchRequestStatus?.isLoading}
      />
    </PersonDetailsCard>
  );
};

export default RefereeDetailsCard;
