import React, { memo } from 'react';
import PropTypes from 'prop-types';

import styles from './CardList.module.scss';

const propTypes = {
  children: PropTypes.node
};

const CardList = ({ children = [] }) => <div className={styles.list}>{children}</div>;

CardList.propTypes = propTypes;

export default memo(CardList);
