import React, { memo, useState } from 'react';

import Dropdown from 'components/Dropdown';

import ArrowButton from '../ArrowButton/ArrowButton';
import { defaultSlidesQuantity } from '../Carousel/Carousel.consts';
import styles from './DropdownWithArrows.module.scss';

type Item = {
  optionName: string;
};

type Props = {
  dropdownId: string;
  dropdownTitle?: string;
  items: Item[];
  lastItemIndex: number;
  selectedItemIndex: number;
  setSelectedItem: React.Dispatch<Item>;
  setSelectedItemIndex: React.Dispatch<number>;
};

const DropdownWithArrows = ({
  dropdownId,
  dropdownTitle,
  items,
  lastItemIndex,
  selectedItemIndex,
  setSelectedItem,
  setSelectedItemIndex
}: Readonly<Props>) => {
  const showArrowButtons = items.length >= defaultSlidesQuantity;

  const firstItemIndex = 0;

  const [showPreviousArrowButton, setShowPreviousArrowButton] = useState(true);
  const [showNextArrowButton, setShowNextArrowButton] = useState(false);

  const handleChangeItem = (newIndex: number): void => {
    setSelectedItemIndex(newIndex);
    setSelectedItem(items[newIndex]);
    setShowPreviousArrowButton(showArrowButtons && newIndex < lastItemIndex);
    setShowNextArrowButton(showArrowButtons && newIndex > firstItemIndex);
  };

  const handlePrevClick = () => {
    const newIndex = selectedItemIndex + 1 > lastItemIndex ? lastItemIndex : selectedItemIndex + 1;
    handleChangeItem(newIndex);
  };

  const handleNextClick = () => {
    const newIndex =
      selectedItemIndex - 1 < firstItemIndex ? firstItemIndex : selectedItemIndex - 1;
    handleChangeItem(newIndex);
  };

  const handleDropdownChange = () => {
    const dropdown = document.getElementById(dropdownId) as HTMLSelectElement;
    handleChangeItem(dropdown.selectedIndex);
  };

  const dropdownOptions = items.map(x => ({ label: x.optionName, value: x.optionName }));

  return (
    <div className={styles.arrows}>
      {/* TODO: usunąć ten komponent? <ArrowButton2 onClick={handlePrevClick} /> */}
      <ArrowButton rotated disabled={!showPreviousArrowButton} onClick={handlePrevClick} />
      <Dropdown
        id={dropdownId}
        title={dropdownTitle}
        value={selectedItemIndex}
        selectedItemIndex={selectedItemIndex}
        options={dropdownOptions}
        onChange={handleDropdownChange}
      />
      <ArrowButton disabled={!showNextArrowButton} onClick={handleNextClick} />
    </div>
  );
};

export default memo(DropdownWithArrows);
