import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';

const propTypes = {
  children: PropTypes.node.isRequired
};

const FormSubmitButton = ({ children, ...others }) => (
  <Button type="submit" {...others}>
    {children}
  </Button>
);

FormSubmitButton.propTypes = propTypes;

export default memo(FormSubmitButton);
