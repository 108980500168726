/* eslint-disable global-require */

// import './wdyr';

import 'regenerator-runtime/runtime';
import 'styles/main.scss';
import './i18n';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { bindActionCreators } from '@reduxjs/toolkit';
// import { BrowserTracing } from '@sentry/tracing'; // TODO: to check
// TODO: to check
// TODO: other integrations https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/plugin/
import * as Sentry from '@sentry/react';
import { ThemeProvider } from 'styled-components'; // import { ThemeProvider } from "@mui/material/styles";

import { persistor, store } from 'store';
import config from 'config/config';
import { isNotDevelopmentEnvironment } from 'utils/environments.utils';
import { get as getArticlesList } from 'modules/articles/actions';
import {
  getBoardMembersForMyClub,
  getContactForMyClub,
  getDetailsForMyClub,
  getHistoryForMyClub,
  getPartnersForMyClub,
  getSponsorsForMyClub,
  getStadiumDescriptionForMyClub,
  getTeamsForMyClub
} from 'modules/club/my-club-actions';
import {
  getCurrentCompetitionsSchedule,
  getCurrentCompetitionsTableSeasonFull,
  getLeagueNameForMyLeague,
  getSeasonNameForCurrentSeason
} from 'modules/competitions/actions';
import {
  getArticles,
  getPreviousMatch,
  getCurrentMatch,
  getNextMatch,
  getTable
} from 'modules/home/actions';
import { getSystemParameters, getYouthTeams } from 'modules/system-parameters/actions';
import {
  getCoachesForMyTeamInCurrentSeason,
  getDetailsForMyTeam,
  getMatchesForMyTeamInCurrentSeason,
  getPlayersWithMatchesAndEventsForMyTeamInCurrentSeason,
  getSeasonsListForMyTeam
  // getSeasonsAdvancedListForMyTeam
} from 'modules/team/my-team-actions';

// TODO: if PWA works, uncomment
// import themeVariables from 'styles/variables.scss';
// TODO: pliki 'styles/variables.scss' i 'styles/variables.module.scss' są identyczne
// tylko po podniesieniu wersji paczki react - scripts jak również przy użyciu vitejs
// jeśli w nazwie nie ma 'module' to nie działa,
// może wiąże się to też z usunięciem paczki 'node-sass' i dodaniem 'sass'
import themeVariables from 'styles/variables.module.scss';

// TODO: should be like:
// const theme = {
//   black:#000000,
//   white:#ffffff
//   red:#f64406
//   gray:#333333,
//   green:#3bbd47
// }
// and then 'theme' instead of 'themeVariables' pass to the ThemeProvider
import App from './App';
import * as serviceWorker from './serviceWorker';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
store.dispatch(getSystemParameters() as any); // TODO: change as any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
store.dispatch(getYouthTeams() as any); // TODO: change as any
// store.dispatch(getArticlesList());

// // note: home page
// store.dispatch(getArticles());
// store.dispatch(getNextMatch());
// store.dispatch(getPartners());
// store.dispatch(getPreviousMatch());
// store.dispatch(getSponsors());
// store.dispatch(getTable());

// // note: competitions
// store.dispatch(getLeagueNameForMyLeague());
// store.dispatch(getSeasonNameForCurrentSeason());
// store.dispatch(getCurrentCompetitionsTableSeasonFull());
// store.dispatch(getCurrentCompetitionsSchedule());
// // store.dispatch(getCurrentCompetitionsTableAutumnRoundAsGuest());
// // store.dispatch(getCurrentCompetitionsTableAutumnRoundAsHost());
// // store.dispatch(getCurrentCompetitionsTableAutumnRoundFull());
// // store.dispatch(getCurrentCompetitionsTableSeasonAsGuest());
// // store.dispatch(getCurrentCompetitionsTableSeasonAsHost());
// // store.dispatch(getCurrentCompetitionsTableSpringRoundAsGuest());
// // store.dispatch(getCurrentCompetitionsTableSpringRoundAsHost());
// // store.dispatch(getCurrentCompetitionsTableSpringRoundFull());

// // note: team
// store.dispatch(getCoachesForMyTeamInCurrentSeason());
// store.dispatch(getCompetitionsListForMyTeam());
// store.dispatch(getDetailsForMyTeam());
// store.dispatch(getMatchesForMyTeamInCurrentSeason());
// store.dispatch(getPlayersWithMatchesAndEventsForMyTeamInCurrentSeason()),

// // note: club
// store.dispatch(getBoardMembersForMyClub());
// store.dispatch(getContactForMyClub());
// store.dispatch(getHistoryForMyClub());
// store.dispatch(getPartnersForMyClub());
// store.dispatch(getSponsorsForMyClub());

// note: takie rozwiązanie żeby pierwsze pobrało dane na stronę główną, a potem kolejne dane w tle,
// jeśli było wszystko tak jak wyżej zakomentowane to miałem wrażenie że dane na stronę główną
// ładują się trochę za długo

// import { Document, Page, pdfjs } from 'react-pdf';

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.mjs',
//   import.meta.url
// ).toString();

const dispatchChaining = () => async dispatch => {
  await Promise.all([
    dispatch(getArticles()),
    dispatch(getPreviousMatch()),
    dispatch(getCurrentMatch()),
    dispatch(getNextMatch()),
    dispatch(getTable())
  ]);

  await Promise.all([
    dispatch(getArticlesList()),

    // note: competitions
    dispatch(getLeagueNameForMyLeague()),
    dispatch(getSeasonNameForCurrentSeason()),
    dispatch(getCurrentCompetitionsTableSeasonFull()),
    dispatch(getCurrentCompetitionsSchedule()),

    // note: team
    dispatch(getCoachesForMyTeamInCurrentSeason()),
    dispatch(getSeasonsListForMyTeam()),
    // TODO: check if this working: dispatch(getSeasonsAdvancedListForMyTeam()),

    dispatch(getDetailsForMyTeam()),
    dispatch(getMatchesForMyTeamInCurrentSeason()),
    dispatch(getPlayersWithMatchesAndEventsForMyTeamInCurrentSeason()),

    // note: club
    dispatch(getDetailsForMyClub()),
    dispatch(getContactForMyClub()),
    dispatch(getTeamsForMyClub()),
    dispatch(getBoardMembersForMyClub()),
    dispatch(getPartnersForMyClub()),
    dispatch(getSponsorsForMyClub()),
    dispatch(getHistoryForMyClub()),
    dispatch(getStadiumDescriptionForMyClub())

    // TODO: data for youthTeams
  ]);

  // TODO: czy trzeba done?
  // return store.dispatch(updateDone());
};

const actions = bindActionCreators({ dispatchChaining }, store.dispatch);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(actions.dispatchChaining() as any).then(() => {}); // TODO: usunąć as any i poprawić błąd

const container = document.getElementById('root') as HTMLElement; // TODO: or document.querySelector('#root') as HTMLElement
const root = createRoot(container);
root.render(
  // TODO: StrictMode - prawdopodobnie trzeba usunąć jeśli będą działały powiadomienia push, bo ze StrictMode wyświetlają się dwa razy, dlaczego?
  // TODO: StrictMode - wykonują się dwa razy te same requesty po przejściu na stronę, dlaczego?
  // <StrictMode>
  //   <MuiThemeProvider theme={theme}>
  //     <StyledThemeProvider theme={theme}>
  //       <LocalizationProvider dateAdapter={DateAdapter}>
  //         <CssBaseline />
  <ThemeProvider theme={themeVariables}>
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <App />
      </PersistGate>
    </Provider>
  </ThemeProvider>
  //       </LocalizationProvider>
  //     </StyledThemeProvider>
  //   </MuiThemeProvider>
  // </StrictMode>
);

if (isNotDevelopmentEnvironment()) {
  // TODO: może rozszerzyć konfiguracje: https://www.npmjs.com/package/@sentry/browser

  // TODO: jeśli dane z backendu to zrobić coś takiego jak jest zakomentowane poniżej:
  // configAPI.apiConfigGet().then(config => {
  //   if (!config.data.sentry?.dsn) {
  //     return;
  //   }

  Sentry.init({
    dsn: config.sentryDns,
    debug: false,
    tracesSampleRate: 1.0,
    environment: config.sentryEnvironment,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      }),
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true
      }),
      Sentry.captureConsoleIntegration()
    ]
  });
  // });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// TODO:
// reportWebVitals();

// if you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// TODO: if PWA works, uncomment
// serviceWorker.register();
