import breakpoints from 'styles/breakpoints.module.scss';

const sizes = {
  mobileWidth: breakpoints.mobileWidth,
  navbarMobileWidth: breakpoints.navbarMobileWidth,
  bigMobileWidth: breakpoints.bigMobileWidth,
  smallLaptopWidth: breakpoints.smallLaptopWidth,
  laptopWidth: breakpoints.laptopWidth
};

export const mediaValues = {
  mobileAndDown: `(max-width: ${sizes.mobileWidth})`,
  navbarMobileAndDown: `(max-width: ${sizes.navbarMobileWidth})`,
  navbarMobileAndUp: `(min-width: ${sizes.navbarMobileWidth})`,
  bigMobileAndDown: `(max-width: ${sizes.bigMobileWidth})`,
  bigMobileAndUp: `(min-width: ${sizes.bigMobileWidth})`,
  smallLaptopAndDown: `(max-width: ${sizes.smallLaptopWidth})`,
  laptopAndDown: `(max-width: ${sizes.laptopWidth})`
};
