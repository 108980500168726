import PropTypes from 'prop-types';

export const simpleTeamPropTypes = {
  id: PropTypes.string,
  logo: PropTypes.string,
  name: PropTypes.string,
  ageGroupName: PropTypes.string
};

export const simpleTeamPropTypesShape = PropTypes.exact({ ...simpleTeamPropTypes });
