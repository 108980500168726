import React from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { useAppDispatch } from 'store';
import { icons } from 'consts/icons';
import copyToClipboard from 'utils/copyToClipboard';
import Skeleton from 'components/Skeleton';
import { displayToastr } from 'components/Toastr/actions';
import ToastTypes from 'components/Toastr/ToastTypes';

import Link from '../Links/Link';
import styles from './SingleInformationMultiline.module.scss';
import { StyledTitle, StyledValue, StyledWrapper } from './SingleInformationMultiline.styles';

type Props = {
  title?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
  link?: {
    to: string;
    title: string;
  };
  isLoading?: boolean;
  isCopyEnabled?: boolean;
  isBiggerText?: boolean;
};

const SingleInformationMultiline = ({
  title,
  value,
  link,
  isLoading,
  isCopyEnabled,
  isBiggerText
}: Readonly<Props>) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const styledValueTag = link ? Link : 'div';

  const iconClassName = cx(styles.information_icon, icons.copy);

  const onValueClick = () => {
    copyToClipboard(value);
    dispatch(
      displayToastr(`${t('SuccessfullyCopiedToTheClipboard')}: ${title || value}`, ToastTypes.info)
    );
  };

  let itemToRender: React.ReactNode;

  if (isLoading) {
    itemToRender = <Skeleton />;
  } else if (isCopyEnabled) {
    itemToRender = (
      <StyledValue as={styledValueTag} {...link} isBiggerText={isBiggerText} onClick={onValueClick}>
        {value}
        <i className={iconClassName} />
      </StyledValue>
    );
  } else {
    itemToRender = (
      <StyledValue as={styledValueTag} {...link} isBiggerText={isBiggerText}>
        {value}
      </StyledValue>
    );
  }

  return (
    <StyledWrapper>
      {title && <StyledTitle>{title}</StyledTitle>}
      {itemToRender}
    </StyledWrapper>
  );
};

export default SingleInformationMultiline;
