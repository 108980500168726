import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import CompaniesCarousel from 'components/Carousel/CompaniesCarousel';

import styles from './CompaniesList.module.scss';

const propTypes = {
  companies: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      logo: PropTypes.string,
      websiteUrl: PropTypes.string
    })
  ),
  title: PropTypes.string.isRequired,
  className: PropTypes.string
};

const CompaniesList = ({ companies = [], title, className }) => {
  const companyContainerClassName = cx(styles.company_container, className);

  return (
    <div className={companyContainerClassName}>
      <h2 className={styles.title}>{title}</h2>
      <CompaniesCarousel companies={companies} />
    </div>
  );
};

CompaniesList.propTypes = propTypes;

export default CompaniesList;
