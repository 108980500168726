import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'store';
import { useDispatchOnMount } from 'utils/customHooks';
import { useGetMyClubId } from 'utils/systemParameterProvider';

import { getSponsorsForMyClub } from '../my-club-actions';
import CompaniesPage from './CompaniesPage';

const SponsorsPageContainer = props => {
  const { t } = useTranslation();

  const myClubId = useGetMyClubId();

  const sponsors = useAppSelector(state => state.clubs[myClubId]?.sponsors);

  useDispatchOnMount(getSponsorsForMyClub());

  return <CompaniesPage {...props} companies={sponsors} title={t('Sponsors')} />;
};

export default SponsorsPageContainer;
