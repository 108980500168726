import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import Card from 'components/Card';
import Column from 'components/Column';
import Loader from 'components/Loader';

import styles from './CardMatch.module.scss';
import placeholderStyles from './CardMatchPlaceholder.module.scss';

const propTypes = {
  nextMatch: PropTypes.bool.isRequired,
  titleBackground: PropTypes.oneOf(['dark-opaque', 'green']).isRequired
};

const CardMatchPlaceholder = ({ nextMatch, titleBackground }) => {
  const { t } = useTranslation();

  const logoClassName = cx(
    placeholderStyles.loading,
    placeholderStyles.logo,
    styles.match_logo,
    styles.match_logo_container
  );
  const scoreClassName = cx(placeholderStyles.loading, placeholderStyles.score, styles.match_score);
  const dividerClassName = cx(
    placeholderStyles.loading,
    placeholderStyles.score,
    styles.match_score_divider,
    placeholderStyles.score_thin
  );

  return (
    <Card
      renderTitle={() => (nextMatch ? t('NextMatch') : t('PreviousMatch'))}
      titleBackground={titleBackground}
    >
      <div className={styles.match}>
        <Column className={styles.match_left}>
          <div className={`${placeholderStyles.loading} ${placeholderStyles.time}`} />
          <div className={logoClassName}>
            <div className={placeholderStyles.loader_container}>
              <Loader />
            </div>
          </div>
          <div className={scoreClassName} />
        </Column>
        <Column className={styles.match_middle}>
          <div className={dividerClassName} />
        </Column>
        <Column className={styles.match_right}>
          <div className={`${placeholderStyles.loading} ${placeholderStyles.time}`} />
          <div className={logoClassName}>
            <div className={placeholderStyles.loader_container}>
              <Loader />
            </div>
          </div>
          <div className={scoreClassName} />
        </Column>
      </div>
    </Card>
  );
};

CardMatchPlaceholder.propTypes = propTypes;

export default memo(CardMatchPlaceholder);
