import actionTypes from './actionTypes';

const initialState = {};

export default function matchesPageReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.STORE_MATCH_DETAILS:
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          details: { id: action.data.id, ...action.data.details }
        }
      };
    case actionTypes.STORE_MATCHES_BETWEEN_TEAMS_DETAILS:
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          matchesBetweenTeams: action.data.matchesBetweenTeams
        }
      };
    default:
      return state;
  }
}
