import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { colorArray, EColors } from 'consts/colors';

import styles from './withColors.module.scss';

const propTypes = {
  className: PropTypes.string,
  colorType: PropTypes.oneOf(colorArray)
};

const defaultProps = {
  colorType: EColors.green
};

// TODO: Vorbert -> poprzerabiać komponenty, tak by korzystały z tego HOC-a

const withBasicColors = Component => {
  const wrappedComponent = props => {
    const colorsClassName = cx(props.className, styles[`colorType--${props.colorType}`]);

    return <Component {...props} className={colorsClassName} />;
  };

  wrappedComponent.propTypes = propTypes;
  wrappedComponent.defaultProps = defaultProps;

  return wrappedComponent;
};

export default withBasicColors;
