import { memo } from 'react';

import { useGetFilesQuery } from './data';
import DocumentsPage from './DocumentsPage';

const DocumentsPageContainer = () => {
  const files = useGetFilesQuery();

  return <DocumentsPage files={files} />;
};

export default memo(DocumentsPageContainer);
