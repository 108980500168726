import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import TextReavealer from '../TextReavealer';
import styles from './WorkingOnWebsiteText.module.scss';

// TODO: Vorbert -> przerobić na styled-component
const WorkingOnWebsiteText = () => {
  const { t } = useTranslation();

  return (
    <TextReavealer text={t('SiteIsCurrentlyBeingCreated')} className={styles.revealer_container} />
  );
};

export default memo(WorkingOnWebsiteText);
