import React, { memo, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import cx from 'classnames';

import { useAppDispatch, useAppSelector } from 'store';
import { isExactCurrentSubRoute } from 'utils/utils';

import styles from './MenuLink.module.scss';
import Submenu from './Submenu';

const propTypes = {
  address: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  subLinks: PropTypes.arrayOf(PropTypes.object),
  external: PropTypes.bool
};

const MenuLink = ({ address, label, subLinks, external, ...others }) => {
  const [visibleSubmenu, setVisibleSubmenu] = useState(false);
  const [isAnySubLinkActive, setIsAnySubLinkActive] = useState(false);
  const activeLinkUrl = useAppSelector(state => get(state, 'navbar.activeLinkUrl'));
  const dispatch = useAppDispatch();

  const isCurrentSubLinkRoute = () =>
    subLinks &&
    !isEmpty(
      [...subLinks].filter(subLink => isExactCurrentSubRoute(activeLinkUrl, subLink.address))
    );

  const linkClassName = cx(styles.link, {
    [styles.hovered]: visibleSubmenu,
    [styles.active]: isAnySubLinkActive && !!activeLinkUrl
  });

  const activeLinkClassName = cx({
    [styles.active]: !subLinks
  });

  useEffect(() => {
    if (activeLinkUrl) {
      setIsAnySubLinkActive(isCurrentSubLinkRoute());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLinkUrl, dispatch, address]);

  return (
    <li
      className={styles.link_container}
      onMouseEnter={() => setVisibleSubmenu(true)}
      onMouseLeave={() => setVisibleSubmenu(false)}
    >
      {!external ? (
        <NavLink
          {...others}
          to={address}
          title={(!subLinks && label) || undefined}
          className={({ isActive }) => linkClassName + (isActive ? ` ${activeLinkClassName}` : '')}
        >
          {label}
        </NavLink>
      ) : (
        <a target="_blank" rel="noreferrer" href={address} title={label} className={linkClassName}>
          {label}
        </a>
      )}
      {subLinks && <Submenu subLinksData={subLinks} visible={visibleSubmenu} />}
    </li>
  );
};

MenuLink.propTypes = propTypes;

export default memo(MenuLink);
