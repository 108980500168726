import React, { memo } from 'react';
import PropTypes from 'prop-types';

import StyledIcon from './StyledIcon';
import StyledWrapper from './StyledWrapper';

const propTypes = {
  children: PropTypes.any.isRequired,
  icon: PropTypes.string.isRequired
};

const InfoWithIcon = ({ icon, children, ...passedProps }) => (
  <StyledWrapper {...passedProps}>
    <StyledIcon icon={icon} />
    {children}
  </StyledWrapper>
);

InfoWithIcon.propTypes = propTypes;

export default memo(InfoWithIcon);
