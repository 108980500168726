// TODO: compare with method from utils.js
export const scrollToElement = (
  element: HTMLElement,
  scrollToElementStart = true,
  offset = 60
): void =>
  // element?.scrollIntoView({ behavior: 'smooth', block: scrollToElementStart ? 'start' : 'end' });
  window.scrollTo({
    behavior: 'smooth',
    top: element?.getBoundingClientRect().top - document.body.getBoundingClientRect().top - offset
  });

export const scrollTo = (top = 0, left = 0, behavior: ScrollBehavior = 'smooth') =>
  window.scrollTo({ top, left, behavior });
