import React, { memo } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import InnerContentWrapper from 'components/InnerContentWrapper';
import LoadingPageData from 'components/LoadingPageData';
import PageHeader from 'components/PageHeader';

import CompaniesList from './CompaniesList';

const propTypes = {
  companies: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string,
      name: PropTypes.string,
      logo: PropTypes.string,
      websiteUrl: PropTypes.string
    })
  ),
  title: PropTypes.string.isRequired
};

const CompaniesPage = ({ companies, title }) => (
  <InnerContentWrapper subpageWrap>
    <PageHeader title={title} />
    {!isEmpty(companies) ? <CompaniesList companies={companies} /> : <LoadingPageData />}
  </InnerContentWrapper>
);

CompaniesPage.propTypes = propTypes;

export default memo(CompaniesPage);
