import React, { memo } from 'react';
import PropTypes from 'prop-types';

import StyledWrapper from './StyledWrapper';

const propTypes = {
  children: PropTypes.node.isRequired,
  horizontalOffset: PropTypes.bool,
  centerContent: PropTypes.bool,
  subpageWrap: PropTypes.bool
};

const InnerContentWrapper = ({ children, ...passedProps }) => (
  <StyledWrapper {...passedProps}>{children}</StyledWrapper>
);

InnerContentWrapper.propTypes = propTypes;

export default memo(InnerContentWrapper);
