/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import config from 'config/config';
import { isNotProductionEnvironment } from 'utils/environments.utils';

import { IPlayerDetailsResponse } from './models/playerResponse.models';

const baseApiUrl = isNotProductionEnvironment() ? config.apiUrl : `${location.origin}/api/`;

export const playersApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: `${baseApiUrl}client/player/` }),
  reducerPath: 'players',
  endpoints: builder => ({
    getDetails: builder.query<IPlayerDetailsResponse, string>({
      query: id => `${id}`
    })
    // updatePokemon: builder.mutation({
    //   query: ({ name, patch }) => ({
    //     url: `pokemon/${name}`,
    //     // When performing a mutation, you typically use a method of
    //     // PATCH/PUT/POST/DELETE for REST endpoints
    //     method: 'PATCH',
    //     // fetchBaseQuery automatically adds `content-type: application/json` to
    //     // the Headers and calls `JSON.stringify(patch)`
    //     body: patch
    //   })
    // })
  })
});

export const { useGetDetailsQuery } = playersApi;
