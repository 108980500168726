import React, { memo } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import Article from 'components/Article';
import ArticlePlaceholder from 'components/ArticlePlaceholder';
import InnerContentWrapper from 'components/InnerContentWrapper';

const propTypes = {
  articleDetails: PropTypes.shape({
    photo: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.string,
    createdDate: PropTypes.string,
    author: PropTypes.string
  })
};

const ArticleDetailsPage = ({ articleDetails }) => (
  <InnerContentWrapper subpageWrap>
    {!isEmpty(articleDetails) ? (
      <Article {...articleDetails} />
    ) : (
      <ArticlePlaceholder withCreationData />
    )}
  </InnerContentWrapper>
);

ArticleDetailsPage.propTypes = propTypes;

export default memo(ArticleDetailsPage);
