import React, { memo } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { boardMemberDetailsPropTypes } from 'propTypes';
import InnerContentWrapper from 'components/InnerContentWrapper';
import LoadingPageData from 'components/LoadingPageData';

import BoardMemberDetailsCard from './BoardMemberDetailsCard';

const propTypes = {
  match: PropTypes.object.isRequired,
  boardMemberDetails: boardMemberDetailsPropTypes
};

const BoardMemberDetailsPage = ({ boardMemberDetails }) => (
  <InnerContentWrapper centerContent subpageWrap>
    {!isEmpty(boardMemberDetails) ? (
      <BoardMemberDetailsCard {...boardMemberDetails} />
    ) : (
      <LoadingPageData />
    )}
  </InnerContentWrapper>
);

BoardMemberDetailsPage.propTypes = propTypes;

export default memo(BoardMemberDetailsPage);
