import React, { memo } from 'react';

import { icons } from 'consts/icons';
import { CircleInformation } from 'components/CircleInformation';

const MaintancePage = () => (
  <CircleInformation
    icon={icons.tools}
    messageTranslationKey="SiteIsUnderMaintanceWeAreSorryForTheInconvenience"
  />
);

export default memo(MaintancePage);
