import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { icons } from 'consts/icons';
import Column from 'components/Column';
import IconButton from 'components/IconButton';

import styles from './ErrorPage.module.scss';

const propTypes = {
  errorCode: PropTypes.number,
  description: PropTypes.string
};

const ErrorPage = ({ errorCode = 404, description }) => {
  const { t } = useTranslation();

  return (
    <Column className={styles.info_container}>
      <h1 className={styles.info_error_code}>{errorCode}</h1>
      <p className={styles.info_description}>
        {description || t('PageThatYouWereSearchingForDoesNotExistOrHasBeenRelocated')}
      </p>
      <IconButton
        title={t('ReturnToTheHomePage')}
        to="/"
        icon={icons.return}
        className={styles.info_button}
        color="white"
      >
        {t('ReturnToTheHomePage')}
      </IconButton>
    </Column>
  );
};

ErrorPage.propTypes = propTypes;

export default memo(ErrorPage);
