import actionTypes from './actionTypes';

const initialState = [];

export default function galleryPageReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.STORE_GALLERY_DATA:
      return [...action.data];
    default:
      return state;
  }
}
