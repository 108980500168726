import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { getArrayWithLength } from 'utils/utils';

import styles from './Article/Article.module.scss';
import headerStyles from './Article/ArticleHeader.module.scss';
import imageStyles from './Article/ArticlePhoto.module.scss';
import placeholderStyles from './ArticlePlaceholder.module.scss';
import Row from './Row';

const propTypes = {
  withCreationData: PropTypes.bool,
  linesOfText: PropTypes.number
};

const ArticlePlaceholder = ({ withCreationData, linesOfText = 25 }) => {
  const imageClassName = cx(placeholderStyles.image, imageStyles.image);
  const headerInfoClassName = cx(
    headerStyles.header_info_container,
    placeholderStyles.info_container
  );
  const headerTitleClassName = cx(headerStyles.header_title, placeholderStyles.title);
  const textLineClassName = cx(styles.content, placeholderStyles.text);

  return (
    <>
      <div className={imageClassName} />
      <div className={headerStyles.header}>
        {withCreationData && (
          <Row className={headerInfoClassName}>
            <div />
            <div />
          </Row>
        )}
        <h2 className={headerTitleClassName}>&nbsp;</h2>
      </div>
      {getArrayWithLength(linesOfText).map((_line, lineIndex) => (
        <p className={textLineClassName} key={lineIndex} />
      ))}
    </>
  );
};

ArticlePlaceholder.propTypes = propTypes;

export default memo(ArticlePlaceholder);
