import React, { memo } from 'react';
import Link from 'components/Links/Link';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';

import fieldNames from 'consts/fieldNames';
import formNames from 'consts/formNames';
import { isEmail, isRequired } from 'utils/formValidators';
import MyInputEmailField from 'components/reduxFormFields/MyInputEmailField';

import FormButtonsContainer from '../FormButtonsContainer';
import FormSubmitButton from '../FormSubmitButton';
import Form from '../StyledForm';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
  dirty: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired
};

const ResetPasswordForm = ({ handleSubmit, submitting, submitSucceeded, dirty, valid }) => {
  const { t } = useTranslation();

  const buttonIsDisabled = submitting || !dirty || !valid;

  return (
    <>
      <Form handleSubmit={handleSubmit} isVisible>
        <legend>{t('ResetPasswordInformation')}</legend>
        <MyInputEmailField
          label={t('Email')}
          placeholder={t('Email')}
          name={fieldNames.resetPasswordForm.email}
        />
        <FormButtonsContainer
          renderSubmitButton={() => (
            <FormSubmitButton disabled={buttonIsDisabled}>{t('ResetPassword')}</FormSubmitButton>
          )}
        />
      </Form>
      {submitSucceeded && (
        <>
          <p>{t('ResetPasswordSend')}</p>
          <Link to="/">{t('BackToMainPage')}</Link>
        </>
      )}
    </>
  );
};

ResetPasswordForm.propTypes = propTypes;

const validate = values => {
  const errors = {};

  isRequired(errors, values, fieldNames.resetPasswordForm.email, i18n.t('FieldRequired'));

  isEmail(errors, values, fieldNames.resetPasswordForm.email, i18n.t('FieldMustBeValidEmail'));

  return errors;
};

export default reduxForm({
  form: formNames.resetPasswordForm,
  validate
})(memo(ResetPasswordForm));
