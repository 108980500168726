import PropTypes from 'prop-types';

import { playerMatchPropTypesShape } from './player-match';

export const playerStatisticsPropTypes = {
  minutesCount: PropTypes.number,

  matches: playerMatchPropTypesShape,
  matchesCount: PropTypes.number,

  matchesStartedInFirstSquad: playerMatchPropTypesShape,
  matchesStartedInFirstSquadCount: PropTypes.number,

  matchesStartedAsReserved: playerMatchPropTypesShape,
  matchesStartedAsReservedCount: PropTypes.number,

  matchesFullPlayed: playerMatchPropTypesShape,
  matchesFullPlayedCount: PropTypes.number,

  matchesStartedAsReservedAndNotEntering: playerMatchPropTypesShape,
  matchesStartedAsReservedAndNotEnteringCount: PropTypes.number,

  matchesWhereEntering: playerMatchPropTypesShape,
  matchesWhereEnteringCount: PropTypes.number,

  matchesWhereGoingDown: playerMatchPropTypesShape,
  matchesWhereGoingDownCount: PropTypes.number,

  matchesWithGoals: playerMatchPropTypesShape,
  matchesWithGoalsCount: PropTypes.number,
  goalsCount: PropTypes.number,

  matchesWithOwnGoals: playerMatchPropTypesShape,
  matchesWithOwnGoalsCount: PropTypes.number,
  ownGoalsCount: PropTypes.number,

  matchesWithPenaltyGoals: playerMatchPropTypesShape,
  matchesWithPenaltyGoalsCount: PropTypes.number,
  penaltyGoalsCount: PropTypes.number,

  matchesWithAssists: playerMatchPropTypesShape,
  matchesWithAssistsCount: PropTypes.number,
  assistsCount: PropTypes.number,

  matchesWithYellowCards: playerMatchPropTypesShape,
  matchesWithYellowCardsCount: PropTypes.number,
  yellowCardsCount: PropTypes.number,

  matchesWithSecondYellowCards: playerMatchPropTypesShape,
  matchesWithSecondYellowCardsCount: PropTypes.number,
  secondYellowCardsCount: PropTypes.number,

  matchesWithRedCards: playerMatchPropTypesShape,
  matchesWithRedCardsCount: PropTypes.number,
  redCardsCount: PropTypes.number
};

export const playerStatisticsPropTypesShape = PropTypes.shape({ ...playerStatisticsPropTypes });
