import { reduxForm } from 'redux-form';

import fieldNames from 'consts/fieldNames';
import formNames from 'consts/formNames';
import { isRequiredArray } from 'utils/formValidators';

import LoginForm from './LoginForm';

const validate = values => {
  const errors = {};

  isRequiredArray(
    errors,
    values,
    [fieldNames.loginForm.emailOrLogin, fieldNames.loginForm.password],
    'Pole wymagane' // TODO: nie działają translacje w defaultProps i tam gdzie jest użycie "i18n.t" w całej apce: i18n.t('SignIn'),
  );

  return errors;
};

export default reduxForm({
  form: formNames.loginForm,
  validate
})(LoginForm);
