/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

import { useAppDispatch } from 'store';
import ArrayUtils from 'utils/array.utils';
import ObjectUtils from 'utils/object.utils';
import { shouldMakeRequest } from 'utils/requests.utils';
import { useGetCurrentSeasonId } from 'utils/systemParameterProvider';

import {
  useSelectCoachCurrentTeam,
  useSelectCoachDebutMatch,
  useSelectCoachDetails,
  useSelectCoachPhoto,
  useSelectCoachSeason,
  useSelectCoachSeasons,
  useSelectGetCoachCurrentTeamRequestStatus,
  useSelectGetCoachDebutMatchRequestStatus,
  useSelectGetCoachDetailsRequestStatus,
  useSelectGetCoachMatchesInSeasonRequestStatus,
  useSelectGetCoachPhotoRequestStatus,
  useSelectGetCoachSeasonRequestStatus,
  useSelectGetCoachSeasonsRequestStatus
} from './coach.selectors.hooks';
import {
  getCurrentTeam,
  getDebutMatch,
  getDetails,
  getMatchesInSeason,
  getPhoto,
  getSeason,
  getSeasons,
  getSeasonStatistics
} from './coach.thunks';
import { ICoachSeasonModel } from './models';

// TODO: zastanowić się czy trzeba typy
// type GetCoachDetailsQueryModel = {
//   details: ICoachDetailsResponse | null | undefined;
// } & RequestStatus;

// TODO: zastanowić się czy trzeba typy
// export const useGetCoachDetailsQuery = (id: string ): GetCoachDetailsQueryModel => {
export const useGetCoachDetailsQuery = (id: string) => {
  const dispatch = useAppDispatch();

  const requestStatus = useSelectGetCoachDetailsRequestStatus(id);
  const details = useSelectCoachDetails(id);

  useEffect(() => {
    if (shouldMakeRequest(requestStatus)) {
      dispatch(getDetails(id));
    }
  }, []);

  return { details, requestStatus };
};

export const useGetCoachPhotoQuery = (id: string) => {
  const dispatch = useAppDispatch();

  const requestStatus = useSelectGetCoachPhotoRequestStatus(id);
  const photo = useSelectCoachPhoto(id);

  useEffect(() => {
    if (shouldMakeRequest(requestStatus)) {
      dispatch(getPhoto(id));
    }
  }, []);

  return { photo, requestStatus };
};

export const useGetCoachCurrentTeamQuery = (id: string) => {
  const dispatch = useAppDispatch();

  const requestStatus = useSelectGetCoachCurrentTeamRequestStatus(id);
  const currentTeam = useSelectCoachCurrentTeam(id);

  useEffect(() => {
    if (shouldMakeRequest(requestStatus)) {
      dispatch(getCurrentTeam(id));
    }
  }, []);

  return { currentTeam, requestStatus };
};

export const useGetCoachDebutMatchQuery = (id: string) => {
  const dispatch = useAppDispatch();

  const requestStatus = useSelectGetCoachDebutMatchRequestStatus(id);
  const debutMatch = useSelectCoachDebutMatch(id);

  useEffect(() => {
    if (shouldMakeRequest(requestStatus)) {
      dispatch(getDebutMatch(id));
    }
  }, []);

  return { debutMatch, requestStatus };
};

export const useGetCoachSeasonsQuery = (id: string) => {
  const dispatch = useAppDispatch();

  const requestStatus = useSelectGetCoachSeasonsRequestStatus(id);
  const seasons = useSelectCoachSeasons(id);

  useEffect(() => {
    if (shouldMakeRequest(requestStatus)) {
      dispatch(getSeasons(id)).then(response =>
        (response?.payload as ICoachSeasonModel[]).forEach(season =>
          callForSeasonStatistics(dispatch, id, season)
        )
      );
    }
  }, []);

  return { seasons, requestStatus };
};

export const useGetCoachSeasonQuery = (id: string, seasonId: string) => {
  const dispatch = useAppDispatch();

  const requestStatus = useSelectGetCoachSeasonRequestStatus(id, seasonId);
  const season = useSelectCoachSeason(id, seasonId);

  useEffect(() => {
    if (shouldMakeRequest(requestStatus) && ObjectUtils.isEmpty(season)) {
      dispatch(getSeason({ id, seasonId })).then(response =>
        callForSeasonStatistics(dispatch, id, response?.payload as ICoachSeasonModel)
      );
    }
  }, []);

  return { season, requestStatus };
};

export const useGetMatchesInSeasonQuery = (
  id: string,
  seasonId?: string | null,
  isForCurrentSeason?: boolean,
  seasons?: ICoachSeasonModel[] | null | undefined
) => {
  const dispatch = useAppDispatch();

  const currentSeasonId = useGetCurrentSeasonId();

  const requestStatus = useSelectGetCoachMatchesInSeasonRequestStatus(id, seasonId!);

  const shouldMakeCallForCurrentSeason = seasons?.map(x => x.seasonId).includes(currentSeasonId);

  useEffect(() => {
    if (shouldMakeRequest(requestStatus) && !isForCurrentSeason) {
      dispatch(getMatchesInSeason({ id, seasonId: seasonId! }));
    }
  }, []);

  useEffect(() => {
    if (
      shouldMakeRequest(requestStatus) &&
      currentSeasonId &&
      isForCurrentSeason &&
      shouldMakeCallForCurrentSeason
    ) {
      dispatch(getMatchesInSeason({ id, seasonId: currentSeasonId }));
    }
  }, [shouldMakeCallForCurrentSeason]);
};

export const useCoachData = (id: string) => {
  const {
    details,
    photo,
    currentTeam,
    debutMatch,

    getDetailsRequestStatus,
    getPhotoRequestStatus,
    getCurrentTeamRequestStatus,
    getDebutMatchRequestStatus
  } = useGetCardCoachData(id);

  const { seasons, requestStatus: getSeasonsRequestStatus } = useGetCoachSeasonsQuery(id);

  useGetMatchesInSeasonQuery(id, null, true, seasons);

  return {
    details,
    photo,
    currentTeam,
    debutMatch,
    seasons,

    getDetailsRequestStatus,
    getPhotoRequestStatus,
    getCurrentTeamRequestStatus,
    getDebutMatchRequestStatus,
    getSeasonsRequestStatus
  };
};

export const useCoachDataForStatisticsPage = (id: string, seasonId: string) => {
  const {
    details,
    photo,
    currentTeam,
    debutMatch,

    getDetailsRequestStatus,
    getPhotoRequestStatus,
    getCurrentTeamRequestStatus,
    getDebutMatchRequestStatus
  } = useGetCardCoachData(id);

  const { season, requestStatus: getSeasonRequestStatus } = useGetCoachSeasonQuery(id, seasonId);

  useGetMatchesInSeasonQuery(id, seasonId);

  return {
    details,
    photo,
    currentTeam,
    debutMatch,
    season,

    getDetailsRequestStatus,
    getPhotoRequestStatus,
    getCurrentTeamRequestStatus,
    getDebutMatchRequestStatus,
    getSeasonRequestStatus
  };
};

export const useGetCardCoachData = (id: string) => {
  const { details, requestStatus: getDetailsRequestStatus } = useGetCoachDetailsQuery(id);

  const { photo, requestStatus: getPhotoRequestStatus } = useGetCoachPhotoQuery(id);

  const { currentTeam, requestStatus: getCurrentTeamRequestStatus } =
    useGetCoachCurrentTeamQuery(id);

  const { debutMatch, requestStatus: getDebutMatchRequestStatus } = useGetCoachDebutMatchQuery(id);

  return {
    details,
    photo,
    currentTeam,
    debutMatch,

    getDetailsRequestStatus,
    getPhotoRequestStatus,
    getCurrentTeamRequestStatus,
    getDebutMatchRequestStatus
  };
};

function callForSeasonStatistics(dispatch, id: string, season: ICoachSeasonModel) {
  if (ArrayUtils.isNotEmpty(season.leagues)) {
    season.leagues.forEach(league => {
      if (ArrayUtils.isNotEmpty(league.teams)) {
        league.teams.forEach(team => {
          dispatch(
            getSeasonStatistics({
              id,
              teamId: team.id,
              seasonId: season.seasonId,
              leagueId: league.leagueId
            })
          );
        });
      }
    });
  }
}
