import React from 'react';

import matchEventTypesIds from 'consts/matchEventTypesIds';

import ChangedPlayerEvent from './match-events/ChangedPlayerEvent';
import GivenCardEvent from './match-events/GivenCardEvent';
import ScoredGoalEvent from './match-events/ScoredGoalEvent';

// TODO: ujednolicić metody displayEventData i displayEventShortData oraz komponenty event i shortEvent użyte w tych metodach

const displayEventData = (event, eventIndex) => {
  switch (event.eventTypeId) {
    case matchEventTypesIds.scoredGoal:
      return <ScoredGoalEvent {...event} key={eventIndex} />;
    case matchEventTypesIds.givenCard:
      return <GivenCardEvent {...event} key={eventIndex} />;
    case matchEventTypesIds.playerChanged:
      return <ChangedPlayerEvent {...event} key={eventIndex} />;
    default:
      return '';
  }
};

export default displayEventData;
