import React, { memo } from 'react';
import { PropTypes } from 'prop-types';

import ResetPasswordForm from './ResetPasswordForm';

const propTypes = {
  onSubmit: PropTypes.func.isRequired
};

const ResetPasswordPage = ({ onSubmit }) => <ResetPasswordForm onSubmit={onSubmit} />;

ResetPasswordPage.propTypes = propTypes;

export default memo(ResetPasswordPage);
