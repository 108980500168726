import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import styled from 'styled-components';

import { playerMatchPropTypes } from 'propTypes';
import { icons } from 'consts/icons';
import matchShortEventTypes from 'consts/matchShortEventTypes';
import { sortEvents } from 'utils/utils';
import displayEventShortData from 'modules/match/displayEventShortData'; // TODO: komponent nie może korzystać z modules, sprawdzić w całej aplikacji
import MinutesIcon from 'components/Icon/MinutesIcon';

import DateInfo from './DateInfo';
import Icon from './Icon';
import TeamLink from './Links/TeamLink';
import MatchResults from './MatchResults';
import styles from './MatchRow.module.scss';
import MatchStatus from './MatchStatus';
import TimeInfo from './TimeInfo';
import Tooltip from './Tooltip';

export const StyledEventsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.2rem;
`;

const propTypes = {
  ...playerMatchPropTypes,
  withStatus: PropTypes.bool,
  withStatusAndPlayerStatistics: PropTypes.bool,
  teamIdContext: PropTypes.string
};

const MatchRow = ({
  id,
  host,
  guest,
  hostGoals,
  guestGoals,
  hostFirstHalfGoals,
  guestFirstHalfGoals,
  date,
  comment,
  status,
  isWalkoverForGuest,
  isWalkoverForHost,
  walkoverCause,
  ageGroupName,
  isCaptain,
  isGoalkeeper,
  isJunior,
  minutesCount,
  goals = [],
  assists = [],
  changes = [],
  cards = [],
  withStatus,
  withStatusAndPlayerStatistics,
  teamIdContext
}) => {
  const { t } = useTranslation();

  const events = [
    ...assists.map(x => ({ ...x, shortEventType: matchShortEventTypes.assist })),
    ...goals.map(x => ({ ...x, shortEventType: matchShortEventTypes.scoredGoal })),
    ...changes.map(x => ({ ...x, shortEventType: matchShortEventTypes.playerChanged })),
    ...cards.map(x => ({ ...x, shortEventType: matchShortEventTypes.givenCard }))
  ]
    .sort((previousEvent, nextEvent) => sortEvents(previousEvent, nextEvent))
    .map((event, eventIndex) => (
      <span key={eventIndex} className={styles.match_events_event}>
        {displayEventShortData(event, eventIndex)}
      </span>
    ));

  const isTeamIdContext = () => host.id === teamIdContext || guest.id === teamIdContext;

  const rowClassName = cx({
    [styles.highlightedRow]: isTeamIdContext()
  });

  return (
    /* TODO: może zamiast <tr> i <td> to użyć <Row> i <Column> ? ujednolicić w całej aplikacji */
    <tr className={rowClassName}>
      <td className="align-text-right">
        <TeamLink {...host} reverse />
      </td>
      <td>
        {/* note: można też tak przekazywać propsy: <MatchResults {...{ hostGoals, guestGoals, id }} /> */}
        <MatchResults
          id={id}
          hostGoals={hostGoals}
          guestGoals={guestGoals}
          hostFirstHalfGoals={hostFirstHalfGoals}
          guestFirstHalfGoals={guestFirstHalfGoals}
          isWalkover={isWalkoverForGuest || isWalkoverForHost}
          walkoverCause={walkoverCause}
        />
        <span className={styles.match_ageGroupName}>{ageGroupName}</span>
      </td>
      <td className="align-text-left">
        <TeamLink {...guest} />
      </td>
      {withStatusAndPlayerStatistics && (
        <td className={styles.match_events}>
          {isGoalkeeper ? (
            t('GoalkeeperAbbreviation')
          ) : isCaptain ? (
            t('CaptainAbbreviation')
          ) : isJunior ? (
            t('JuniorAbbreviation')
          ) : (
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          )}
          &nbsp;
          <MinutesIcon />
          &nbsp;
          {minutesCount}
          &apos;
          <StyledEventsContainer>{events}</StyledEventsContainer>
        </td>
      )}
      <td>
        {comment && (
          <Tooltip html={comment}>
            <Icon icon={icons.infoCircle} />
          </Tooltip>
        )}
      </td>
      <td>
        <DateInfo date={date} withDayName />
      </td>
      <td className="align-text-left">
        <TimeInfo date={date} />
      </td>
      <td>{withStatus && <MatchStatus status={status} />}</td>
    </tr>
  );
};

MatchRow.propTypes = propTypes;

export default memo(MatchRow);
