import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import styles from './CardSecondYellowIcon.module.scss';

const propTypes = {
  count: PropTypes.number
};

const CardSecondYellowIcon = ({ count }) => {
  const { t } = useTranslation();

  return (
    <i className={styles.card} title={t('SecondYellowCard')}>
      {count ? <span className={styles.card_count}>{count}</span> : ''}
    </i>
  );
};

CardSecondYellowIcon.propTypes = propTypes;

export default memo(CardSecondYellowIcon);
