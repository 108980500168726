import actionTypes from './actionTypes';

const initialState = {};

export default function clubsReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.STORE_CLUB_DETAILS:
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          details: { id: action.data.id, ...action.data.details }
        }
      };
    case actionTypes.STORE_CLUB_CONTACT:
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          contact: action.data.contact
        }
      };
    case actionTypes.STORE_CLUB_TEAMS:
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          teams: action.data.teams
        }
      };
    case actionTypes.STORE_CLUB_BOARD_MEMBERS:
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          boardMembers: action.data.boardMembers
        }
      };
    case actionTypes.STORE_CLUB_HISTORY:
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          history: action.data.history
        }
      };
    case actionTypes.STORE_CLUB_SPONSORS:
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          sponsors: action.data.sponsors
        }
      };
    case actionTypes.STORE_CLUB_PARTNERS:
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          partners: action.data.partners
        }
      };
    case actionTypes.STORE_CLUB_STADIUM_DESCRIPTION:
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          stadiumDescription: action.data.stadiumDescription
        }
      };
    default:
      return state;
  }
}
