import { RequestStatus } from 'models/common.models';

import { useAppSelector } from 'store';
import { useGetRequestStatusStatus } from 'utils/requests.utils';
import { EFileIds } from 'consts/fileIds';

import { IFileModel } from './models';
import { selectFile, selectFileRequestStatus } from './file.selectors';

export const useSelectFileRequestStatus = (id: EFileIds): RequestStatus =>
  useGetRequestStatusStatus(id, selectFileRequestStatus);

export const useSelectFile = (id: EFileIds): IFileModel | undefined | null =>
  useAppSelector(state => selectFile(state, id));
