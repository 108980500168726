/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import {
  requestStatusFulfilled,
  requestStatusPending,
  requestStatusRejected
} from 'utils/requests.utils';

import { PlayersState } from './models';
import {
  changePlayerValuesOrAddNewPlayer,
  changeSeasonValuesOrAddNewSeason,
  changeTeamValues
} from './player.functions';
import {
  getCurrentTeam,
  getDebutMatch,
  getDetails,
  getMatchesInCurrentSeason,
  getMatchesInSeason,
  getPhoto,
  getSeason,
  getSeasons,
  getSeasonStatistics
} from './player.thunks';

const initialState: PlayersState = {};

const playerSlice = createSlice({
  name: 'player',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getDetails.pending, (state: PlayersState, action) => {
        changePlayerValuesOrAddNewPlayer(state, action, [
          {
            key: 'getDetailsRequestStatus',
            value: requestStatusPending
          }
        ]);
      })
      .addCase(getDetails.fulfilled, (state: PlayersState, action) => {
        changePlayerValuesOrAddNewPlayer(state, action, [
          {
            key: 'getDetailsRequestStatus',
            value: requestStatusFulfilled
          },
          {
            key: 'details',
            value: action.payload
          }
        ]);
      })
      .addCase(getDetails.rejected, (state: PlayersState, action) => {
        changePlayerValuesOrAddNewPlayer(state, action, [
          {
            key: 'getDetailsRequestStatus',
            value: requestStatusRejected(action)
          }
        ]);
      })
      .addCase(getPhoto.pending, (state: PlayersState, action) => {
        changePlayerValuesOrAddNewPlayer(state, action, [
          {
            key: 'getPhotoRequestStatus',
            value: requestStatusPending
          }
        ]);
      })
      .addCase(getPhoto.fulfilled, (state: PlayersState, action) => {
        changePlayerValuesOrAddNewPlayer(state, action, [
          {
            key: 'getPhotoRequestStatus',
            value: requestStatusFulfilled
          },
          {
            key: 'photo',
            value: action.payload
          }
        ]);
      })
      .addCase(getPhoto.rejected, (state: PlayersState, action) => {
        changePlayerValuesOrAddNewPlayer(state, action, [
          {
            key: 'getPhotoRequestStatus',
            value: requestStatusRejected(action)
          }
        ]);
      })
      .addCase(getCurrentTeam.pending, (state: PlayersState, action) => {
        changePlayerValuesOrAddNewPlayer(state, action, [
          {
            key: 'getCurrentTeamRequestStatus',
            value: requestStatusPending
          }
        ]);
      })
      .addCase(getCurrentTeam.fulfilled, (state: PlayersState, action) => {
        changePlayerValuesOrAddNewPlayer(state, action, [
          {
            key: 'getCurrentTeamRequestStatus',
            value: requestStatusFulfilled
          },
          {
            key: 'currentTeam',
            value: action.payload
          }
        ]);
      })
      .addCase(getCurrentTeam.rejected, (state: PlayersState, action) => {
        changePlayerValuesOrAddNewPlayer(state, action, [
          {
            key: 'getCurrentTeamRequestStatus',
            value: requestStatusRejected(action)
          }
        ]);
      })
      .addCase(getDebutMatch.pending, (state: PlayersState, action) => {
        changePlayerValuesOrAddNewPlayer(state, action, [
          {
            key: 'getDebutMatchRequestStatus',
            value: requestStatusPending
          }
        ]);
      })
      .addCase(getDebutMatch.fulfilled, (state: PlayersState, action) => {
        changePlayerValuesOrAddNewPlayer(state, action, [
          {
            key: 'getDebutMatchRequestStatus',
            value: requestStatusFulfilled
          },
          {
            key: 'debutMatch',
            value: action.payload
          }
        ]);
      })
      .addCase(getDebutMatch.rejected, (state: PlayersState, action) => {
        changePlayerValuesOrAddNewPlayer(state, action, [
          {
            key: 'getDebutMatchRequestStatus',
            value: requestStatusRejected(action)
          }
        ]);
      })
      .addCase(getSeasons.pending, (state: PlayersState, action) => {
        changePlayerValuesOrAddNewPlayer(state, action, [
          {
            key: 'getSeasonsRequestStatus',
            value: requestStatusPending
          }
        ]);
      })
      .addCase(getSeasons.fulfilled, (state: PlayersState, action) => {
        changePlayerValuesOrAddNewPlayer(state, action, [
          {
            key: 'getSeasonsRequestStatus',
            value: requestStatusFulfilled
          },
          {
            key: 'seasons',
            value: action.payload
          }
        ]);
      })
      .addCase(getSeasons.rejected, (state: PlayersState, action) => {
        changePlayerValuesOrAddNewPlayer(state, action, [
          {
            key: 'getSeasonsRequestStatus',
            value: requestStatusRejected(action)
          }
        ]);
      })
      .addCase(getSeason.pending, (state: PlayersState, action) => {
        changeSeasonValuesOrAddNewSeason(state, action, [
          {
            key: 'getSeasonRequestStatus',
            value: requestStatusPending
          }
        ]);
      })
      .addCase(getSeason.fulfilled, (state: PlayersState, action) => {
        changeSeasonValuesOrAddNewSeason(state, action, [
          {
            key: 'getSeasonRequestStatus',
            value: requestStatusFulfilled
          },
          {
            key: 'seasonId',
            value: action.payload.seasonId
          },
          {
            key: 'seasonName',
            value: action.payload.seasonName
          },
          {
            key: 'leagues',
            value: action.payload.leagues
          }
        ]);
      })
      .addCase(getSeason.rejected, (state: PlayersState, action) => {
        changeSeasonValuesOrAddNewSeason(state, action, [
          {
            key: 'getSeasonRequestStatus',
            value: requestStatusRejected(action)
          }
        ]);
      })
      .addCase(getSeasonStatistics.pending, (state: PlayersState, action) => {
        changeTeamValues(state, action, [
          {
            key: 'getSeasonStatisticsRequestStatus',
            value: requestStatusPending
          }
        ]);
      })
      .addCase(getSeasonStatistics.fulfilled, (state: PlayersState, action) => {
        changeTeamValues(state, action, [
          {
            key: 'getSeasonStatisticsRequestStatus',
            value: requestStatusFulfilled
          },
          {
            key: 'playerStatistics',
            value: action.payload
          }
        ]);
      })
      .addCase(getSeasonStatistics.rejected, (state: PlayersState, action) => {
        changeTeamValues(state, action, [
          {
            key: 'getSeasonStatisticsRequestStatus',
            value: requestStatusRejected(action)
          }
        ]);
      })
      .addMatcher(
        isAnyOf(getMatchesInCurrentSeason.pending, getMatchesInSeason.pending),
        (state, action) => {
          changeSeasonValuesOrAddNewSeason(state, action, [
            {
              key: 'getMatchesRequestStatus',
              value: requestStatusPending
            }
          ]);
        }
      )
      .addMatcher(
        isAnyOf(getMatchesInCurrentSeason.fulfilled, getMatchesInSeason.fulfilled),
        (state, action) => {
          changeSeasonValuesOrAddNewSeason(state, action, [
            {
              key: 'getMatchesRequestStatus',
              value: requestStatusFulfilled
            },
            {
              key: 'matches',
              value: action.payload
            }
          ]);
        }
      )
      .addMatcher(
        isAnyOf(getMatchesInCurrentSeason.rejected, getMatchesInSeason.rejected),
        (state, action) => {
          changeSeasonValuesOrAddNewSeason(state, action, [
            {
              key: 'getMatchesRequestStatus',
              value: requestStatusRejected(action)
            }
          ]);
        }
      );
  }
});

export default playerSlice.reducer;
