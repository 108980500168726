import React, { memo } from 'react';

import { useAppSelector } from 'store';
import { useDispatchOnMountWithLocationMultiple } from 'utils/customHooks';
import { useGetMyTeamId } from 'utils/systemParameterProvider';

import {
  getCoachesForMyTeamInCurrentSeason,
  getPlayersWithMatchesAndEventsForMyTeamInCurrentSeason
} from '../my-team-actions';
import PlayersPage from './PlayersPage';

const MyTeamPlayersInCurrentSeasonPageContainer = props => {
  const myTeamId = useGetMyTeamId();

  const team = useAppSelector(state => state.teams[myTeamId]);

  const coachesInCurrentSeason = team?.coachesInCurrentSeason;
  const playersWithMatchesAndEventsInCurrentSeason =
    team?.playersWithMatchesAndEventsInCurrentSeason;

  useDispatchOnMountWithLocationMultiple([
    getCoachesForMyTeamInCurrentSeason(),
    getPlayersWithMatchesAndEventsForMyTeamInCurrentSeason()
  ]);

  return (
    <PlayersPage
      {...props}
      coaches={coachesInCurrentSeason}
      players={playersWithMatchesAndEventsInCurrentSeason}
    />
  );
};

export default memo(MyTeamPlayersInCurrentSeasonPageContainer);
