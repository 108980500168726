import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { playerInSquadPropTypesShape } from 'propTypes';
import matchEventTypesIds from 'consts/matchEventTypesIds';
import matchShortEventTypes from 'consts/matchShortEventTypes';
import { sortEvents } from 'utils/utils';
import Column from 'components/Column';
import PlayerLink from 'components/Links/PlayerLink';

import displayEventShortData from './displayEventShortData';
import styles from './MatchTeamSquad.module.scss';

const propTypes = {
  players: PropTypes.arrayOf(playerInSquadPropTypesShape).isRequired,
  events: PropTypes.array
};

const MatchTeamSquad = ({ players, events = [] }) => {
  const { t } = useTranslation();

  const eventsCopy = structuredClone(events);

  const playersLinks = players.map(player => {
    const playerEvents = eventsCopy
      .filter(
        x =>
          x.player?.id === player.id ||
          x.assistantPlayer?.id === player.id ||
          x.enteringPlayer?.id === player.id ||
          x.goingDownPlayer?.id === player.id
      )
      .sort((previousEvent, nextEvent) => sortEvents(previousEvent, nextEvent))
      .map((event, eventIndex) => {
        switch (event.eventTypeId) {
          case matchEventTypesIds.scoredGoal:
            if (event.player?.id === player.id) {
              event.shortEventType = matchShortEventTypes.scoredGoal;
            } else if (event.assistantPlayer?.id === player.id) {
              event.shortEventType = matchShortEventTypes.assist;
            }

            break;
          case matchEventTypesIds.givenCard:
            event.shortEventType = matchShortEventTypes.givenCard;
            break;
          case matchEventTypesIds.playerChanged:
            event.shortEventType = matchShortEventTypes.playerChanged;

            if (event.enteringPlayer?.id === player.id) {
              event.isEnter = true;
            }

            break;
          default:
            break;
        }

        return (
          <span key={eventIndex} className={styles.squad_container_player_events_event}>
            {displayEventShortData(event, eventIndex)}
          </span>
        );
      });

    return (
      <span key={player.id} className={styles.squad_container_player}>
        <span className={styles.squad_container_player_shirtNumber}>{player.shirtNumber}.</span>{' '}
        <PlayerLink {...player} />
        <span className={styles.squad_container_player_function}>
          {player.isGoalkeeper
            ? t('GoalkeeperAbbreviation')
            : player.isCaptain
              ? t('CaptainAbbreviation')
              : player.isJunior
                ? t('JuniorAbbreviation')
                : ''}
        </span>
        <span className={styles.squad_container_player_events}>{playerEvents}</span>
      </span>
    );
  });

  return (
    <section className={styles.squad}>
      <Column className={styles.squad_container}>{playersLinks}</Column>
    </section>
  );
};

MatchTeamSquad.propTypes = propTypes;

export default memo(MatchTeamSquad);
