import React, { memo } from 'react';
import Link from 'components/Links/Link';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { icons } from 'consts/icons';
import urls from 'consts/urls';
import { isMyTeam, isNullOrUndefined } from 'utils/utils';
import Card from 'components/Card';
import Column from 'components/Column';
import DateInfo from 'components/DateInfo';
import TimeInfo from 'components/TimeInfo';

import styles from './CardMatch.module.scss';
import CardMatchPlaceholder from './CardMatchPlaceholder';
import MatchCountDown from './MatchCountDown';
import { EColors } from 'consts/colors';

type SimpleTeam = {
  id: string;
  logo: string;
  name: string;
  ageGroupName: string;
};

type Props = {
  previousMatch?: boolean;
  currentMatch?: boolean;
  nextMatch?: boolean;
  match?: {
    id: string;
    host: SimpleTeam;
    guest: SimpleTeam;
    date: Date;
    hostGoals: number;
    guestGoals: number;
  };
  isMatchLoaded?: boolean;
  withTeamName?: boolean;
  withEmphasisMyTeam?: boolean;
};

const CardMatch: React.FC<Props> = ({
  previousMatch,
  currentMatch,
  nextMatch,
  match,
  isMatchLoaded,
  withTeamName = true,
  withEmphasisMyTeam = true
}: Readonly<Props>) => {
  const { t } = useTranslation();

  const matchBackgroundClass = previousMatch
    ? EColors.opaqueDark
    : currentMatch
      ? EColors.orangeOpaque
      : EColors.green;

  const getGoalsToDisplay = goalsCount =>
    nextMatch || isNullOrUndefined(goalsCount) ? '?' : goalsCount;

  const displayMatch = !isEmpty(match) && isMatchLoaded;

  const noLogoIconClassName = cx(icons.image, styles.match_noLogoIcon);

  const hostTeamNameClassName = cx(styles.match_team_name, {
    [styles.match_team_name_emphasized]: isMyTeam(match?.host?.id) && withEmphasisMyTeam
  });

  const guestTeamNameClassName = cx(styles.match_team_name, {
    [styles.match_team_name_emphasized]: isMyTeam(match?.guest?.id) && withEmphasisMyTeam
  });

  const title = previousMatch
    ? t('PreviousMatch')
    : currentMatch
      ? t('CurrentMatch')
      : t('NextMatch');

  return displayMatch ? (
    <Card
      renderTitle={() => (
        <>
          {title}
          {<MatchCountDown destinationTime={match!.date} />}
        </>
      )}
      renderFooter={() => (
        <Link to={urls.matchDetails(match!.id)} title={t('GoToTheMatchDetails')}>
          {t('MatchDetails')}
        </Link>
      )}
      titleBackground={matchBackgroundClass}
      footerBackground={matchBackgroundClass}
    >
      <div className={styles.match}>
        <Column className={styles.match_left}>
          <div className={styles.match_time}>
            <DateInfo date={match!.date} withDayName shouldWrapDayNameToNextLine />
          </div>
          {/* TODO: spróbować użyć <TeamEntry> i może <MatchResults> lub <MatchScoreSection> lub <MatchScorePoints> może jakoś ujednolicić te komponenty z MatchDetailsCard i przenieść do common? */}
          <Link
            className={styles.match_logo_container}
            to={urls.teamDetails(match!.host.id)}
            title={`${t('GoToTheClubSubpage')} ${match!.host.name}`}
          >
            {withTeamName ? <div className={hostTeamNameClassName}>{match!.host.name}</div> : ''}

            {match!.host.logo ? (
              <img src={match!.host.logo} alt={match!.host.name} className={styles.match_logo} />
            ) : (
              <i className={noLogoIconClassName} />
            )}
          </Link>
          <span className={styles.match_score}>{getGoalsToDisplay(match!.hostGoals)}</span>
        </Column>
        <Column className={styles.match_middle}>
          <span className={styles.match_score_divider}>-</span>
        </Column>
        <Column className={styles.match_right}>
          <div className={styles.match_time}>
            <TimeInfo date={match!.date} />
          </div>
          <Link
            className={styles.match_logo_container}
            to={urls.teamDetails(match!.guest.id)}
            title={`${t('GoToTheClubSubpage')} ${match!.guest.name}`}
          >
            {withTeamName ? <div className={guestTeamNameClassName}>{match!.guest.name}</div> : ''}

            {match!.guest.logo ? (
              <img src={match!.guest.logo} alt={match!.guest.name} className={styles.match_logo} />
            ) : (
              <i className={noLogoIconClassName} />
            )}
          </Link>
          <span className={styles.match_score}>{getGoalsToDisplay(match!.guestGoals)}</span>
        </Column>
      </div>
    </Card>
  ) : isMatchLoaded ? (
    <Card
      renderTitle={() => (nextMatch ? t('NextMatch') : t('PreviousMatch'))}
      titleBackground={matchBackgroundClass}
      footerBackground={matchBackgroundClass}
    >
      <div className={styles.noInformationAboutMatch}>{t('NoInformationAboutMatch')}</div>
    </Card>
  ) : (
    <CardMatchPlaceholder nextMatch={nextMatch ?? false} titleBackground={matchBackgroundClass} />
  );
};

export default memo(CardMatch);
