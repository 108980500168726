import actionTypes from './actionTypes';
import service from './service';

// TODO: przenieść moduł album do galerii jako podrzędny
const getGalleryDataSuccess = data => dispatch =>
  dispatch({
    type: actionTypes.STORE_GALLERY_DATA,
    data
  });

export function get() {
  return (dispatch, getState) => {
    const { gallery } = getState();

    return !gallery && service.get().then(data => dispatch(getGalleryDataSuccess(data)));
  };
}
