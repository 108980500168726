import React, { memo } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';

import {
  matchPropTypes,
  personWithIdAndPhotoPropTypes,
  playerMatchPropTypesShape
} from 'propTypes';
import { icons } from 'consts/icons';
import urls from 'consts/urls';
import ArrayUtils from 'utils/array.utils';
import Accordion from 'components/Accordion';
import Column from 'components/Column';
import IconButton from 'components/IconButton';
import InnerContentWrapper from 'components/InnerContentWrapper';
import LoadingPageData from 'components/LoadingPageData';
import MatchesTableResponsive from 'components/MatchesTableResponsive';
import PageHeader from 'components/PageHeader';
import CoachCard from 'components/PeopleCards/CoachCard';
import PlayerCard from 'components/PeopleCards/PlayerCard';

import TeamDetailsCard from './TeamDetailsCard';
import TeamPlayersMatches from './TeamPlayersMatches';
import styles from './TeamSeasonsStatisticsPage.module.scss';

const propTypes = {
  teamDetails: PropTypes.shape({
    logo: PropTypes.string,
    groupPhoto: PropTypes.string,
    name: PropTypes.string,
    creationDate: PropTypes.string,
    address: PropTypes.shape({
      city: PropTypes.string,
      street: PropTypes.string,
      buildingNumber: PropTypes.string,
      apartmentNumber: PropTypes.string,
      zipCode: PropTypes.string,
      postOffice: PropTypes.string
    }),
    email: PropTypes.string,
    websiteUrl: PropTypes.string
  }),
  season: PropTypes.shape({
    // TODO: other fields
    seasonId: PropTypes.string,
    seasonName: PropTypes.string,
    leagueId: PropTypes.string,
    leagueName: PropTypes.string,
    matches: PropTypes.arrayOf(
      PropTypes.shape({
        ...matchPropTypes,
        status: PropTypes.string
      })
    ),
    coaches: PropTypes.arrayOf(
      PropTypes.shape({
        ...personWithIdAndPhotoPropTypes,
        coachType: PropTypes.string
      })
    ),
    playersWithMatchesAndEvents: PropTypes.arrayOf(playerMatchPropTypesShape)
  })
};

const TeamSeasonsStatisticsPage = ({ teamDetails, season }) => {
  const { t } = useTranslation();

  const coachCards = season?.coaches?.map(coach => <CoachCard key={coach.id} {...coach} />);

  const playerCards = season?.playersWithMatchesAndEvents?.map(player => (
    <PlayerCard key={player.id} {...player} />
  ));

  return (
    <InnerContentWrapper centerContent subpageWrap>
      {!isEmpty(season) ? (
        <Column className={styles.header}>
          <PageHeader title={t('TeamCompetitionsStatistics')} />
          <PageHeader title={season?.seasonName} />
          <IconButton
            icon={icons.arrowRight}
            title={t('CompetitionsDetails')}
            to={urls.competitionsDetails(season?.leagueId, season?.seasonId)}
          >
            {t('CompetitionsDetails')}
          </IconButton>
        </Column>
      ) : (
        <LoadingPageData />
      )}

      {!isEmpty(teamDetails) ? <TeamDetailsCard {...teamDetails} /> : <LoadingPageData />}

      {!isEmpty(season?.coaches) && (
        <Accordion
          hoverable
          className="margin-top"
          title={`${t('Coaches')} ${t('InSeason')} - ${season?.seasonName}`}
        >
          <section className={styles.cards}>{coachCards}</section>
        </Accordion>
      )}

      {ArrayUtils.isNotEmpty(season?.playersWithMatchesAndEvents) && (
        <Accordion
          hoverable
          className="margin-top"
          title={`${t('Players')} ${t('InSeason')} - ${season?.seasonName}`}
        >
          <section className={styles.cards}>{playerCards}</section>
        </Accordion>
      )}

      {!isEmpty(season?.matches) && (
        <Accordion
          hoverable
          className="margin-top"
          title={`${t('Matches')} ${t('InSeason')} - ${season?.seasonName}`}
          withoutPadding
        >
          <MatchesTableResponsive matches={season?.matches} withStatus />
        </Accordion>
      )}

      {!isEmpty(season?.matches) && !isEmpty(season?.playersWithMatchesAndEvents) && (
        <Accordion hoverable className="margin-top" title={t('Statistics')} withoutPadding>
          <TeamPlayersMatches
            matches={season?.matches}
            playersWithMatchesAndEvents={season?.playersWithMatchesAndEvents}
          />
        </Accordion>
      )}
    </InnerContentWrapper>
  );
};

TeamSeasonsStatisticsPage.propTypes = propTypes;

export default memo(TeamSeasonsStatisticsPage);
