import React, { memo } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';

import {
  matchPropTypesShape,
  personWithIdAndPhotoPropTypes,
  playerMatchPropTypesShape
} from 'propTypes';
import playerPositionsIds from 'consts/playerPositions';
import ArrayUtils from 'utils/array.utils';
import { sortByLastNameAscending } from 'utils/sortingMethods';
import Accordion from 'components/Accordion';
import InnerContentWrapper from 'components/InnerContentWrapper';
import LoadingPageData from 'components/LoadingPageData';
import MatchesTableResponsive from 'components/MatchesTableResponsive';
import CoachCard from 'components/PeopleCards/CoachCard';
import PlayerCard from 'components/PeopleCards/PlayerCard';

import TeamPlayersStatistics from './playerStatistics/TeamPlayersStatistics';
import TeamDetailsCard from './TeamDetailsCard';
import styles from './TeamDetailsPage.module.scss';
import TeamPlayersMatches from './TeamPlayersMatches';
import TeamPlayersMinutes from './TeamPlayersMinutes';
import TeamSeasons from './TeamSeasons';

const propTypes = {
  teamId: PropTypes.string,
  details: PropTypes.shape({
    logo: PropTypes.string,
    groupPhoto: PropTypes.string,
    name: PropTypes.string,
    creationDate: PropTypes.string,
    address: PropTypes.shape({
      city: PropTypes.string,
      street: PropTypes.string,
      buildingNumber: PropTypes.string,
      apartmentNumber: PropTypes.string,
      zipCode: PropTypes.string,
      postOffice: PropTypes.string
    }),
    email: PropTypes.string,
    websiteUrl: PropTypes.string,
    ageGroupName: PropTypes.string,
    clubId: PropTypes.string
  }),
  seasons: PropTypes.arrayOf(
    PropTypes.shape({
      seasonId: PropTypes.string,
      seasonName: PropTypes.string,
      leagueId: PropTypes.string,
      leagueName: PropTypes.string
    })
  ),
  coachesInCurrentSeason: PropTypes.arrayOf(
    PropTypes.shape({
      ...personWithIdAndPhotoPropTypes,
      coachType: PropTypes.string
    })
  ),
  matchesInCurrentSeason: PropTypes.arrayOf(matchPropTypesShape),
  playersWithMatchesAndEventsInCurrentSeason: PropTypes.arrayOf(playerMatchPropTypesShape)
};

const TeamDetailsPage = ({
  teamId,
  details,
  seasons,
  coachesInCurrentSeason,
  matchesInCurrentSeason,
  playersWithMatchesAndEventsInCurrentSeason
}) => {
  const { t } = useTranslation();

  const coachCards = coachesInCurrentSeason?.map(coach => <CoachCard key={coach.id} {...coach} />);

  const getPlayersByPositionId = positionId => {
    let players = window.structuredClone(playersWithMatchesAndEventsInCurrentSeason);

    if (positionId) {
      players = players?.filter(player => player.position?.id === positionId);
    } else {
      players = players?.filter(player => !player.position);
    }

    return sortByLastNameAscending(players);
  };

  const goalkeepers = getPlayersByPositionId(playerPositionsIds.goalkeeperId);
  const defenders = getPlayersByPositionId(playerPositionsIds.defenderId);
  const midfielders = getPlayersByPositionId(playerPositionsIds.midfielderId);
  const strikers = getPlayersByPositionId(playerPositionsIds.strikerId);
  const unknowns = getPlayersByPositionId(null);

  const playerCards = playersWithMatchesAndEventsInCurrentSeason
    ? [...goalkeepers, ...defenders, ...midfielders, ...strikers, ...unknowns]?.map(player => (
        <PlayerCard key={player.id} {...player} />
      ))
    : null;

  return (
    <InnerContentWrapper subpageWrap>
      {!isEmpty(details) ? (
        <section className={styles.content_container}>
          <TeamDetailsCard {...details} />
        </section>
      ) : (
        <LoadingPageData />
      )}

      {!isEmpty(seasons) && (
        <Accordion hoverable className="margin-top" title={t('Competitions')} withoutPadding>
          <TeamSeasons teamId={teamId} seasons={seasons} />
        </Accordion>
      )}

      {!isEmpty(coachesInCurrentSeason) && (
        <Accordion
          hoverable
          className="margin-top"
          title={`${t('Coaches')} ${t('InCurrentSeason')}`}
        >
          <section className={styles.cards}>{coachCards}</section>
        </Accordion>
      )}

      {ArrayUtils.isNotEmpty(playersWithMatchesAndEventsInCurrentSeason) && (
        <Accordion
          hoverable
          className="margin-top"
          title={`${t('Players')} ${t('InCurrentSeason')}`}
        >
          <section className={styles.cards}>{playerCards}</section>
        </Accordion>
      )}

      {!isEmpty(matchesInCurrentSeason) && (
        <Accordion
          hoverable
          className="margin-top"
          title={`${t('Matches')} ${t('InCurrentSeason')}`}
          withoutPadding
        >
          <MatchesTableResponsive matches={matchesInCurrentSeason} withStatus />
        </Accordion>
      )}

      {/* TODO: */}

      {!isEmpty(matchesInCurrentSeason) && !isEmpty(playersWithMatchesAndEventsInCurrentSeason) && (
        <Accordion hoverable className="margin-top" title={t('Statistics')} withoutPadding>
          <TeamPlayersStatistics
            playersWithMatchesAndEvents={playersWithMatchesAndEventsInCurrentSeason}
          />
        </Accordion>
      )}

      {!isEmpty(matchesInCurrentSeason) && !isEmpty(playersWithMatchesAndEventsInCurrentSeason) && (
        <Accordion hoverable className="margin-top" title={t('StatisticsInMatches')} withoutPadding>
          <TeamPlayersMatches
            matches={matchesInCurrentSeason}
            playersWithMatchesAndEvents={playersWithMatchesAndEventsInCurrentSeason}
          />
        </Accordion>
      )}

      {!isEmpty(matchesInCurrentSeason) && !isEmpty(playersWithMatchesAndEventsInCurrentSeason) && (
        <Accordion hoverable className="margin-top" title={t('MinutesInMatches')} withoutPadding>
          <TeamPlayersMinutes
            matches={matchesInCurrentSeason}
            playersWithMatchesAndEvents={playersWithMatchesAndEventsInCurrentSeason}
          />
        </Accordion>
      )}
    </InnerContentWrapper>
  );
};

TeamDetailsPage.propTypes = propTypes;

export default memo(TeamDetailsPage);
