import React from 'react';

import { useAppSelector } from 'store';
import { useDispatchOnMount } from 'utils/customHooks';
import { useGetMyClubId } from 'utils/systemParameterProvider';

import { getStadiumDescriptionForMyClub } from '../my-club-actions';
import StadiumDescriptionPage from './StadiumDescriptionPage';

const StadiumDescriptionPageContainer = props => {
  const myClubId = useGetMyClubId();

  const stadiumDescription = useAppSelector(state => state.clubs[myClubId]?.stadiumDescription);

  useDispatchOnMount(getStadiumDescriptionForMyClub());

  return <StadiumDescriptionPage {...props} stadiumDescription={stadiumDescription} />;
};

export default StadiumDescriptionPageContainer;
