import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RequestStatus } from 'models/common.models';

import ArrayUtils from 'utils/array.utils';
import { scrollToElement } from 'utils/scrollTo';
import { useDynamicRefs } from 'utils/useDynamicRefs';
import Accordion from 'components/Accordion';
import InnerContentWrapper from 'components/InnerContentWrapper';
import MatchesTableResponsive from 'components/MatchesTableResponsive';
import { StyledMatchesNoDataMessage } from 'components/StyledMatchesNoDataMessage';
import ThreeRowsSkeletons from 'components/ThreeRowsSkeletons';

import {
  IPlayerCurrentTeamModel,
  IPlayerDebutMatchModel,
  IPlayerDetailsModel,
  IPlayerMatchModel,
  IPlayerSeasonModel
} from './data/models';
import PlayerDetailsCard from './PlayerDetailsCard';
import PlayerSeasons from './seasons/PlayerSeasons';

type Props = {
  playerId: string;

  details?: IPlayerDetailsModel | null;
  photo?: string | null;
  currentTeam?: IPlayerCurrentTeamModel | null;
  debutMatch?: IPlayerDebutMatchModel | null;
  seasons?: IPlayerSeasonModel[] | null;
  matchesInCurrentSeason?: IPlayerMatchModel[] | null;

  getDetailsRequestStatus?: RequestStatus | null;
  getPhotoRequestStatus?: RequestStatus | null;
  getCurrentTeamRequestStatus?: RequestStatus | null;
  getDebutMatchRequestStatus?: RequestStatus | null;
  getSeasonsRequestStatus?: RequestStatus | null;
  getMatchesInCurrentSeasonRequestStatus?: RequestStatus | null;

  currentSeasonId: string;

  onGetMatchesInSeason: (seasonId: string) => void;
};

const PlayerDetailsPage = ({
  playerId,
  details,
  photo,
  currentTeam,
  debutMatch,
  seasons,
  matchesInCurrentSeason,
  getDetailsRequestStatus,
  getPhotoRequestStatus,
  getCurrentTeamRequestStatus,
  getDebutMatchRequestStatus,
  getSeasonsRequestStatus,
  getMatchesInCurrentSeasonRequestStatus,
  currentSeasonId,
  onGetMatchesInSeason
}: Readonly<Props>) => {
  const { t } = useTranslation();

  const [selectedSeasonId, setSelectedSeasonId] = useState<string>(currentSeasonId);
  const [selectedSeasonIds, setSelectedSeasonIds] = useState<string[]>([]);

  const [getRef, setRef] = useDynamicRefs();

  const scrollToMatchesInSeason = (seasonId: string) => {
    const ref = getRef(seasonId);

    if (ref && 'current' in ref) {
      scrollToElement(ref.current as HTMLElement);
    }
  };

  const handleSetSelectedSeasonId = (seasonId: string) => {
    setSelectedSeasonId(seasonId);
    setSelectedSeasonIds([...selectedSeasonIds, seasonId]);
    onGetMatchesInSeason(seasonId);

    setTimeout(() => scrollToMatchesInSeason(seasonId), 500);
  };

  useEffect(
    () => {
      setSelectedSeasonId(currentSeasonId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentSeasonId]
  );

  const matchesInSeasonAccordions = seasons
    ?.filter(
      x =>
        x.seasonId !== currentSeasonId &&
        (selectedSeasonIds.includes(x.seasonId) || ArrayUtils.isNotEmpty(x.matches))
    )
    .map(season => (
      <div key={season.seasonId} ref={setRef(season.seasonId) as React.RefObject<HTMLDivElement>}>
        <Accordion
          key={season.seasonId}
          hoverable
          className="margin-top"
          title={`${t('Matches')} ${t('InSeason')} ${season.seasonName}`}
          withoutPadding
          isStyleDisplayContents
          extendedOnInit={selectedSeasonId === season.seasonId}
        >
          {season.getMatchesRequestStatus?.isLoading ? (
            <ThreeRowsSkeletons />
          ) : season.matches?.length ? (
            <MatchesTableResponsive
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              matches={season.matches as any}
              withStatus
              withStatusAndPlayerStatistics
            />
          ) : (
            <StyledMatchesNoDataMessage>{t('NoData')}</StyledMatchesNoDataMessage>
          )}
        </Accordion>
      </div>
    ));

  return (
    <InnerContentWrapper centerContent subpageWrap>
      <PlayerDetailsCard
        details={details}
        photo={photo}
        currentTeam={currentTeam}
        debutMatch={debutMatch}
        getDetailsRequestStatus={getDetailsRequestStatus}
        getPhotoRequestStatus={getPhotoRequestStatus}
        getCurrentTeamRequestStatus={getCurrentTeamRequestStatus}
        getDebutMatchRequestStatus={getDebutMatchRequestStatus}
      />

      <Accordion
        hoverable
        className="margin-top"
        title={t('Competitions')}
        withoutPadding
        isStyleDisplayContents
      >
        {getSeasonsRequestStatus?.isLoading ? (
          <ThreeRowsSkeletons />
        ) : (
          <PlayerSeasons
            playerId={playerId}
            seasons={seasons}
            isSelectable
            onSetSelectedSeasonId={handleSetSelectedSeasonId}
          />
        )}
      </Accordion>

      <Accordion
        hoverable
        className="margin-top"
        title={`${t('Matches')} ${t('InCurrentSeason')}`}
        withoutPadding
        isStyleDisplayContents
        extendedOnInit={selectedSeasonId === currentSeasonId}
      >
        {getMatchesInCurrentSeasonRequestStatus?.isLoading ? (
          <ThreeRowsSkeletons />
        ) : matchesInCurrentSeason?.length ? (
          <MatchesTableResponsive
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            matches={matchesInCurrentSeason as any}
            withStatus
            withStatusAndPlayerStatistics
          />
        ) : (
          <StyledMatchesNoDataMessage>{t('NoData')}</StyledMatchesNoDataMessage>
        )}
      </Accordion>

      {matchesInSeasonAccordions}
    </InnerContentWrapper>
  );
};

export default memo(PlayerDetailsPage);
