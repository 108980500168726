/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

import { useAppDispatch } from 'store';
import { shouldMakeRequest } from 'utils/requests.utils';
import { EFileIds } from 'consts/fileIds';

import { useSelectFile, useSelectFileRequestStatus } from './file.selectors.hooks';
import { getFile } from './file.thunks';
import urls from 'consts/urls';
import { FileMap } from './models';

export const useGetFileQuery = (id: EFileIds) => {
  const dispatch = useAppDispatch();

  const requestStatus = useSelectFileRequestStatus(id);
  const file = useSelectFile(id);

  useEffect(() => {
    if (shouldMakeRequest(requestStatus)) {
      dispatch(getFile(id));
    }
  }, [id]);

  return { file, requestStatus };
};

export const useGetFilesQuery = (): FileMap => {
  const fileIds = [
    EFileIds.PolicyOnTheProtectionOfChildrenAndMinorsId,
    EFileIds.StatementByParentAndChildAboutPolicyOnTheProtectionOfChildrenAndMinorsId,
    EFileIds.AcademyMembershipDeclarationId,
    EFileIds.AcademyRegulationsId
  ];

  const fileUrlMap = {
    [EFileIds.PolicyOnTheProtectionOfChildrenAndMinorsId]:
      urls.policyOnTheProtectionOfChildrenAndMinors,
    [EFileIds.StatementByParentAndChildAboutPolicyOnTheProtectionOfChildrenAndMinorsId]:
      urls.statementByParentAndChildAboutPolicyOnTheProtectionOfChildrenAndMinors,
    [EFileIds.AcademyMembershipDeclarationId]: urls.academyMembershipDeclaration,
    [EFileIds.AcademyRegulationsId]: urls.academyRegulations
  };

  return fileIds.reduce((acc, id) => {
    const { file, requestStatus } = useGetFileQuery(id);

    if (!file || !requestStatus) {
      return acc;
    }

    return {
      ...acc,
      [id]: { file, requestStatus, url: fileUrlMap[id] }
    };
  }, {} as FileMap);
};
