import React, { memo } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';

import { cardPropTypes, goalPropTypes, personWithIdAndPhotoPropTypesShape } from 'propTypes';
import { sortEvents } from 'utils/utils';
import Column from 'components/Column';

import displayEventData from './displayEventData';
import styles from './MatchHalfEvents.module.scss';

const propTypes = {
  goals: PropTypes.arrayOf(
    PropTypes.shape({
      eventTypeId: PropTypes.string,
      teamId: PropTypes.string,
      player: personWithIdAndPhotoPropTypesShape,
      ...goalPropTypes
    })
  ),
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      eventTypeId: PropTypes.string,
      teamId: PropTypes.string,
      player: personWithIdAndPhotoPropTypesShape,
      ...cardPropTypes
    })
  ),
  changes: PropTypes.arrayOf(
    PropTypes.shape({
      eventTypeId: PropTypes.string.isRequired,
      teamId: PropTypes.string.isRequired,
      minute: PropTypes.number.isRequired,
      goingDownPlayer: personWithIdAndPhotoPropTypesShape.isRequired,
      enteringPlayer: personWithIdAndPhotoPropTypesShape.isRequired
    })
  ),
  guestId: PropTypes.string.isRequired,
  isTwoLineVersion: PropTypes.bool
};

const MatchHalfEvents = ({ goals = [], cards = [], changes = [], guestId, isTwoLineVersion }) => {
  const { t } = useTranslation();

  const getEventsData = () => {
    const eventsArray = [...goals, ...cards, ...changes];

    if (eventsArray.length > 0) {
      return [...eventsArray]
        .sort((previousEvent, nextEvent) => sortEvents(previousEvent, nextEvent))
        .map(event => ({
          ...event,
          reversed: event.teamId === guestId,
          isTwoLineVersion
        }));
    }

    return eventsArray;
  };

  const events = getEventsData().map((event, eventIndex) => displayEventData(event, eventIndex));

  return (
    <section>
      <Column className={styles.event_container}>
        {!isEmpty(goals) || !isEmpty(cards) || !isEmpty(changes) ? (
          events
        ) : (
          <div>{t('NoData')}</div>
        )}
      </Column>
    </section>
  );
};

MatchHalfEvents.propTypes = propTypes;

export default memo(MatchHalfEvents);
