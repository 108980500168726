import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { getArrayWithLength } from 'utils/utils';
import Card from 'components/Card';
import Loader from 'components/Loader';

import styles from './Article.module.scss';
import placeholderStyles from './ArticlePlaceholder.module.scss';

const propTypes = {
  vertical: PropTypes.bool
};

const ArticlePlaceholder = ({ vertical }) => {
  const imageClassName = cx(styles.image, placeholderStyles.image);
  const placeholderClassName = cx(styles.article_container, {
    [styles.vertical]: vertical
  });

  return (
    <div className={placeholderClassName}>
      <Card>
        <div className={styles.article}>
          <div className={styles.image_container}>
            <div className={imageClassName}>
              <Loader />
            </div>
          </div>
          <div className={placeholderStyles.information_container}>
            <div className={placeholderStyles.text_section}>
              <div className={placeholderStyles.title} />
              <div className={placeholderStyles.content}>
                {getArrayWithLength(vertical ? 4 : 6).map((x, index) => (
                  <div key={index} />
                ))}
              </div>
            </div>
            <div className={placeholderStyles.bottom_section}>
              <div className={placeholderStyles.date_container} />
              <div className={placeholderStyles.button_container} />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

ArticlePlaceholder.propTypes = propTypes;

export default memo(ArticlePlaceholder);
