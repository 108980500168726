import { createSelector } from '@reduxjs/toolkit';
import { RequestStatusType } from 'models/common.models';

import { RootState } from 'store';

import { IFileModel, FilesState } from './models';
import { getExistingFile } from './file.functions';
import { EFileIds } from 'consts/fileIds';

const selectSelf = (state: RootState) => state.files;
const getId = (_state: RootState, id: EFileIds) => id;

export const selectFile = createSelector(
  selectSelf,
  getId,
  (state: FilesState, id: EFileIds): IFileModel | undefined | null =>
    getExistingFile(state, id)?.file
);

export const selectFileRequestStatus = createSelector(
  selectSelf,
  getId,
  (state: FilesState, id: EFileIds): RequestStatusType => getExistingFile(state, id)?.requestStatus
);
