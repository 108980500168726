import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { downloadPdfFromBase64, openInNewTabFromBase64 } from 'utils/filesHelper';
import { icons } from 'consts/icons';
import Row from 'components/Row';
import Column from 'components/Column';
import Icon from 'components/Icon';
import Tooltip from 'components/Tooltip';
import Link from 'components/Links/Link';

import styles from './FileLine.module.scss';

type Props = {
  url: string;
  fileName: string;
  base64Content: string;
  isDownloadEnabled?: boolean;
  isOpenInNewTabEnabled?: boolean;
};

const FileLine = ({
  url,
  fileName,
  base64Content,
  isOpenInNewTabEnabled = true,
  isDownloadEnabled = true
}: Readonly<Props>) => {
  const { t } = useTranslation();

  const onOpenInNewTabClick = (): void => openInNewTabFromBase64(base64Content);

  const onDownloadClick = (): void => downloadPdfFromBase64(base64Content, fileName);

  return (
    <Row className={styles.container}>
      <Column>
        <Link to={url} className={styles.container_link} title={fileName}>
          <span className={styles.container_filename}>{fileName}</span>
        </Link>
      </Column>

      <Column>
        {isOpenInNewTabEnabled && (
          <Tooltip html={t('OpenInNewTab')}>
            <Icon
              icon={icons.arrowUpRightFromSquare}
              className={styles.container_icon}
              onClick={onOpenInNewTabClick}
            />
          </Tooltip>
        )}
      </Column>

      <Column>
        {isDownloadEnabled && (
          <Tooltip html={t('Download')}>
            <Icon
              icon={icons.download}
              className={styles.container_icon}
              onClick={onDownloadClick}
            />
          </Tooltip>
        )}
      </Column>
    </Row>
  );
};

export default memo(FileLine);
