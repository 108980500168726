import { connect } from 'react-redux';

import { loginAsync, registerAsync } from './actions';
import AuthPage from './AuthPage';

const mapDispatchToProps = dispatch => ({
  onRegister: model => dispatch(registerAsync(model)),
  onLogin: model => dispatch(loginAsync(model))
});

const AuthPageContainer = connect(undefined, mapDispatchToProps)(AuthPage);

export default AuthPageContainer;
