import styled, { css } from 'styled-components';

import Button from '../Button';
import Icon from '../Icon';

const iconOffset = '0.75rem';

export const StyledIcon = styled(Icon)`
  opacity: 0;
  margin-right: 0;
  margin-left: -${iconOffset};
  transition: all ${({ theme }) => theme.defaultTransition} ease;
`;

const activeIconStyles = css`
  ${StyledIcon} {
    opacity: 1;
    margin-left: ${iconOffset};
  }
`;

export const StyledButton = styled(Button)`
  ${({ isActive }) => isActive && activeIconStyles}

  &:not(:disabled):hover,
  &:focus {
    ${activeIconStyles}
  }
`;
