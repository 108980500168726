import { memo } from 'react';
import styled from 'styled-components';

const CircleImage = styled.img`
  border-radius: 50%;
  object-fit: cover;
  max-height: 145px;
  max-width: 145px;
`;

export default memo(CircleImage);
