import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { icons } from 'consts/icons';
import { appDefaultLanguageCode, ILanguage, supportedLanguages } from 'consts/language';
import { useCurrentLanguage } from 'utils/customHooks';
import ObjectUtils from 'utils/object.utils';

import DropdownItem from './DropdownItem';
import UserAction from './UserAction';

const LanguageBar = () => {
  const { i18n: translator, t } = useTranslation();
  const currentLanguage = useCurrentLanguage() as ILanguage;

  const onLanguageChange = (languageCode: string) => translator.changeLanguage(languageCode);

  useEffect(() => {
    if (ObjectUtils.isEmpty(currentLanguage)) {
      onLanguageChange(appDefaultLanguageCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UserAction
      renderDropdown={() =>
        supportedLanguages.map(language => (
          <DropdownItem
            key={language.code}
            renderItem={className => (
              <button className={className} onClick={() => onLanguageChange(language.code)}>
                {language.displayName}
                {translator.language === language.code && <i className={icons.check} />}
              </button>
            )}
          />
        ))
      }
    >
      {`${t('Language')}: ${currentLanguage.displayName}`}
      <i className={icons.chevronDown} />
    </UserAction>
  );
};

export default memo(LanguageBar);
