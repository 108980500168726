// Generate random unique id / GUID using Math.Random
// Example 1:
// http://slavik.meltser.info/the-efficient-way-to-create-guid-uuid-in-javascript-with-explanation/
export function generate1() {
  function _p8(s) {
    const p = `${Math.random().toString(16)}000000000`.slice(2, 10);
    return s ? `-${p.slice(0, 4)}-${p.slice(4, 8)}` : p;
  }

  return _p8() + _p8(true) + _p8(true) + _p8();
}

export function generate2() {
  function S4() {
    return Math.trunc((1 + Math.random()) * 0x1_00_00)
      .toString(16)
      .slice(1);
  }

  return `${S4() + S4()}-${S4()}-4${S4().slice(0, 3)}-${S4()}-${S4()}${S4()}${S4()}`.toLowerCase();
}

export function generate3() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x1_00_00)
      .toString(16)
      .slice(1);
  }

  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}

export function generate4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = Math.trunc(Math.random() * 16);
    // TODO: or: const r = (Math.random() * 16) | 0;
    // TODO:

    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function generate5() {
  let dt = Date.now();
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = Math.trunc((dt + Math.random() * 16) % 16);
    dt = Math.floor(dt / 16); // TODO: why we need this? it's not used after modified?
    // TODO:

    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
