// TODO: https://dev.to/jam3/creating-a-localized-experience-for-visitors-from-other-countries-using-react-redux-6gk
// TODO: https://phrase.com/blog/posts/react-redux-tutorial-internationalization-with-react-i18n-redux/
// TODO: https://phrase.com/blog/posts/roll-your-own-i18n-solution-react-redux/
// TODO: add translations to redux store?

import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

import { isNotProductionEnvironment } from 'utils/environments.utils';

import { appDefaultLanguageCode } from './consts/language';

i18next
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: appDefaultLanguageCode,
    debug: isNotProductionEnvironment(),
    interpolation: {
      escapeValue: false
    }
  });

export default i18next;
