/* eslint-disable @typescript-eslint/no-explicit-any */

import { ESortDirections } from 'consts/ESortDirections';

export const sortNumericValues = (array: any[], fieldName: string, ascending: boolean) =>
  window
    .structuredClone(array)
    ?.sort((a, b) => (ascending ? a[fieldName] - b[fieldName] : b[fieldName] - a[fieldName]));

export const sortTextValues = (array: any[], fieldName: string, ascending: boolean) =>
  window
    .structuredClone(array)
    ?.sort((a, b) =>
      ascending
        ? a[fieldName].localeCompare(b[fieldName])
        : b[fieldName].localeCompare(a[fieldName])
    );

export const sortDateValues = (array: any[], fieldName: string, ascending: boolean) =>
  window
    .structuredClone(array)
    ?.sort((a, b) =>
      ascending
        ? getTime(new Date(a[fieldName])) - getTime(new Date(b[fieldName]))
        : getTime(new Date(b[fieldName])) - getTime(new Date(a[fieldName]))
    );

// TODO: try to use sortDateValues method
export const sortByDateAscending = (a, b) => {
  if (!a.date) {
    return 1;
  }

  if (!b.date) {
    return -1;
  }

  return getTime(new Date(a.date)) - getTime(new Date(b.date));
};

// TODO: try to use sortDateValues method
export const sortByDateDescending = (a, b) => {
  if (!a.date) {
    return 1;
  }

  if (!b.date) {
    return -1;
  }

  return getTime(new Date(b.date)) - getTime(new Date(a.date));
};

export const sortByLastNameAscending = (array: any[]) => sortTextValues(array, 'lastName', true);

export const getCurrentSortingDirection = (
  currentSortDirection: ESortDirections | null,
  currentColumnIndex: number,
  sortedColumnIndex: number
): ESortDirections => {
  let sortingDirection: ESortDirections;

  switch (currentSortDirection) {
    case ESortDirections.none:
      sortingDirection = ESortDirections.ascending;
      break;
    case ESortDirections.ascending:
      sortingDirection = ESortDirections.descending;
      break;
    case ESortDirections.descending:
      sortingDirection = ESortDirections.none;
      break;
    default:
      sortingDirection = ESortDirections.none;
      break;
  }

  const currentSortingDirection =
    currentColumnIndex === sortedColumnIndex ? sortingDirection : ESortDirections.ascending;

  return currentSortingDirection;
};

export const getSortDirectionTextTranslationKey = (
  isSortingHeader: boolean,
  sortDirection: ESortDirections | null
) => {
  if (!isSortingHeader || (isSortingHeader && sortDirection === ESortDirections.none)) {
    return 'SortAscending';
  }

  if (isSortingHeader && sortDirection === ESortDirections.ascending) {
    return 'SortDescending';
  }

  return 'DisableSorting';
};

const getTime = (date?: Date): number => (date != null ? date.getTime() : 0);
