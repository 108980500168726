import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { queuePropTypes } from 'propTypes';
import { icons } from 'consts/icons';
import Card from 'components/Card';
import Column from 'components/Column';
import DateInfo from 'components/DateInfo';
import TeamLink from 'components/Links/TeamLink';
import MatchesTableResponsive from 'components/MatchesTableResponsive';
import Row from 'components/Row';

import styles from './CompetitionsScheduleQueue.module.scss';

const propTypes = {
  ...queuePropTypes,
  teamIdContext: PropTypes.string,
  filterValue: PropTypes.string
};

const CompetitionsScheduleQueue = ({
  number,
  dateFrom,
  dateTo,
  roundName,
  pauseTeam,
  matches,
  teamIdContext,
  filterValue
}) => {
  const { t } = useTranslation();

  const [isExtended, setIsExtended] = useState(true);
  const toggleIsExtended = () => setIsExtended(!isExtended);

  const queueHeader = `${number}. ${t('Queue')} ${roundName.toLowerCase()}`;
  const queueIsVisible = queueHeader.toLowerCase().includes(filterValue.trim().toLowerCase());

  const queueCardClassName = cx(styles.queue, {
    [styles.queue_invisible]: !queueIsVisible
  });

  const queueNumberColumnClassName = cx(styles.queue_header_numberColumn);
  const queueDateColumnClassName = cx(styles.queue_header_dateColumn);
  const queuePauseTeamColumnClassName = cx(styles.queue_header_pauseTeamColumn);
  const queuePauseTeamClassName = cx(styles.queue_header_pauseTeamColumn_pauseTeam);
  const iconColumnClassName = cx(styles.queue_header_iconColumn);

  const iconClassName = cx(icons.chevronDown, {
    [styles.rotated]: isExtended
  });

  return (
    <Card className={queueCardClassName} hoverable>
      <Row className={styles.queue_header} onClick={toggleIsExtended}>
        <Column className={queueNumberColumnClassName}>
          <h3>{queueHeader}</h3>
        </Column>
        <Column className={queueDateColumnClassName}>
          <span>
            {dateFrom !== dateTo ? (
              <>
                <DateInfo date={dateFrom} /> - <DateInfo date={dateTo} />
              </>
            ) : (
              <DateInfo date={dateFrom} />
            )}
          </span>
        </Column>
        <Column className={queuePauseTeamColumnClassName}>
          {pauseTeam && (
            <span className={queuePauseTeamClassName}>
              {t('Pause')}: <TeamLink {...pauseTeam} />
            </span>
          )}
        </Column>
        <Column className={iconColumnClassName}>
          <i className={iconClassName} />
        </Column>
      </Row>
      <MatchesTableResponsive
        matches={matches}
        teamIdContext={teamIdContext}
        isExtended={isExtended}
      />
    </Card>
  );
};

CompetitionsScheduleQueue.propTypes = propTypes;

export default memo(CompetitionsScheduleQueue);
