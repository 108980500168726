import { memo } from 'react';
import styled from 'styled-components';
import { placeholderImageLoading } from 'styling';

import StyledImage from './StyledImage';

const SlidePlaceholder = styled(StyledImage)`
  display: block !important;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.darkPlaceholderColor};
  animation: ${placeholderImageLoading} 1250ms ease-in-out infinite;
  border-radius: 0.5rem;
`;

export default memo(SlidePlaceholder);
