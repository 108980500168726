import ArrayUtils from 'utils/array.utils';

import actionTypes from './actionTypes';

const initialState = {};

export default function teamsReducer(state = initialState, action) {
  let elementToUpdate = undefined;
  let elementToUpdateIndex = undefined;

  if (
    (actionTypes.STORE_TEAM_MATCHES_IN_SEASON ||
      actionTypes.STORE_TEAM_COACHES_IN_SEASON ||
      actionTypes.STORE_TEAM_PLAYERS_WITH_MATCHES_AND_EVENTS_IN_SEASON) &&
    action?.data?.teamId &&
    action?.data?.seasonId
  ) {
    const elementToUpdatePredicate = x => x.seasonId === action.data.seasonId;
    elementToUpdate = state[action.data.teamId]?.seasons?.find(elementToUpdatePredicate);
    elementToUpdateIndex = state[action.data.teamId]?.seasons?.findIndex(elementToUpdatePredicate);
  }

  switch (action.type) {
    case actionTypes.STORE_TEAM_DETAILS:
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          details: { id: action.data.id, ...action.data.details }
        }
      };
    case actionTypes.STORE_TEAM_SEASONS_LIST:
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          seasons: action.data.seasons
        }
      };
    case actionTypes.STORE_TEAM_SEASONS_ADVANCED_LIST:
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          seasons: action.data.seasons
        }
      };
    case actionTypes.STORE_TEAM_COACHES_IN_CURRENT_SEASON:
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          coachesInCurrentSeason: action.data.coachesInCurrentSeason
        }
      };
    case actionTypes.STORE_TEAM_PLAYERS_WITH_MATCHES_AND_EVENTS_IN_CURRENT_SEASON:
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          playersWithMatchesAndEventsInCurrentSeason:
            action.data.playersWithMatchesAndEventsInCurrentSeason
        }
      };
    case actionTypes.STORE_TEAM_MATCHES_IN_CURRENT_SEASON:
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          matchesInCurrentSeason: action.data.matchesInCurrentSeason
        }
      };
    case actionTypes.STORE_TEAM_MATCHES_IN_SEASON:
      return {
        ...state,
        [action.data.teamId]: {
          ...state[action.data.teamId],
          seasons: ArrayUtils.updateAndReturnNewArray(
            state[action.data.teamId]?.seasons,
            elementToUpdateIndex,
            {
              seasonId: action.data.seasonId,
              ...elementToUpdate,
              matches: [...action.data.matches]
            }
          )
        }
      };
    case actionTypes.STORE_TEAM_COACHES_IN_SEASON:
      return {
        ...state,
        [action.data.teamId]: {
          ...state[action.data.teamId],
          seasons: ArrayUtils.updateAndReturnNewArray(
            state[action.data.teamId]?.seasons,
            elementToUpdateIndex,
            {
              seasonId: action.data.seasonId,
              ...elementToUpdate,
              coaches: [...action.data.coaches]
            }
          )
        }
      };
    case actionTypes.STORE_TEAM_PLAYERS_WITH_MATCHES_AND_EVENTS_IN_SEASON:
      return {
        ...state,
        [action.data.teamId]: {
          ...state[action.data.teamId],
          seasons: ArrayUtils.updateAndReturnNewArray(
            state[action.data.teamId]?.seasons,
            elementToUpdateIndex,
            {
              seasonId: action.data.seasonId,
              ...elementToUpdate,
              playersWithMatchesAndEvents: action.data.playersWithMatchesAndEventsInSeason
            }
          )
        }
      };

    // -------------------------------------------- TO TEST -----------------------------------------

    case actionTypes.STORE_TEAM_PLAYERS_IN_CURRENT_SEASON:
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          playersInCurrentSeason: action.data.playersInCurrentSeason
        }
      };
    case actionTypes.STORE_TEAM_PLAYER_MATCHES_AND_EVENTS_IN_CURRENT_SEASON:
      return {
        ...state,
        [action.data.teamId]: {
          ...state[action.data.teamId],
          playersInCurrentSeason: ArrayUtils.updateAndReturnNewArray(
            state[action.data.teamId]?.playersInCurrentSeason,
            elementToUpdateIndex,
            {
              playerId: action.data.playerId,
              ...elementToUpdate
              // playersWithMatchesAndEvents: action.data.playersWithMatchesAndEventsInSeason
            }
          )
        }
      };
    default:
      return state;
  }
}
