import { memo } from 'react';
import styled from 'styled-components';

const StyledWrapper = styled.svg`
  border-radius: 50%;
  object-fit: cover;
  max-height: 145px;
  max-width: 145px;
  min-width: 1rem;
  background-color: var(--position-opaque, rgb(194, 240, 220));
`;

export default memo(StyledWrapper);
