export function getUserLabel(data: {
  firstName: string;
  lastName: string;
  login: string;
  email: string;
}): string {
  if (!data) {
    return '';
  }

  if (data.firstName && data.lastName) {
    return `${data.firstName} ${data.lastName}`;
  }

  if (data.login) {
    return data.login;
  }

  if (data.email) {
    return data.email;
  }

  return '';
}
