export function componentLoader(
  lazyComponent: () => Promise<unknown>,
  attemptsLeft = 5,
  interval = 1500
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          if (attemptsLeft === 1) {
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          componentLoader(lazyComponent, attemptsLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

export async function componentLoaderAsync(
  lazyComponent: () => Promise<unknown>,
  attemptsLeft = 2,
  interval = 1000
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  try {
    return await lazyComponent();
  } catch (error) {
    if (attemptsLeft) {
      await wait(interval);
      return componentLoaderAsync(lazyComponent, attemptsLeft - 1, interval);
    } else {
      throw error; // TODO: new Error(error);
    }
  }
}

function wait(ms: number | undefined) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
