import React, { memo, useState } from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ArticleGalleryItem from './ArticleGalleryItem';
import styles from './ArticleGalleryRow.module.scss';

// TODO: react-images package - note from npm
// ⚠️ Warning!
// Don't use this in a new project. This package hasn't been properly maintained in a long time and there are much better options available.
// Instead, try...
// React Responsive Carousel (http://react-responsive-carousel.js.org/)

const propTypes = {
  photos: PropTypes.arrayOf(PropTypes.string).isRequired
};

// TODO: Vorbert -> ujednolicić te galerie z albumami
const ArticleGalleryRow = ({ photos }) => {
  const [galleryIsOpen, setGalleryIsOpen] = useState(false);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(undefined);

  const closeGallery = () => setGalleryIsOpen(false);

  const openGallery = photoId => {
    setCurrentPhotoIndex(photoId);
    setGalleryIsOpen(true);
  };

  const { t } = useTranslation();

  return (
    <div className={styles.photo_container}>
      {photos.map((photo, photoIndex) => (
        <ArticleGalleryItem
          photo={photo}
          key={photoIndex}
          onClick={() => openGallery(photoIndex)}
        />
      ))}

      <ModalGateway>
        {galleryIsOpen && (
          <Modal onClose={closeGallery}>
            <Carousel
              currentIndex={currentPhotoIndex}
              frameProps={{ autoSize: 'height' }}
              views={[...photos].map((photo, photoIndex) => ({
                caption: `${t('Photo')} ${photoIndex + 1}/${photos.length}`,
                source: photo
              }))}
            />
          </Modal>
        )}
      </ModalGateway>
    </div>
  );
};

ArticleGalleryRow.propTypes = propTypes;

export default memo(ArticleGalleryRow);
