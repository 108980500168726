import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { icons } from 'consts/icons';

import styles from './ChangeGoingDownIcon.module.scss';

const ChangeGoingDownIcon = () => {
  const { t } = useTranslation();

  const arrowDownClassName = cx(icons.shortArrowDown, styles.icon_red);

  return <i className={arrowDownClassName} title={t('GoingDownPlayer')} />;
};

export default memo(ChangeGoingDownIcon);
