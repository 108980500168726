import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import InnerContentWrapper from 'components/InnerContentWrapper';
import PageHeader from 'components/PageHeader';

import ArticlesList from './ArticlesList';

const propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      photo: PropTypes.string,
      title: PropTypes.string,
      content: PropTypes.string,
      createdDate: PropTypes.string,
      author: PropTypes.string
    })
  )
};

const ArticlesPage = ({ articles = [] }) => {
  const { t } = useTranslation();
  return (
    <InnerContentWrapper>
      <PageHeader title={t('ArticleList')} />
      <ArticlesList articles={articles} />
    </InnerContentWrapper>
  );
};

ArticlesPage.propTypes = propTypes;

export default memo(ArticlesPage);
