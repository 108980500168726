import React, { memo } from 'react';
import cx from 'classnames';

import TeamLink from 'components/Links/TeamLink';

import styles from './CardTableRow.module.scss';
import { EColumnIndexes, EColumnNames } from './table.consts';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  team: any; // TODO: type
  hoveredColumnIndex: number | null;
  lowestPositionNumber: number;
};
const CardTableRow = ({ team, hoveredColumnIndex, lowestPositionNumber }: Readonly<Props>) => {
  // TODO: move to the appSettings or to DB and get from backend
  const promotedTeamsNumber = 1;
  const relegatedTeamsNumber = 4;

  const getGoalsDifference = () => `${team.goalsDifference > 0 ? '+' : ''}${team.goalsDifference}`;

  const getCellClassName = (columnName: string, columnIndex: number): string =>
    cx(styles.cell, styles[`cell_${columnName}`], {
      [styles.cell_active]: columnIndex === hoveredColumnIndex
    });

  const isHighlightedBlue = () => team.position <= promotedTeamsNumber;
  const isHighlightedRed = () => team.position > lowestPositionNumber - relegatedTeamsNumber;

  const rowClassName = cx(styles.row, {
    [styles['row--blue']]: isHighlightedBlue(),
    [styles['row--red']]: isHighlightedRed()
  });

  return (
    <tr className={rowClassName}>
      <td className={getCellClassName(EColumnNames.position, EColumnIndexes.position)}>
        {team.position}
      </td>
      <td className={getCellClassName(EColumnNames.name, EColumnIndexes.name)}>
        <TeamLink id={team.id} logo={team.logo} name={team.name} />
      </td>
      <td
        className={getCellClassName(
          EColumnNames.matchesPlayedNumber,
          EColumnIndexes.matchesPlayedNumber
        )}
      >
        {team.matchesPlayedNumber}
      </td>
      <td className={getCellClassName(EColumnNames.points, EColumnIndexes.points)}>
        {team.points}
      </td>
      <td className={getCellClassName(EColumnNames.goalsScored, EColumnIndexes.goalsScored)}>
        {team.goalsScored}
      </td>
      <td className={getCellClassName(EColumnNames.goalsLost, EColumnIndexes.goalsLost)}>
        {team.goalsLost}
      </td>
      <td
        className={getCellClassName(EColumnNames.goalsDifference, EColumnIndexes.goalsDifference)}
      >
        {getGoalsDifference()}
      </td>
    </tr>
  );
};

export default memo(CardTableRow);
