import intersection from 'lodash/intersection';

import { useAppSelector } from 'store';

// TODO: add types in file

export function useIsLogged() {
  return useAppSelector(state => state.auth?.currentUser);
}

export function useGetUserPermissions() {
  return useAppSelector(state => state.auth?.currentUser?.permissions || []);
}

export function useGetUserRoles() {
  return useAppSelector(state => state.auth?.currentUser?.roles || []);
}

export const useCanTicketsGenerate = () => {
  // TODO:
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isLogged = useIsLogged();

  // TODO:
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const userPermissions = useGetUserPermissions();

  return isLogged && !!intersection(['Tickets generation', 'admin'], userPermissions).length;
};

export function useCanPostersGenerate() {
  // TODO:
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isLogged = useIsLogged();

  // TODO:
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const userPermissions = useGetUserPermissions();

  return isLogged && !!intersection(['Posters generation', 'admin'], userPermissions).length;
}
