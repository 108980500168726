import React, { memo } from 'react';

import { useAppDispatch } from 'store';
import Button from 'components/Button';
import { displayToastr } from 'components/Toastr/actions';
import toastTypes from 'components/Toastr/ToastTypes';

const ToastExample = () => {
  const dispatch = useAppDispatch();

  const getRandomIndex = () => Math.floor(Math.random() * Object.values(toastTypes).length);
  const addToast = () =>
    dispatch(displayToastr('Przykładowy tościk :D', Object.values(toastTypes)[getRandomIndex()]));

  return <Button onClick={addToast}>Przykładowy toast</Button>;
};

export default memo(ToastExample);
