import styled, { css } from 'styled-components';

type Props = {
  alignItems?: string;
  justifyContent?: string;
  alignSelf?: string;
};

const Flex = styled.div<Props>`
  display: flex;

  ${({ alignItems }) =>
    alignItems &&
    css`
      align-items: ${alignItems};
    `}

  ${({ justifyContent }) =>
    justifyContent &&
    css`
      justify-content: ${justifyContent};
    `}

${({ alignSelf }) =>
    alignSelf &&
    css`
      align-self: ${alignSelf};
    `}
`;

export default Flex;
