import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { icons } from 'consts/icons';

import styles from './GoalIcon.module.scss';

const propTypes = {
  isOwn: PropTypes.bool,
  withOwnText: PropTypes.bool,
  isPenalty: PropTypes.bool,
  withPenaltyText: PropTypes.bool
};

const GoalIcon = ({ isOwn, withOwnText = true, isPenalty, withPenaltyText = true }) => {
  const { t } = useTranslation();

  const goalClassName = cx(icons.ball, {
    [styles.icon_green]: !isOwn && !isPenalty,
    [styles.icon_red]: isOwn && !isPenalty,
    [styles.icon_orange]: !isOwn && isPenalty
  });

  const title = !isOwn ? t('ScoredGoal') : t('OwnGoal');

  return (
    <>
      <i className={goalClassName} title={title} />
      {isOwn && withOwnText && <span className={styles.own_goal}>&nbsp;({t('OwnGoal')})</span>}
      {isPenalty && withPenaltyText && (
        <span className={styles.penalty_goal}>&nbsp;({t('Penalty')})</span>
      )}
    </>
  );
};

GoalIcon.propTypes = propTypes;

export default memo(GoalIcon);
