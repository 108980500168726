import React, { memo } from 'react';
import PropTypes from 'prop-types';
// TODO: coach default avatar
import defaultAvatar from 'assets/images/BoardMember_default_avatar.svg';

import { personWithIdAndPhotoPropTypes } from 'propTypes';
import urls from 'consts/urls';

import PersonTile from '../PersonTile';

const propTypes = {
  ...personWithIdAndPhotoPropTypes,
  coachType: PropTypes.string.isRequired
};

const CoachCard = ({ id, photo, firstName, lastName, coachType }) => {
  const coachDetailsUrl = urls.coachDetails(id);

  return (
    <PersonTile
      photo={photo || defaultAvatar}
      firstName={firstName}
      lastName={lastName}
      description={coachType}
      detailsUrl={coachDetailsUrl}
    />
  );
};

CoachCard.propTypes = propTypes;

export default memo(CoachCard);
