import React, { memo } from 'react';
import cx from 'classnames';

import styles from './TextReavealer.module.scss';

type Props = {
  text: string;
  className: string;
};

// TODO: Vorbert -> przerobić na styled-component i ujednolicić z <WorkingOnWebsiteText />, bo nigdzie indziej ten komponent (<TextRevealer />) nie jest używany
const TextReavealer = ({ text, className }: Readonly<Props>) => {
  const textClassName = cx(styles.text, className);

  return <h2 className={textClassName}>{text}</h2>;
};

export default memo(TextReavealer);
