import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import styles from './FormButtonsContainer.module.scss';
import FormSubmitButton from './FormSubmitButton';
import SwitchFormsButton from './SwitchFormsButton';

const propTypes = {
  renderSubmitButton: PropTypes.func.isRequired,
  renderSecondaryButton: PropTypes.func
};

const FormButtonsContainer = ({ renderSubmitButton, renderSecondaryButton }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.button_container}>
      {renderSubmitButton(FormSubmitButton)}
      {renderSecondaryButton && (
        <>
          <div className={styles.button_divider}>{t('Or').toLowerCase()}</div>
          {renderSecondaryButton(SwitchFormsButton)}
        </>
      )}
    </div>
  );
};

FormButtonsContainer.propTypes = propTypes;

export default memo(FormButtonsContainer);
