import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ELanguageDisplayNames, ILanguage } from 'consts/language';
import { useCurrentLanguage, useImmediateInterval } from 'utils/customHooks';
import { getDifferenceDurationTime } from 'utils/dateFormat';
import { getCountdownText } from 'utils/timeTranslations';

import styles from './MatchCountDown.module.scss';

enum MatchPart {
  FIRST_HALF = 'FIRST_HALF',
  HALF_TIME = 'HALF_TIME',
  SECOND_HALF = 'SECOND_HALF'
}

interface TimeState {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  isBeforeMatch: boolean;
  matchMinute: number;
  totalMinutes: number;
  matchPart: MatchPart;
}

const FIRST_HALF_DURATION = 45;
const HALF_TIME_BREAK = 15;

type CurrentMatchCountdownProps = {
  matchMinute: number;
  seconds: number;
  matchPart: MatchPart;
  totalMinutes: number;
  matchDuration: number;
};

const CurrentMatchCountdown: React.FC<CurrentMatchCountdownProps> = ({
  matchMinute,
  seconds,
  matchPart,
  totalMinutes,
  matchDuration
}: Readonly<CurrentMatchCountdownProps>) => {
  const { t } = useTranslation();

  const matchSeconds = Math.floor(seconds);
  const formattedSeconds = matchSeconds.toString().padStart(2, '0');

  const currentHalfDuration =
    matchPart === MatchPart.SECOND_HALF ? matchDuration : FIRST_HALF_DURATION;

  if (matchPart === MatchPart.HALF_TIME) {
    const breakMinutesLeft = HALF_TIME_BREAK - (totalMinutes - FIRST_HALF_DURATION);
    return (
      <div className={styles.countdown_live}>
        {t('HalfTimeBreak')} {t('Left')}: {Math.ceil(breakMinutesLeft)}
      </div>
    );
  }

  if (matchMinute > currentHalfDuration) {
    const extraMinutes = matchMinute - currentHalfDuration;
    return (
      <div className={styles.countdown_live}>
        {`${currentHalfDuration}+${extraMinutes}:${formattedSeconds}'`}
      </div>
    );
  }

  return <div className={styles.countdown_live}>{`${matchMinute}:${formattedSeconds}'`}</div>;
};

type Props = {
  destinationTime: Date;
  matchDuration?: number; // in minutes, default 90
  hasExtraTime?: boolean;
};

const MatchCountDown: React.FC<Props> = ({
  destinationTime,
  matchDuration = 90,
  hasExtraTime = false
}: Readonly<Props>) => {
  const { t } = useTranslation();
  const currentLanguage = useCurrentLanguage() as ILanguage;
  const isPolish = currentLanguage.displayName === ELanguageDisplayNames.Polski;

  const [timeState, setTimeState] = useState<TimeState>({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    isBeforeMatch: true,
    matchMinute: 0,
    totalMinutes: 0,
    matchPart: MatchPart.FIRST_HALF
  });

  useImmediateInterval(() => {
    const now = new Date();
    const matchDate = new Date(destinationTime);
    const differenceTime = getDifferenceDurationTime(now, matchDate);
    const isBeforeMatch = now < matchDate;

    const totalMinutes = Math.floor(Math.abs(differenceTime.asMinutes()));

    let matchPart = MatchPart.FIRST_HALF;
    let matchMinute = totalMinutes;

    if (
      totalMinutes >= FIRST_HALF_DURATION &&
      totalMinutes < FIRST_HALF_DURATION + HALF_TIME_BREAK
    ) {
      matchPart = MatchPart.HALF_TIME;
      matchMinute = FIRST_HALF_DURATION;
    } else if (totalMinutes > FIRST_HALF_DURATION + HALF_TIME_BREAK) {
      matchPart = MatchPart.SECOND_HALF;
      matchMinute = totalMinutes - HALF_TIME_BREAK;
    }

    if (!hasExtraTime && matchMinute > matchDuration) {
      matchMinute = matchDuration;
    }

    setTimeState({
      days: Math.floor(Math.abs(differenceTime.asDays())),
      hours: Math.abs(differenceTime.hours()),
      minutes: Math.abs(differenceTime.minutes()),
      seconds: Math.abs(differenceTime.seconds()),
      isBeforeMatch,
      matchMinute,
      totalMinutes,
      matchPart
    });
  }, 1000);

  const isMatchInProgress = !timeState.isBeforeMatch && timeState.totalMinutes <= matchDuration;

  const isMatchFinished = !timeState.isBeforeMatch && timeState.totalMinutes > matchDuration;

  const countdownText = getCountdownText(
    timeState.days,
    timeState.hours,
    timeState.minutes,
    timeState.seconds,
    isPolish
  );

  const renderContent = (): React.ReactNode => {
    if (isMatchFinished) {
      return `${countdownText} ${t('Ago')}`;
    }

    if (isMatchInProgress) {
      return (
        <CurrentMatchCountdown
          matchMinute={timeState.matchMinute}
          seconds={timeState.seconds}
          matchPart={timeState.matchPart}
          totalMinutes={timeState.totalMinutes}
          matchDuration={matchDuration}
        />
      );
    }

    if (timeState.isBeforeMatch) {
      return `${t('WillStartIn')} ${countdownText}`;
    }

    return null;
  };

  return <div className={styles.countdown}>{renderContent()}</div>;
};

export default memo(MatchCountDown);
