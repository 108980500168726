import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { icons } from 'consts/icons';
import urls from 'consts/urls';
import ColumnCard from 'components/ColumnCard';
import IconButton from 'components/IconButton';

import styles from './GalleryItem.module.scss';

const propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  thumbnailContentBase64: PropTypes.string.isRequired
};

const GalleryItem = ({ id, name, thumbnailContentBase64 }) => {
  const { t } = useTranslation();

  const linkToTheAlbum = urls.galleryAlbum(id);
  const linkTitle = `${t('GoToTheAlbum')}: ${name}`;
  const [isActive, setIsActive] = useState(false);

  return (
    <ColumnCard
      className={styles.item}
      hoverable
      hoverableCursor
      to={linkToTheAlbum}
      title={linkTitle}
      autoHeight
      onHoverChange={isBeingHovered => setIsActive(isBeingHovered)}
    >
      <div className={styles.item_photo_container}>
        <img alt={t('AlbumMiniature')} className={styles.item_photo} src={thumbnailContentBase64} />
      </div>
      <div className={styles.item_body}>
        <h3 className={styles.item_name}>{name}</h3>
        <IconButton
          className={styles.item_button}
          to={linkToTheAlbum}
          title={linkTitle}
          icon={icons.chevronRight}
          isActive={isActive}
          disabled
        >
          {t('GoToTheAlbum')}
        </IconButton>
      </div>
    </ColumnCard>
  );
};

GalleryItem.propTypes = propTypes;

export default memo(GalleryItem);
