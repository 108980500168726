import React from 'react';

import cardTypes from 'consts/cardTypes';
import matchShortEventTypes from 'consts/matchShortEventTypes';

import AssistShortEvent from './match-events/AssistShortEvent';
import ChangedEnteringShortEvent from './match-events/ChangedEnteringShortEvent';
import ChangedGoingDownShortEvent from './match-events/ChangedGoingDownShortEvent';
import GivenCardRedShortEvent from './match-events/GivenCardRedShortEvent';
import GivenCardSecondYellowShortEvent from './match-events/GivenCardSecondYellowShortEvent';
import GivenCardYellowShortEvent from './match-events/GivenCardYellowShortEvent';
import ScoredGoalShortEvent from './match-events/ScoredGoalShortEvent';

// TODO: ujednolicić metody displayEventData i displayEventShortData oraz komponenty event i shortEvent użyte w tych metodach

const displayEventShortData = (event, eventIndex) => {
  switch (event.shortEventType) {
    case matchShortEventTypes.assist:
      return <AssistShortEvent key={eventIndex} minute={event.minute} />;

    case matchShortEventTypes.scoredGoal:
      return (
        <ScoredGoalShortEvent
          key={eventIndex}
          minute={event.minute}
          isOwn={event.isOwn}
          isPenalty={event.isPenalty}
        />
      );
    case matchShortEventTypes.givenCard:
      if (event.cardType === cardTypes.yellow && !event.isSecond) {
        return (
          <GivenCardYellowShortEvent key={eventIndex} minute={event.minute} count={event.count} />
        );
      }

      if (event.cardType === cardTypes.yellow && event.isSecond) {
        return (
          <GivenCardSecondYellowShortEvent
            key={eventIndex}
            minute={event.minute}
            count={event.count}
          />
        );
      }

      if (event.cardType === cardTypes.red) {
        return (
          <GivenCardRedShortEvent key={eventIndex} minute={event.minute} count={event.count} />
        );
      }

      return ''; // note: nie powinno być takiego przypadku
    case matchShortEventTypes.playerChanged:
      if (event.isEnter) {
        return <ChangedEnteringShortEvent key={eventIndex} minute={event.minute} />;
      }

      return <ChangedGoingDownShortEvent key={eventIndex} minute={event.minute} />;
    default:
      return '';
  }
};

export default displayEventShortData;
