import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

import fieldNames from 'consts/fieldNames';
import formNames from 'consts/formNames';

import { updateUserInformationAsync } from '../actions';
import UserInformationForm from './UserInformationForm';

const selector = formValueSelector(formNames.myProfileForm);

const mapStateToProps = state => ({
  initialValues: {
    login: state.auth.currentUser.login,
    avatar: state.auth.currentUser.avatar
  },
  firstName: state.auth.currentUser.firstName,
  avatar: selector(state, fieldNames.myProfileUserInformationForm.avatar)
});

const mapDispatchToProps = dispatch => ({
  onSubmit: model => dispatch(updateUserInformationAsync(model))
});

const UserInformationFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserInformationForm);

export default UserInformationFormContainer;
