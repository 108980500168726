import { createSelector } from '@reduxjs/toolkit';
import { RequestStatusType } from 'models/common.models';

import { RootState } from 'store';

import {
  IPlayerCurrentTeamModel,
  IPlayerDebutMatchModel,
  IPlayerDetailsModel,
  IPlayerMatchModel,
  IPlayerModel,
  IPlayerSeasonModel,
  PlayersState
} from './models';
import { getExistingPlayer, getExistingSeason } from './player.functions';

const selectSelf = (state: RootState) => state.players;
const getId = (_state: RootState, id: string) => id;
const getSeasonId = (_state: RootState, _id: string, seasonId: string) => seasonId;

export const selectPlayer = createSelector(
  selectSelf,
  getId,
  (state: PlayersState, id: string): IPlayerModel | undefined | null => getExistingPlayer(state, id)
);

export const selectGetPlayerDetailsRequestStatus = createSelector(
  selectSelf,
  getId,
  (state: PlayersState, id: string): RequestStatusType =>
    getExistingPlayer(state, id)?.getDetailsRequestStatus
);

export const selectPlayerDetails = createSelector(
  selectSelf,
  getId,
  (state: PlayersState, id: string): IPlayerDetailsModel | undefined | null =>
    getExistingPlayer(state, id)?.details
);

export const selectGetPlayerPhotoRequestStatus = createSelector(
  selectSelf,
  getId,
  (state: PlayersState, id: string): RequestStatusType =>
    getExistingPlayer(state, id)?.getPhotoRequestStatus
);

export const selectPlayerPhoto = createSelector(
  selectSelf,
  getId,
  (state: PlayersState, id: string): string | undefined | null =>
    getExistingPlayer(state, id)?.photo
);

export const selectGetPlayerCurrentTeamRequestStatus = createSelector(
  selectSelf,
  getId,
  (state: PlayersState, id: string): RequestStatusType =>
    getExistingPlayer(state, id)?.getCurrentTeamRequestStatus
);

export const selectPlayerCurrentTeam = createSelector(
  selectSelf,
  getId,
  (state: PlayersState, id: string): IPlayerCurrentTeamModel | undefined | null =>
    getExistingPlayer(state, id)?.currentTeam
);

export const selectGetPlayerDebutMatchRequestStatus = createSelector(
  selectSelf,
  getId,
  (state: PlayersState, id: string): RequestStatusType =>
    getExistingPlayer(state, id)?.getDebutMatchRequestStatus
);

export const selectPlayerDebutMatch = createSelector(
  selectSelf,
  getId,
  (state: PlayersState, id: string): IPlayerDebutMatchModel | undefined | null =>
    getExistingPlayer(state, id)?.debutMatch
);

export const selectGetPlayerSeasonsRequestStatus = createSelector(
  selectSelf,
  getId,
  (state: PlayersState, id: string): RequestStatusType =>
    getExistingPlayer(state, id)?.getSeasonsRequestStatus
);

export const selectPlayerSeasons = createSelector(
  selectSelf,
  getId,
  (state: PlayersState, id: string): IPlayerSeasonModel[] | undefined | null =>
    getExistingPlayer(state, id)?.seasons
);

export const selectGetPlayerSeasonRequestStatus = createSelector(
  [selectSelf, getId, getSeasonId],
  (state: PlayersState, id: string, seasonId: string): RequestStatusType =>
    getExistingSeason(state, id, seasonId)?.getSeasonRequestStatus
);

export const selectPlayerSeason = createSelector(
  [selectSelf, getId, getSeasonId],
  (state: PlayersState, id: string, seasonId: string): IPlayerSeasonModel | undefined | null =>
    getExistingSeason(state, id, seasonId)
);

export const selectGetPlayerMatchesInSeasonRequestStatus = createSelector(
  [selectSelf, getId, getSeasonId],
  (state: PlayersState, id: string, seasonId: string): RequestStatusType =>
    getExistingSeason(state, id, seasonId)?.getMatchesRequestStatus
);

export const selectPlayerMatchesInSeason = createSelector(
  [selectSelf, getId, getSeasonId],
  (state: PlayersState, id: string, seasonId: string): IPlayerMatchModel[] | undefined | null =>
    getExistingSeason(state, id, seasonId)?.matches
);
