export class StringUtils {
  static capitalize(input: string): string {
    return input.charAt(0).toUpperCase() + input.slice(1);
  }

  static uppercase(input: string): string {
    return input?.toUpperCase();
  }

  static lowercase(input: string): string {
    return input?.toLowerCase();
  }

  static isNotEmpty(input: string): boolean {
    if (input === undefined || input === null || input === '') {
      return false;
    }

    return true;
  }
}
