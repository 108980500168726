import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';

import {
  cardPropTypes,
  goalPropTypes,
  matchPropTypesShape,
  personWithIdAndPhotoPropTypesShape,
  playerInSquadPropTypesShape
} from 'propTypes';
import ArrayUtils from 'utils/array.utils';
import { downloadFile } from 'utils/filesHelper';
import Card from 'components/Card';
import InnerContentContainer from 'components/InnerContentWrapper';
import LoadingButton from 'components/LoadingButton';
import LoadingPageData from 'components/LoadingPageData';
import MatchesTableResponsive from 'components/MatchesTableResponsive';

import MatchDetailsCard from './MatchDetailsCard';
import service from './service';

const propTypes = {
  id: PropTypes.string,
  matchDetails: PropTypes.shape({
    host: PropTypes.shape({
      id: PropTypes.string,
      logo: PropTypes.string,
      name: PropTypes.string
    }).isRequired,
    guest: PropTypes.shape({
      id: PropTypes.string,
      logo: PropTypes.string,
      name: PropTypes.string
    }).isRequired,
    hostGoals: PropTypes.number,
    guestGoals: PropTypes.number,
    hostFirstHalfGoals: PropTypes.number,
    guestFirstHalfGoals: PropTypes.number,
    hostSecondHalfGoals: PropTypes.number,
    guestSecondHalfGoals: PropTypes.number,
    date: PropTypes.string,
    comment: PropTypes.string,
    isWalkoverForGuest: PropTypes.bool,
    isWalkoverForHost: PropTypes.bool,
    walkoverCause: PropTypes.string,
    type: PropTypes.string,
    league: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    }),
    queue: PropTypes.shape({
      id: PropTypes.string,
      number: PropTypes.number
    }),
    stadium: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      address: PropTypes.string
    }),
    referees: PropTypes.arrayOf(personWithIdAndPhotoPropTypesShape), // TODO: change type to referee with function
    footballAssociationActivists: PropTypes.arrayOf(personWithIdAndPhotoPropTypesShape), // TODO: change type to person with function hostCoaches: PropTypes.arrayOf(personWithIdAndPhotoPropTypesShape), // TODO: change type to coach with coachType
    hostCoaches: PropTypes.arrayOf(personWithIdAndPhotoPropTypesShape), // TODO: change type to coach with coachType
    guestCoaches: PropTypes.arrayOf(personWithIdAndPhotoPropTypesShape), // TODO: change type to coach with coachType
    hostClubActivists: PropTypes.arrayOf(personWithIdAndPhotoPropTypesShape), // TODO: change type to person with function
    guestClubActivists: PropTypes.arrayOf(personWithIdAndPhotoPropTypesShape), // TODO: change type to person with function
    firstHalfGoals: PropTypes.arrayOf(
      PropTypes.shape({
        eventTypeId: PropTypes.string,
        teamId: PropTypes.string,
        player: personWithIdAndPhotoPropTypesShape,
        ...goalPropTypes
      })
    ),
    secondHalfGoals: PropTypes.arrayOf(
      PropTypes.shape({
        eventTypeId: PropTypes.string,
        teamId: PropTypes.string,
        player: personWithIdAndPhotoPropTypesShape,
        ...goalPropTypes
      })
    ),
    firstHalfCards: PropTypes.arrayOf(
      PropTypes.shape({
        eventTypeId: PropTypes.string,
        teamId: PropTypes.string,
        player: personWithIdAndPhotoPropTypesShape,
        ...cardPropTypes
      })
    ),
    secondHalfCards: PropTypes.arrayOf(
      PropTypes.shape({
        eventTypeId: PropTypes.string,
        teamId: PropTypes.string,
        player: personWithIdAndPhotoPropTypesShape,
        ...cardPropTypes
      })
    ),
    firstHalfChanges: PropTypes.arrayOf(
      PropTypes.shape({
        eventTypeId: PropTypes.string,
        teamId: PropTypes.string,
        minute: PropTypes.number,
        goingDownPlayer: personWithIdAndPhotoPropTypesShape,
        enteringPlayer: personWithIdAndPhotoPropTypesShape
      })
    ),
    secondHalfChanges: PropTypes.arrayOf(
      PropTypes.shape({
        eventTypeId: PropTypes.string,
        teamId: PropTypes.string,
        minute: PropTypes.number,
        goingDownPlayer: personWithIdAndPhotoPropTypesShape,
        enteringPlayer: personWithIdAndPhotoPropTypesShape
      })
    ),
    hostFirstSquad: PropTypes.arrayOf(playerInSquadPropTypesShape),
    hostReservedSquad: PropTypes.arrayOf(playerInSquadPropTypesShape),
    guestFirstSquad: PropTypes.arrayOf(playerInSquadPropTypesShape),
    guestReservedSquad: PropTypes.arrayOf(playerInSquadPropTypesShape)
  }),
  matchesBetweenTeams: PropTypes.arrayOf(matchPropTypesShape),
  canTicketsGenerate: PropTypes.bool,
  canPostersGenerate: PropTypes.bool
};

const MatchDetailsPage = ({
  id,
  matchDetails,
  matchesBetweenTeams,
  canTicketsGenerate,
  canPostersGenerate
}) => {
  const { t } = useTranslation();

  const [showSpinner, setShowSpinner] = useState(false);

  const onGeneratePoster = () => {
    setShowSpinner(true);

    service
      .generatePoster(id)
      .then(pdf => downloadFile(pdf, 'plakat.pdf'))
      .finally(() => setShowSpinner(false));
  };

  const onGenerateTickets = () => {
    setShowSpinner(true);

    service
      .generateTickets(id)
      .then(pdf => downloadFile(pdf, 'bilety.pdf'))
      .finally(() => setShowSpinner(false));
  };

  return (
    <InnerContentContainer subpageWrap>
      {/* TODO: do wystylowania buttony i spinner podczas generowania pdf, sprawdzić uprawnienia czy działają, do stylowania itp. usunąć ify */}
      {canTicketsGenerate && (
        <LoadingButton isLoading={showSpinner} onClick={onGenerateTickets}>
          bilety
        </LoadingButton>
      )}
      {canPostersGenerate && (
        <LoadingButton isLoading={showSpinner} onClick={onGeneratePoster}>
          plakat
        </LoadingButton>
      )}

      {showSpinner && <LoadingPageData />}

      {!isEmpty(matchDetails) ? (
        <>
          <MatchDetailsCard {...matchDetails} />

          {/* TODO: accordion i skład i wydarzenia do osobnych accordionów, kolory z zielonego zmienić bo kolejka czarna, do zastanowienia */}

          {ArrayUtils.isNotEmpty(matchesBetweenTeams) && (
            <Card
              hoverable
              className="margin-top"
              renderTitle={() => t('PreviousMatchesBetweenTeams')}
            >
              <MatchesTableResponsive matches={matchesBetweenTeams} />
            </Card>
          )}
        </>
      ) : (
        <LoadingPageData />
      )}
    </InnerContentContainer>
  );
};

MatchDetailsPage.propTypes = propTypes;

export default memo(MatchDetailsPage);
