import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import urls from 'consts/urls';
import { isMyClub } from 'utils/utils';

import styles from './ClubLink.module.scss';
import Link from './Link';

const propTypes = {
  id: PropTypes.string.isRequired,
  logo: PropTypes.string,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  reverse: PropTypes.bool,
  withoutEmphasisMyClub: PropTypes.bool
};

const ClubLink = ({ id, logo, name, className, reverse, withoutEmphasisMyClub }) => {
  const { t } = useTranslation();

  const linkClassName = cx(styles.club_link, className, {
    [styles.club_link_reverse]: reverse
  });

  const clubNameClassName = cx(styles.club_name, {
    [styles.club_name_emphasized]: isMyClub(id) && !withoutEmphasisMyClub
  });

  return (
    <Link
      to={urls.clubDetails(id)}
      className={linkClassName}
      title={`${t('GoToTheClubSubpage')} ${name}`}
    >
      {logo && <img src={logo} alt={t('ClubLogo')} className={styles.club_logo} />}
      <span className={clubNameClassName}>{name}</span>
    </Link>
  );
};

ClubLink.propTypes = propTypes;

export default memo(ClubLink);
