import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { matchPropTypesShape } from 'propTypes';

import MobileMatchRow from './MobileMatchRow';

const propTypes = {
  matches: PropTypes.arrayOf(matchPropTypesShape), // TODO: player statistics props isCaptain, ageGropName, goals, etc.
  withStatus: PropTypes.bool,
  withStatusAndPlayerStatistics: PropTypes.bool,
  teamIdContext: PropTypes.string,
  isExtended: PropTypes.bool
};

const MobileMatchesTable = ({
  matches = [],
  withStatus,
  withStatusAndPlayerStatistics,
  teamIdContext
  // isExtended = true
}) => {
  const matchesRows = matches.map((match, matchIndex) => (
    <MobileMatchRow
      key={matchIndex}
      {...match}
      withStatus={withStatus}
      withStatusAndPlayerStatistics={withStatusAndPlayerStatistics}
      teamIdContext={teamIdContext}
    />
  ));

  return matchesRows;
};

MobileMatchesTable.propTypes = propTypes;

export default memo(MobileMatchesTable);
