import React, { memo } from 'react';

import { useAppSelector } from 'store';
import { useDispatchOnMountWithLocationMultiple } from 'utils/customHooks';
import { useGetMyClubId } from 'utils/systemParameterProvider';

import {
  getBoardMembersForMyClub,
  getContactForMyClub,
  getDetailsForMyClub,
  getHistoryForMyClub,
  getPartnersForMyClub,
  getSponsorsForMyClub,
  getStadiumDescriptionForMyClub,
  getTeamsForMyClub
} from '../my-club-actions';
import ClubDetailsPage from './ClubDetailsPage';

const MyClubDetailsPageContainer = props => {
  const myClubId = useGetMyClubId();

  const club = useAppSelector(state => state.clubs[myClubId]);

  const details = club?.details;
  const contact = club?.contact;
  const teams = club?.teams;
  const boardMembers = club?.boardMembers;
  const partners = club?.partners;
  const sponsors = club?.sponsors;
  const history = club?.history;
  const stadiumDescription = club?.stadiumDescription;

  useDispatchOnMountWithLocationMultiple([
    getDetailsForMyClub(),
    getContactForMyClub(),
    getTeamsForMyClub(),
    getBoardMembersForMyClub(),
    getPartnersForMyClub(),
    getSponsorsForMyClub(),
    getHistoryForMyClub(),
    getStadiumDescriptionForMyClub()
  ]);

  return (
    <ClubDetailsPage
      {...props}
      details={details}
      contact={contact}
      teams={teams}
      boardMembers={boardMembers}
      partners={partners}
      sponsors={sponsors}
      history={history}
      stadiumDescription={stadiumDescription}
    />
  );
};

export default memo(MyClubDetailsPageContainer);
