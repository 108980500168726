import React, { memo, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { ESortDirections } from 'consts/ESortDirections';
import { icons } from 'consts/icons';
import { getSortDirectionTextTranslationKey } from 'utils/sortingMethods';

import styles from './TableHeaderCell.module.scss';

type Props = {
  columnIndex: number;
  columnName: string;
  sortedColumnIndex: number;
  sortDirection: ESortDirections | null;
  setHoveredColumnIndex: (columnIndex: number) => void;
  sortColumn: (columnIndex: number) => void;
  children?: ReactNode;
};

const TableHeaderCell = ({
  columnIndex,
  setHoveredColumnIndex,
  sortColumn,
  sortedColumnIndex,
  sortDirection,
  columnName: headerName,
  children
}: Readonly<Props>) => {
  const { t } = useTranslation();

  const handleOnMouseEnter = () => setHoveredColumnIndex(columnIndex);
  const handleOnClick = () => sortColumn(columnIndex);

  const isSortingHeader = sortedColumnIndex === columnIndex && !!sortDirection;

  const headerClassName = cx(styles.header, styles[`header_${headerName}`], {
    [styles.header_sorting]: isSortingHeader
  });

  const iconClassName = cx(styles.sorting_icon, {
    [icons.chevronDown]: sortDirection === ESortDirections.ascending,
    [icons.chevronUp]: sortDirection === ESortDirections.descending
  });

  const getSortDirectionText = () =>
    getSortDirectionTextTranslationKey(isSortingHeader, sortDirection);

  return (
    <th
      className={headerClassName}
      onMouseEnter={handleOnMouseEnter}
      onClick={handleOnClick}
      title={`${t('ClickTo')} ${t(getSortDirectionText()).toLowerCase()}`}
    >
      {children}
      {isSortingHeader && <i className={iconClassName} />}
    </th>
  );
};

export default memo(TableHeaderCell);
