import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useMediaQuery as useMediaQueryReactResponsive } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from 'store';
import { supportedLanguages } from 'consts/language';

import breakpoints from 'styles/breakpoints.module.scss';

import { scrollTo } from './scrollTo';

// TODO: tak nie działa z nową wersją react-scripts:
// const isDesktop = useMediaQuery({ minDeviceWidth: breakpoints.navbarMobileWidth }); czy linijka poniżej robi to samo co ta ?
// tak też nie działa:
// const isDesktop = useMediaQuery({ query: `${media.navbarMobile}` });
// dlaczego?
export const useIsDesktop = () =>
  useMediaQueryReactResponsive({ query: `(min-width: ${breakpoints.navbarMobileWidth})` });

export const useIsMobile = () =>
  useMediaQueryReactResponsive({ query: `(max-width: ${breakpoints.navbarMobileWidth})` });

export const useScrollToUp = () => {
  useEffect(() => {
    scrollTo(document.body); // TODO: that method should not have argument? should be scrollTo()? check
  });
};

export const useScrollTopNavbar = (useScroll = true) => {
  useEffect(() => {
    if (useScroll) {
      const siteHeader = document.querySelector('.scroll-destination');
      siteHeader &&
        (() => {
          const topOffset = siteHeader.getBoundingClientRect().height;
          scrollTo(topOffset);
        })();
    }
  }, [useScroll]);
};

export const useHandleScrollPosition = (scrollPosition, setScrollPosition) => {
  const handleScroll = useCallback(
    e => {
      const window = e.currentTarget;

      if (scrollPosition > window.scrollY) {
        // console.log('scrolling up');
      } else if (scrollPosition < window.scrollY) {
        // console.log('scrolling down');
      }

      const position = window.scrollY;
      setScrollPosition(position);
    },
    [scrollPosition, setScrollPosition]
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);
};

export const useCheckIfSticky = (elementRef, isStickyReference, setIsSticky) => {
  const checkIfSticky = useCallback(() => {
    if (!elementRef) {
      return;
    }

    const shouldBeSticky = elementRef.current.offsetTop <= window.scrollY;

    if (shouldBeSticky !== isStickyReference.current) {
      setIsSticky(shouldBeSticky);
    }
  }, [isStickyReference, setIsSticky]);

  useEffect(() => {
    checkIfSticky();
    window.addEventListener('scroll', checkIfSticky);
    return () => window.removeEventListener('scroll', checkIfSticky);
  }, [checkIfSticky]);
};

export const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (delay != null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }

    return undefined;
  }, [delay]);
};

export function useImmediateInterval(callback, delay, clearingStatement = false) {
  const savedCallback = useRef();

  useEffect(() => {
    callback();
  }, []);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      const id = setInterval(tick, delay);
      clearingStatement && clearInterval(id);
      return () => clearInterval(id);
    }

    return undefined;
  }, [delay, clearingStatement]);
}

export const useDispatchOnMount = actionToDispatch => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(actionToDispatch);
  }, []);
};

export const useDispatchOnMountWithLocation = actionToDispatch => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(actionToDispatch);
  }, [location.pathname]);
};

export const useMultipleDispatchOnMount = actionsToDispatch => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    for (const action of actionsToDispatch) {
      dispatch(action);
    }
  }, []);
};

export const useDispatchOnMountWithLocationMultiple = actionsToDispatch => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    for (const action of actionsToDispatch) {
      dispatch(action);
    }
  }, [location.pathname]);
};

export const useRefState = initialValue => {
  const [value, setValue] = useState(initialValue);
  const valueReference = useRef(value);

  const updateStateAndReference = newValue => {
    valueReference.current = newValue;
    setValue(newValue);
  };

  return [value, valueReference, updateStateAndReference];
};

export const useIsCurrentlyOnUrl = (url = '') => {
  const { pathname } = useLocation();
  const [isOnGivenUrl, setIsOnGivenUrl] = useState(pathname === url);

  useEffect(() => {
    setIsOnGivenUrl(pathname === url);
  }, [pathname, url]);

  return isOnGivenUrl;
};

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return size;
};

export const useMediaQuery = query => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);

    if (media.matches !== matches) {
      setMatches(media.matches);
    }

    const listener = () => setMatches(media.matches);
    media.addEventListener(listener);

    return () => media.removeEventListener(listener);
  }, [matches, query]);

  return matches;
};

// const text = useMediaQuery('(max-width: 400px)', 'Less than 400px wide', 'More than 400px wide');

// TODO:
// https://github.com/contra/react-responsive/blob/master/src/useMediaQuery.ts
// https://github.com/beautifulinteractions/beautiful-react-hooks/blob/9a3804568f977d6ffed6e98e75ba1242773146c5/src/useMediaQuery.js#L16
// react-device-detect npm
// export const isMobile = () => window.matchMedia && window.matchMedia("(max-width: 480px)").matches

// export function useMediaQuery(query: string): boolean {
// 	const [matches, setMatches] = useState(() => matchMedia(query).matches)

// 	useLayoutEffect(() => {
// 		const mediaQuery = matchMedia(query)

// 		function onMediaQueryChange(): void {
// 			setMatches(mediaQuery.matches)
// 		}

// 		mediaQuery.addEventListener('change', onMediaQueryChange)

// 		return (): void => {
// 			mediaQuery.removeEventListener('change', onMediaQueryChange)
// 		}
// 	}, [query])

// 	return matches
// }

// // usage: const isTabletAndUp = useMediaQuery('(min-width: 600px)');

export const useCurrentLanguage = () => {
  const { i18n: translator } = useTranslation();

  const currentLanguage = useMemo(
    () =>
      supportedLanguages.find(
        supportedLanguage => supportedLanguage.code === translator.language
      ) || {},
    [translator.language]
  );

  return currentLanguage;
};

export const useEventListener = (eventName, eventCallback) => {
  useEffect(() => {
    window.addEventListener(eventName, eventCallback);
    return () => window.removeEventListener(eventName, eventCallback);
  }, []);
};

export const useFocusOnFirstInput = (formRef, isInputContainerVisible) => {
  useEffect(() => {
    const { current } = formRef;
    if (isInputContainerVisible) {
      const firstInput = current?.querySelectorAll('input')?.[0];
      firstInput?.focus();
    }
  }, [isInputContainerVisible]);
};
