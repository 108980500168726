import React, { memo } from 'react';
import Link from 'components/Links/Link';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// import logo from 'assets/images/transparent_herb.png';
import logo from 'assets/images/transparent_herb_stu_lecie.png';
import cx from 'classnames';

import styles from './MenuLogoLink.module.scss';

const propTypes = {
  smaller: PropTypes.bool
};

const MenuLogoLink = ({ smaller }) => {
  const { t } = useTranslation();

  const logoContainerClassName = cx(styles.logo_container, {
    [styles.logo_container_smaller]: smaller
  });

  return (
    <div className={logoContainerClassName}>
      <Link className={styles.logo_link} to="/">
        <img src={logo} alt={t('ClubLogo')} className={styles.logo_image} />
      </Link>
    </div>
  );
};

MenuLogoLink.propTypes = propTypes;

export default memo(MenuLogoLink);
