import React, { memo } from 'react';
import Link from 'components/Links/Link';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import defaultPhoto from 'assets/images/Default_image_cropped.jpg';
import cx from 'classnames';

import { icons } from 'consts/icons';
import urls from 'consts/urls';
import Card from 'components/Card';
import DateTimeInfo from 'components/DateTimeInfo';
import IconButton from 'components/IconButton';

import styles from './Article.module.scss';

const propTypes = {
  id: PropTypes.string.isRequired,
  photo: PropTypes.string,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  createdDate: PropTypes.string.isRequired,
  // TODO: Vorbert -> zastanowić się nad tym propsem
  // author: PropTypes.string.isRequired,
  vertical: PropTypes.bool
};

// TODO: dodać info o autorze i wtedy usunąć eslint-disable
/* eslint-disable no-unused-vars */
const Article = ({ id, photo, title, content, createdDate /* , author */, vertical }) => {
  const { t } = useTranslation();

  const articleLink = urls.articleDetails(id);
  const articleContainerClassName = cx(styles.article_container, {
    [styles.vertical]: vertical
  });

  return (
    <Card hoverable className={articleContainerClassName}>
      <div className={styles.article}>
        <Link className={styles.image_container} to={articleLink}>
          <div
            className={styles.image}
            style={{
              backgroundImage: `url('${photo || defaultPhoto}')`
            }}
          />
          <div className={styles.image_text}>{t('ReadMore')}</div>
        </Link>
        <div className={styles.information_container}>
          <div className={styles.text_section}>
            <div className={styles.title}>{title}</div>
            {/* eslint-disable-next-line react/no-danger */}
            <div className={styles.content} dangerouslySetInnerHTML={{ __html: content }} />
          </div>
          <div className={styles.bottom_section}>
            <DateTimeInfo date={createdDate} />
            <IconButton
              icon={icons.arrowRight}
              to={articleLink}
              title={t('GoToTheArticleDetailsSubpage')}
            >
              {t('ReadMore')}
            </IconButton>
          </div>
        </div>
      </div>
    </Card>
  );
};

Article.propTypes = propTypes;

export default memo(Article);
