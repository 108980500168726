import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';

import { getArrayWithLength } from 'utils/utils';
import CardList from 'components/CardList';
import PersonTilePlaceholder from 'components/PersonTile/PersonTilePlaceholder';

import PlayerPositionCard from './PlayerPositionCard';

const propTypes = {
  // note: only required to hoc withPositionColors in PlayerPositionCard component
  positionName: PropTypes.string,
  placeholderAmount: PropTypes.number
};

const PlayerListPlaceholder = ({ positionName, placeholderAmount = 4 }) => {
  const [areCardsHighlighted, setAreCardsHighlighted] = useState(false);

  return (
    <CardList>
      <PlayerPositionCard
        position={{ name: positionName }}
        onMouseEnter={() => setAreCardsHighlighted(true)}
        onMouseLeave={() => setAreCardsHighlighted(false)}
      />
      {getArrayWithLength(placeholderAmount).map((x, index) => (
        <PersonTilePlaceholder
          key={index}
          isHighlighted={areCardsHighlighted}
          withDescription={false}
        />
      ))}
    </CardList>
  );
};

PlayerListPlaceholder.propTypes = propTypes;

export default memo(PlayerListPlaceholder);
