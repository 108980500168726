import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation, withTranslation } from 'react-i18next';
import navbarImage from 'assets/images/navbar_image.svg';

import { useIsDesktop } from 'utils/customHooks';

// import breakpoints from 'styles/breakpoints.module.scss';
import StyledHeader from './StyledHeader';
import StyledHeaderCaption from './StyledHeaderCaption';
import StyledImage from './StyledImage';
import StyledTextWrapper from './StyledTextWrapper';
import StyledWrapper from './StyledWrapper';

const propTypes = {
  caption: PropTypes.string,
  header: PropTypes.string
};

const HeroSection = ({
  caption = 'Oficjalna strona klubu sportowego', // TODO: nie działają translacje w defaultProps i tam gdzie jest użycie "i18n.t" w całej apce: i18n.t('OfficialWebsiteText'),
  header = 'Błażowianka Błażowa'
}) => {
  const { t } = useTranslation();

  const isDesktop = useIsDesktop();

  return (
    <StyledWrapper className="scroll-destination">
      <StyledTextWrapper>
        <StyledHeaderCaption>{caption}</StyledHeaderCaption>
        <StyledHeader>{header}</StyledHeader>
      </StyledTextWrapper>
      {isDesktop ? <StyledImage src={navbarImage} alt={t('SoccerPlayingIllustration')} /> : ''}
    </StyledWrapper>
  );
};

HeroSection.propTypes = propTypes;

export default withTranslation()(memo(HeroSection));
