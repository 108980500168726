// TODO:
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Dispatch } from 'react';
import i18n from 'i18n';
import { Middleware, UnknownAction } from '@reduxjs/toolkit';
import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';

import { store } from 'store';
import urls from 'consts/urls';
import { isNotProductionEnvironment } from 'utils/environments.utils';
import { loginSuccess, logout } from 'modules/auth/actions';
import authActionTypes from 'modules/auth/actionTypes';
import { displayToastr } from 'components/Toastr/actions';
import toastTypes from 'components/Toastr/ToastTypes';

import config from './config';

const refreshTokenEndpoint = '/auth/refreshToken';

function addBearerTokenFromLocalStorage(config2: InternalAxiosRequestConfig<any>) {
  // TODO: config2 zmienić nazwę, sprawdzić jak jest w HH i w pracy
  const token = localStorage.getItem('accessToken');
  if (token && config2?.headers) {
    config2.headers.Authorization = `Bearer ${token}`;
  }

  return config2;
}

function doNothing(config_: any) {
  return config_;
}

const instance = axios.create({
  baseURL: isNotProductionEnvironment() ? config.apiUrl : `${location.origin}/api/`
});

function handle400(response: { data: { message: any; errors: any } }) {
  let errorMessage = response?.data?.message;

  if (!errorMessage && response?.data?.errors) {
    errorMessage = JSON.stringify(response.data.errors); // TODO: może jakiś ładniejszy error message lub submission error z redux forma ?
  }

  store.dispatch(displayToastr(errorMessage, toastTypes.warning) as any); // TODO: change as any
}

function handle500(response: { data: { exceptionGuid: string } }) {
  const exceptionGuid = response.data.exceptionGuid;
  const errorMessage = `${i18n.t('ErrorHasOccuredWithTheIdentificator')}: ${exceptionGuid}`;
  store.dispatch(displayToastr(errorMessage, toastTypes.error) as any); // TODO: change as any
}

instance.interceptors.request.use(addBearerTokenFromLocalStorage);

instance.interceptors.response.use(doNothing, error => {
  const response = error.response || error;
  const originalRequest = error.config;

  if (response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;

    return instance
      .post(refreshTokenEndpoint, {
        token: localStorage.getItem('refreshToken')
      })
      .then(result => {
        store.dispatch(loginSuccess(result.data) as any); // TODO: change as any
        error.config.headers.Authorization = `Bearer ${result.data.accessToken}`;
        return instance(originalRequest);
      })
      .catch(catchedError => {
        if (catchedError.config.url.endsWith(refreshTokenEndpoint)) {
          store.dispatch({
            type: authActionTypes.AUTH_LOGOUT
          });
        }

        return Promise.reject(error);
      });
  }

  // if (response.status === 403) {
  //   store.dispatch(replace(urls.accessDeniedUrl));
  //   // TODO: or toast and redirect?
  //   return Promise.reject(error);
  // }

  if (response.status === 400) {
    handle400(response);
  } else if (response.status >= 500 && response.status < 600) {
    handle500(response);
  } else {
    store.dispatch(displayToastr(response.message, toastTypes.error) as any); // TODO: change as any
  }

  // eslint-disable-next-line no-console
  console.error(response); // TODO: maybe only in dev mode

  return Promise.reject(error);
});

export default instance;

// TODO: code from Treneo

// note: if we want add to redux/toolkit like errorMiddleware, we should wrap interceptors like below in commented code

export const axiosMiddleware: Middleware =
  (store: any) => (next: Dispatch<UnknownAction>) => (action: any) => {
    setupInterceptors(store);
    return next(action);
  };

let axiosInterceptor: number | null = null;

const setupInterceptors = (store: any) => {
  if (!store) {
    return;
  }

  if (!!axiosInterceptor || axiosInterceptor === 0) {
    axios.interceptors.response.eject(axiosInterceptor);
  }

  axiosInterceptor = axios.interceptors.response.use(onResponse, onResponseError(store));
};

// const setupInterceptors2 = (axiosInstance: AxiosInstance): AxiosInstance => {
//   axiosInstance.interceptors.request.use(onRequest, onRequestError);
//   axiosInstance.interceptors.response.use(onResponse, onResponseError);

//   return axiosInstance;
// };

// const doNothing_2 = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => config;

// const onRequest = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
//   // console.info(`[request] [${JSON.stringify(config)}]`);
//   return doNothing_2(config);
// };

// const onRequestError = (error: AxiosError): Promise<AxiosError> => {
//   // console.error(`[request error] [${JSON.stringify(error)}]`);
//   return Promise.reject(error);
// };

const onResponse = (response: AxiosResponse): AxiosResponse => {
  // console.info(`[response] [${JSON.stringify(response)}]`);
  return response;
};

// const onResponseError = (error: AxiosError): Promise<AxiosError> => {
const onResponseError =
  (store: any): ((error: AxiosError) => Promise<AxiosError>) =>
  (error: AxiosError) => {
    const response = (error.response || error) as AxiosResponse; // TODO: why 'as AxiosResponse' is needed?

    if (
      error.config?.url?.toLocaleLowerCase().includes('auth/') ||
      error.config?.url?.toLocaleLowerCase().includes('logout')
    ) {
      return Promise.reject(error);
    }

    if (response?.status === 401) {
      store.dispatch(logout());
    } else if (response?.status === 403) {
      store.dispatch(displayToastr(i18n.t(response?.data?.message), toastTypes.error) as any); // TODO: change as any
      window.location.href = `${window.location.protocol}//${window.location.host}${urls.forbiddenPage}`;
    } else {
      handleException(response);
    }

    if (isNotProductionEnvironment()) {
      // eslint-disable-next-line no-console
      console.error(response);
    }

    return Promise.reject(error);
  };

const handleException = (response: AxiosResponse): void => {
  let errorMessage = response?.data?.message;

  if (!errorMessage && response?.data?.errors) {
    errorMessage = JSON.stringify(response.data.errors); // TODO: może jakiś ładniejszy error message lub submission error z redux forma ?
  }

  store.dispatch(displayToastr(i18n.t(errorMessage), toastTypes.error) as any); // TODO: change as any
};
