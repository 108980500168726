import React, { memo } from 'react';
import { useParams } from 'react-router-dom';

import { useAppSelector } from 'store';
import { useDispatchOnMountWithLocationMultiple } from 'utils/customHooks';

import {
  getCoachesInSeason,
  getDetails,
  getMatchesInSeason,
  getPlayersWithMatchesAndEventsInSeason,
  getSeasonsList
} from './actions';
import TeamSeasonsStatisticsPage from './TeamSeasonsStatisticsPage';

const TeamSeasonsStatisticsPageContainer = props => {
  const { teamId, seasonId } = useParams();

  const team = useAppSelector(state => state.teams[teamId]);
  const teamDetails = team?.details;
  const season = team?.seasons?.find(x => x.seasonId === seasonId);

  useDispatchOnMountWithLocationMultiple([
    getDetails(teamId),
    getSeasonsList(teamId),
    getMatchesInSeason(teamId, seasonId),
    getCoachesInSeason(teamId, seasonId),
    getPlayersWithMatchesAndEventsInSeason(teamId, seasonId)
  ]);

  return <TeamSeasonsStatisticsPage {...props} teamDetails={teamDetails} season={season} />;
};

export default memo(TeamSeasonsStatisticsPageContainer);
