import React, { memo } from 'react';

import { useAppSelector } from 'store';
import { useDispatchOnMountWithLocation } from 'utils/customHooks';

import { get } from './actions';
import ArticlesPage from './ArticlesPage';

const ArticlesPageContainer = props => {
  const articles = useAppSelector(state => state.articles);

  useDispatchOnMountWithLocation(get());

  return <ArticlesPage {...props} articles={articles} />;
};

export default memo(ArticlesPageContainer);
