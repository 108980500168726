import React, { memo } from 'react';
import PropTypes from 'prop-types';

import GalleryItem from './GalleryItem';
import styles from './GalleryRow.module.scss';

const propTypes = {
  albums: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      thumbnailContentBase64: PropTypes.string.isRequired
    })
  ).isRequired
};

const GalleryRow = ({ albums }) => (
  <div className={styles.albums_container}>
    {albums.map(album => (
      <GalleryItem key={album.id} {...album} />
    ))}
  </div>
);

GalleryRow.propTypes = propTypes;

export default memo(GalleryRow);
