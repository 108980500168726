import React, { memo } from 'react';
import { useParams } from 'react-router-dom';

import { useAppSelector } from 'store';
import { useDispatchOnMountWithLocationMultiple } from 'utils/customHooks';

import { getCompetitionsInfo, getCompetitionsSchedule } from '../actions';
import CompetitionsSchedulePage from './CompetitionsSchedulePage';

const CompetitionsSchedulePageContainer = props => {
  const { leagueId, seasonId } = useParams();

  const competitions = useAppSelector(state =>
    state.competitions?.find(x => x.leagueId === leagueId && x.seasonId === seasonId)
  );

  const leagueName = competitions?.leagueName;
  const seasonName = competitions?.seasonName;

  const competitionsSchedule = competitions?.competitionsSchedule;

  useDispatchOnMountWithLocationMultiple([
    getCompetitionsInfo(leagueId, seasonId),
    getCompetitionsSchedule(leagueId, seasonId)
  ]);

  return (
    <CompetitionsSchedulePage
      {...props}
      leagueName={leagueName}
      seasonName={seasonName}
      competitionsSchedule={competitionsSchedule}
    />
  );
};

export default memo(CompetitionsSchedulePageContainer);
