import './CompetitionsScheduleFilter.scss';

import React, { memo, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const propTypes = {
  filterValue: PropTypes.string.isRequired,
  setFilterValue: PropTypes.func.isRequired,
  filterData: PropTypes.arrayOf(PropTypes.string).isRequired,
  autoFocus: PropTypes.bool
};

// TODO: Vorbert (zajmie chwilkę) -> zrobić uniwersalny komponencik
const CompetitionsScheduleFilter = ({
  filterValue: value,
  setFilterValue: setValue,
  filterData,
  autoFocus
}) => {
  const { t } = useTranslation();
  const [suggestions, setSuggestions] = useState([]);

  const escapeRegexCharacters = string => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const getSuggestions = typedValue => {
    const escapedValue = escapeRegexCharacters(typedValue ? typedValue.trim() : '');

    if (!escapedValue.trim()) {
      return filterData;
    }

    const regex = new RegExp(escapedValue, 'i');

    return [...filterData].filter(filterName => regex.test(filterName));
  };
  const onSuggestionsFetchRequested = ({ value: typedValue }) =>
    setSuggestions(getSuggestions(typedValue));
  const onSuggestionsClearRequested = () => setSuggestions([]);
  const renderSuggestion = suggestion => <span>{suggestion}</span>;
  const getSuggestionValue = suggestion => suggestion;
  const onChange = (event, { newValue }) => setValue(newValue);
  const inputProps = {
    value,
    onChange,
    placeholder: t('FilterShowedQueues'),
    autoFocus
  };

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
      alwaysRenderSuggestions
    />
  );
};

CompetitionsScheduleFilter.propTypes = propTypes;

export default memo(CompetitionsScheduleFilter);
