import React, { memo } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';

import InnerContentWrapper from 'components/InnerContentWrapper';
import LoadingPageData from 'components/LoadingPageData';
import PageHeader from 'components/PageHeader';

import ContactCard from './ContactCard';

const propTypes = {
  contact: PropTypes.shape({
    logo: PropTypes.string,
    photo: PropTypes.string,
    name: PropTypes.string,
    address: PropTypes.exact({
      city: PropTypes.string,
      street: PropTypes.string,
      buildingNumber: PropTypes.string,
      apartmentNumber: PropTypes.string,
      zipCode: PropTypes.string,
      postOffice: PropTypes.string
    }),
    nip: PropTypes.string,
    regon: PropTypes.string,
    krs: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
    bankName: PropTypes.string,
    bankAccountNumber: PropTypes.string
  })
};

const ContactPage = ({ contact }) => {
  const { t } = useTranslation();

  return (
    <InnerContentWrapper subpageWrap>
      <PageHeader title={t('ContactPage')} />
      {!isEmpty(contact) ? <ContactCard contact={contact} /> : <LoadingPageData />}
    </InnerContentWrapper>
  );
};

ContactPage.propTypes = propTypes;

export default memo(ContactPage);
