import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { goalPropTypes, personWithIdAndPhotoPropTypesShape } from 'propTypes';
import AssistIcon from 'components/Icon/AssistIcon';
import GoalIcon from 'components/Icon/GoalIcon';

import EventPlayerLink from './EventPlayerLink';
import EventWrapper from './EventWrapper';
import styles from './ScoredGoalEvent.module.scss';

const propTypes = {
  teamId: PropTypes.string.isRequired,
  player: personWithIdAndPhotoPropTypesShape.isRequired,
  reversed: PropTypes.bool.isRequired,
  playerId: PropTypes.string,
  ...goalPropTypes,
  isTwoLineVersion: PropTypes.bool
};

const ScoredGoalEvent = ({
  minute,
  isOwn,
  isPenalty,
  player,
  assistantPlayer,
  reversed,
  isTwoLineVersion
}) => (
  <EventWrapper
    color={!isOwn ? (!isPenalty ? 'green' : 'orange') : 'red'}
    reversed={reversed}
    minute={minute}
  >
    {isTwoLineVersion ? (
      <div className={styles.twoLines}>
        <div className={styles.player}>
          <EventPlayerLink {...player} />
          <GoalIcon isOwn={isOwn} isPenalty={isPenalty} />
        </div>

        {!!assistantPlayer && (
          <div className={styles.player}>
            <EventPlayerLink {...assistantPlayer} assist className={styles.player_assistant} />
            <AssistIcon />
          </div>
        )}
      </div>
    ) : (
      <>
        <EventPlayerLink {...player} />
        <GoalIcon isOwn={isOwn} isPenalty={isPenalty} />

        {!!assistantPlayer && (
          <>
            <EventPlayerLink {...assistantPlayer} assist className={styles.player_assistant} />
            <AssistIcon />
          </>
        )}
      </>
    )}
  </EventWrapper>
);

ScoredGoalEvent.propTypes = propTypes;

export default memo(ScoredGoalEvent);
