import React, { memo } from 'react';
import PropTypes from 'prop-types';

import ChangeEnteringIcon from 'components/Icon/ChangeEnteringIcon';

import EventWrapper from './EventWrapper';

const propTypes = {
  minute: PropTypes.number.isRequired
};

const ChangedEnteringShortEvent = ({ minute }) => (
  <EventWrapper color="blue" minute={minute}>
    <ChangeEnteringIcon />
  </EventWrapper>
);

ChangedEnteringShortEvent.propTypes = propTypes;

export default memo(ChangedEnteringShortEvent);
