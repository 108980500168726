import React, { memo } from 'react';
import { useParams } from 'react-router-dom';

import { useAppSelector } from 'store';
import { useDispatchOnMountWithLocationMultiple } from 'utils/customHooks';

import {
  getCoachesInCurrentSeason,
  getDetails,
  getMatchesInCurrentSeason,
  // getPlayerMatchesAndEventsInCurrentSeason,
  // getPlayersInCurrentSeason,
  getPlayersWithMatchesAndEventsInCurrentSeason,
  getSeasonsList
} from './actions';
import TeamDetailsPage from './TeamDetailsPage';

const TeamDetailsPageContainer = props => {
  const { id } = useParams();
  // const dispatch = useAppDispatch();

  const team = useAppSelector(state => state.teams[id]);

  const details = team?.details;
  const seasons = team?.seasons;
  const coachesInCurrentSeason = team?.coachesInCurrentSeason;
  const matchesInCurrentSeason = team?.matchesInCurrentSeason;
  const playersWithMatchesAndEventsInCurrentSeason =
    team?.playersWithMatchesAndEventsInCurrentSeason;

  // const players = team?.playersWithMatchesAndEventsInCurrentSeason;

  // TODO: jak jest wydajniej, czy może bez różnicy? tak jak wyżej czyli poprzez useSelector wyciągana drużyna i potem poszczególne pola czu tak jak poniżej zakomentowane że za każdym razem użyty useSelector?
  // const details = useAppSelector(state => state.teams[id]?.details);
  // const seasons = useAppSelector(state => state.teams[id]?.seasons);
  // const coachesInCurrentSeason = useAppSelector(state => state.teams[id]?.coachesInCurrentSeason);
  // const playersGroupByPositionsInCurrentSeason = useAppSelector(
  //   state => state.teams[id]?.playersGroupByPositionsInCurrentSeason
  // );
  // const matchesInCurrentSeason = useAppSelector(state => state.teams[id]?.matchesInCurrentSeason);

  // TODO: czy ta linijka poniżej zastąpi te cztery powyższe? czy dalej każdy komponent będzie renderowany asynchronicznie, osobno? sprawdzić czy teraz tak jest
  // i jeśli nie ma nic w storze to leci błąd: TypeError: Cannot destructure property 'details' of 'Object(...)(...)' as it is undefined.
  // const { details, coaches, players, /* TODO: boardMembers, */ matches } = useAppSelector(
  //   state => state.teams[id]
  // );

  // TODO: czy potrzeba useDispatchOnMountWithLocationMultiple czy wystarczy useDispatchOnMount
  // useDispatchOnMount(get(id));
  useDispatchOnMountWithLocationMultiple([
    getDetails(id),
    getSeasonsList(id),
    getCoachesInCurrentSeason(id),
    getPlayersWithMatchesAndEventsInCurrentSeason(id),
    getMatchesInCurrentSeason(id)
  ]);

  // const [arePlayersLoaded, setArePlayersLoaded] = useState(false);

  // useEffect(() => {
  //   if (arePlayersLoaded) {
  //     if (players?.length) {
  //       for (const player of players) {
  //         dispatch(getPlayerMatchesAndEventsInCurrentSeason(id, player.id));
  //       }
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [arePlayersLoaded]);

  // useEffect(() => {
  //   if (players?.length <= 0) {
  //     setArePlayersLoaded(false);
  //     dispatch(getPlayersInCurrentSeason(id)).then(() => setArePlayersLoaded(true));
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    // TODO: po co {...props}? w props jest tylko location?
    <TeamDetailsPage
      {...props}
      teamId={id}
      details={details}
      seasons={seasons}
      coachesInCurrentSeason={coachesInCurrentSeason}
      matchesInCurrentSeason={matchesInCurrentSeason}
      playersWithMatchesAndEventsInCurrentSeason={playersWithMatchesAndEventsInCurrentSeason} // TODO: name, z current czy bez
    />
  );
};

export default memo(TeamDetailsPageContainer);
