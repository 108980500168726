import ArrayUtils from 'utils/array.utils';
import ObjectUtils from 'utils/object.utils';
import { getMyTeamId } from 'utils/systemParameterProvider';

import {
  getCoachesInCurrentSeasonSuccess,
  getMatchesInCurrentSeasonSuccess,
  getPlayersWithMatchesAndEventsInCurrentSeasonSuccess,
  getSeasonsListSuccess as getSeasonsListSuccess,
  getTeamDetailsSuccess
} from './actions';
import { TeamSrv } from './service';

export function getDetailsForMyTeam() {
  return (dispatch, getState) => {
    const { teams } = getState();
    // TODO: zastanowić się czy tutaj trzeba pobierać cały state
    const myTeamId = getMyTeamId(getState());

    if (myTeamId && ObjectUtils.isEmpty(teams[myTeamId]?.details)) {
      return TeamSrv.getDetailsForMyTeam().then(details =>
        dispatch(getTeamDetailsSuccess(myTeamId, details))
      );
    }
  };
}

export function getSeasonsListForMyTeam() {
  return (dispatch, getState) => {
    const state = getState();
    const myTeamId = getMyTeamId(state);

    if (myTeamId && ArrayUtils.isEmpty(state.teams[myTeamId]?.seasons)) {
      return TeamSrv.getSeasonsListForMyTeam().then(seasons =>
        dispatch(getSeasonsListSuccess(myTeamId, seasons))
      );
    }
  };
}

export function getSeasonsAdvancedListForMyTeam() {
  return (dispatch, getState) => {
    const state = getState();
    const myTeamId = getMyTeamId(state);

    if (myTeamId) {
      return TeamSrv.getSeasonsAdvancedListForMyTeam().then(seasons =>
        dispatch(getSeasonsListSuccess(myTeamId, seasons))
      );
    }
  };
}

export function getCoachesForMyTeamInCurrentSeason() {
  return (dispatch, getState) => {
    const state = getState();
    const myTeamId = getMyTeamId(state);

    if (myTeamId && ArrayUtils.isEmpty(state.teams[myTeamId]?.coachesInCurrentSeason)) {
      return TeamSrv.getCoachesForMyTeamInCurrentSeason().then(coachesInCurrentSeason =>
        dispatch(getCoachesInCurrentSeasonSuccess(myTeamId, coachesInCurrentSeason))
      );
    }
  };
}

export function getPlayersWithMatchesAndEventsForMyTeamInCurrentSeason() {
  return (dispatch, getState) => {
    const state = getState();
    const myTeamId = getMyTeamId(state);

    if (
      myTeamId &&
      ObjectUtils.isEmpty(state.teams[myTeamId]?.playersWithMatchesAndEventsInCurrentSeason)
    ) {
      return TeamSrv.getPlayersWithMatchesAndEventsForMyTeamInCurrentSeason().then(
        playersWithMatchesAndEventsInCurrentSeason => {
          dispatch(
            getPlayersWithMatchesAndEventsInCurrentSeasonSuccess(
              myTeamId,
              playersWithMatchesAndEventsInCurrentSeason
            )
          );
        }
      );
    }
  };
}

export function getMatchesForMyTeamInCurrentSeason() {
  return (dispatch, getState) => {
    const state = getState();
    const myTeamId = getMyTeamId(state);

    if (myTeamId && ArrayUtils.isEmpty(state.teams[myTeamId]?.matchesInCurrentSeason)) {
      return TeamSrv.getMatchesForMyTeamInCurrentSeason().then(matchesInCurrentSeason =>
        dispatch(getMatchesInCurrentSeasonSuccess(myTeamId, matchesInCurrentSeason))
      );
    }
  };
}
