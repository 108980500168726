import React, { memo } from 'react';
import Link from 'components/Links/Link';
import cx from 'classnames';
import { ISimpleTeamModel } from 'models/businessCommon.models';

import matchResultTypes from 'consts/matchResultTypes';
import urls from 'consts/urls';
import MatchStatus from 'components/MatchStatus';
import Tooltip from 'components/Tooltip';

import stylesLastMatch from './LastMatch.module.scss';
import NextMatchInfo from './NextMatchInfo';

type Props = {
  id: string;
  host: ISimpleTeamModel;
  guest: ISimpleTeamModel;
  date: Date;
  setActiveTeamId: (teamId: string) => void;
  clearActiveTeamId: () => void;
};

const NextMatch = ({
  id,
  host,
  guest,
  date,
  setActiveTeamId,
  clearActiveTeamId
}: Readonly<Props>) => {
  const tooltipClassName = cx(stylesLastMatch.match_container);

  const handleMouseEnter = () => setActiveTeamId(guest.id);
  const handleMouseLeave = () => clearActiveTeamId();

  return (
    <Tooltip
      className={tooltipClassName}
      html={<NextMatchInfo host={host} guest={guest} date={date} />}
    >
      <Link
        className={stylesLastMatch.match_link}
        to={urls.matchDetails(id)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <MatchStatus status={matchResultTypes.next} />
      </Link>
    </Tooltip>
  );
};

export default memo(NextMatch);
