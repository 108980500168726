/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';

import {
  requestStatusFulfilled,
  requestStatusPending,
  requestStatusRejected
} from 'utils/requests.utils';

import { FilesState } from './models';
import { changeFileValuesOrAddNewFile } from './file.functions';
import { getFile } from './file.thunks';

const initialState: FilesState = {};

const keys = {
  requestStatus: 'requestStatus',
  file: 'file'
};

const fileSlice = createSlice({
  name: 'file',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getFile.pending, (state: FilesState, action) => processPendingAction(state, action))
      .addCase(getFile.fulfilled, (state: FilesState, action) =>
        processFulfilledAction(state, action)
      )
      .addCase(getFile.rejected, (state: FilesState, action) =>
        processRejectedAction(state, action)
      );
  }
});

export default fileSlice.reducer;

function processPendingAction(state: FilesState, action): void {
  const id = action.meta.arg;

  changeFileValuesOrAddNewFile(id, state, [
    {
      key: keys.requestStatus,
      value: requestStatusPending
    }
  ]);
}

function processFulfilledAction(state: FilesState, action): void {
  const id = action.meta.arg;

  changeFileValuesOrAddNewFile(id, state, [
    {
      key: keys.requestStatus,
      value: requestStatusFulfilled
    },
    {
      key: keys.file,
      value: action.payload
    }
  ]);
}

function processRejectedAction(state: FilesState, action): void {
  const id = action.meta.arg;

  changeFileValuesOrAddNewFile(id, state, [
    {
      key: keys.requestStatus,
      value: requestStatusRejected(action)
    }
  ]);
}
