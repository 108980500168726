/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import {
  requestStatusFulfilled,
  requestStatusPending,
  requestStatusRejected
} from 'utils/requests.utils';

import { RefereesState } from './models';
import {
  changeRefereeValuesOrAddNewReferee,
  changeSeasonValuesOrAddNewSeason
} from './referee.functions';
import {
  getDebutMatch,
  getDetails,
  getMatchesInCurrentSeason,
  getMatchesInSeason,
  getPhoto,
  getSeason,
  getSeasons
  // getSeasonStatistics
} from './referee.thunks';

const initialState: RefereesState = {};

const refereeSlice = createSlice({
  name: 'referee',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getDetails.pending, (state: RefereesState, action) => {
        changeRefereeValuesOrAddNewReferee(state, action, [
          {
            key: 'getDetailsRequestStatus',
            value: requestStatusPending
          }
        ]);
      })
      .addCase(getDetails.fulfilled, (state: RefereesState, action) => {
        changeRefereeValuesOrAddNewReferee(state, action, [
          {
            key: 'getDetailsRequestStatus',
            value: requestStatusFulfilled
          },
          {
            key: 'details',
            value: action.payload
          }
        ]);
      })
      .addCase(getDetails.rejected, (state: RefereesState, action) => {
        changeRefereeValuesOrAddNewReferee(state, action, [
          {
            key: 'getDetailsRequestStatus',
            value: requestStatusRejected(action)
          }
        ]);
      })
      .addCase(getPhoto.pending, (state: RefereesState, action) => {
        changeRefereeValuesOrAddNewReferee(state, action, [
          {
            key: 'getPhotoRequestStatus',
            value: requestStatusPending
          }
        ]);
      })
      .addCase(getPhoto.fulfilled, (state: RefereesState, action) => {
        changeRefereeValuesOrAddNewReferee(state, action, [
          {
            key: 'getPhotoRequestStatus',
            value: requestStatusFulfilled
          },
          {
            key: 'photo',
            value: action.payload
          }
        ]);
      })
      .addCase(getPhoto.rejected, (state: RefereesState, action) => {
        changeRefereeValuesOrAddNewReferee(state, action, [
          {
            key: 'getPhotoRequestStatus',
            value: requestStatusRejected(action)
          }
        ]);
      })
      .addCase(getDebutMatch.pending, (state: RefereesState, action) => {
        changeRefereeValuesOrAddNewReferee(state, action, [
          {
            key: 'getDebutMatchRequestStatus',
            value: requestStatusPending
          }
        ]);
      })
      .addCase(getDebutMatch.fulfilled, (state: RefereesState, action) => {
        changeRefereeValuesOrAddNewReferee(state, action, [
          {
            key: 'getDebutMatchRequestStatus',
            value: requestStatusFulfilled
          },
          {
            key: 'debutMatch',
            value: action.payload
          }
        ]);
      })
      .addCase(getDebutMatch.rejected, (state: RefereesState, action) => {
        changeRefereeValuesOrAddNewReferee(state, action, [
          {
            key: 'getDebutMatchRequestStatus',
            value: requestStatusRejected(action)
          }
        ]);
      })
      .addCase(getSeasons.pending, (state: RefereesState, action) => {
        changeRefereeValuesOrAddNewReferee(state, action, [
          {
            key: 'getSeasonsRequestStatus',
            value: requestStatusPending
          }
        ]);
      })
      .addCase(getSeasons.fulfilled, (state: RefereesState, action) => {
        changeRefereeValuesOrAddNewReferee(state, action, [
          {
            key: 'getSeasonsRequestStatus',
            value: requestStatusFulfilled
          },
          {
            key: 'seasons',
            value: action.payload
          }
        ]);
      })
      .addCase(getSeasons.rejected, (state: RefereesState, action) => {
        changeRefereeValuesOrAddNewReferee(state, action, [
          {
            key: 'getSeasonsRequestStatus',
            value: requestStatusRejected(action)
          }
        ]);
      })
      .addCase(getSeason.pending, (state: RefereesState, action) => {
        changeSeasonValuesOrAddNewSeason(state, action, [
          {
            key: 'getSeasonRequestStatus',
            value: requestStatusPending
          }
        ]);
      })
      .addCase(getSeason.fulfilled, (state: RefereesState, action) => {
        changeSeasonValuesOrAddNewSeason(state, action, [
          {
            key: 'getSeasonRequestStatus',
            value: requestStatusFulfilled
          },
          {
            key: 'seasonId',
            value: action.payload.seasonId
          },
          {
            key: 'seasonName',
            value: action.payload.seasonName
          },
          {
            key: 'leagues',
            value: action.payload.leagues
          }
        ]);
      })
      .addCase(getSeason.rejected, (state: RefereesState, action) => {
        changeSeasonValuesOrAddNewSeason(state, action, [
          {
            key: 'getSeasonRequestStatus',
            value: requestStatusRejected(action)
          }
        ]);
      })
      // TODO: ageGroups instead of teams? thinking
      // .addCase(getSeasonStatistics.pending, (state: RefereesState, action) => {
      //   changeTeamValues(state, action, [
      //     {
      //       key: 'getSeasonStatisticsRequestStatus',
      //       value: requestStatusPending
      //     }
      //   ]);
      // })
      // .addCase(getSeasonStatistics.fulfilled, (state: RefereesState, action) => {
      //   changeTeamValues(state, action, [
      //     {
      //       key: 'getSeasonStatisticsRequestStatus',
      //       value: requestStatusFulfilled
      //     },
      //     {
      //       key: 'refereeStatistics',
      //       value: action.payload
      //     }
      //   ]);
      // })
      // .addCase(getSeasonStatistics.rejected, (state: RefereesState, action) => {
      //   changeTeamValues(state, action, [
      //     {
      //       key: 'getSeasonStatisticsRequestStatus',
      //       value: requestStatusRejected(action)
      //     }
      //   ]);
      // })
      .addMatcher(
        isAnyOf(getMatchesInCurrentSeason.pending, getMatchesInSeason.pending),
        (state, action) => {
          changeSeasonValuesOrAddNewSeason(state, action, [
            {
              key: 'getMatchesRequestStatus',
              value: requestStatusPending
            }
          ]);
        }
      )
      .addMatcher(
        isAnyOf(getMatchesInCurrentSeason.fulfilled, getMatchesInSeason.fulfilled),
        (state, action) => {
          changeSeasonValuesOrAddNewSeason(state, action, [
            {
              key: 'getMatchesRequestStatus',
              value: requestStatusFulfilled
            },
            {
              key: 'matches',
              value: action.payload
            }
          ]);
        }
      )
      .addMatcher(
        isAnyOf(getMatchesInCurrentSeason.rejected, getMatchesInSeason.rejected),
        (state, action) => {
          changeSeasonValuesOrAddNewSeason(state, action, [
            {
              key: 'getMatchesRequestStatus',
              value: requestStatusRejected(action)
            }
          ]);
        }
      );
  }
});

export default refereeSlice.reducer;
