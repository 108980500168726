import React from 'react';
import { useTranslation } from 'react-i18next';
import { RequestStatus } from 'models/common.models';

import { playerPositionsTranslationKeysEn } from 'consts/playerPositions';
import { StringUtils } from 'utils/string.utils';
import DebutMatch from 'components/DebutMatch';
import TeamLink from 'components/Links/TeamLink';
import PersonDetailsCard from 'components/PersonDetailsCard';
import SingleInformationMultiline from 'components/SingleInformationMultiline';

import {
  IPlayerCurrentTeamModel,
  IPlayerDebutMatchModel,
  IPlayerDetailsModel
} from './data/models';

type Props = {
  details?: IPlayerDetailsModel | null;
  photo?: string | null;
  currentTeam?: IPlayerCurrentTeamModel | null;
  debutMatch?: IPlayerDebutMatchModel | null;

  getDetailsRequestStatus?: RequestStatus | null;
  getPhotoRequestStatus?: RequestStatus | null;
  getCurrentTeamRequestStatus?: RequestStatus | null;
  getDebutMatchRequestStatus?: RequestStatus | null;
};

const PlayerDetailsCard = ({
  details,
  photo,
  currentTeam,
  debutMatch,
  getDetailsRequestStatus,
  getPhotoRequestStatus,
  getCurrentTeamRequestStatus,
  getDebutMatchRequestStatus
}: Readonly<Props>) => {
  const { t } = useTranslation();

  const positionName = details?.position
    ? t(details?.position.name)
    : t(playerPositionsTranslationKeysEn.unknown);

  return (
    <PersonDetailsCard
      photo={photo}
      firstName={details?.firstName}
      lastName={details?.lastName}
      badgeLabel={t('Player')}
      isPhotoLoading={getPhotoRequestStatus?.isLoading}
      isDetailsLoading={getDetailsRequestStatus?.isLoading}
    >
      <SingleInformationMultiline
        title={t('Position')}
        value={StringUtils.capitalize(positionName)}
        isLoading={getDetailsRequestStatus?.isLoading}
      />

      <SingleInformationMultiline
        title={`${t('Team')} ${t('InCurrentSeason')}`}
        value={
          currentTeam ? (
            <TeamLink {...currentTeam} withEmphasisMyTeam={false} />
          ) : (
            <div>{t('NoData')}</div>
          )
        }
        isLoading={getCurrentTeamRequestStatus?.isLoading}
      />

      <SingleInformationMultiline
        title={t('SeniorMatchDebut')}
        value={debutMatch ? <DebutMatch debutMatch={debutMatch} /> : <div>{t('NoData')}</div>}
        isLoading={getDebutMatchRequestStatus?.isLoading}
      />
    </PersonDetailsCard>
  );
};

export default PlayerDetailsCard;
