import React, { memo, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'store';
import { useCanPostersGenerate, useCanTicketsGenerate } from 'utils/authProvider';
import { useDispatchOnMountWithLocation } from 'utils/customHooks';

import { get as getMatchDetails, getMatchesBetweenTeamsBeforeDate } from './actions';
import MatchDetailsPage from './MatchDetailsPage';

let tempHostId = null;
let tempGuestId = null;

const MatchDetailsPageContainer = props => {
  const { id } = useParams();
  const matchDetails = useAppSelector(state => state.matches[id]?.details);
  const matchesBetweenTeams = useAppSelector(state => state.matches[id]?.matchesBetweenTeams);
  const hostId = matchDetails?.host?.id;
  const guestId = matchDetails?.guest?.id;

  const canTicketsGenerate = useCanTicketsGenerate();
  const canPostersGenerate = useCanPostersGenerate();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (matchDetails && (tempHostId !== hostId || tempGuestId !== guestId)) {
      tempHostId = hostId;
      tempGuestId = guestId;

      dispatch(
        getMatchesBetweenTeamsBeforeDate(
          id,
          hostId,
          guestId,
          matchDetails.date || new Date().toISOString()
        )
      );
    }
  }, [dispatch, guestId, hostId, id, matchDetails]);

  useDispatchOnMountWithLocation(getMatchDetails(id));

  return (
    <MatchDetailsPage
      {...props}
      id={id}
      matchDetails={matchDetails}
      matchesBetweenTeams={matchesBetweenTeams}
      canTicketsGenerate={canTicketsGenerate}
      canPostersGenerate={canPostersGenerate}
    />
  );
};

export default memo(MatchDetailsPageContainer);
