import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { ESortDirections } from 'consts/ESortDirections';
import {
  getCurrentSortingDirection,
  sortNumericValues,
  sortTextValues
} from 'utils/sortingMethods';

import styles from './Table.module.scss';
import TableFooter from './TableFooter';
import TableHeader from './TableHeader';
import TableRow from './TableRow';

const propTypes = {
  teams: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      position: PropTypes.number,
      logo: PropTypes.string,
      name: PropTypes.string,
      matchesPlayedNumber: PropTypes.number,
      points: PropTypes.number,
      winsNumber: PropTypes.number,
      drawsNumber: PropTypes.number,
      lostNumber: PropTypes.number,
      goalsScored: PropTypes.number,
      goalsLost: PropTypes.number,
      nextMatch: PropTypes.exact({
        id: PropTypes.string,
        host: PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string
        }),
        guest: PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string
        }),
        date: PropTypes.string
      }),
      lastFiveMatches: PropTypes.arrayOf(
        PropTypes.exact({
          id: PropTypes.string,
          host: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string
          }),
          guest: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string
          }),
          hostGoals: PropTypes.number,
          guestGoals: PropTypes.number,
          date: PropTypes.string,
          status: PropTypes.string
        })
      )
    })
  ),
  teamIdContext: PropTypes.string
};

const Table = ({ teams = [], teamIdContext }) => {
  const [activeCellIndex, setActiveCellIndex] = useState(-1);
  const [activeTeamId, setActiveTeamId] = useState(undefined);

  // note: Przyjmowane wartości -> ascending || descending || undefined
  const [sortDirection, setSortDirection] = useState(undefined);
  const [sortedColumnIndex, setSortedColumnIndex] = useState(undefined);

  const columnsToDisplay = [
    'position',
    'name',
    'matchesPlayedNumber',
    'points',
    'winsNumber',
    'drawsNumber',
    'lostNumber',
    'goalsScored',
    'goalsLost',
    'goalsDifference'
  ];

  const getTeamsWithGoalsDifference = () =>
    teams.map(team => ({
      ...team,
      goalsDifference: team.goalsScored - team.goalsLost
    }));

  const [teamsData, setTeamsData] = useState(() => getTeamsWithGoalsDifference());

  const clearActiveIndex = () => setActiveCellIndex(-1);
  const clearActiveTeamId = () => setActiveTeamId(undefined);

  const resetTeamsData = () =>
    setTeamsData(sortNumericValues([...getTeamsWithGoalsDifference()], columnsToDisplay[0], true));

  const sortTeamData = () => {
    const sortingDataName = columnsToDisplay[sortedColumnIndex];
    const sortingType = typeof teamsData[0][sortingDataName] === 'number' ? 'numeric' : 'text';
    const teamsDataToSort = [
      [...teamsData],
      columnsToDisplay[sortedColumnIndex],
      sortDirection === ESortDirections.ascending
    ];

    setTeamsData(
      sortingType === 'numeric'
        ? sortNumericValues(...teamsDataToSort)
        : sortTextValues(...teamsDataToSort)
    );
  };

  const sortColumn = columnIndex => {
    const currentSortingDirection = getCurrentSortingDirection(
      sortDirection,
      columnIndex,
      sortedColumnIndex
    );

    setSortDirection(currentSortingDirection);
    setSortedColumnIndex(columnIndex);
  };

  useEffect(
    () => {
      // note: Tymczasowe zabezpieczenie przed sortowaniem "formy" xD
      if (sortedColumnIndex !== 10 && sortedColumnIndex >= 0 && !!sortDirection) {
        sortTeamData();
      } else {
        resetTeamsData();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [teams, sortDirection, sortedColumnIndex]
  );

  const lowestPositionNumber = Math.max(...teamsData.map(x => x.position));

  const teamRows = teamsData.map(team => (
    <TableRow
      {...team}
      key={team.id}
      activeCellIndex={activeCellIndex}
      setActiveCellIndex={setActiveCellIndex}
      clearActiveIndex={clearActiveIndex}
      activeTeamId={activeTeamId}
      setActiveTeamId={setActiveTeamId}
      clearActiveTeamId={clearActiveTeamId}
      lowestPositionNumber={lowestPositionNumber}
      teamIdContext={teamIdContext}
    />
  ));

  return (
    <div className={styles.table_container}>
      <table className={styles.table}>
        <thead>
          <TableHeader
            setActiveCellIndex={setActiveCellIndex}
            clearActiveIndex={clearActiveIndex}
            sortDirection={sortDirection}
            sortedColumnIndex={sortedColumnIndex}
            sortColumn={sortColumn}
          />
        </thead>
        <tbody>{teamRows}</tbody>
        <tfoot>
          <TableFooter />
        </tfoot>
      </table>
    </div>
  );
};

Table.propTypes = propTypes;

export default memo(Table);
