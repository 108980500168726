import 'react-tippy/dist/tippy.css';

import React, { lazy, memo, Suspense } from 'react';
import PropTypes from 'prop-types';

import { componentLoader } from 'utils/componentLoader';

// TODO: if PWA works, uncomment
// import themeVariables from 'styles/variables.scss';
// TODO: pliki 'styles/variables.scss' i 'styles/variables.module.scss' są identyczne
// tylko po podniesieniu wersji paczki react - scripts jak również przy użyciu vitejs
// jeśli w nazwie nie ma 'module' to nie działa,
// może wiąże się to też z usunięciem paczki 'node-sass' i dodaniem 'sass'
import Router from './Router';

const Toastr = lazy(() => componentLoader(() => import('components/Toastr')));
const Cookies = lazy(() => componentLoader(() => import('components/Cookies')));

const propTypes = {
  store: PropTypes.object,
  history: PropTypes.object
};

const App = () => (
  <Suspense fallback={<div />}>
    <Router />
    {/* TODO: <GlobalLoader /> */}
    <Toastr />
    <Cookies />
  </Suspense>
);

App.propTypes = propTypes;

export default memo(App);
