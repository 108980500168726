import { createAsyncThunk } from '@reduxjs/toolkit';

import { EFileIds } from 'consts/fileIds';
import { IFileModel } from './models';
import fileApi from './file.api';

export const getFile = createAsyncThunk<IFileModel, EFileIds>(
  'file/getFile',
  async (id: EFileIds) => await fileApi.getFile(id)
);
