const tableTypes = {
  tableSeasonFull: 0,
  tableSeasonAsHost: 1,
  tableSeasonAsGuest: 2,
  tableAutumnRoundFull: 3,
  tableAutumnRoundAsHost: 4,
  tableAutumnRoundAsGuest: 5,
  tableSpringRoundFull: 6,
  tableSpringRoundAsHost: 7,
  tableSpringRoundAsGuest: 8
};

export default tableTypes;
