const fieldNames = {
  registerForm: {
    email: 'email',
    login: 'login',
    password: 'password',
    confirmPassword: 'confirmPassword',
    acceptRegulations: 'acceptRegulations'
  },
  loginForm: {
    emailOrLogin: 'emailOrLogin',
    password: 'password'
  },
  resetPasswordForm: {
    email: 'email'
  },
  setNewPasswordForm: {
    email: 'email',
    newPassword: 'newPassword',
    confirmPassword: 'confirmPassword'
  },
  myProfileUserInformationForm: {
    login: 'login',
    avatar: 'avatar'
  },
  userPasswordChangeForm: {
    currentPassword: 'currentPassword',
    newPassword: 'newPassword',
    confirmPassword: 'confirmPassword'
  },
  userInformationForm: {
    login: 'login',
    email: 'email',
    firstName: 'firstName',
    lastName: 'lastName',
    phoneNumber: 'phoneNumber',
    facility: 'facility',
    company: 'company',
    department: 'department',
    isBanned: 'isBanned'
  }
};

export default fieldNames;
