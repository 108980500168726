import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { personWithPhotoPropTypes } from 'propTypes';

import Link from '../Links/Link';
import Text from '../Text';
import { StyledAvatar, StyledName, StyledTextContent, StyledWrapper } from './PersonTile.styles';

const propTypes = {
  ...personWithPhotoPropTypes,
  detailsUrl: PropTypes.string,
  description: PropTypes.string,
  isHighlighted: PropTypes.bool
};

const PersonTile = ({
  photo,
  firstName,
  lastName,
  detailsUrl,
  description,
  isHighlighted = true,
  ...passedProps
}) => {
  const { t } = useTranslation();

  // TODO: Vorbert -> stworzyć komponent <Text /> podpięty do providera kolorów
  // TODO: Vorbert -> Dostosować również <PersonTilePlaceholder />
  // TODO: Vorbert -> przerobić to na PlayerCard oraz zrobić z tego komponent, który mógłby być użyty przez:
  /*
    - BoardMemberCard
    - CoachCard
    Jeśli zostalibyśmy przy nazwie "card", bo jednak nie jest to card xD
  */
  const fullName = useMemo(() => `${firstName} ${lastName}`, [firstName, lastName]);

  return (
    <StyledWrapper as={detailsUrl && Link} to={detailsUrl} {...passedProps}>
      <StyledAvatar src={photo} alt={`${t('Photo')} - ${fullName}`} />
      <StyledTextContent>
        <StyledName isHighlighted={isHighlighted}>{fullName}</StyledName>
        {description && <Text>{description}</Text>}
      </StyledTextContent>
    </StyledWrapper>
  );
};

PersonTile.propTypes = propTypes;

export default memo(PersonTile);
