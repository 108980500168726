export const defaultSlidesQuantity = 5;

const responsiveRules = [
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 4
    }
  },
  {
    breakpoint: 800,
    settings: {
      slidesToShow: 3
    }
  },
  {
    breakpoint: 650,
    settings: {
      slidesToShow: 2
    }
  },
  {
    breakpoint: 500,
    settings: {
      slidesToShow: 1
    }
  }
];

export const settings = {
  dots: false,
  arrows: false,
  autoplay: true,
  speed: 500,
  pauseOnHover: true,
  swipeToSlide: true,
  slidesToShow: defaultSlidesQuantity,
  adaptiveHeight: true,
  responsive: responsiveRules
};
