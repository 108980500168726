import React, { memo } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';

import InnerContentWrapper from 'components/InnerContentWrapper';
import LoadingPageData from 'components/LoadingPageData';
import PageHeader from 'components/PageHeader';

import GalleryRow from './GalleryRow';

const propTypes = {
  albums: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      thumbnailContentBase64: PropTypes.string.isRequired
    })
  ).isRequired
};

const GalleryPage = ({ albums }) => {
  const { t } = useTranslation();

  return (
    <InnerContentWrapper subpageWrap>
      <PageHeader title={t('Gallery')} />
      {!isEmpty(albums) ? <GalleryRow albums={albums} /> : <LoadingPageData />}
    </InnerContentWrapper>
  );
};

GalleryPage.propTypes = propTypes;

export default memo(GalleryPage);
