import React, { memo } from 'react';

import { useAppSelector } from 'store';
import { useDispatchOnMount } from 'utils/customHooks';
import { useGetMyClubId } from 'utils/systemParameterProvider';

import { getBoardMembersForMyClub } from '../my-club-actions';
import BoardMembersPage from './BoardMembersPage';

const BoardMembersPageContainer = props => {
  const myClubId = useGetMyClubId();

  const boardMembers = useAppSelector(state => state.clubs[myClubId]?.boardMembers);

  useDispatchOnMount(getBoardMembersForMyClub());

  return <BoardMembersPage {...props} boardMembers={boardMembers} />;
};

export default memo(BoardMembersPageContainer);
