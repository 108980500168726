import React, { memo } from 'react';
import PropTypes from 'prop-types';

import ChangeGoingDownIcon from 'components/Icon/ChangeGoingDownIcon';

import EventWrapper from './EventWrapper';

const propTypes = {
  minute: PropTypes.number.isRequired
};

const ChangedGoingDownShortEvent = ({ minute }) => (
  <EventWrapper color="red" minute={minute}>
    <ChangeGoingDownIcon />
  </EventWrapper>
);

ChangedGoingDownShortEvent.propTypes = propTypes;

export default memo(ChangedGoingDownShortEvent);
