export enum EColumnIndexes {
  position = 0,
  name = 1,
  matchesPlayedNumber = 2,
  points = 3,
  goalsScored = 4,
  goalsLost = 5,
  goalsDifference = 6
}

export enum EColumnNames {
  position = 'position',
  name = 'name',
  matchesPlayedNumber = 'matchesPlayedNumber',
  points = 'points',
  goalsScored = 'goalsScored',
  goalsLost = 'goalsLost',
  goalsDifference = 'goalsDifference'
}

export const columnsToDisplay = [
  EColumnNames.position,
  EColumnNames.name,
  EColumnNames.matchesPlayedNumber,
  EColumnNames.points,
  EColumnNames.goalsScored,
  EColumnNames.goalsLost,
  EColumnNames.goalsDifference
];
