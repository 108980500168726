import React, { memo } from 'react';
import PropTypes from 'prop-types';

import AssistIcon from 'components/Icon/AssistIcon';

import EventWrapper from './EventWrapper';

const propTypes = {
  minute: PropTypes.number.isRequired
};

const AssistGoalEvent = ({ minute }) => (
  <EventWrapper color="green" minute={minute}>
    <AssistIcon />
  </EventWrapper>
);

AssistGoalEvent.propTypes = propTypes;

export default memo(AssistGoalEvent);
