import React, { memo } from 'react';
import { useParams } from 'react-router-dom';

import { useAppSelector } from 'store';
import { useDispatchOnMountWithLocationMultiple } from 'utils/customHooks';

import {
  getCoachesInCurrentSeason,
  getPlayersWithMatchesAndEventsInCurrentSeason
} from '../actions';
import PlayersPage from './PlayersPage';

const TeamPlayersInCurrentSeasonPageContainer = props => {
  const { id } = useParams();

  const team = useAppSelector(state => state.teams[id]);

  const coachesInCurrentSeason = team?.coachesInCurrentSeason;
  const playersWithMatchesAndEventsInCurrentSeason =
    team?.playersWithMatchesAndEventsInCurrentSeason;

  useDispatchOnMountWithLocationMultiple([
    getCoachesInCurrentSeason(id),
    getPlayersWithMatchesAndEventsInCurrentSeason(id)
  ]);

  return (
    <PlayersPage
      {...props}
      coaches={coachesInCurrentSeason}
      players={playersWithMatchesAndEventsInCurrentSeason}
    />
  );
};

export default memo(TeamPlayersInCurrentSeasonPageContainer);
