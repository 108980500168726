import React, { memo } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { personWithIdAndPhotoPropTypes } from 'propTypes';
import { getArrayWithLength } from 'utils/utils';
import CardList from 'components/CardList';
import BoardMemberCard from 'components/PeopleCards/BoardMemberCard';
import PersonTilePlaceholder from 'components/PersonTile/PersonTilePlaceholder';

const propTypes = {
  boardMembers: PropTypes.arrayOf(
    PropTypes.exact({
      ...personWithIdAndPhotoPropTypes,
      stand: PropTypes.string.isRequired
    })
  )
};

const BoardMemberList = ({ boardMembers }) => {
  const boardMembersPlaceholderQuantity = 15;

  const boardMemberCards = boardMembers.map(boardMember => (
    <BoardMemberCard key={boardMember.id} {...boardMember} />
  ));

  const placeholderCards = getArrayWithLength(boardMembersPlaceholderQuantity).map((x, index) => (
    <PersonTilePlaceholder key={index} withDescritpion />
  ));

  return <CardList>{!isEmpty(boardMembers) ? boardMemberCards : placeholderCards}</CardList>;
};

BoardMemberList.propTypes = propTypes;

export default memo(BoardMemberList);
