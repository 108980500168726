import PropTypes from 'prop-types';

import { simpleTeamPropTypesShape } from './simple-team';

export const matchPropTypes = {
  id: PropTypes.string,
  host: simpleTeamPropTypesShape,
  guest: simpleTeamPropTypesShape,
  hostGoals: PropTypes.number,
  guestGoals: PropTypes.number,
  hostFirstHalfGoals: PropTypes.number,
  guestFirstHalfGoals: PropTypes.number,
  date: PropTypes.string,
  comment: PropTypes.string,
  isWalkoverForGuest: PropTypes.bool,
  isWalkoverForHost: PropTypes.bool,
  walkoverCause: PropTypes.string
};

export const matchPropTypesShape = PropTypes.shape({ ...matchPropTypes });
