import React, { memo } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';

import { personWithIdAndPhotoPropTypes } from 'propTypes';
import Accordion from 'components/Accordion';
import Article from 'components/Article';
import TeamsCarousel from 'components/Carousel/TeamsCarousel';
import CompaniesList from 'components/CompaniesList';
import InnerContentWrapper from 'components/InnerContentWrapper';

import BoardMemberList from '../board-members/BoardMemberList';
import ContactCard from '../contact/ContactCard';

const propTypes = {
  details: PropTypes.any,
  contact: PropTypes.shape({
    logo: PropTypes.string,
    photo: PropTypes.string,
    name: PropTypes.string,
    address: PropTypes.exact({
      city: PropTypes.string,
      street: PropTypes.string,
      buildingNumber: PropTypes.string,
      apartmentNumber: PropTypes.string,
      zipCode: PropTypes.string,
      postOffice: PropTypes.string
    }),
    nip: PropTypes.string,
    regon: PropTypes.string,
    krs: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
    bankName: PropTypes.string,
    bankAccountNumber: PropTypes.string
  }),
  teams: PropTypes.shape({
    seasons: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        yearFrom: PropTypes.number,
        yearTo: PropTypes.number,
        teams: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            logo: PropTypes.string,
            name: PropTypes.string,
            ageGroupName: PropTypes.string,
            league: PropTypes.shape({
              id: PropTypes.string,
              name: PropTypes.string
            })
          })
        )
      })
    )
  }),
  boardMembers: PropTypes.arrayOf(
    PropTypes.exact({
      ...personWithIdAndPhotoPropTypes,
      stand: PropTypes.string.isRequired
    })
  ),
  partners: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string,
      name: PropTypes.string,
      logo: PropTypes.string,
      websiteUrl: PropTypes.string
    })
  ),
  sponsors: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string,
      name: PropTypes.string,
      logo: PropTypes.string,
      websiteUrl: PropTypes.string
    })
  ),
  history: PropTypes.shape({
    photo: PropTypes.string,
    history: PropTypes.string
  }),
  stadiumDescription: PropTypes.shape({
    photos: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string,
    description: PropTypes.string
  })
};

const ClubDetailsPage = ({
  details,
  contact,
  teams,
  boardMembers,
  partners,
  sponsors,
  history,
  stadiumDescription
}) => {
  const { t } = useTranslation();

  return (
    <InnerContentWrapper subpageWrap>
      {/* TODO: */}
      {!isEmpty(details) ? <span /> : <span />}

      {!isEmpty(contact) && <ContactCard contact={contact} />}

      {!isEmpty(boardMembers) && (
        <Accordion hoverable className="margin-top" title={t('ClubManagement')} withoutPadding>
          <BoardMemberList boardMembers={boardMembers} />
        </Accordion>
      )}

      {!isEmpty(teams) && (
        <Accordion hoverable className="margin-top" title={t('Teams')} withoutPadding>
          <TeamsCarousel seasons={teams.seasons} />
        </Accordion>
      )}

      {!isEmpty(partners) && (
        <Accordion hoverable className="margin-top" title={t('Partners')} withoutPadding>
          <CompaniesList companies={partners} title={t('Partners')} />
        </Accordion>
      )}

      {!isEmpty(sponsors) && (
        <Accordion hoverable className="margin-top" title={t('Sponsors')} withoutPadding>
          <CompaniesList companies={sponsors} title={t('Sponsors')} />
        </Accordion>
      )}

      {!isEmpty(history?.history) && (
        <Accordion hoverable className="margin-top" title={t('ClubHistory')} extendedOnInit={false}>
          <Article photo={history.photo} title={t('ClubHistory')} content={history.history} />
        </Accordion>
      )}

      {!isEmpty(stadiumDescription?.description) && (
        <Accordion hoverable className="margin-top" title={t('Stadium')} extendedOnInit={false}>
          <Article
            photo={stadiumDescription.photos?.length ? stadiumDescription.photos[0] : null} // TODO: maybe some main photo? and also show other photos, maybe like in gallery? will do new component for stadium description and also new common component for photos
            title={stadiumDescription.name}
            content={stadiumDescription.description}
          />
        </Accordion>
      )}
    </InnerContentWrapper>
  );
};

ClubDetailsPage.propTypes = propTypes;

export default memo(ClubDetailsPage);
