import ObjectUtils from 'utils/object.utils';

import actionTypes from './actionTypes';
import service from './service';

const getBoardMemberDetailsSuccess = data => dispatch =>
  dispatch({
    type: actionTypes.STORE_BOARD_MEMBER_DETAILS,
    data
  });

export function get(id) {
  return (dispatch, getState) => {
    const { boardMembers } = getState();

    return (
      ObjectUtils.isEmpty(boardMembers[id]) &&
      service.get(id).then(boardMember =>
        dispatch(
          getBoardMemberDetailsSuccess({
            id,
            ...boardMember
          })
        )
      )
    );
  };
}
