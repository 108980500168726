import React, { memo } from 'react';

import { useAppDispatch, useAppSelector } from 'store';
import tableTypes from 'consts/tableTypes';
import { useDispatchOnMountWithLocationMultiple } from 'utils/customHooks';

import {
  getCurrentCompetitionsSchedule,
  getCurrentCompetitionsTableAutumnRoundAsGuest,
  getCurrentCompetitionsTableAutumnRoundAsHost,
  getCurrentCompetitionsTableAutumnRoundFull,
  getCurrentCompetitionsTableSeasonAsGuest,
  getCurrentCompetitionsTableSeasonAsHost,
  getCurrentCompetitionsTableSeasonFull,
  getCurrentCompetitionsTableSpringRoundAsGuest,
  getCurrentCompetitionsTableSpringRoundAsHost,
  getCurrentCompetitionsTableSpringRoundFull,
  getLeagueNameForMyLeague,
  getSeasonNameForCurrentSeason
} from '../actions';
import CompetitionsDetailsPage from './CompetitionsDetailsPage';

const CurrentCompetitionsDetailsPageContainer = props => {
  const dispatch = useAppDispatch();

  // TODO: Vorbert -> połączyć selektory, tak by wyciągać tyle danych z pojedyńczego ile to jest możliwe
  // Przykład: const {asHost, asGuest, full } = useAppSelector(state => state.currentCompetitions?.table?.autumn);

  // TODO: czy robić za każdym razem useSelector? czy oże być tak jak jest teraz albo jakoś jeszcze inaczej? jak będzie najwydajniej? może użyć reselect? - po zmianach zmienić w całej aplikacji analogicznie
  // const leagueName = useAppSelector(state => state.currentCompetitions?.leagueName);
  // const seasonName = useAppSelector(state => state.currentCompetitions?.seasonName);

  const currentCompetitions = useAppSelector(state => state.currentCompetitions);

  const leagueName = currentCompetitions?.leagueName;
  const seasonName = currentCompetitions?.seasonName;

  const tableSeasonFull = currentCompetitions?.table?.season?.full;
  const tableSeasonAsHost = currentCompetitions?.table?.season?.asHost;
  const tableSeasonAsGuest = currentCompetitions?.table?.season?.asGuest;

  const tableAutumnRoundFull = currentCompetitions?.table?.autumnRound?.full;
  const tableAutumnRoundAsHost = currentCompetitions?.table?.autumnRound?.asHost;
  const tableAutumnRoundAsGuest = currentCompetitions?.table?.autumnRound?.asGuest;

  const tableSpringRoundFull = currentCompetitions?.table?.springRound?.full;
  const tableSpringRoundAsHost = currentCompetitions?.table?.springRound?.asHost;
  const tableSpringRoundAsGuest = currentCompetitions?.table?.springRound?.asGuest;

  const competitionsSchedule = currentCompetitions?.competitionsSchedule;

  const handleGetTableData = tableType => {
    switch (tableType) {
      case tableTypes.tableSeasonFull: {
        dispatch(getCurrentCompetitionsTableSeasonFull());
        break;
      }
      case tableTypes.tableSeasonAsHost: {
        dispatch(getCurrentCompetitionsTableSeasonAsHost());
        break;
      }
      case tableTypes.tableSeasonAsGuest: {
        dispatch(getCurrentCompetitionsTableSeasonAsGuest());
        break;
      }
      case tableTypes.tableAutumnRoundFull: {
        dispatch(getCurrentCompetitionsTableAutumnRoundFull());
        break;
      }
      case tableTypes.tableAutumnRoundAsHost: {
        dispatch(getCurrentCompetitionsTableAutumnRoundAsHost());
        break;
      }
      case tableTypes.tableAutumnRoundAsGuest: {
        dispatch(getCurrentCompetitionsTableAutumnRoundAsGuest());
        break;
      }
      case tableTypes.tableSpringRoundFull: {
        dispatch(getCurrentCompetitionsTableSpringRoundFull());
        break;
      }
      case tableTypes.tableSpringRoundAsHost: {
        dispatch(getCurrentCompetitionsTableSpringRoundAsHost());
        break;
      }
      case tableTypes.tableSpringRoundAsGuest: {
        dispatch(getCurrentCompetitionsTableSpringRoundAsGuest());
        break;
      }

      default:
        break;
    }
  };

  useDispatchOnMountWithLocationMultiple([
    getLeagueNameForMyLeague(),
    getSeasonNameForCurrentSeason(),

    getCurrentCompetitionsTableSeasonFull(),

    getCurrentCompetitionsSchedule()
  ]);

  return (
    <CompetitionsDetailsPage
      {...props}
      leagueName={leagueName}
      seasonName={seasonName}
      tableSeasonFull={tableSeasonFull}
      tableSeasonAsHost={tableSeasonAsHost}
      tableSeasonAsGuest={tableSeasonAsGuest}
      tableAutumnRoundFull={tableAutumnRoundFull}
      tableAutumnRoundAsHost={tableAutumnRoundAsHost}
      tableAutumnRoundAsGuest={tableAutumnRoundAsGuest}
      tableSpringRoundFull={tableSpringRoundFull}
      tableSpringRoundAsHost={tableSpringRoundAsHost}
      tableSpringRoundAsGuest={tableSpringRoundAsGuest}
      competitionsSchedule={competitionsSchedule}
      onGetTableData={handleGetTableData}
    />
  );
};

export default memo(CurrentCompetitionsDetailsPageContainer);
