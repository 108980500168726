import axios from 'config/axios';

const prefix = 'client/competitions';

export default class CompetitionsSrv {
  static getLeagueNameForMyLeague() {
    return axios.get(`${prefix}/league-name`).then(result => result.data);
  }

  // note: not use
  static getLeagueName(leagueId) {
    return axios.get(`${prefix}/league-name/${leagueId}`).then(result => result.data);
  }

  static getSeasonNameForCurrentSeason() {
    return axios.get(`${prefix}/season-name`).then(result => result.data);
  }

  // note: not use
  static getSeasonName(seasonId) {
    return axios.get(`${prefix}/season-name/${seasonId}`).then(result => result.data);
  }

  static getCurrentCompetitionsInfo() {
    return axios.get(`${prefix}/info`).then(result => result.data);
  }

  static getCompetitionsInfo(leagueId, seasonId) {
    return axios.get(`${prefix}/info/${leagueId}/${seasonId}`).then(result => result.data);
  }

  static getCurrentCompetitionsTableSeasonFull() {
    return axios.get(`${prefix}/table/season/full`).then(result => result.data);
  }

  static getCurrentCompetitionsTableSeasonAsHost() {
    return axios.get(`${prefix}/table/season/asHost`).then(result => result.data);
  }

  static getCurrentCompetitionsTableSeasonAsGuest() {
    return axios.get(`${prefix}/table/season/asGuest`).then(result => result.data);
  }

  static getCurrentCompetitionsTableAutumnRoundFull() {
    return axios.get(`${prefix}/table/autumnRound/full`).then(result => result.data);
  }

  static getCurrentCompetitionsTableAutumnRoundAsHost() {
    return axios.get(`${prefix}/table/autumnRound/asHost`).then(result => result.data);
  }

  static getCurrentCompetitionsTableAutumnRoundAsGuest() {
    return axios.get(`${prefix}/table/autumnRound/asGuest`).then(result => result.data);
  }

  static getCurrentCompetitionsTableSpringRoundFull() {
    return axios.get(`${prefix}/table/springRound/full`).then(result => result.data);
  }

  static getCurrentCompetitionsTableSpringRoundAsHost() {
    return axios.get(`${prefix}/table/springRound/asHost`).then(result => result.data);
  }

  static getCurrentCompetitionsTableSpringRoundAsGuest() {
    return axios.get(`${prefix}/table/springRound/asGuest`).then(result => result.data);
  }

  static getCompetitionsTableSeasonFull(leagueId, seasonId) {
    return axios
      .get(`${prefix}/table/season/full/${leagueId}/${seasonId}`)
      .then(result => result.data);
  }

  static getCompetitionsTableSeasonAsHost(leagueId, seasonId) {
    return axios
      .get(`${prefix}/table/season/asHost/${leagueId}/${seasonId}`)
      .then(result => result.data);
  }

  static getCompetitionsTableSeasonAsGuest(leagueId, seasonId) {
    return axios
      .get(`${prefix}/table/season/asGuest/${leagueId}/${seasonId}`)
      .then(result => result.data);
  }

  static getCompetitionsTableAutumnRoundFull(leagueId, seasonId) {
    return axios
      .get(`${prefix}/table/autumnRound/full/${leagueId}/${seasonId}`)
      .then(result => result.data);
  }

  static getCompetitionsTableAutumnRoundAsHost(leagueId, seasonId) {
    return axios
      .get(`${prefix}/table/autumnRound/asHost/${leagueId}/${seasonId}`)
      .then(result => result.data);
  }

  static getCompetitionsTableAutumnRoundAsGuest(leagueId, seasonId) {
    return axios
      .get(`${prefix}/table/autumnRound/asGuest/${leagueId}/${seasonId}`)
      .then(result => result.data);
  }

  static getCompetitionsTableSpringRoundFull(leagueId, seasonId) {
    return axios
      .get(`${prefix}/table/springRound/full/${leagueId}/${seasonId}`)
      .then(result => result.data);
  }

  static getCompetitionsTableSpringRoundAsHost(leagueId, seasonId) {
    return axios
      .get(`${prefix}/table/springRound/asHost/${leagueId}/${seasonId}`)
      .then(result => result.data);
  }

  static getCompetitionsTableSpringRoundAsGuest(leagueId, seasonId) {
    return axios
      .get(`${prefix}/table/springRound/asGuest/${leagueId}/${seasonId}`)
      .then(result => result.data);
  }

  static getCurrentCompetitionsSchedule() {
    return axios.get(`${prefix}/competitions-schedule`).then(result => result.data);
  }

  static getCompetitionsSchedule(leagueId, seasonId) {
    return axios
      .get(`${prefix}/competitions-schedule/${leagueId}/${seasonId}`)
      .then(result => result.data);
  }
}
