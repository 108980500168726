// TODO: change any to correct types

/* eslint-disable @typescript-eslint/no-explicit-any */

import { RootState, useAppSelector } from 'store';
import systemParameterIds from 'consts/systemParameterIds';

export function getSystemParameterValue(state: RootState, id: string): any {
  return state.systemParameters?.systemParameters.find(x => x.id === id)?.value;
}

export function getMyTeamId(state: RootState): string {
  return getSystemParameterValue(state, systemParameterIds.myTeamId);
}

export function useGetMyTeamId(): string {
  return useAppSelector((state: RootState) => getMyTeamId(state));
}

export function getMyClubId(state: RootState): string {
  return getSystemParameterValue(state, systemParameterIds.myClubId);
}

export function useGetMyClubId(): string {
  return useAppSelector((state: RootState) => getMyClubId(state));
}

export function getCurrentSeasonId(state: RootState): string {
  return getSystemParameterValue(state, systemParameterIds.currentSeasonId);
}

export function useGetCurrentSeasonId(): string {
  return useAppSelector((state: RootState) => getCurrentSeasonId(state));
}

export function getYouthTeams(state: RootState): any {
  return state.systemParameters?.youthTeams;
}

export function useGetYouthTeams(): any {
  return useAppSelector((state: RootState) => getYouthTeams(state));
}

// note: użycie

// const currentSeasonId = useAppSelector(state =>
//   getSystemParameterValue(state, systemParameterIds.currentSeasonId)
// );

// lub

// export function getDetailsForMyTeam() {
//   return (dispatch, getState) => {
//     const state = getState();
//     const myTeamId = getSystemParameterValue(state, systemParameterIds.myTeamId);
//   }
// }
