import React, { memo } from 'react';
import PropTypes from 'prop-types';

import styles from './DropdownItem.module.scss';

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  renderItem: PropTypes.func
};

const DropdownItem = ({
  children = () => {},
  renderItem = (className, children) => <span className={className}>{children}</span>
}) => renderItem(styles.item, children);

DropdownItem.propTypes = propTypes;

export default memo(DropdownItem);
