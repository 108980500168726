import 'slick-carousel/slick/slick.css';

import React, { memo, useRef } from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';

import displayPlaceholders from 'utils/displayPlaceholders';

import AdjustableFlexContent from '../AdjustableFlexContent';
import ArrowButton from '../ArrowButton/ArrowButton';
import { defaultSlidesQuantity, settings } from './Carousel.consts';
import Slide from './Slide';
import SlidePlaceholder from './SlidePlaceholder';
import StyledWrapper from './StyledWrapper';

// TODO: wydzielić uniwersalny komponent, nie tylko dla teams i companies, zmienić na items itp.

const propTypes = {
  companies: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      logo: PropTypes.string,
      websiteUrl: PropTypes.string
    })
  ).isRequired
};

const CompaniesCarousel = ({ companies }) => {
  const showArrowButtons = companies.length >= defaultSlidesQuantity;
  const showPlaceholders = !companies.length;
  const sliderRef = useRef(null);

  const handlePrevSlide = () => sliderRef.current?.slickPrev();
  const handleNextSlide = () => sliderRef.current?.slickNext();

  return (
    <StyledWrapper>
      {showArrowButtons && <ArrowButton rotated onClick={handlePrevSlide} />}
      <AdjustableFlexContent>
        <Slider
          {...settings}
          autoplay={showArrowButtons}
          swipe={showArrowButtons}
          swipeToSlide={showArrowButtons}
          slidesToShow={showArrowButtons ? defaultSlidesQuantity : companies.length}
          ref={sliderRef}
        >
          {showPlaceholders
            ? displayPlaceholders(5, SlidePlaceholder)
            : companies.map(company => <Slide {...company} key={company.id} />)}
        </Slider>
      </AdjustableFlexContent>
      {showArrowButtons && <ArrowButton onClick={handleNextSlide} />}
    </StyledWrapper>
  );
};

CompaniesCarousel.propTypes = propTypes;

export default memo(CompaniesCarousel);
