import styled, { css } from 'styled-components';

type Props = {
  isCapitalize?: boolean;
};

export const StyledBadge = styled.div<Props>`
  width: fit-content;
  margin: 0.5em auto;
  padding: 0.15em 0.5em;
  background-color: ${({ theme }) => theme.light};
  border-radius: 0.5em;
  font-size: 1.5rem;
  font-weight: bold;
  color: ${({ theme }) => theme.blackColor};

  ${({ isCapitalize }) =>
    isCapitalize &&
    css`
      text-transform: capitalize;
    `}
`;
