import React, { memo } from 'react';
import PropTypes from 'prop-types';

import ContentRevealer from 'components/ContentRevealer';

import styles from './ErrorMessage.module.scss';

const propTypes = {
  isVisible: PropTypes.bool,
  errorMsg: PropTypes.string
};

const ErrorMessage = ({ isVisible = true, errorMsg }) => (
  <ContentRevealer
    isVisible={isVisible}
    visibleClassName={styles.error_container}
    mutableValues={[errorMsg]}
  >
    <div>
      <span className={styles.error}>{errorMsg}</span>
    </div>
  </ContentRevealer>
);

ErrorMessage.propTypes = propTypes;

export default memo(ErrorMessage);
