import PropTypes from 'prop-types';

import { cardPropTypesShape } from './card';
import { matchPropTypes } from './match';

export const refereeSeasonPropTypes = {
  seasonId: PropTypes.string,
  seasonName: PropTypes.string,
  leagues: PropTypes.arrayOf(
    PropTypes.shape({
      leagueId: PropTypes.string,
      leagueName: PropTypes.string,
      ageGroupName: PropTypes.string,
      matches: PropTypes.arrayOf(
        PropTypes.shape({
          ...matchPropTypes,
          ageGroupName: PropTypes.string,
          functionName: PropTypes.string,
          cards: PropTypes.arrayOf(cardPropTypesShape)
        })
      ),
      matchesCount: PropTypes.number
    })
  )
};

export const refereeSeasonPropTypesShape = PropTypes.shape({ ...refereeSeasonPropTypes });
