import axios from 'config/axios';

import {
  ICoachCurrentTeamResponse,
  ICoachDebutMatchResponse,
  ICoachDetailsResponse,
  ICoachMatchResponse,
  ICoachSeasonResponse,
  ICoachSeasonStatisticsResponse
} from './models/coachResponse.models';

const prefix = 'client/coach';

export default class CoachApi {
  static async getDetails(id: string): Promise<ICoachDetailsResponse> {
    const result = await axios.get<ICoachDetailsResponse>(`${prefix}/${id}`);
    return result.data;
  }

  static async getPhoto(id: string): Promise<string> {
    const result = await axios.get<string>(`${prefix}/${id}/photo`);
    return result.data;
  }

  static async getCurrentTeam(id: string): Promise<ICoachCurrentTeamResponse> {
    const result = await axios.get<ICoachCurrentTeamResponse>(`${prefix}/${id}/currentTeam`);
    return result.data;
  }

  static async getDebutMatch(id: string): Promise<ICoachDebutMatchResponse> {
    const result = await axios.get<ICoachDebutMatchResponse>(`${prefix}/${id}/debutMatch`);
    return result.data;
  }

  static async getSeasons(id: string): Promise<ICoachSeasonResponse[]> {
    const result = await axios.get<ICoachSeasonResponse[]>(`${prefix}/${id}/seasons`);
    return result.data;
  }

  static async getSeason(id: string, seasonId: string): Promise<ICoachSeasonResponse> {
    const result = await axios.get<ICoachSeasonResponse>(`${prefix}/${id}/season/${seasonId}`);
    return result.data;
  }

  static async getMatchesInCurrentSeason(id: string): Promise<ICoachMatchResponse[]> {
    const result = await axios.get<ICoachMatchResponse[]>(`${prefix}/${id}/matches`);
    return result.data;
  }

  static async getMatchesInSeason(id: string, seasonId: string): Promise<ICoachMatchResponse[]> {
    const result = await axios.get<ICoachMatchResponse[]>(`${prefix}/${id}/${seasonId}/matches`);
    return result.data;
  }

  // note: not used
  static async getMatchesInCompetitions(
    id: string,
    leagueId: string,
    seasonId: string
  ): Promise<ICoachMatchResponse[]> {
    const result = await axios.get<ICoachMatchResponse[]>(
      `${prefix}/${id}/${leagueId}/${seasonId}/matches`
    );
    return result.data;
  }

  static async getSeasonStatistics(
    id: string,
    seasonId: string,
    leagueId: string,
    teamId: string
  ): Promise<ICoachSeasonStatisticsResponse> {
    const result = await axios.get<ICoachSeasonStatisticsResponse>(
      `${prefix}/${id}/${teamId}/${leagueId}/${seasonId}/seasonsStatistics`
    );
    return result.data;
  }
}
