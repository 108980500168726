import { KeyValuePair } from 'models/common.models';

import ArrayUtils from 'utils/array.utils';

import { CoachesState, ICoachModel, ICoachSeasonModel, ILeagueModel, ITeamModel } from './models';

export function getExistingCoach(
  state: CoachesState,
  coachId: string
): ICoachModel | undefined | null {
  return state[coachId];
}

export function getExistingSeason(
  state: CoachesState,
  coachId: string,
  seasonId: string
): ICoachSeasonModel | undefined | null {
  const existingCoach = getExistingCoach(state, coachId);

  if (existingCoach?.seasons?.length) {
    return existingCoach?.seasons.find(season => season.seasonId === seasonId);
  }
}

export function getExistingLeague(
  state: CoachesState,
  coachId: string,
  seasonId: string,
  leagueId: string
): ILeagueModel | undefined | null {
  const existingSeason = getExistingSeason(state, coachId, seasonId);

  if (existingSeason) {
    return existingSeason?.leagues.find(league => league.leagueId === leagueId);
  }
}

export function getExistingTeam(
  state: CoachesState,
  coachId: string,
  seasonId: string,
  leagueId: string,
  teamId: string
): ITeamModel | undefined | null {
  const existingLeague = getExistingLeague(state, coachId, seasonId, leagueId);

  if (existingLeague) {
    return existingLeague?.teams.find(team => team.id === teamId);
  }
}

export function changeCoachValuesOrAddNewCoach(
  state: CoachesState,
  action,
  keyValues: KeyValuePair[]
): void {
  const coachId = action.meta.arg;

  const existingCoach = getExistingCoach(state, coachId);

  if (existingCoach) {
    for (const keyValue of keyValues) {
      existingCoach[keyValue.key] = keyValue.value;
    }
  } else {
    state[coachId] = {} as ICoachModel;

    for (const keyValue of keyValues) {
      state[coachId][keyValue.key] = keyValue.value;
    }
  }
}

export function changeSeasonValuesOrAddNewSeason(
  state: CoachesState,
  action,
  keyValues: KeyValuePair[]
): void {
  const coachId = action.meta.arg.id;
  const seasonId = action.meta.arg.seasonId;

  const existingSeason = getExistingSeason(state, coachId, seasonId);

  if (existingSeason) {
    for (const keyValue of keyValues) {
      existingSeason[keyValue.key] = keyValue.value;
    }
  } else {
    if (ArrayUtils.isEmpty(state[coachId].seasons!)) {
      state[coachId].seasons = [];
    }

    state[coachId].seasons!.push({ seasonId } as ICoachSeasonModel);

    const existingSeason = getExistingSeason(state, coachId, seasonId);

    for (const keyValue of keyValues) {
      existingSeason![keyValue.key] = keyValue.value;
    }
  }
}

// TODO: add orAddNewTeam? and logic?
export function changeTeamValues(state: CoachesState, action, keyValues: KeyValuePair[]): void {
  const coachId = action.meta.arg.id;
  const seasonId = action.meta.arg.seasonId;
  const leagueId = action.meta.arg.leagueId;
  const teamId = action.meta.arg.teamId;

  const existingTeam = getExistingTeam(state, coachId, seasonId, leagueId, teamId);

  if (existingTeam) {
    for (const keyValue of keyValues) {
      existingTeam[keyValue.key] = keyValue.value;
    }
  }
}
