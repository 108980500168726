export const colors = {
  success: 'success',
  info: 'info',
  warning: 'warning',
  error: 'error',
  green: 'green',
  dark: 'dark',
  opaqueDark: 'dark-opaque',
  orangeOpaque: 'orange-opaque',
  white: 'white'
};

export enum EColors {
  success = 'success',
  info = 'info',
  warning = 'warning',
  error = 'error',
  green = 'green',
  dark = 'dark',
  opaqueDark = 'dark-opaque',
  orangeOpaque = 'orange-opaque',
  white = 'white'
}

export const colorArray = Object.values(EColors);

export type ColorsType =
  | EColors.success
  | EColors.info
  | EColors.warning
  | EColors.error
  | EColors.green
  | EColors.dark
  | EColors.opaqueDark
  | EColors.orangeOpaque
  | EColors.white;
