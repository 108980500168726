import { memo } from 'react';
import styled from 'styled-components';

const StyledImage = styled.img`
  display: block;
  margin: 0 auto;
  height: 110px;
  width: 100%;
  max-width: 75%;
  object-fit: contain;
  pointer-events: all;

  @media screen and (max-width: 1250px) {
    height: 5rem;
    max-width: 85%;
  }
`;

export default memo(StyledImage);
