import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { personWithIdAndPhotoPropTypes } from 'propTypes';
import InnerContentWrapper from 'components/InnerContentWrapper';
import PageHeader from 'components/PageHeader';

import BoardMemberList from './BoardMemberList';

const propTypes = {
  boardMembers: PropTypes.arrayOf(
    PropTypes.exact({
      ...personWithIdAndPhotoPropTypes,
      stand: PropTypes.string.isRequired
    })
  )
};

const BoardMembersPage = ({ boardMembers = [] }) => {
  const { t } = useTranslation();

  return (
    <InnerContentWrapper subpageWrap>
      <PageHeader title={t('ClubManagement')} />
      <BoardMemberList boardMembers={boardMembers} />
    </InnerContentWrapper>
  );
};

BoardMembersPage.propTypes = propTypes;

export default memo(BoardMembersPage);
