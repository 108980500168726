import PropTypes from 'prop-types';

import { matchPropTypes } from './match';
import { simpleTeamPropTypes } from './simple-team';

export const coachSeasonPropTypes = {
  seasonId: PropTypes.string,
  seasonName: PropTypes.string,
  leagues: PropTypes.arrayOf(
    PropTypes.shape({
      leagueId: PropTypes.string,
      leagueName: PropTypes.string,
      ageGroupName: PropTypes.string,
      teams: PropTypes.arrayOf(
        PropTypes.shape({
          ...simpleTeamPropTypes,
          matches: PropTypes.arrayOf(
            PropTypes.shape({
              ...matchPropTypes,
              ageGroupName: PropTypes.string,
              coachTypeName: PropTypes.string
            })
          ),
          matchesCount: PropTypes.number
        })
      )
    })
  )
};

export const coachSeasonPropTypesShape = PropTypes.shape({ ...coachSeasonPropTypes });
