import React from 'react';
import cx from 'classnames';
import { IPositionModel } from 'models/businessCommon.models';

import { playerPositionNamesEn } from 'consts/playerPositions';
import { mapPlayerPositionName } from 'mappers';

import styles from './withPositionColors.module.scss';

type Props = {
  position?: IPositionModel | null;
};

const withPositionColors = Component => {
  const wrappedComponent = (props: Readonly<Props>) => {
    let positionName = mapPlayerPositionName(props.position?.name);

    if (!positionName) {
      positionName = props.position?.name?.toLowerCase();
    }

    const colorsClassName = cx(
      styles[`positionColor--${positionName || playerPositionNamesEn.unknown}`]
    );

    return <Component {...props} className={colorsClassName} />;
  };

  return wrappedComponent;
};

export default withPositionColors;
