import PropTypes from 'prop-types';

import { playerPropTypes } from './player';

export const goalPropTypes = {
  minute: PropTypes.number,
  isOwn: PropTypes.bool,
  isPenalty: PropTypes.bool,
  assistantPlayer: PropTypes.shape({ ...playerPropTypes })
};

export const goalPropTypesShape = PropTypes.shape({ ...goalPropTypes });
