import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import styles from './MobileSubmenu.module.scss';
import MobileMenuLink from './MobileMenuLink';
import MobileSubmenuLink from './MobileSubmenuLink';

const propTypes = {
  subLinksData: PropTypes.arrayOf(PropTypes.object).isRequired
};

const MobileSubmenu = ({ subLinksData }) => {
  const { t } = useTranslation();
  const hasNestedSubmenus = subLinksData.some(link => link.subLinks?.length > 0);

  return (
    <ul className={styles.subLink_container}>
      {subLinksData.map((linkData, index) => (
        <li key={index}>
          {hasNestedSubmenus ? (
            <MobileMenuLink
              {...linkData}
              label={linkData.shouldNotTranslateLabel ? linkData.label : t(linkData.label)}
            />
          ) : (
            <MobileSubmenuLink
              {...linkData}
              label={linkData.shouldNotTranslateLabel ? linkData.label : t(linkData.label)}
            />
          )}
        </li>
      ))}
    </ul>
  );
};

MobileSubmenu.propTypes = propTypes;

export default memo(MobileSubmenu);
