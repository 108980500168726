import { createAsyncThunk } from '@reduxjs/toolkit';

import coachApi from './coach.api';
import {
  ICoachCurrentTeamModel,
  ICoachDebutMatchModel,
  ICoachDetailsModel,
  ICoachMatchModel,
  ICoachSeasonModel,
  ICoachSeasonStatisticsModel
} from './models';

export const getDetails = createAsyncThunk<ICoachDetailsModel, string>(
  'coach/getDetails',
  async (id: string) => {
    const response = await coachApi.getDetails(id);
    return response;
  }
);

export const getPhoto = createAsyncThunk<string, string>('coach/getPhoto', async (id: string) => {
  const response = await coachApi.getPhoto(id);
  return response;
});

export const getCurrentTeam = createAsyncThunk<ICoachCurrentTeamModel, string>(
  'coach/getCurrentTeam',
  async (id: string) => {
    const response = await coachApi.getCurrentTeam(id);
    return response;
  }
);

export const getDebutMatch = createAsyncThunk<ICoachDebutMatchModel, string>(
  'coach/getDebutMatch',
  async (id: string) => {
    const response = await coachApi.getDebutMatch(id);
    return response;
  }
);

export const getSeasons = createAsyncThunk<ICoachSeasonModel[], string>(
  'coach/getSeasons',
  async (id: string): Promise<ICoachSeasonModel[]> => {
    const response = await coachApi.getSeasons(id);
    return response as ICoachSeasonModel[];
  }
);

export const getSeason = createAsyncThunk<ICoachSeasonModel, { id: string; seasonId: string }>(
  'coach/getSeason',
  async ({ id, seasonId }: { id: string; seasonId: string }): Promise<ICoachSeasonModel> => {
    const response = await coachApi.getSeason(id, seasonId);
    return response as ICoachSeasonModel;
  }
);

export const getSeasonStatistics = createAsyncThunk<
  ICoachSeasonStatisticsModel,
  { id: string; seasonId: string; leagueId: string; teamId: string }
>(
  'coach/getSeasonStatistics',
  async ({
    id,
    seasonId,
    leagueId,
    teamId
  }: {
    id: string;
    seasonId: string;
    leagueId: string;
    teamId: string;
  }) => {
    const response = await coachApi.getSeasonStatistics(id, seasonId, leagueId, teamId);
    return response;
  }
);

export const getMatchesInCurrentSeason = createAsyncThunk<
  ICoachMatchModel[],
  { id: string; seasonId: string }
>(
  'coach/getMatchesInCurrentSeason',
  // note: seasonId is currentSeasonId get from systemParameters
  // it is used only in the fulfilled case reducer to match seasonId from store and add matches to the season
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  async ({ id, seasonId }: { id: string; seasonId: string }) => {
    const response = await coachApi.getMatchesInCurrentSeason(id);
    return response;
  }
);

export const getMatchesInSeason = createAsyncThunk<
  ICoachMatchModel[],
  { id: string; seasonId: string }
>('coach/getMatchesInSeason', async ({ id, seasonId }: { id: string; seasonId: string }) => {
  const response = await coachApi.getMatchesInSeason(id, seasonId);
  return response;
});
