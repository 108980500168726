import axios from 'config/axios';

const prefix = 'client/home';

export default class HomePageSrv {
  static getArticles() {
    return axios.get(`${prefix}/articles`).then(result => result.data);
  }

  static getTable() {
    return axios.get(`${prefix}/table`).then(result => result.data);
  }

  static getPreviousMatch() {
    return axios.get(`${prefix}/previous-match`).then(result => result.data);
  }

  static getCurrentMatch() {
    return axios.get(`${prefix}/current-match`).then(result => result.data);
  }

  static getNextMatch() {
    return axios.get(`${prefix}/next-match`).then(result => result.data);
  }
}
