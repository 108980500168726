import actionTypes from './actionTypes';
import service from './service';

const getSystemParametersSuccess = data => dispatch =>
  dispatch({
    type: actionTypes.STORE_SYSTEM_PARAMETERS,
    data
  });

export function getSystemParameters() {
  return dispatch => service.get().then(data => dispatch(getSystemParametersSuccess(data)));
}

const getYouthTeamsSuccess = data => dispatch =>
  dispatch({
    type: actionTypes.STORE_YOUTH_TEAMS,
    data
  });

export function getYouthTeams() {
  return dispatch => service.getYouthTeams().then(data => dispatch(getYouthTeamsSuccess(data)));
}
