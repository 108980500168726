import React, { memo } from 'react';

import { withAlternativeSign } from 'utils/utils';

import styles from './MatchDetailsHalfGoals.module.scss';

type Props = {
  title: string;
  hostGoals: number;
  guestGoals: number;
};

const MatchDetailsHalfGoals = ({ title, hostGoals, guestGoals }: Readonly<Props>) => (
  <h3 className={styles.half_goals}>
    {`${title}: ${withAlternativeSign(hostGoals)} - ${withAlternativeSign(guestGoals)}`}
  </h3>
);

export default memo(MatchDetailsHalfGoals);
