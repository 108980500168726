import ArrayUtils from 'utils/array.utils';

import actionTypes from './actionTypes';

const initialState = [];

export default function competitionsReducer(state = initialState, action) {
  let elementToUpdate = undefined;
  let elementToUpdateIndex = undefined;

  if (
    (actionTypes.STORE_COMPETITIONS_INFO ||
      actionTypes.STORE_COMPETITIONS_TABLE_SEASON_FULL ||
      actionTypes.STORE_COMPETITIONS_TABLE_SEASON_AS_HOST ||
      actionTypes.STORE_COMPETITIONS_TABLE_SEASON_AS_GUEST ||
      actionTypes.STORE_COMPETITIONS_TABLE_AUTUMN_ROUND_FULL ||
      actionTypes.STORE_COMPETITIONS_TABLE_AUTUMN_ROUND_AS_HOST ||
      actionTypes.STORE_COMPETITIONS_TABLE_AUTUMN_ROUND_AS_GUEST ||
      actionTypes.STORE_COMPETITIONS_TABLE_SPRING_ROUND_FULL ||
      actionTypes.STORE_COMPETITIONS_TABLE_SPRING_ROUND_AS_HOST ||
      actionTypes.STORE_COMPETITIONS_TABLE_SPRING_ROUND_AS_GUEST ||
      actionTypes.STORE_COMPETITIONS_SCHEDULE) &&
    action?.data?.leagueId &&
    action?.data?.seasonId
  ) {
    const elementToUpdatePredicate = x =>
      x.leagueId === action.data.leagueId && x.seasonId === action.data.seasonId;
    elementToUpdate = state.find(elementToUpdatePredicate);
    elementToUpdateIndex = state.findIndex(elementToUpdatePredicate);
  }

  switch (action.type) {
    case actionTypes.STORE_COMPETITIONS_INFO:
      return ArrayUtils.updateAndReturnNewArray(state, elementToUpdateIndex, {
        ...elementToUpdate,
        ...action.data
      });
    case actionTypes.STORE_COMPETITIONS_TABLE_SEASON_FULL:
      return ArrayUtils.updateAndReturnNewArray(state, elementToUpdateIndex, {
        leagueId: action.data.leagueId,
        seasonId: action.data.seasonId,
        ...elementToUpdate,
        table: {
          ...elementToUpdate?.table,
          season: {
            ...elementToUpdate?.table?.season,
            full: action.data.competitionsTable
          }
        }
      });
    case actionTypes.STORE_COMPETITIONS_TABLE_SEASON_AS_HOST:
      return ArrayUtils.updateAndReturnNewArray(state, elementToUpdateIndex, {
        leagueId: action.data.leagueId,
        seasonId: action.data.seasonId,
        ...elementToUpdate,
        table: {
          ...elementToUpdate?.table,
          season: {
            ...elementToUpdate?.table?.season,
            asHost: action.data.competitionsTable
          }
        }
      });
    case actionTypes.STORE_COMPETITIONS_TABLE_SEASON_AS_GUEST:
      return ArrayUtils.updateAndReturnNewArray(state, elementToUpdateIndex, {
        leagueId: action.data.leagueId,
        seasonId: action.data.seasonId,
        ...elementToUpdate,
        table: {
          ...elementToUpdate?.table,
          season: {
            ...elementToUpdate?.table?.season,
            asGuest: action.data.competitionsTable
          }
        }
      });
    case actionTypes.STORE_COMPETITIONS_TABLE_AUTUMN_ROUND_FULL:
      return ArrayUtils.updateAndReturnNewArray(state, elementToUpdateIndex, {
        leagueId: action.data.leagueId,
        seasonId: action.data.seasonId,
        ...elementToUpdate,
        table: {
          ...elementToUpdate?.table,
          autumnRound: {
            ...elementToUpdate?.table?.autumnRound,
            full: action.data.competitionsTable
          }
        }
      });
    case actionTypes.STORE_COMPETITIONS_TABLE_AUTUMN_ROUND_AS_HOST:
      return ArrayUtils.updateAndReturnNewArray(state, elementToUpdateIndex, {
        leagueId: action.data.leagueId,
        seasonId: action.data.seasonId,
        ...elementToUpdate,
        table: {
          ...elementToUpdate?.table,
          autumnRound: {
            ...elementToUpdate?.table?.autumnRound,
            asHost: action.data.competitionsTable
          }
        }
      });
    case actionTypes.STORE_COMPETITIONS_TABLE_AUTUMN_ROUND_AS_GUEST:
      return ArrayUtils.updateAndReturnNewArray(state, elementToUpdateIndex, {
        leagueId: action.data.leagueId,
        seasonId: action.data.seasonId,
        ...elementToUpdate,
        table: {
          ...elementToUpdate?.table,
          autumnRound: {
            ...elementToUpdate?.table?.autumnRound,
            asGuest: action.data.competitionsTable
          }
        }
      });
    case actionTypes.STORE_COMPETITIONS_TABLE_SPRING_ROUND_FULL:
      return ArrayUtils.updateAndReturnNewArray(state, elementToUpdateIndex, {
        leagueId: action.data.leagueId,
        seasonId: action.data.seasonId,
        ...elementToUpdate,
        table: {
          ...elementToUpdate?.table,
          springRound: {
            ...elementToUpdate?.table?.springRound,
            full: action.data.competitionsTable
          }
        }
      });
    case actionTypes.STORE_COMPETITIONS_TABLE_SPRING_ROUND_AS_HOST:
      return ArrayUtils.updateAndReturnNewArray(state, elementToUpdateIndex, {
        leagueId: action.data.leagueId,
        seasonId: action.data.seasonId,
        ...elementToUpdate,
        table: {
          ...elementToUpdate?.table,
          springRound: {
            ...elementToUpdate?.table?.springRound,
            asHost: action.data.competitionsTable
          }
        }
      });
    case actionTypes.STORE_COMPETITIONS_TABLE_SPRING_ROUND_AS_GUEST:
      return ArrayUtils.updateAndReturnNewArray(state, elementToUpdateIndex, {
        leagueId: action.data.leagueId,
        seasonId: action.data.seasonId,
        ...elementToUpdate,
        table: {
          ...elementToUpdate?.table,
          springRound: {
            ...elementToUpdate?.table?.springRound,
            asGuest: action.data.competitionsTable
          }
        }
      });
    case actionTypes.STORE_COMPETITIONS_SCHEDULE:
      return ArrayUtils.updateAndReturnNewArray(state, elementToUpdateIndex, {
        leagueId: action.data.leagueId,
        seasonId: action.data.seasonId,
        ...elementToUpdate,
        competitionsSchedule: { ...action.data.competitionsSchedule }
      });
    default:
      return state;
  }
}
