import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { personWithIdAndPhotoPropTypesShape } from 'propTypes';
import ChangeEnteringIcon from 'components/Icon/ChangeEnteringIcon';
import ChangeGoingDownIcon from 'components/Icon/ChangeGoingDownIcon';

import styles from './ChangedPlayerEvent.module.scss';
import EventPlayerLink from './EventPlayerLink';
import EventWrapper from './EventWrapper';

const propTypes = {
  teamId: PropTypes.string.isRequired,
  minute: PropTypes.number.isRequired,
  goingDownPlayer: personWithIdAndPhotoPropTypesShape.isRequired,
  enteringPlayer: personWithIdAndPhotoPropTypesShape.isRequired,
  reversed: PropTypes.bool.isRequired,
  isTwoLineVersion: PropTypes.bool
};

const ChangedPlayerEvent = ({
  minute,
  goingDownPlayer,
  enteringPlayer,
  reversed,
  isTwoLineVersion
}) => (
  <EventWrapper color="blue" reversed={reversed} minute={minute}>
    {isTwoLineVersion ? (
      <div className={styles.twoLines}>
        <div className={styles.player}>
          <EventPlayerLink {...goingDownPlayer} className={styles.player_goingDown} />
          <ChangeGoingDownIcon />
        </div>

        <div className={styles.player}>
          <EventPlayerLink {...enteringPlayer} />
          <ChangeEnteringIcon />
        </div>
      </div>
    ) : (
      <>
        <EventPlayerLink {...goingDownPlayer} className={styles.player_goingDown} />
        <ChangeGoingDownIcon />
        <EventPlayerLink {...enteringPlayer} />
        <ChangeEnteringIcon />
      </>
    )}
  </EventWrapper>
);

ChangedPlayerEvent.propTypes = propTypes;

export default memo(ChangedPlayerEvent);
