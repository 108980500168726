import axios from 'config/axios';

const prefix = 'client/team';

export const TeamSrv = {
  async getDetailsForMyTeam() {
    const result = await axios.get(`${prefix}/details`);
    return result.data;
  },

  async getDetails(id) {
    const result = await axios.get(`${prefix}/${id}/details`);
    return result.data;
  },

  async getSeasonsListForMyTeam() {
    const result = await axios.get(`${prefix}/seasons`);
    return result.data;
  },

  async getSeasonsList(id) {
    const result = await axios.get(`${prefix}/${id}/seasons`);
    return result.data;
  },

  async getSeasonsAdvancedListForMyTeam() {
    const result = await axios.get(`${prefix}/seasons/advanced`);
    return result.data;
  },

  async getSeasonsAdvancedList(id) {
    const result = await axios.get(`${prefix}/${id}/seasons/advanced`);
    return result.data;
  },

  async getCoachesForMyTeamInCurrentSeason() {
    const result = await axios.get(`${prefix}/coaches`);
    return result.data;
  },

  async getCoachesInCurrentSeason(id) {
    const result = await axios.get(`${prefix}/${id}/coaches`);
    return result.data;
  },

  // note: not use
  async getCoachesInSeason(id, seasonId) {
    const result = await axios.get(`${prefix}/${id}/${seasonId}/coaches`);
    return result.data;
  },

  async getPlayersWithMatchesAndEventsForMyTeamInCurrentSeason() {
    const result = await axios.get(`${prefix}/playersWithMatchesAndEvents`);
    return result.data;
  },

  async getPlayersWithMatchesAndEventsInCurrentSeason(id) {
    const result = await axios.get(`${prefix}/${id}/playersWithMatchesAndEvents`);
    return result.data;
  },

  // note: not use
  async getPlayersWithMatchesAndEventsInSeason(id, seasonId) {
    const result = await axios.get(`${prefix}/${id}/${seasonId}/playersWithMatchesAndEvents`);
    return result.data;
  },

  async getMatchesForMyTeamInCurrentSeason() {
    const result = await axios.get(`${prefix}/matches`);
    return result.data;
  },

  async getMatchesInCurrentSeason(id) {
    const result = await axios.get(`${prefix}/${id}/matches`);
    return result.data;
  },

  // note: not use
  async getMatchesInSeason(id, seasonId) {
    const result = await axios.get(`${prefix}/${id}/${seasonId}/matches`);
    return result.data;
  },

  // -------------------------------------------- TO TEST -----------------------------------------

  async getPlayersInCurrentSeason(id) {
    const result = await axios.get(`${prefix}/${id}/players`);
    return result.data;
  },

  async getPlayerMatchesAndEventsInCurrentSeason(id, playerId) {
    const result = await axios.get(`${prefix}/${id}/${playerId}/matchesAndEvents`);
    return result.data;
  }
};
