import React from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { useAppDispatch } from 'store';
import { icons } from 'consts/icons';
import copyToClipboard from 'utils/copyToClipboard';
import { displayToastr } from 'components/Toastr/actions';
import ToastTypes from 'components/Toastr/ToastTypes';

import styles from './SingleInformation.module.scss';

type Props = {
  title?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  isCopyEnabled: boolean;
  isBiggerText?: boolean;
};

const SingleInformation = ({ title, value, isCopyEnabled, isBiggerText }: Readonly<Props>) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const titleClassName = cx(styles.information_text, styles.information_title, {
    [styles.information_bigger_text]: isBiggerText
  });
  const valueClassName = cx(styles.information_text, styles.information_value, {
    [styles.information_bigger_text]: isBiggerText,
    [styles['information_value--with-hover']]: isCopyEnabled
  });
  const iconClassName = cx(styles.information_icon, icons.copy);

  const onValueClick = () => {
    copyToClipboard(value);
    dispatch(
      displayToastr(`${t('SuccessfullyCopiedToTheClipboard')}: ${title || value}`, ToastTypes.info)
    );
  };

  return (
    <div className={styles.information}>
      {title && <span className={titleClassName}>{title}:&nbsp;</span>}
      {isCopyEnabled ? (
        <span className={valueClassName} onClick={onValueClick} title={t('CopyInformation')}>
          {value}
          <i className={iconClassName} />
        </span>
      ) : (
        <span className={valueClassName}>{value}</span>
      )}
    </div>
  );
};

export default SingleInformation;
