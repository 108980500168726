import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { competitionsSchedulePropTypes } from 'propTypes';
import Accordion from 'components/Accordion';

import styles from './CompetitionsSchedule.module.scss';
import CompetitionsScheduleFilter from './CompetitionsScheduleFilter';
import CompetitionsScheduleQueues from './CompetitionsScheduleQueues';

// TODO: rafkan - ujednolicić kolejność propTypes w komponentach, kolejność importów itp.

const propTypes = {
  competitionsSchedule: competitionsSchedulePropTypes,
  teamIdContext: PropTypes.string
};

const CompetitionsSchedule = ({ competitionsSchedule, teamIdContext }) => {
  const { t } = useTranslation();

  const springRoundClassName = cx(styles.round, styles.round_spring);

  const [filterValue, setFilterValue] = useState('');

  const getQueueName = (number, roundName) => `${number}. ${t('Queue')} ${roundName.toLowerCase()}`;

  const getFilterableData = () => {
    const rounds =
      competitionsSchedule.rounds &&
      [...competitionsSchedule.rounds].flatMap(round =>
        round.queues.map(queue => getQueueName(queue.number, round.name))
      );

    return rounds || [];
  };

  return (
    <>
      <CompetitionsScheduleFilter
        filterValue={filterValue}
        setFilterValue={setFilterValue}
        filterData={getFilterableData()}
      />

      <Accordion
        className={styles.round}
        title={competitionsSchedule.rounds[0].name}
        withoutPadding
      >
        <div className={styles.round_queues}>
          <CompetitionsScheduleQueues
            queues={competitionsSchedule.rounds[0].queues}
            roundName={competitionsSchedule.rounds[0].name}
            teamIdContext={teamIdContext}
            filterValue={filterValue}
          />
        </div>
      </Accordion>

      <Accordion
        className={springRoundClassName}
        title={competitionsSchedule.rounds[1].name}
        withoutPadding
      >
        <div className={styles.round_queues}>
          <CompetitionsScheduleQueues
            queues={competitionsSchedule.rounds[1].queues}
            roundName={competitionsSchedule.rounds[1].name}
            teamIdContext={teamIdContext}
            filterValue={filterValue}
          />
        </div>
      </Accordion>
    </>
  );
};

CompetitionsSchedule.propTypes = propTypes;

export default memo(CompetitionsSchedule);
