import styled, { css } from 'styled-components';

type Props = {
  isBiggerText?: boolean;
};

export const StyledWrapper = styled.div`
  margin-top: 0.3em;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const StyledTitle = styled.div`
  font-size: ${({ theme }) => theme.smallFontSize};
  color: ${({ theme }) => theme.opaqueDark};
`;

export const StyledValue = styled.div<Props>`
  font-weight: 500;
  font-size: ${({ theme }) => theme.normalFontSize};
  color: ${({ theme }) => theme.dark};

  ${({ isBiggerText }) =>
    isBiggerText &&
    css`
      font-size: ${({ theme }) => theme.bigFontSize};
    `}
`;
