import PropTypes from 'prop-types';

import { personWithPhotoPropTypes } from './person';
import { simpleTeamPropTypesShape } from './simple-team';

export const boardMemberDetailsPropTypes = PropTypes.shape({
  ...personWithPhotoPropTypes,
  stand: PropTypes.string,
  currentClub: simpleTeamPropTypesShape
});
