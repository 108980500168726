import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import personTileStyles from './PersonTile.module.scss';
import styles from './PersonTilePlaceholder.module.scss';

const propTypes = {
  withDescription: PropTypes.bool,
  isHighlighted: PropTypes.bool,
  className: PropTypes.string
};

export const PersonTilePlaceholder = ({ withDescription = true, isHighlighted, className }) => {
  const cardClassName = cx(personTileStyles.card, className, {
    [styles.card_active]: isHighlighted
  });

  const cardImageClassName = cx(personTileStyles.card_image, styles.card_image);
  const cardTextContainer = cx(personTileStyles.card_text_content, styles.card_text_content);

  return (
    <div className={cardClassName}>
      <div className={cardImageClassName} />
      <div className={cardTextContainer}>
        <span className={personTileStyles.card_name} />
        {withDescription && <span className={styles.accent} />}
      </div>
    </div>
  );
};

PersonTilePlaceholder.propTypes = propTypes;

export default memo(PersonTilePlaceholder);
