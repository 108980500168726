import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { icons } from 'consts/icons';

import styles from './AssistIcon.module.scss';

const AssistIcon = () => {
  const { t } = useTranslation();

  const assistIconClassName = cx(icons.lightbulb, styles.icon_green);

  return <i className={assistIconClassName} title={t('PlayerAssist')} />;
};

export default memo(AssistIcon);
