import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './asInputGroup.module.scss';

const propTypes = {
  WrappedComponent: PropTypes.node.isRequired,
  withoutInputAdjustments: PropTypes.bool
};

const asInputGroup =
  (WrappedComponent, withoutInputAdjustments) =>
  // eslint-disable-next-line react/prop-types
  ({ placeholder, required, invalid, meta, icon, ...others }) => {
    // eslint-disable-next-line react/prop-types
    const isInvalid = !meta.dirty || invalid;

    const requiredAsterixClassName = cx(isInvalid ? 'text-error' : 'text-green');
    const inputGroupClassName = cx(styles.input_group, {
      [styles.input_group_invalid]: isInvalid
    });
    const iconClassName = cx(icon, styles.input_icon);
    const wrappedClassName = cx({
      [styles.input_without_icon]: !icon
    });

    return (
      <div className={inputGroupClassName}>
        <WrappedComponent
          {...others}
          required
          invalid={isInvalid}
          invalidClassName={styles.invalid}
          className={wrappedClassName}
        />
        {!withoutInputAdjustments && (
          <>
            <span className={styles.underline} />
            {!!icon && <span className={iconClassName} />}
            <label>
              {placeholder} {required && <span className={requiredAsterixClassName}>*</span>}
            </label>
          </>
        )}
      </div>
    );
  };

asInputGroup.propTypes = propTypes;

export default asInputGroup;
