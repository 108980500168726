import { memo } from 'react';
import styled, { css } from 'styled-components';

import Icon from '../Icon';

const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.accent};
  font-size: 2.5rem;
  transition: all ${({ theme }) => theme.defaultTransition} ease;
  padding: 0 1rem;

  &:hover {
    cursor: pointer;
    transform: scale(0.85) translate3d(0.5rem, 0, 0);
  }

  ${({ rotated }) =>
    rotated &&
    css`
      transform: rotate(180deg);

      &:hover {
        transform: rotate(180deg) scale(0.85) translate3d(0.5rem, 0, 0);
      }
    `}
`;

export default memo(StyledIcon);
