import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Form from 'components/Form';

import styles from './StyledForm.module.scss';
import StyledFormWrapper from './StyledFormWrapper';

const propTypes = {
  children: PropTypes.node.isRequired,
  isVisible: PropTypes.bool,
  className: PropTypes.string
};

const StyledForm = ({ children, className, isVisible, ...others }) => {
  const formClassName = cx(styles.form, className);

  return (
    <StyledFormWrapper isVisible={isVisible}>
      <Form {...others} className={formClassName} isVisible={false}>
        {children}
      </Form>
    </StyledFormWrapper>
  );
};

StyledForm.propTypes = propTypes;

export default memo(StyledForm);
