import React, { memo } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { getArrayWithLength } from 'utils/utils';
import Article from 'modules/home/articles/Article';
import ArticlePlaceholder from 'modules/home/articles/ArticlePlaceholder';

import styles from './ArticlesList.module.scss';

const propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      photo: PropTypes.string,
      title: PropTypes.string,
      content: PropTypes.string,
      createdDate: PropTypes.string,
      author: PropTypes.string
    })
  )
};

const ArticlesList = ({ articles = [] }) => (
  <div className={styles.article_list}>
    {!isEmpty(articles)
      ? articles.map((article, index) => <Article key={index} {...article} vertical />)
      : getArrayWithLength(4).map((x, index) => <ArticlePlaceholder vertical key={index} />)}
  </div>
);

ArticlesList.propTypes = propTypes;

export default memo(ArticlesList);
