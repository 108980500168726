import PropTypes from 'prop-types';

export const tablePropTypes = {
  teams: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      position: PropTypes.number,
      logo: PropTypes.string,
      name: PropTypes.string,
      matchesPlayedNumber: PropTypes.number,
      points: PropTypes.number,
      winsNumber: PropTypes.number,
      drawsNumber: PropTypes.number,
      lostNumber: PropTypes.number,
      goalsScored: PropTypes.number,
      goalsLost: PropTypes.number,
      nextMatch: PropTypes.exact({
        id: PropTypes.string,
        host: PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string
        }),
        guest: PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string
        }),
        date: PropTypes.string
      }),
      lastFiveMatches: PropTypes.arrayOf(
        PropTypes.exact({
          id: PropTypes.string,
          host: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string
          }),
          guest: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string
          }),
          hostGoals: PropTypes.number,
          guestGoals: PropTypes.number,
          date: PropTypes.string,
          status: PropTypes.string
        })
      )
    })
  )
};

export const tablePropTypesShape = PropTypes.shape({ ...tablePropTypes });
