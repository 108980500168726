import React, { memo } from 'react';
import Link from 'components/Links/Link';
import cx from 'classnames';

import urls from 'consts/urls';
import { useIsDesktop } from 'utils/customHooks';
import Column from 'components/Column';
import Row from 'components/Row';

import { IRefereeSeasonModel } from '../data/models';
import AgeGroupsRows from './AgeGroupsRows';
import styles from './LeaguesRows.module.scss';

type Props = {
  season: IRefereeSeasonModel;
};

const LeaguesRows = ({ season }: Readonly<Props>) => {
  const isDesktop = useIsDesktop();

  const leagueRowClassName = cx(styles.league_row, {
    [styles.league_row_mobile]: !isDesktop
  });

  const leagueNameColumnClassName = cx(styles.leagueName_column, {
    [styles.leagueName_column_mobile]: !isDesktop
  });

  const ageGroupsColumnClassName = cx(styles.ageGroups_column, {
    [styles.ageGroups_column_mobile]: !isDesktop
  });

  const leaguesRows = season.leagues?.map(league => (
    <Row key={league.leagueId} className={leagueRowClassName}>
      <Column className={leagueNameColumnClassName} width={isDesktop ? '20%' : '100%'}>
        <Link
          to={urls.competitionsDetails(league.leagueId, season.seasonId)}
          className={styles.link}
        >
          {league.leagueName}
        </Link>
      </Column>

      <Column className={ageGroupsColumnClassName} width={isDesktop ? '80%' : '100%'}>
        <AgeGroupsRows league={league}></AgeGroupsRows>
      </Column>
    </Row>
  ));

  return leaguesRows;
};

export default memo(LeaguesRows);
