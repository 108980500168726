import React, { memo } from 'react';
import { useParams } from 'react-router-dom';

import CoachSeasonStatisticsPage from './CoachSeasonStatisticsPage';
import { useCoachDataForStatisticsPage } from './data';

const CoachSeasonStatisticsPageContainer = () => {
  const { coachId, seasonId } = useParams();

  const {
    details,
    photo,
    currentTeam,
    debutMatch,
    season,

    getDetailsRequestStatus,
    getPhotoRequestStatus,
    getCurrentTeamRequestStatus,
    getDebutMatchRequestStatus,
    getSeasonRequestStatus
  } = useCoachDataForStatisticsPage(coachId!, seasonId!);

  const matchesInSeason = season?.matches;

  return (
    <CoachSeasonStatisticsPage
      details={details}
      photo={photo}
      currentTeam={currentTeam}
      debutMatch={debutMatch}
      season={season}
      matchesInSeason={matchesInSeason}
      getDetailsRequestStatus={getDetailsRequestStatus}
      getPhotoRequestStatus={getPhotoRequestStatus}
      getCurrentTeamRequestStatus={getCurrentTeamRequestStatus}
      getDebutMatchRequestStatus={getDebutMatchRequestStatus}
      getSeasonRequestStatus={getSeasonRequestStatus}
      getMatchesInSeasonRequestStatus={season?.getMatchesRequestStatus}
    />
  );
};

export default memo(CoachSeasonStatisticsPageContainer);
