import React, { memo } from 'react';
import cx from 'classnames';

import matchResultTypes from 'consts/matchResultTypes';
import { useIsDesktop } from 'utils/customHooks';
import Column from 'components/Column';
import MatchStatus from 'components/MatchStatus';
import Row from 'components/Row';
import Skeleton from 'components/Skeleton';

import { ILeagueModel } from '../data/models';
import styles from './AgeGroupsRows.module.scss';
import EventCountColumn from './EventCountColumn';

type Props = {
  league: ILeagueModel;
};

const AgeGroups = ({ league }: Readonly<Props>) => {
  const isDesktop = useIsDesktop();

  const teamRowClassName = cx(styles.team_row, {
    [styles.team_row_mobile]: !isDesktop
  });

  const ageGroupNameColumnClassName = cx(styles.ageGroupName_column, {
    [styles.ageGroupName_column_mobile]: !isDesktop
  });

  const eventCountColumnClassName = cx(styles.events_count_column, {
    [styles.events_count_column_mobile]: !isDesktop
  });

  const ageGroups = league.ageGroups?.map(ageGroup => (
    <Row key={ageGroup.name} className={teamRowClassName}>
      <Column className={ageGroupNameColumnClassName} width={isDesktop ? '25%' : '100%'}>
        {ageGroup.name}
      </Column>

      <Column className={eventCountColumnClassName} width={isDesktop ? '75%' : '100%'}>
        <Row className={styles.statistics_row}>
          {ageGroup.getSeasonStatisticsRequestStatus?.isLoading ? (
            <Skeleton width="20rem" />
          ) : (
            <>
              {/* // TODO: yellow, red cards given, penalty etc. */}
              <EventCountColumn
                icon={<MatchStatus status={matchResultTypes.win} withTooltip />}
                eventCounts={ageGroup.refereeStatistics?.matchesCount}
                bigger
              />
              {/* <EventCountColumn
                icon={<MatchStatus status={matchResultTypes.draw} withTooltip />}
                eventCounts={team.refereeStatistics?.matchesDrawnCount}
                bigger
              />
              <EventCountColumn
                icon={<MatchStatus status={matchResultTypes.loss} withTooltip />}
                eventCounts={team.refereeStatistics?.matchesLostCount}
                bigger
              /> */}
            </>
          )}
        </Row>
      </Column>
    </Row>
  ));

  return ageGroups;
};

export default memo(AgeGroups);
