import { generate4 } from 'utils/guid-generator';

import actionTypes from './actionTypes';

const initialState = {
  notifications: []
};

// TODO: change to notificationsReducer with fields: toasts, dialogs, etc.
export default function toastrReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.TOASTR_DISPLAY:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            id: generate4(),
            message: action.message,
            type: action.toastrType,
            duration: action.duration
          }
        ]
      };
    case actionTypes.TOASTR_CLOSE:
      return {
        ...state,
        notifications: [...state.notifications.filter(x => x.id !== action.id)]
      };
    default:
      return state;
  }
}
