import { memo } from 'react';
import { EFileIds } from 'consts/fileIds';
import urls from 'consts/urls';

import { useGetFileQuery } from './data';
import DocumentPage from './DocumentPage';

const filePageUrl = {
  [EFileIds.PolicyOnTheProtectionOfChildrenAndMinorsId]: {
    url: urls.policyOnTheProtectionOfChildrenAndMinors
  },
  [EFileIds.StatementByParentAndChildAboutPolicyOnTheProtectionOfChildrenAndMinorsId]: {
    url: urls.statementByParentAndChildAboutPolicyOnTheProtectionOfChildrenAndMinors
  },
  [EFileIds.AcademyMembershipDeclarationId]: {
    url: urls.academyMembershipDeclaration
  },
  [EFileIds.AcademyRegulationsId]: {
    url: urls.academyRegulations
  },
  [EFileIds.StadiumRegulationsId]: {
    url: urls.stadiumRegulations
  },
  [EFileIds.StatuteId]: {
    url: urls.statute
  }
};

type Props = {
  id: EFileIds;
};

const DocumentPageContainer = ({ id }: Readonly<Props>) => {
  const { url } = filePageUrl[id];
  const { file, requestStatus } = useGetFileQuery(id);

  return <DocumentPage url={url} file={file} requestStatus={requestStatus} />;
};

export default memo(DocumentPageContainer);
