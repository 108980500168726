import actionTypes from './actionTypes';

const initialState = [];

export default function articlesPageReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.STORE_ARTICLES:
      return [...action.data];
    default:
      return state;
  }
}
