import React, { memo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';

import ArticleGalleryRow from './ArticleGalleryRow';

type Props = {
  photos: string[];
};

const ArticleGallery = ({ photos }: Readonly<Props>) => {
  const { t } = useTranslation();
  return (
    !isEmpty(photos) && (
      <>
        <h3>{t('Gallery')}:</h3>
        <ArticleGalleryRow photos={photos} />
      </>
    )
  );
};

export default memo(ArticleGallery);
