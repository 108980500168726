import React, { memo } from 'react';

import { useAppSelector } from 'store';
import { useDispatchOnMountWithLocationMultiple } from 'utils/customHooks';

import {
  getCurrentCompetitionsInfo,
  getCurrentCompetitionsSchedule,
  getLeagueNameForMyLeague,
  getSeasonNameForCurrentSeason
} from '../actions';
import CompetitionsSchedulePage from './CompetitionsSchedulePage';

const CurrentCompetitionsSchedulePageContainer = props => {
  const leagueName = useAppSelector(state => state.currentCompetitions?.leagueName);
  const seasonName = useAppSelector(state => state.currentCompetitions?.seasonName);

  const competitionsSchedule = useAppSelector(
    state => state.currentCompetitions?.competitionsSchedule
  );

  useDispatchOnMountWithLocationMultiple([
    getLeagueNameForMyLeague(),
    getSeasonNameForCurrentSeason(),
    getCurrentCompetitionsInfo(), // TODO: check and implement on backend
    getCurrentCompetitionsSchedule()
  ]);

  return (
    <CompetitionsSchedulePage
      {...props}
      leagueName={leagueName}
      seasonName={seasonName}
      competitionsSchedule={competitionsSchedule}
    />
  );
};

export default memo(CurrentCompetitionsSchedulePageContainer);
