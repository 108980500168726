const playerPositionsIds = {
  goalkeeperId: '3b829354-65d6-4c9f-9b93-bf6b1020ffb3',
  defenderId: 'c9889591-d02f-4690-abdd-0520e1c2e8a9',
  midfielderId: '01ff3322-8e19-4cac-87e5-acfc84197edf',
  strikerId: '18be7864-27e3-4a89-a0c5-f70eb123fddd'
};

export const playerPositionNamesEn = {
  goalkeeper: 'goalkeeper',
  defender: 'defender',
  midfielder: 'midfielder',
  striker: 'striker',
  unknown: 'unknown'
};

export const playerPositionNamesPl = {
  goalkeeper: 'Bramkarz',
  defender: 'Obrońca',
  midfielder: 'Pomocnik',
  striker: 'Napastnik',
  unknown: 'Nieznana pozycja'
};

export const playerPositionsTranslationKeysEn = {
  goalkeeper: 'Goalkeeper',
  defender: 'Defender',
  midfielder: 'Midfielder',
  striker: 'Striker',
  unknown: 'UnknownPosition'
};

export default playerPositionsIds;
