import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Card from './Card';

const propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

// TODO: Vorbert -> połączyć komponent <Card /> oraz <Column />, możliwe że wykorzystując styled-components
const ColumnCard = ({ children, className, ...others }) => {
  const cardClassName = cx('column', className);

  return (
    <Card {...others} className={cardClassName}>
      {children}
    </Card>
  );
};

ColumnCard.propTypes = propTypes;

export default memo(ColumnCard);
