import get from 'lodash/get';
import isString from 'lodash/isString';
import set from 'lodash/set';
import { t } from 'i18next';

import { isNullOrUndefined } from 'utils/utils';

export function isRequired(errors, values, fieldName, validationMessage) {
  const valueToCheck = get(values, fieldName);

  if (isNullOrUndefined(valueToCheck)) {
    set(errors, fieldName, validationMessage);
    return;
  }

  if (typeof valueToCheck === 'string' && valueToCheck.trim() === '') {
    set(errors, fieldName, validationMessage);
  }
}

export function isRequiredField(errorMessage) {
  return value => {
    if (isNullOrUndefined(value) || (Array.isArray(value) && !value.length)) {
      return errorMessage;
    }
    return undefined;
  };
}

export const isRequiredFieldValidator = isRequiredField(t('FieldRequired'));

export function isRequiredArray(errors, values, fieldNamesArray, validationMessage) {
  for (const x of fieldNamesArray) {
    isRequired(errors, values, x, validationMessage);
  }
}

export function isTrue(errors, values, fieldName, validationMessage) {
  const valueToCheck = values[fieldName];
  if (valueToCheck === undefined || valueToCheck === null) {
    return;
  }

  if (valueToCheck !== true) {
    errors[fieldName] = validationMessage;
  }
}

export function isEmail(errors, values, fieldName, validationMessage) {
  const valueToCheck = values[fieldName];
  if (!valueToCheck) {
    return;
  }

  const pattern =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!pattern.test(valueToCheck)) {
    errors[fieldName] = validationMessage;
  }
}

export function isEmailField(errorMessage) {
  return value => {
    const pattern =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (value && !pattern.test(value)) {
      return errorMessage;
    }
    return undefined;
  };
}

export const isEmailFieldValidator = isEmailField(t('FieldMustBeValidEmail'));

function isValidNip(value) {
  const nipWithoutDashes = value.trim().replace(/-/g, '');
  const reg = /^[0-9]{10}$/;

  if (!reg.test(nipWithoutDashes)) {
    return false;
  }

  if (nipWithoutDashes === '0000000000') {
    return false;
  }

  const digits = nipWithoutDashes.split('').map(s => parseInt(s, 10));

  const control =
    (6 * digits[0] +
      5 * digits[1] +
      7 * digits[2] +
      2 * digits[3] +
      3 * digits[4] +
      4 * digits[5] +
      5 * digits[6] +
      6 * digits[7] +
      7 * digits[8]) %
    11;

  return digits[9] === control;
}

export function isNip(errors, values, fieldName, validationMessage) {
  const valueToCheck = values[fieldName];
  if (!valueToCheck) {
    return;
  }

  if (!isValidNip(valueToCheck)) {
    errors[fieldName] = validationMessage;
  }
}

export function isNipField(errorMessage) {
  return value => {
    if (!value) {
      return undefined;
    }

    if (!isValidNip(value)) {
      return errorMessage;
    }
    return undefined;
  };
}

export const isNipFieldValidator = isNipField(t('InvalidNip'));

function isValidRegon(value) {
  // REGON is a 9 or 14 digit number. Last digit is control digit from equation:
  // [ sum from 1 to (9 or 14) (x[i]*w[i]) ] mod 11; where x[i] is pointed NIP digit and w[i] is pointed digit
  // from [8 9 2 3 4 5 6 7] for 9 and [2 4 8 5 0 9 7 3 6 1 2 4 8] for 14 digits.

  const n = value.length;
  let cd = 0; // Control digit (last digit)
  const isOnlyDigit = /^\d+$/.test(value);

  if (n !== 9 && n !== 14 && !isOnlyDigit) {
    return false; // error
  }

  const w = n === 9 ? [8, 9, 2, 3, 4, 5, 6, 7] : [2, 4, 8, 5, 0, 9, 7, 3, 6, 1, 2, 4, 8];

  for (let index = 0; index < n - 1; index++) {
    cd += w[index] * parseInt(value.charAt(index));
  }

  cd %= 11;

  if (cd === 10) {
    cd = 0;
  }

  if (cd !== parseInt(value.charAt(n - 1))) {
    return false; // not valid
  }
  return true; // valid
}

export function isRegon(errors, values, fieldName, validationMessage) {
  const valueToCheck = values[fieldName];
  if (!valueToCheck) {
    return;
  }

  if (!isValidRegon(valueToCheck)) {
    errors[fieldName] = validationMessage;
  }
}

export function isRegonField(errorMessage) {
  return value => {
    if (!value) {
      return undefined;
    }

    if (!isValidRegon(value)) {
      return errorMessage;
    }
    return undefined;
  };
}

export const isRegonFieldValidator = isRegonField(t('InvalidRegon'));

export function maskedInputField(errorMessage) {
  return value => {
    if (!value || (isString(value) && !value.trim())) {
      return undefined;
    }

    if (value[value.length - 1] === ' ') {
      return errorMessage;
    }

    return undefined;
  };
}

export const maskedInputFieldValidator = maskedInputField(t('FieldValueIncorrect'));

// note: użycie:

/* <MyInputEmailField
  label={t('Email')}
  placeholder={t('Email')}
  name={fieldNames.simpleAddUserForm.email}
  validate={[isRequiredFieldValidator, isEmailFieldValidator]}
/>

const validate = values => {
  const errors = {};

  isRequired(
    errors,
    values,
    fnames.loadingAddress,
    t('FieldRequired(),
  );

  isRequiredArray(
    errors,
    values,
    [
      fieldNames.userInformationForm.login,
      fieldNames.userInformationForm.firstName,
      fieldNames.userInformationForm.lastName,
      fieldNames.userInformationForm.email,
    ],
    t('FieldRequired(),
  );

  isEmail(
    errors,
    values,
    fieldNames.userInformationForm.email,
    t('FieldMustBeValidEmail(),
  );

  return errors;
};
*/

export function areEquals(errors, values, fieldNamesArray, validationMessage) {
  const firstValue = values[fieldNamesArray[0]];

  for (let index = 0; index < fieldNamesArray.length; index++) {
    const fieldName = fieldNamesArray[index];
    if (values[fieldName] !== firstValue) {
      for (const errorName of fieldNamesArray) {
        errors[errorName] = validationMessage;
      }
      return;
    }
  }
}

export function areNotEquals(errors, values, fieldNamesArray, validationMessage) {
  const firstValue = values[fieldNamesArray[0]];

  for (let index = 1; index < fieldNamesArray.length; index++) {
    const fieldName = fieldNamesArray[index];
    if (values[fieldName] === firstValue) {
      for (const errorName of fieldNamesArray) {
        errors[errorName] = validationMessage;
      }
      return;
    }
  }
}

export function isValidPassword(errors, values, fieldName, validationMessage) {
  const valueToCheck = values[fieldName];

  if (
    !/[a-z]/.test(valueToCheck) ||
    !/[A-Z]/.test(valueToCheck) ||
    !/\d/.test(valueToCheck) ||
    !/[^a-zA-Z\d\s:]/.test(valueToCheck)
  ) {
    errors[fieldName] = validationMessage;
  }
}

export function isValidPasswordArray(errors, values, fieldNamesArray, validationMessage) {
  for (const x of fieldNamesArray) {
    isValidPassword(errors, values, x, validationMessage);
  }
}

/**
 * Validates `path` in `values` according to `validators`
 * and updates `errors` object which should be returned
 * from redux-form submit level validation function.
 * @param {*} values all form values
 * @param {*} errors errors object, can be empty initially.
 * @param {string} path to a field in values
 * @param {Array<function>} validators
 */
export function setValidation(values, errors, path, validators) {
  if (validators.length === 1) {
    const result = validators[0](get(values, path), values);
    if (result) set(errors, path, result);
  } else {
    const result = [];
    for (const validator of validators) {
      const resultString = validator(get(values, path), values);
      if (resultString) result.push(resultString);
    }
    if (result.length) set(errors, path, result);
  }
}

/**
 * Validates `pathInArray` for every item in `pathToArray` in values according to `validators`
 * and updates `errors` object which should be returned
 * from redux-form submit level validation function.
 * @param {*} values
 * @param {*} errors errors object, can be empty initially.
 * @param {string} path to a field in values
 * @param {Array<function>} validators
 */
export function setValidationInArray(
  values,
  errors,
  pathToArray,
  pathInArray,
  validators,
  condition
) {
  const elements = get(values, pathToArray);
  for (const index in elements) {
    if (!condition || condition(elements[index]))
      setValidation(values, errors, `${pathToArray}[${index}].${pathInArray}`, validators);
  }
}

/*
    Each function here should accept 3 parameters:
    - value - value to validate
    - formValues - other form values, it's an object with the following shape
    {
        fieldName: fieldValue
    }
    - formProps - props of the entire form

    Important quirk about messages.
    They have to be available in language service IN THE APP WHICH USES THE LIB.
    The keys of the lang should be PascalCased versions of method names here.
*/

export function required(value /* , formValues, formProps */) {
  if (isNullOrUndefined(value) || value.length === 0) {
    return t('PropertyValueRequired');
  }

  if (typeof value === 'string' && !value.trim()) {
    return t('PropertyValueRequired');
  }

  return undefined;
}

export function min(limit) {
  return function (value) {
    if (value !== undefined && value < limit) {
      return t('MinNumberValidation').replace("'{0}'", limit - 1);
    }

    return undefined;
  };
}

export function max(limit) {
  return function (value) {
    if (value !== undefined && value > limit) {
      return t('MaxNumberValidation').replace("'{0}'", limit + 1);
    }

    return undefined;
  };
}

export function validDecimalNumber(value) {
  if (value && value !== 0 && !parseFloat(value)) {
    return t('DecimalNumberValidation');
  }

  return undefined;
}

export function validStringIntegerNumber(value) {
  if (value && typeof value === 'string' && (value.includes('.') || value.includes(','))) {
    return t('IntegerNumberValidation');
  }

  return undefined;
}

export function email(value) {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!re.test(String(value).toLowerCase())) {
    return t('EmailValidation');
  }

  return undefined;
}

export const validateRange = (from, to) => value => {
  if (value >= from && value <= to) {
    return undefined;
  }
  return t('ValueIsOutOfRange');
};

export const peselValidation = value => {
  if (value) {
    const reg = /^[0-9]{11}$/;
    if (reg.test(value) === false) return t('IncorrectPesel');

    const digits = `${value}`.split('');
    if (parseInt(value.slice(4, 6)) > 31 || parseInt(value.slice(2, 4)) > 12) return false;

    let checksum =
      (1 * parseInt(digits[0]) +
        3 * parseInt(digits[1]) +
        7 * parseInt(digits[2]) +
        9 * parseInt(digits[3]) +
        1 * parseInt(digits[4]) +
        3 * parseInt(digits[5]) +
        7 * parseInt(digits[6]) +
        9 * parseInt(digits[7]) +
        1 * parseInt(digits[8]) +
        3 * parseInt(digits[9])) %
      10;
    if (checksum === 0) checksum = 10;
    checksum = 10 - checksum;

    if (parseInt(digits[10]) !== checksum) return t('IncorrectPesel');
  }
  return undefined;
};

export const nipValidation = value => {
  if (value) {
    const parsedValue = value.replace(/-/g, '');
    const reg = /^[0-9]{10}$/;
    if (reg.test(parsedValue) === false) {
      return t('IncorrectNip');
    }

    const digits = `${parsedValue}`.split('');

    const checksum =
      (6 * parseInt(digits[0]) +
        5 * parseInt(digits[1]) +
        7 * parseInt(digits[2]) +
        2 * parseInt(digits[3]) +
        3 * parseInt(digits[4]) +
        4 * parseInt(digits[5]) +
        5 * parseInt(digits[6]) +
        6 * parseInt(digits[7]) +
        7 * parseInt(digits[8])) %
      11;

    if (parseInt(digits[9]) !== checksum) {
      return t('IncorrectNip');
    }
  }
  return undefined;
};

export function minLength(limit) {
  return function (value) {
    if (value && value.length < limit) return t('TypeMinCharacters').replace('{0}', limit);
  };
}

export function maxLength(limit) {
  return function (value) {
    if (value && value.length > limit) return t('TypeMaxCharacters').replace('{0}', limit);
  };
}

export function length(limit) {
  return function (value) {
    if (value && value.length !== limit) {
      return t('TypeCharacters').replace('{0}', limit);
    }
  };
}

export const urlValidation = value => {
  const reg = /^(ftp|http|https):\/\/[^ "]+$/;
  if (value && reg.test(value) === false) {
    return t('IncorrectUrl');
  }

  return undefined;
};

export const decimalPrecision = value => {
  if (value && value[0] === '.') {
    return t('IncorrentBid');
  }

  if (value && value[value.length - 1] === '.') {
    return undefined;
  }

  const regex = /^(?!0+$)\d+(\.\d{1,2})?$/;

  if (value && regex.test(value) === false) {
    return t('IncorrentBid');
  }
};

export const decimalPrecisionWithZero = value => {
  if (value && value[0] === '.') {
    return t('IncorrentBid');
  }

  if (value && value[value.length - 1] === '.') {
    return undefined;
  }

  // cannot start from 0 if length is greater than 1
  // and there is no dot after first 0
  if (value && value.length > 1 && value[0] === '0' && value[1] !== '.') {
    return t('IncorrentBid');
  }

  // allows 0 be first if length is equal 1
  const oneNumber = /^(\d*\.)?\d+$/;
  if (value && value.length === 1 && oneNumber.test(value) === false) {
    return t('IncorrentBid');
  }

  // checks if length is greater than 1,
  // decimal value can have precision max equal 2
  const regex = /^(?!0+$)\d+(\.\d{1,2})?$/;
  if (value && value.length > 1 && regex.test(value) === false) {
    return t('IncorrentBid');
  }
};

export function valueGreaterThanZero(value) {
  if (!isNaN(value) && !(value > 0)) {
    return t('ValueGreaterThanZero');
  }
}
