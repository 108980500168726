import PropTypes from 'prop-types';

import { matchPropTypesShape } from './match';
import { playerPropTypes } from './player';
import { simpleTeamPropTypesShape } from './simple-team';

export const playerDetailsPropTypes = {
  ...playerPropTypes,
  photo: PropTypes.string,
  currentTeam: simpleTeamPropTypesShape,
  debutMatch: matchPropTypesShape
};

export const playerDetailsPropTypesShape = PropTypes.shape({ ...playerDetailsPropTypes });
