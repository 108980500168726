import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { icons } from 'consts/icons';
import urls from 'consts/urls';
import { isMyTeam } from 'utils/utils';

import Link from './Link';
import styles from './TeamLink.module.scss';

const propTypes = {
  id: PropTypes.string.isRequired,
  logo: PropTypes.string,
  name: PropTypes.string.isRequired,
  ageGroupName: PropTypes.string,
  className: PropTypes.string,
  reverse: PropTypes.bool,
  withEmphasisMyTeam: PropTypes.bool,
  withAgeGroupName: PropTypes.bool
};

const TeamLink = ({
  id,
  logo,
  name,
  ageGroupName,
  className,
  reverse,
  withEmphasisMyTeam = true,
  withAgeGroupName
}) => {
  const { t } = useTranslation();

  const linkClassName = cx(styles.team_link, className, {
    [styles.team_link_reverse]: reverse
  });

  const logoAndNameContainerClassName = cx(styles.team_link, className, {
    [styles.team_link_reverse]: reverse,
    [styles.team_emphasized]: isMyTeam(id) && withEmphasisMyTeam
  });

  const teamNameClassName = cx(styles.team_name, {
    [styles.team_name_emphasized]: isMyTeam(id) && withEmphasisMyTeam
  });

  const ageGroupNameClassName = cx({
    [styles.team_ageGroupName]: withAgeGroupName
  });

  const noLogoIconClassName = cx(icons.image, styles.team_noLogoIcon, {
    [styles.team_noLogoIcon_notReverse]: !reverse,
    [styles.team_noLogoIcon_reverse]: reverse
  });

  return (
    <Link
      to={urls.teamDetails(id)}
      className={linkClassName}
      title={`${t('GoToTheClubSubpage')} ${name}`}
    >
      <div className={logoAndNameContainerClassName}>
        {logo ? (
          <img src={logo} alt={t('ClubLogo')} className={styles.team_logo} />
        ) : (
          <i className={noLogoIconClassName} />
        )}
        <span className={teamNameClassName}>{name}</span>
        {withAgeGroupName && ageGroupName && (
          <span className={ageGroupNameClassName}>{ageGroupName}</span>
        )}
      </div>
    </Link>
  );
};

TeamLink.propTypes = propTypes;

export default memo(TeamLink);
