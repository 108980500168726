import React, { memo } from 'react';

import styles from './Dropdown.module.scss';

export type DropdownOption = {
  label: string;
  value: string;
};

type Props = {
  id: string;
  title?: string;
  value: string | number;
  selectedItemIndex: number;
  options: DropdownOption[];
  onChange: () => void;
};

const Dropdown = ({ id, title, value, selectedItemIndex, options, onChange }: Readonly<Props>) => {
  const dropdownOptions = options.map((item, index) => (
    <option
      key={item.value}
      value={index}
      selected={selectedItemIndex === index}
      className={styles.option}
    >
      {item.label}
    </option>
  ));

  return (
    <div>
      <select id={id} title={title} value={value} onChange={onChange} className={styles.select}>
        {dropdownOptions}
      </select>
    </div>
  );
};

export default memo(Dropdown);
