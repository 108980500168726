import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { withAlternativeSign } from 'utils/utils';
import Row from 'components/Row';

import styles from './EventWrapper.module.scss';

const propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf(['green', 'yellow', 'red', 'blue', 'orange']),
  minute: PropTypes.number.isRequired,
  reversed: PropTypes.bool,
  className: PropTypes.string
};

const EventWrapper = ({ children, color = 'green', minute, reversed, className }) => {
  const { t } = useTranslation();

  const eventWrapperClassName = cx(
    styles.event_wrapper,
    styles[`event_wrapper_${color}`],
    className,
    {
      [styles.event_wrapper_reversed]: reversed
    }
  );

  const minutesClassName = cx(styles.event_minute, {
    [styles.event_minute_reversed]: reversed
  });

  const minutes = (
    <div className={minutesClassName} title={`${minute} ${t('MinuteOfTheMatch').toLowerCase()}`}>
      {`${withAlternativeSign(minute)}'`}
    </div>
  );

  return (
    <Row className={eventWrapperClassName}>
      {!reversed ? (
        <>
          {minutes}
          {children}
        </>
      ) : (
        <>
          {children}
          {minutes}
        </>
      )}
    </Row>
  );
};

EventWrapper.propTypes = propTypes;

export default memo(EventWrapper);
