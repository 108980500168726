import i18n from 'i18n';
import dayjs from 'dayjs';
import duration, { Duration } from 'dayjs/plugin/duration';

import { formatNumberLength } from './format';

dayjs.extend(duration);

// TODO: ten plik ma raczej złą nazwę, trzeba wydzielić z niego plik dateUtils/helpers czy coś w tym stylu, żeby były dwa pliki: jeden na formaty, drugi na utilsy/helpery
// TODO: uporządkować metody formatujące daty/czas
// TODO: parameters and return types
// TODO: obsłużyć wszędzie null gdzie hest date?: Date

export class DateFormat {
  static date = 'YYYY-MM-DD';
  static time = 'HH:mm';
  static dateAndTime = `${DateFormat.date} ${DateFormat.time}`;
}

export const toDateTimeFormat = (date?: Date): string =>
  (date && dayjs(date).format(DateFormat.dateAndTime)) || '';

export const toDateFormat = (date?: Date): string =>
  (date && dayjs(date).format(DateFormat.date)) || '';

export const toTimeFormat = (date?: Date): string =>
  (date && dayjs(date).format(DateFormat.time)) || '';

export const toYearFormat = (date?: Date): string => (date && dayjs(date).format('YYYY')) || '';

export const getWeekDay = (date?: Date): number | undefined => date && dayjs(date).day();

export const getDifferenceTime = (firstDate?: Date, secondDate?: Date): number =>
  dayjs(secondDate).diff(firstDate);

export const getDifferenceDurationTime = (firstDate?: Date, secondDate?: Date): Duration =>
  dayjs.duration(getDifferenceTime(firstDate, secondDate));

export const getWeekDayName = (date?: Date): string => {
  const currentWeekDay = getWeekDay(date);

  if (typeof currentWeekDay !== 'number') {
    return '';
  }

  switch (currentWeekDay) {
    case 1:
      return i18n.t('Monday');
    case 2:
      return i18n.t('Tuesday');
    case 3:
      return i18n.t('Wednesday');
    case 4:
      return i18n.t('Thursday');
    case 5:
      return i18n.t('Friday');
    case 6:
      return i18n.t('Saturday');
    default:
      return i18n.t('Sunday');
  }
};

export const defaultDateFormat = 'YYYY-MM-DD';
export const defaultDateTimeFormat = 'YYYY-MM-DD HH:mm';
export const timeFormat = 'HH:mm';

export function minutesToHours(minutes: number): string {
  return minutes ? `${Math.floor(minutes / 60)}:${formatNumberLength(minutes % 60, 2)}` : '0:00';
}
