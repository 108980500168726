import React, { memo } from 'react';

import InnerContentWrapper from 'components/InnerContentWrapper';
import WorkingOnWebsiteText from 'components/WorkingOnWebsiteText';

const StatisticsPage = () => (
  <InnerContentWrapper>
    <WorkingOnWebsiteText />
  </InnerContentWrapper>
);

export default memo(StatisticsPage);
