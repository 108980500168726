import { connect } from 'react-redux';

import { changePasswordFormAsync } from '../actions';
import UserPasswordChangeForm from './UserPasswordChangeForm';

const mapDispatchToProps = dispatch => ({
  onSubmit: model =>
    dispatch(
      changePasswordFormAsync({
        currentPassword: model.currentPassword,
        newPassword: model.newPassword
      })
    )
});

const UserPasswordChangeFormContainer = connect(
  undefined,
  mapDispatchToProps
)(UserPasswordChangeForm);

export default UserPasswordChangeFormContainer;
