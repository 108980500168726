import dayjs from 'dayjs';

import { NumberUtils } from './number.utils';

export default class DateUtils {
  static readonly febMonth = 1;
  static readonly extraOneDay = 1;
  static readonly extraTwoDays = 2;
  static readonly leapYear = 29;
  static readonly daysInAMonth = 30;
  static readonly zuluTimeDayStart = 'T00:00:00Z';
  static readonly zuluTimeDayEnd = 'T23:59:59Z';

  // TODO:
  static translateNumeralDayName(nr: number): string {
    const cases = [2, 0, 1, 1, 1, 2];
    // TODO: for different numeric endings in RU language => titles = ['день', 'дня', 'дней'];
    const titles = ['services.working_day1', 'services.working_day2', 'services.working_days'];
    return titles[nr % 100 > 4 && nr % 100 < 20 ? 2 : cases[nr % 10 < 5 ? nr % 10 : 5]];
  }

  static monthBetweenDates(fromDate: Date, toDate: Date): number {
    const { totalMonths } = DateUtils.getTotalMonthsAndDiffs(fromDate, toDate);

    return totalMonths;
  }

  // TODO: check, compare and test ageCalculation methods and choose the best one and remove others?
  static ageCalculation(fromDate: Date, toDate: Date, type: string): number {
    const { dayDiff, monthDiff, yearDiff, totalMonths } = DateUtils.getTotalMonthsAndDiffs(
      fromDate,
      toDate
    );

    if (type === 'years') {
      return parseFloat(yearDiff + '.' + totalMonths);
    }

    return yearDiff * 12 + monthDiff + (dayDiff > 0 ? 1 : 0);
  }

  static ageCalculation2(fromDate: Date, toDate: Date, type: string): number {
    const { monthDiff, yearDiff, totalMonths, daysIntoMonths } = DateUtils.getTotalMonthsAndDiffs(
      fromDate,
      toDate
    );

    let age: number;

    if (type === 'years') {
      age = parseFloat(yearDiff + '.' + totalMonths);
    } else {
      age = yearDiff * 12 + monthDiff + daysIntoMonths;
    }

    return age;
  }

  static ageCalculation3(birthDate: string | Date): number {
    return dayjs().diff(birthDate, 'years');
  }

  static ageCalculation4(birthDate: string | Date): number {
    let birthday = new Date(birthDate);

    // TODO: check if date is valid (if type cast was success);
    if (isNaN(birthday.getTime())) {
      birthday = new Date(dayjs(birthDate /* TODO: , dateFormat.toUpperCase() */).toDate());
    }

    const diff = Date.now() - birthday.getTime();
    const ageDate = new Date(diff);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  static ageCalculation5(birthDate: string | Date): string {
    const birthDateDayjs = dayjs(birthDate);
    const currentDate = dayjs(new Date());
    const ageDays = currentDate.diff(birthDateDayjs, 'days');
    const ageMonths = currentDate.diff(birthDateDayjs, 'months');
    const ageYears = currentDate.diff(birthDateDayjs, 'years');

    if (ageYears >= 1) {
      return `${ageYears}y`;
    } else if (ageDays >= 30 && ageMonths >= 1 && ageMonths <= 12) {
      return `${ageMonths}m`;
    } else if (ageDays >= 1 && ageDays <= 30) {
      return `${ageDays}d`;
    }

    return '';
  }

  static getAgeInMonths(dateOfBirth: Date, toDate: Date): number {
    let ageInMonths = 0;
    let days = 0;
    dateOfBirth.setHours(0, 0, 0, 0);
    toDate.setHours(0, 0, 0, 0);

    if (!dateOfBirth) {
      return ageInMonths;
    }

    let dob = dateOfBirth;
    let dateWithAddedMonths = new Date(dob.setMonth(dob.getMonth() + 1));

    while (dateWithAddedMonths <= toDate) {
      dob = window.structuredClone(dateWithAddedMonths);
      dateWithAddedMonths = new Date(dob.setMonth(dob.getMonth() + 1));

      ageInMonths++;
    }

    dob = new Date(dob.setMonth(dob.getMonth() - 1));

    let dateWithAddedDays = new Date(dob.setDate(dob.getDate() + 1));

    while (dateWithAddedDays <= toDate) {
      dob = dateWithAddedDays;
      dateWithAddedDays = new Date(dob.setDate(dob.getDate() + 1));
      days++;
    }

    ageInMonths += NumberUtils.roundTwoDecimals(days / 30);

    return ageInMonths;
  }

  // TODO: or name: calculateAgeInYears
  private static getTotalMonthsAndDiffs(
    fromDate: Date,
    toDate: Date
  ): {
    dayDiff: number;
    monthDiff: number;
    yearDiff: number;
    totalMonths: number;
    daysIntoMonths: number;
  } {
    const startYear = fromDate.getFullYear();
    const february =
      (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0 ? 29 : 28;
    const daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    let yearDiff = toDate.getFullYear() - startYear;
    let monthDiff = toDate.getMonth() - fromDate.getMonth();
    let dayDiff = toDate.getDate() - fromDate.getDate();

    if (monthDiff < 0) {
      yearDiff--;
      monthDiff += 12;
    }

    if (dayDiff < 0) {
      if (monthDiff > 0) {
        monthDiff--;
      } else {
        yearDiff--;
        monthDiff = 11;
      }

      dayDiff += daysInMonth[fromDate.getMonth()];

      /**
       * We're converting days into months. As far as february is concerned, we will add 1 day if it is a leap year.
       */
      if (fromDate?.getMonth() === DateUtils.febMonth) {
        dayDiff =
          february === DateUtils.leapYear
            ? dayDiff + DateUtils.extraOneDay
            : dayDiff + DateUtils.extraTwoDays;
      }
    }

    const totalMonths = monthDiff > 0 ? monthDiff : dayDiff > 0 ? 1 : 0;
    const daysIntoMonths = NumberUtils.roundTwoDecimals(dayDiff / DateUtils.daysInAMonth);

    return { yearDiff, monthDiff, dayDiff, totalMonths, daysIntoMonths };
  }

  static checkIsDateValid(date: Date, format: string): boolean {
    return dayjs(date, format, true).isValid();
  }

  static areDatesEqual(firstDateRaw: Date | string, secondDateRaw: Date | string): boolean {
    const firstDate = firstDateRaw instanceof Date ? firstDateRaw : new Date(firstDateRaw);
    const secondDate = secondDateRaw instanceof Date ? secondDateRaw : new Date(secondDateRaw);

    // remove time
    firstDate.setHours(0, 0, 0, 0);
    secondDate.setHours(0, 0, 0, 0);

    const firstTime = firstDate.getTime();
    const secondTime = secondDate.getTime();

    return firstTime === secondTime;
  }

  static getDateWithoutTimeLocale(date: Date, locale?: string): string {
    // locale = DateUtils.checkAndGetLocale(locale);

    const dateLocale = date.toLocaleDateString(locale);

    return `${dateLocale}`;
  }

  static getDateWithTimeLocale(date: Date, locale?: string): string {
    // locale = DateUtils.checkAndGetLocale(locale);

    const dateLocale = date.toLocaleDateString(locale);
    const timeLocale = date.toLocaleTimeString(locale, { timeStyle: 'short' });

    return `${dateLocale} ${timeLocale}`;
  }

  static getDateWithoutTime(date: Date): string {
    const year = date.getFullYear();
    const month = DateUtils.getMonth(date);
    const day = DateUtils.getDay(date);

    const newValue = `${year}-${month}-${day}`;

    return newValue;
  }

  static getDateWithTime(date: Date): string {
    const year = date.getFullYear();
    const month = DateUtils.getMonth(date);
    const day = DateUtils.getDay(date);
    const hours = date.getHours();
    const minutes = date.getMinutes();

    const newValue = `${year}-${month}-${day} ${hours}:${minutes}`;

    return newValue;
  }

  static getDateAndTime(date: Date): string {
    const year = date.getFullYear();
    const month = DateUtils.getMonth(date);
    const day = DateUtils.getDay(date);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    const newValue = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return newValue;
  }

  private static getMonth(date: Date): string {
    const month = date.getMonth() + 1;

    let monthString = month.toString();

    if (month < 10) {
      monthString = `0${month}`;
    }

    return monthString;
  }

  private static getDay(date: Date): string {
    const day = date.getDate() + 1;

    let dayString = day.toString();

    if (day < 10) {
      dayString = `0${day}`;
    }

    return dayString;

    // TODO: check results from above and below
    // return date.getDate() >= 10 ? date.getDate() : '0' + date.getDate();
  }

  static getBeginningOfToday(date = new Date()): string {
    return DateUtils.dateFormatter(date);
  }

  static getStartDate(lastDays: number): Date {
    const currentDate = new Date();
    const startDate = new Date();
    startDate.setDate(currentDate.getDate() - lastDays);
    return startDate;
  }

  static getBeginningDate(date = new Date()): string {
    date.setDate(date.getDate() - 7);
    return DateUtils.dateFormatter(date);
  }

  static etEndOfToday(date = new Date()): string {
    return DateUtils.dateFormatter(date, DateUtils.zuluTimeDayEnd);
  }

  static dateFormatter(date: Date, zuluTime = DateUtils.zuluTimeDayStart, separator = '-'): string {
    const year = date.getFullYear();

    let month: number | string = date.getMonth() + 1;

    const day = date.getDate() >= 10 ? date.getDate() : '0' + date.getDate();

    if (month < 10) {
      month = '0' + month;
    }

    return `${year}${separator}${month}${separator}${day}${zuluTime}`;
  }

  static addHours(date: Date, hours: number): Date {
    const dateCopy = new Date(date);
    const hoursToAdd = hours * 60 * 60 * 1000;
    dateCopy.setTime(dateCopy.getTime() + hoursToAdd);
    return dateCopy;
  }

  static getCurrentYear(): number {
    return dayjs().year();
  }
}
