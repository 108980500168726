import styled, { css } from 'styled-components';

const activeStyles = css`
  background-color: var(--color);
  color: var(--text-color);
`;

const StyledWrapper = styled.button<{ isActive?: boolean }>`
  font-size: 1em;
  padding: 0.5em 0.75em;
  font-weight: bold;
  border: none;
  color: var(--color);
  background-color: var(--opaque-color);
  border-radius: 7px;
  white-space: pre;
  outline: none !important;
  transition: all ${({ theme }) => theme.quickTransition} ease;

  &:hover {
    cursor: pointer;
  }

  &:active {
    transform: scale(0.9);
  }

  &:not(:disabled):hover,
  &:focus {
    ${activeStyles}
  }

  /* TODO: Vorbert -> zastanowić się po refaktorze, czy props 'isActive' jest przydatny/potrzebny */
  ${({ isActive }) => isActive && activeStyles}

  &:disabled {
    background-color: var(--color);
    color: var(--text-color);

    &:hover {
      cursor: not-allowed;
    }
  }
`;

export default StyledWrapper;
