import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { asAddress } from 'utils/format';
import SingleInformation from 'components/SingleInformation';

import styles from './ContactInformations.module.scss';

const propTypes = {
  address: PropTypes.shape({
    city: PropTypes.string,
    street: PropTypes.string,
    buildingNumber: PropTypes.string,
    apartmentNumber: PropTypes.string,
    zipCode: PropTypes.string,
    postOffice: PropTypes.string
  }),
  nip: PropTypes.string,
  regon: PropTypes.string,
  krs: PropTypes.string,
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
  bankName: PropTypes.string,
  bankAccountNumber: PropTypes.string
};

const ContactInformations = ({
  address,
  nip,
  regon,
  krs,
  email,
  phoneNumber,
  bankName,
  bankAccountNumber
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.information_container}>
      {address && address.city && (
        <SingleInformation
          title={t('Address')}
          value={asAddress(address)}
          isCopyEnabled
          isBiggerText
        />
      )}
      {krs && (
        <SingleInformation
          title={t('PublicBenefitOrganization')}
          value={krs}
          isCopyEnabled
          isBiggerText
        />
      )}
      {bankAccountNumber && (
        <SingleInformation title={bankName} value={bankAccountNumber} isCopyEnabled isBiggerText />
      )}
      {phoneNumber && (
        <SingleInformation
          title={t('PhoneNumber')}
          value={phoneNumber}
          isCopyEnabled
          isBiggerText
        />
      )}
      {email && (
        <SingleInformation title={t('EmailAddress')} value={email} isCopyEnabled isBiggerText />
      )}
      {nip && <SingleInformation title={t('Nip')} value={nip} isCopyEnabled isBiggerText />}
      {regon && <SingleInformation title={t('Regon')} value={regon} isCopyEnabled isBiggerText />}
    </div>
  );
};

ContactInformations.propTypes = propTypes;

export default memo(ContactInformations);
