import ObjectUtils from 'utils/object.utils';

import actionTypes from './actionTypes';
import service from './service';

const getMyLeagueNameSuccess = data => dispatch =>
  dispatch({
    type: actionTypes.STORE_MY_LEAGUE_NAME,
    data
  });

export function getLeagueNameForMyLeague() {
  return (dispatch, getState) => {
    const { currentCompetitions } = getState();

    if (!currentCompetitions?.leagueName) {
      return service
        .getLeagueNameForMyLeague()
        .then(data => dispatch(getMyLeagueNameSuccess(data)));
    }
  };
}

const getCurrentSeasonNameSuccess = data => dispatch =>
  dispatch({
    type: actionTypes.STORE_CURRENT_SEASON_NAME,
    data
  });

export function getSeasonNameForCurrentSeason() {
  return (dispatch, getState) => {
    const { currentCompetitions } = getState();

    if (!currentCompetitions?.seasonName) {
      return service
        .getSeasonNameForCurrentSeason()
        .then(data => dispatch(getCurrentSeasonNameSuccess(data)));
    }
  };
}

const getCurrentCompetitionsInfoSuccess = data => dispatch =>
  dispatch({
    type: actionTypes.STORE_CURRENT_COMPETITIONS_INFO,
    data
  });

export function getCurrentCompetitionsInfo() {
  return (dispatch, getState) => {
    const { currentCompetitions } = getState();

    if (ObjectUtils.isEmpty(currentCompetitions?.competitionsInfo)) {
      return service
        .getCurrentCompetitionsInfo()
        .then(competitionsInfo => dispatch(getCurrentCompetitionsInfoSuccess(competitionsInfo)));
    }
  };
}
const getCompetitionsInfoSuccess = data => dispatch =>
  dispatch({
    type: actionTypes.STORE_COMPETITIONS_INFO,
    data
  });

export function getCompetitionsInfo(leagueId, seasonId) {
  return (dispatch, getState) => {
    const { competitions } = getState();

    const foundedCompetitions = competitions?.find(
      x => x.leagueId === leagueId && x.seasonId === seasonId
    );

    if (ObjectUtils.isEmpty(foundedCompetitions?.competitionsInfo)) {
      return service
        .getCompetitionsInfo(leagueId, seasonId)
        .then(competitionsInfo => dispatch(getCompetitionsInfoSuccess(competitionsInfo)));
    }
  };
}

const getCurrentCompetitionsTableSeasonFullSuccess = data => dispatch =>
  dispatch({
    type: actionTypes.STORE_CURRENT_COMPETITIONS_TABLE_SEASON_FULL,
    data
  });

export function getCurrentCompetitionsTableSeasonFull() {
  return (dispatch, getState) => {
    const { currentCompetitions } = getState();

    if (ObjectUtils.isEmpty(currentCompetitions?.table?.season?.full)) {
      return service
        .getCurrentCompetitionsTableSeasonFull()
        .then(competitionsTable =>
          dispatch(getCurrentCompetitionsTableSeasonFullSuccess(competitionsTable))
        );
    }
  };
}

const getCurrentCompetitionsTableSeasonAsHostSuccess = data => dispatch =>
  dispatch({
    type: actionTypes.STORE_CURRENT_COMPETITIONS_TABLE_SEASON_AS_HOST,
    data
  });

export function getCurrentCompetitionsTableSeasonAsHost() {
  return (dispatch, getState) => {
    const { currentCompetitions } = getState();

    if (ObjectUtils.isEmpty(currentCompetitions?.table?.season?.asHost)) {
      return service
        .getCurrentCompetitionsTableSeasonAsHost()
        .then(competitionsTable =>
          dispatch(getCurrentCompetitionsTableSeasonAsHostSuccess(competitionsTable))
        );
    }
  };
}

const getCurrentCompetitionsTableSeasonAsGuestSuccess = data => dispatch =>
  dispatch({
    type: actionTypes.STORE_CURRENT_COMPETITIONS_TABLE_SEASON_AS_GUEST,
    data
  });

export function getCurrentCompetitionsTableSeasonAsGuest() {
  return (dispatch, getState) => {
    const { currentCompetitions } = getState();

    if (ObjectUtils.isEmpty(currentCompetitions?.table?.season?.asGuest)) {
      return service
        .getCurrentCompetitionsTableSeasonAsGuest()
        .then(competitionsTable =>
          dispatch(getCurrentCompetitionsTableSeasonAsGuestSuccess(competitionsTable))
        );
    }
  };
}

const getCurrentCompetitionsTableAutumnRoundFullSuccess = data => dispatch =>
  dispatch({
    type: actionTypes.STORE_CURRENT_COMPETITIONS_TABLE_AUTUMN_ROUND_FULL,
    data
  });

export function getCurrentCompetitionsTableAutumnRoundFull() {
  return (dispatch, getState) => {
    const { currentCompetitions } = getState();

    if (ObjectUtils.isEmpty(currentCompetitions?.table?.autumnRound?.full)) {
      return service
        .getCurrentCompetitionsTableAutumnRoundFull()
        .then(competitionsTable =>
          dispatch(getCurrentCompetitionsTableAutumnRoundFullSuccess(competitionsTable))
        );
    }
  };
}

const getCurrentCompetitionsTableAutumnRoundAsHostSuccess = data => dispatch =>
  dispatch({
    type: actionTypes.STORE_CURRENT_COMPETITIONS_TABLE_AUTUMN_ROUND_AS_HOST,
    data
  });

export function getCurrentCompetitionsTableAutumnRoundAsHost() {
  return (dispatch, getState) => {
    const { currentCompetitions } = getState();

    if (ObjectUtils.isEmpty(currentCompetitions?.table?.autumnRound?.asHost)) {
      return service
        .getCurrentCompetitionsTableAutumnRoundAsHost()
        .then(competitionsTable =>
          dispatch(getCurrentCompetitionsTableAutumnRoundAsHostSuccess(competitionsTable))
        );
    }
  };
}

const getCurrentCompetitionsTableAutumnRoundAsGuestSuccess = data => dispatch =>
  dispatch({
    type: actionTypes.STORE_CURRENT_COMPETITIONS_TABLE_AUTUMN_ROUND_AS_GUEST,
    data
  });

export function getCurrentCompetitionsTableAutumnRoundAsGuest() {
  return (dispatch, getState) => {
    const { currentCompetitions } = getState();

    if (ObjectUtils.isEmpty(currentCompetitions?.table?.autumnRound?.asGuest)) {
      return service
        .getCurrentCompetitionsTableAutumnRoundAsGuest()
        .then(competitionsTable =>
          dispatch(getCurrentCompetitionsTableAutumnRoundAsGuestSuccess(competitionsTable))
        );
    }
  };
}

const getCurrentCompetitionsTableSpringRoundFullSuccess = data => dispatch =>
  dispatch({
    type: actionTypes.STORE_CURRENT_COMPETITIONS_TABLE_SPRING_ROUND_FULL,
    data
  });

export function getCurrentCompetitionsTableSpringRoundFull() {
  return (dispatch, getState) => {
    const { currentCompetitions } = getState();

    if (ObjectUtils.isEmpty(currentCompetitions?.table?.springRound?.full)) {
      return service
        .getCurrentCompetitionsTableSpringRoundFull()
        .then(competitionsTable =>
          dispatch(getCurrentCompetitionsTableSpringRoundFullSuccess(competitionsTable))
        );
    }
  };
}

const getCurrentCompetitionsTableSpringRoundAsHostSuccess = data => dispatch =>
  dispatch({
    type: actionTypes.STORE_CURRENT_COMPETITIONS_TABLE_SPRING_ROUND_AS_HOST,
    data
  });

export function getCurrentCompetitionsTableSpringRoundAsHost() {
  return (dispatch, getState) => {
    const { currentCompetitions } = getState();

    if (ObjectUtils.isEmpty(currentCompetitions?.table?.springRound?.asHost)) {
      return service
        .getCurrentCompetitionsTableSpringRoundAsHost()
        .then(competitionsTable =>
          dispatch(getCurrentCompetitionsTableSpringRoundAsHostSuccess(competitionsTable))
        );
    }
  };
}

const getCurrentCompetitionsTableSpringRoundAsGuestSuccess = data => dispatch =>
  dispatch({
    type: actionTypes.STORE_CURRENT_COMPETITIONS_TABLE_SPRING_ROUND_AS_GUEST,
    data
  });

export function getCurrentCompetitionsTableSpringRoundAsGuest() {
  return (dispatch, getState) => {
    const { currentCompetitions } = getState();

    if (ObjectUtils.isEmpty(currentCompetitions?.table?.springRound?.asGuest)) {
      return service
        .getCurrentCompetitionsTableSpringRoundAsGuest()
        .then(competitionsTable =>
          dispatch(getCurrentCompetitionsTableSpringRoundAsGuestSuccess(competitionsTable))
        );
    }
  };
}

const getCompetitionsTableSeasonFullSuccess = data => dispatch =>
  dispatch({
    type: actionTypes.STORE_COMPETITIONS_TABLE_SEASON_FULL,
    data
  });

export function getCompetitionsTableSeasonFull(leagueId, seasonId) {
  return (dispatch, getState) => {
    const { competitions } = getState();

    const foundedCompetitions = competitions?.find(
      x => x.leagueId === leagueId && x.seasonId === seasonId
    );

    if (ObjectUtils.isEmpty(foundedCompetitions?.table?.season?.full)) {
      return service
        .getCompetitionsTableSeasonFull(leagueId, seasonId)
        .then(competitionsTable =>
          dispatch(getCompetitionsTableSeasonFullSuccess({ leagueId, seasonId, competitionsTable }))
        );
    }
  };
}

const getCompetitionsTableSeasonAsHostSuccess = data => dispatch =>
  dispatch({
    type: actionTypes.STORE_COMPETITIONS_TABLE_SEASON_AS_HOST,
    data
  });

export function getCompetitionsTableSeasonAsHost(leagueId, seasonId) {
  return (dispatch, getState) => {
    const { competitions } = getState();

    const foundedCompetitions = competitions?.find(
      x => x.leagueId === leagueId && x.seasonId === seasonId
    );

    if (ObjectUtils.isEmpty(foundedCompetitions?.table?.season?.asHost)) {
      return service
        .getCompetitionsTableSeasonAsHost(leagueId, seasonId)
        .then(competitionsTable =>
          dispatch(
            getCompetitionsTableSeasonAsHostSuccess({ leagueId, seasonId, competitionsTable })
          )
        );
    }
  };
}

const getCompetitionsTableSeasonAsGuestSuccess = data => dispatch =>
  dispatch({
    type: actionTypes.STORE_COMPETITIONS_TABLE_SEASON_AS_GUEST,
    data
  });

export function getCompetitionsTableSeasonAsGuest(leagueId, seasonId) {
  return (dispatch, getState) => {
    const { competitions } = getState();

    const foundedCompetitions = competitions?.find(
      x => x.leagueId === leagueId && x.seasonId === seasonId
    );

    if (ObjectUtils.isEmpty(foundedCompetitions?.table?.season?.asGuest)) {
      return service
        .getCompetitionsTableSeasonAsGuest(leagueId, seasonId)
        .then(competitionsTable =>
          dispatch(
            getCompetitionsTableSeasonAsGuestSuccess({ leagueId, seasonId, competitionsTable })
          )
        );
    }
  };
}

const getCompetitionsTableAutumnRoundFullSuccess = data => dispatch =>
  dispatch({
    type: actionTypes.STORE_COMPETITIONS_TABLE_AUTUMN_ROUND_FULL,
    data
  });

export function getCompetitionsTableAutumnRoundFull(leagueId, seasonId) {
  return (dispatch, getState) => {
    const { competitions } = getState();

    const foundedCompetitions = competitions?.find(
      x => x.leagueId === leagueId && x.seasonId === seasonId
    );

    if (ObjectUtils.isEmpty(foundedCompetitions?.table?.autumnRound?.full)) {
      return service
        .getCompetitionsTableAutumnRoundFull(leagueId, seasonId)
        .then(competitionsTable =>
          dispatch(
            getCompetitionsTableAutumnRoundFullSuccess({ leagueId, seasonId, competitionsTable })
          )
        );
    }
  };
}

const getCompetitionsTableAutumnRoundAsHostSuccess = data => dispatch =>
  dispatch({
    type: actionTypes.STORE_COMPETITIONS_TABLE_AUTUMN_ROUND_AS_HOST,
    data
  });

export function getCompetitionsTableAutumnRoundAsHost(leagueId, seasonId) {
  return (dispatch, getState) => {
    const { competitions } = getState();

    const foundedCompetitions = competitions?.find(
      x => x.leagueId === leagueId && x.seasonId === seasonId
    );

    if (ObjectUtils.isEmpty(foundedCompetitions?.table?.autumnRound?.asHost)) {
      return service
        .getCompetitionsTableAutumnRoundAsHost(leagueId, seasonId)
        .then(competitionsTable =>
          dispatch(
            getCompetitionsTableAutumnRoundAsHostSuccess({ leagueId, seasonId, competitionsTable })
          )
        );
    }
  };
}

const getCompetitionsTableAutumnRoundAsGuestSuccess = data => dispatch =>
  dispatch({
    type: actionTypes.STORE_COMPETITIONS_TABLE_AUTUMN_ROUND_AS_GUEST,
    data
  });

export function getCompetitionsTableAutumnRoundAsGuest(leagueId, seasonId) {
  return (dispatch, getState) => {
    const { competitions } = getState();

    const foundedCompetitions = competitions?.find(
      x => x.leagueId === leagueId && x.seasonId === seasonId
    );

    if (ObjectUtils.isEmpty(foundedCompetitions?.table?.autumnRound?.asGuest)) {
      return service
        .getCompetitionsTableAutumnRoundAsGuest(leagueId, seasonId)
        .then(competitionsTable =>
          dispatch(
            getCompetitionsTableAutumnRoundAsGuestSuccess({ leagueId, seasonId, competitionsTable })
          )
        );
    }
  };
}

const getCompetitionsTableSpringRoundFullSuccess = data => dispatch =>
  dispatch({
    type: actionTypes.STORE_COMPETITIONS_TABLE_SPRING_ROUND_FULL,
    data
  });

export function getCompetitionsTableSpringRoundFull(leagueId, seasonId) {
  return (dispatch, getState) => {
    const { competitions } = getState();

    const foundedCompetitions = competitions?.find(
      x => x.leagueId === leagueId && x.seasonId === seasonId
    );

    if (ObjectUtils.isEmpty(foundedCompetitions?.table?.springRound?.full)) {
      return service
        .getCompetitionsTableSpringRoundFull(leagueId, seasonId)
        .then(competitionsTable =>
          dispatch(
            getCompetitionsTableSpringRoundFullSuccess({ leagueId, seasonId, competitionsTable })
          )
        );
    }
  };
}

const getCompetitionsTableSpringRoundAsHostSuccess = data => dispatch =>
  dispatch({
    type: actionTypes.STORE_COMPETITIONS_TABLE_SPRING_ROUND_AS_HOST,
    data
  });

export function getCompetitionsTableSpringRoundAsHost(leagueId, seasonId) {
  return (dispatch, getState) => {
    const { competitions } = getState();

    const foundedCompetitions = competitions?.find(
      x => x.leagueId === leagueId && x.seasonId === seasonId
    );

    if (ObjectUtils.isEmpty(foundedCompetitions?.table?.springRound?.asHost)) {
      return service
        .getCompetitionsTableSpringRoundAsHost(leagueId, seasonId)
        .then(competitionsTable =>
          dispatch(
            getCompetitionsTableSpringRoundAsHostSuccess({ leagueId, seasonId, competitionsTable })
          )
        );
    }
  };
}

const getCompetitionsTableSpringRoundAsGuestSuccess = data => dispatch =>
  dispatch({
    type: actionTypes.STORE_COMPETITIONS_TABLE_SPRING_ROUND_AS_GUEST,
    data
  });

export function getCompetitionsTableSpringRoundAsGuest(leagueId, seasonId) {
  return (dispatch, getState) => {
    const { competitions } = getState();

    const foundedCompetitions = competitions?.find(
      x => x.leagueId === leagueId && x.seasonId === seasonId
    );

    if (ObjectUtils.isEmpty(foundedCompetitions?.table?.springRound?.asGuest)) {
      return service
        .getCompetitionsTableSpringRoundAsGuest(leagueId, seasonId)
        .then(competitionsTable =>
          dispatch(
            getCompetitionsTableSpringRoundAsGuestSuccess({ leagueId, seasonId, competitionsTable })
          )
        );
    }
  };
}

const getCurrentCompetitionsScheduleSuccess = data => dispatch =>
  dispatch({
    type: actionTypes.STORE_CURRENT_COMPETITIONS_SCHEDULE,
    data
  });

export function getCurrentCompetitionsSchedule() {
  return (dispatch, getState) => {
    const { currentCompetitions } = getState();

    if (ObjectUtils.isEmpty(currentCompetitions?.competitionsSchedule)) {
      return service
        .getCurrentCompetitionsSchedule()
        .then(data => dispatch(getCurrentCompetitionsScheduleSuccess(data)));
    }
  };
}

const getCompetitionsScheduleSuccess = data => dispatch =>
  dispatch({
    type: actionTypes.STORE_COMPETITIONS_SCHEDULE,
    data
  });

export function getCompetitionsSchedule(leagueId, seasonId) {
  return (dispatch, getState) => {
    const { competitions } = getState();

    const foundedCompetitions = competitions?.find(
      x => x.leagueId === leagueId && x.seasonId === seasonId
    );

    if (ObjectUtils.isEmpty(foundedCompetitions?.competitionsSchedule)) {
      return service
        .getCompetitionsSchedule(leagueId, seasonId)
        .then(competitionsSchedule =>
          dispatch(getCompetitionsScheduleSuccess({ leagueId, seasonId, competitionsSchedule }))
        );
    }
  };
}
