/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

import { useAppDispatch } from 'store';
import ArrayUtils from 'utils/array.utils';
import ObjectUtils from 'utils/object.utils';
import { shouldMakeRequest } from 'utils/requests.utils';
import { useGetCurrentSeasonId } from 'utils/systemParameterProvider';

import { IPlayerSeasonModel } from './models';
import {
  useSelectGetPlayerCurrentTeamRequestStatus,
  useSelectGetPlayerDebutMatchRequestStatus,
  useSelectGetPlayerDetailsRequestStatus,
  useSelectGetPlayerMatchesInSeasonRequestStatus,
  useSelectGetPlayerPhotoRequestStatus,
  useSelectGetPlayerSeasonRequestStatus,
  useSelectGetPlayerSeasonsRequestStatus,
  useSelectPlayerCurrentTeam,
  useSelectPlayerDebutMatch,
  useSelectPlayerDetails,
  useSelectPlayerPhoto,
  useSelectPlayerSeason,
  useSelectPlayerSeasons
} from './player.selectors.hooks';
import {
  getCurrentTeam,
  getDebutMatch,
  getDetails,
  getMatchesInSeason,
  getPhoto,
  getSeason,
  getSeasons,
  getSeasonStatistics
} from './player.thunks';

// TODO: zastanowić się czy trzeba typy
// type GetPlayerDetailsQueryModel = {
//   details: IPlayerDetailsResponse | null | undefined;
// } & RequestStatus;

// TODO: zastanowić się czy trzeba typy
// export const useGetPlayerDetailsQuery = (id: string ): GetPlayerDetailsQueryModel => {
export const useGetPlayerDetailsQuery = (id: string) => {
  const dispatch = useAppDispatch();

  const requestStatus = useSelectGetPlayerDetailsRequestStatus(id);
  const details = useSelectPlayerDetails(id);

  useEffect(() => {
    if (shouldMakeRequest(requestStatus)) {
      dispatch(getDetails(id));
    }
  }, []);

  return { details, requestStatus };
};

export const useGetPlayerPhotoQuery = (id: string) => {
  const dispatch = useAppDispatch();

  const requestStatus = useSelectGetPlayerPhotoRequestStatus(id);
  const photo = useSelectPlayerPhoto(id);

  useEffect(() => {
    if (shouldMakeRequest(requestStatus)) {
      dispatch(getPhoto(id));
    }
  }, []);

  return { photo, requestStatus };
};

export const useGetPlayerCurrentTeamQuery = (id: string) => {
  const dispatch = useAppDispatch();

  const requestStatus = useSelectGetPlayerCurrentTeamRequestStatus(id);
  const currentTeam = useSelectPlayerCurrentTeam(id);

  useEffect(() => {
    if (shouldMakeRequest(requestStatus)) {
      dispatch(getCurrentTeam(id));
    }
  }, []);

  return { currentTeam, requestStatus };
};

export const useGetPlayerDebutMatchQuery = (id: string) => {
  const dispatch = useAppDispatch();

  const requestStatus = useSelectGetPlayerDebutMatchRequestStatus(id);
  const debutMatch = useSelectPlayerDebutMatch(id);

  useEffect(() => {
    if (shouldMakeRequest(requestStatus)) {
      dispatch(getDebutMatch(id));
    }
  }, []);

  return { debutMatch, requestStatus };
};

export const useGetPlayerSeasonsQuery = (id: string) => {
  const dispatch = useAppDispatch();

  const requestStatus = useSelectGetPlayerSeasonsRequestStatus(id);
  const seasons = useSelectPlayerSeasons(id);

  useEffect(() => {
    if (shouldMakeRequest(requestStatus)) {
      dispatch(getSeasons(id)).then(response =>
        (response?.payload as IPlayerSeasonModel[]).forEach(season =>
          callForSeasonStatistics(dispatch, id, season)
        )
      );
    }
  }, []);

  return { seasons, requestStatus };
};

export const useGetPlayerSeasonQuery = (id: string, seasonId: string) => {
  const dispatch = useAppDispatch();

  const requestStatus = useSelectGetPlayerSeasonRequestStatus(id, seasonId);
  const season = useSelectPlayerSeason(id, seasonId);

  useEffect(() => {
    if (shouldMakeRequest(requestStatus) && ObjectUtils.isEmpty(season)) {
      dispatch(getSeason({ id, seasonId })).then(response =>
        callForSeasonStatistics(dispatch, id, response?.payload as IPlayerSeasonModel)
      );
    }
  }, []);

  return { season, requestStatus };
};

export const useGetMatchesInSeasonQuery = (
  id: string,
  seasonId?: string | null,
  isForCurrentSeason?: boolean,
  seasons?: IPlayerSeasonModel[] | null | undefined
) => {
  const dispatch = useAppDispatch();

  const currentSeasonId = useGetCurrentSeasonId();

  const requestStatus = useSelectGetPlayerMatchesInSeasonRequestStatus(id, seasonId!);

  const shouldMakeCallForCurrentSeason = seasons?.map(x => x.seasonId).includes(currentSeasonId);

  useEffect(() => {
    if (shouldMakeRequest(requestStatus) && !isForCurrentSeason) {
      dispatch(getMatchesInSeason({ id, seasonId: seasonId! }));
    }
  }, []);

  useEffect(() => {
    if (
      shouldMakeRequest(requestStatus) &&
      currentSeasonId &&
      isForCurrentSeason &&
      shouldMakeCallForCurrentSeason
    ) {
      dispatch(getMatchesInSeason({ id, seasonId: currentSeasonId }));
    }
  }, [shouldMakeCallForCurrentSeason]);
};

export const usePlayerData = (id: string) => {
  const {
    details,
    photo,
    currentTeam,
    debutMatch,

    getDetailsRequestStatus,
    getPhotoRequestStatus,
    getCurrentTeamRequestStatus,
    getDebutMatchRequestStatus
  } = useGetCardPlayerData(id);

  const { seasons, requestStatus: getSeasonsRequestStatus } = useGetPlayerSeasonsQuery(id);

  useGetMatchesInSeasonQuery(id, null, true, seasons);

  return {
    details,
    photo,
    currentTeam,
    debutMatch,
    seasons,

    getDetailsRequestStatus,
    getPhotoRequestStatus,
    getCurrentTeamRequestStatus,
    getDebutMatchRequestStatus,
    getSeasonsRequestStatus
  };
};

export const usePlayerDataForStatisticsPage = (id: string, seasonId: string) => {
  const {
    details,
    photo,
    currentTeam,
    debutMatch,

    getDetailsRequestStatus,
    getPhotoRequestStatus,
    getCurrentTeamRequestStatus,
    getDebutMatchRequestStatus
  } = useGetCardPlayerData(id);

  const { season, requestStatus: getSeasonRequestStatus } = useGetPlayerSeasonQuery(id, seasonId);

  useGetMatchesInSeasonQuery(id, seasonId);

  return {
    details,
    photo,
    currentTeam,
    debutMatch,
    season,

    getDetailsRequestStatus,
    getPhotoRequestStatus,
    getCurrentTeamRequestStatus,
    getDebutMatchRequestStatus,
    getSeasonRequestStatus
  };
};

export const useGetCardPlayerData = (id: string) => {
  const { details, requestStatus: getDetailsRequestStatus } = useGetPlayerDetailsQuery(id);

  const { photo, requestStatus: getPhotoRequestStatus } = useGetPlayerPhotoQuery(id);

  const { currentTeam, requestStatus: getCurrentTeamRequestStatus } =
    useGetPlayerCurrentTeamQuery(id);

  const { debutMatch, requestStatus: getDebutMatchRequestStatus } = useGetPlayerDebutMatchQuery(id);

  return {
    details,
    photo,
    currentTeam,
    debutMatch,

    getDetailsRequestStatus,
    getPhotoRequestStatus,
    getCurrentTeamRequestStatus,
    getDebutMatchRequestStatus
  };
};

function callForSeasonStatistics(dispatch, id: string, season: IPlayerSeasonModel) {
  if (ArrayUtils.isNotEmpty(season.leagues)) {
    season.leagues.forEach(league => {
      if (ArrayUtils.isNotEmpty(league.teams)) {
        league.teams.forEach(team => {
          dispatch(
            getSeasonStatistics({
              id,
              teamId: team.id,
              seasonId: season.seasonId,
              leagueId: league.leagueId
            })
          );
        });
      }
    });
  }
}
