import React, { memo, ReactElement } from 'react';

import Column from 'components/Column';
import Row from 'components/Row';

import styles from './EventCountColumn.module.scss';

type Props = {
  icon: ReactElement;
  eventCounts: number | string | undefined;
};

const EventCountColumn = ({ icon, eventCounts }: Readonly<Props>) => (
  <Column className={styles.event_count_column}>
    <Row>
      <Column>{icon}</Column>

      <Column>
        <span className={styles.event_count_number}>{eventCounts}</span>
      </Column>
    </Row>
  </Column>
);

export default memo(EventCountColumn);
