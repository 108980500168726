import actionTypes from './actionTypes';

const initialState = {
  activeLinkItem: {
    offset: 0,
    width: 0
  },
  hoveredLinkItem: {
    offset: 0,
    width: 0
  },
  activeLinkUrl: undefined
};

export default function navbarReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_HOVERED_LINK_ITEM:
      return { ...state, hoveredLinkItem: action.payload };
    case actionTypes.SET_ACTIVE_LINK_URL:
      return { ...state, activeLinkUrl: action.payload };
    default:
      return state;
  }
}
