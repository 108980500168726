import { memo } from 'react';
import styled, { css } from 'styled-components';

const StyledWrapper = styled.i<{ disabled?: boolean }>`
  ${({ disabled }) =>
    disabled &&
    css`
      &:disabled,
      &[disabled] {
        color: ${({ theme }) => theme.greyColor};
      }
    `}
`;

export default memo(StyledWrapper);
