import React, { memo } from 'react';
import Link from 'components/Links/Link';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { icons } from 'consts/icons';
import urls from 'consts/urls';
import { useIsDesktop } from 'utils/customHooks';
import Column from 'components/Column';
import IconButton from 'components/IconButton';
import Row from 'components/Row';

import styles from './TeamSeasons.module.scss';

const propTypes = {
  teamId: PropTypes.string,
  seasons: PropTypes.arrayOf(
    PropTypes.shape({
      leagueId: PropTypes.string,
      leagueName: PropTypes.string,
      seasonId: PropTypes.string,
      seasonName: PropTypes.string
    })
  )
};

const TeamSeasons = ({ teamId, seasons }) => {
  const { t } = useTranslation();
  const isDesktop = useIsDesktop();

  const rowClassName = cx(styles.row, {
    [styles.row_mobile]: !isDesktop
  });

  const seasonAndLeagueNameColumnClassName = cx(styles.row_seasonAndLeagueName_column, {
    [styles.row_seasonAndLeagueName_column_mobile]: !isDesktop
  });

  const buttonColumnClassName = cx(styles.row_button_column, {
    [styles.row_button_column_mobile]: !isDesktop
  });

  const seasonsRows = seasons?.map(x => (
    <Row key={`${x.leagueId}${x.seasonId}`} className={rowClassName}>
      <Column className={seasonAndLeagueNameColumnClassName}>
        <Link to={urls.competitionsDetails(x.leagueId, x.seasonId)} className={styles.link}>
          {x.seasonName} - {x.leagueName}
        </Link>
      </Column>

      <Column className={buttonColumnClassName}>
        <IconButton
          className={styles.details_button}
          icon={icons.arrowRight}
          title={t('TeamCompetitionsStatistics')}
          to={urls.teamSeasonStatistics(teamId, x.seasonId)}
        >
          {t('TeamCompetitionsStatistics')}
        </IconButton>
      </Column>
    </Row>
  ));

  return seasonsRows;
};

TeamSeasons.propTypes = propTypes;

export default memo(TeamSeasons);
