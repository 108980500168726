import React, { memo, useRef } from 'react';
import PropTypes from 'prop-types';

import { useFocusOnFirstInput } from 'utils/customHooks';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  isVisible: PropTypes.bool
};

const Form = ({ handleSubmit, children, isVisible = true, ...others }) => {
  const formRef = useRef(undefined);
  useFocusOnFirstInput(formRef, isVisible);

  return (
    <form noValidate onSubmit={handleSubmit} ref={formRef} {...others}>
      {children}
    </form>
  );
};

Form.propTypes = propTypes;

export default memo(Form);
