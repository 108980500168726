import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { icons } from 'consts/icons';
import Card from 'components/Card';
import Column from 'components/Column';
import IconButton from 'components/IconButton';

// TODO: import AvatarPlaceholder from 'components/AvatarPlaceholder';
import styles from './SingleCompany.module.scss';

const propTypes = {
  name: PropTypes.string.isRequired,
  logo: PropTypes.string,
  websiteUrl: PropTypes.string
};

const SingleCompany = ({ name, logo, websiteUrl }) => {
  const { t } = useTranslation();

  const logoContent = logo ? (
    <img src={logo} alt={`${name} ${t('Logo')}`} className={styles.company_img} />
  ) : (
    <div />
    // TODO:
    // <AvatarPlaceholder
    //   label={name.charAt(0)}
    //   small
    //   additionalClassNames={styles.company_img_placeholder}
    // />
  );

  return (
    <Card className={`${styles.company_container} ${styles.company}`} autoHeight hoverable>
      <Column className={styles.company_column}>
        {logoContent}
        <h3 className={styles.company_name}>{name}</h3>
        {websiteUrl && (
          <IconButton
            className={styles.company_button}
            icon={icons.arrowRight}
            title={`${t('GoToTheCompanyWebsite')}: ${name}`}
            to={websiteUrl}
          >
            {t('GoToTheCompanyWebsite')}
          </IconButton>
        )}
      </Column>
    </Card>
  );
};

SingleCompany.propTypes = propTypes;

export default memo(SingleCompany);
