import axios from 'config/axios';
import config from 'config/config';

const prefix = 'client/auth';

export default class AuthSrv {
  static register(model) {
    model.origin = window.location.origin + config.publicUrl;
    return axios.post(`${prefix}/register`, model).then(result => result.data);
  }

  static login(model) {
    return axios.post(`${prefix}/login`, model).then(result => result.data);
  }

  static resetPassword(model) {
    model.origin = window.location.origin + config.publicUrl;
    return axios.post(`${prefix}/resetPassword`, model);
  }

  static setNewPasswordAsync(model) {
    return axios.post(`${prefix}/setNewPassword`, model);
  }

  static updateUserInformation(model) {
    return axios.post(`${prefix}/updateUserInformation`, model).then(result => result.data);
  }

  static changePassword(model) {
    return axios.put(`${prefix}/changePassword`, model);
  }
}
