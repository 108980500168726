import urls from 'consts/urls';

interface YouthTeam {
  ageGroupName: string;
  teamId: string;
  currentLeagueId: string;
}

interface MenuLink {
  label: string;
  address: string;
  external?: boolean;
  shouldNotTranslateLabel?: boolean;
  subLinks?: MenuLink[];
}

export const getLinksData = (currentSeasonId = '', youthTeams: YouthTeam[] = []): MenuLink[] => {
  const youthTeamsMenuItems: MenuLink[] = youthTeams.map(youthTeam => ({
    shouldNotTranslateLabel: true,
    label: youthTeam.ageGroupName,
    address: '#',
    subLinks: [
      {
        label: 'Team',
        address: urls.teamDetails(youthTeam.teamId)
      },
      {
        label: 'Players',
        address: urls.teamPlayers(youthTeam.teamId)
      },
      {
        label: 'CompetitionsDetails',
        address: urls.competitionsDetails(youthTeam.currentLeagueId, currentSeasonId)
      },
      {
        label: 'CompetitionsSchedule',
        address: urls.competitionsSchedule(youthTeam.currentLeagueId, currentSeasonId)
      },
      {
        label: 'Table',
        address: urls.competitionsTable(youthTeam.currentLeagueId, currentSeasonId)
      }
      // TODO:
      // {
      //   label: 'Statistics',
      //   address: urls.statistics
      // }
    ]
  }));

  return [
    {
      label: 'HomePage',
      address: '/'
    },
    {
      label: 'Articles',
      address: urls.articles
    },
    {
      label: 'Club',
      address: '#',
      subLinks: [
        {
          label: 'AboutClub',
          address: urls.myClubDetails
        },
        {
          label: 'BoardMembers',
          address: urls.myClubBoardMembers
        },
        {
          label: 'History',
          address: urls.myClubHistory
        },
        {
          label: 'Partners',
          address: urls.myClubPartners
        },
        {
          label: 'Sponsors',
          address: urls.myClubSponsors
        },
        {
          label: 'Contact',
          address: urls.myClubContact
        },
        {
          label: 'Gallery',
          address: urls.gallery
        },
        {
          label: 'Stadium',
          address: urls.myClubStadiumDescription
        }
      ]
    },
    {
      label: 'Seniors',
      address: '#',
      subLinks: [
        {
          label: 'Team',
          address: urls.myTeamDetails
        },
        {
          label: 'Players',
          address: urls.myTeamPlayers
        },
        {
          label: 'CompetitionsDetails',
          address: urls.currentCompetitionsDetails
        },
        {
          label: 'CompetitionsSchedule',
          address: urls.currentCompetitionsSchedule
        },
        {
          label: 'Table',
          address: urls.currentCompetitionsTable
        }
        // TODO:
        // {
        //   label: 'Statistics',
        //   address: urls.statistics
        // }
      ]
    },
    {
      label: 'YouthTeams',
      address: '#',
      subLinks: [...youthTeamsMenuItems]
    },
    // TODO:
    // {
    //   label: 'ArchivalResults',
    //   address: '#',
    //   subLinks: [
    //     {
    //       label: '1959 - 1968',
    //       address: 'todo'
    //     },
    //     {
    //       label: '1968 - 1978',
    //       address: 'todo'
    //     },
    //     {
    //       label: '1978 - 1988',
    //       address: 'todo'
    //     },
    //     {
    //       label: '1988 - 1998',
    //       address: 'todo'
    //     },
    //     {
    //       label: '1998 - 2008',
    //       address: 'todo'
    //     },
    //     {
    //       label: '2008 - 2018',
    //       address: 'todo'
    //     },
    //     {
    //       label: '2018 - obecnie',
    //       address: '$todo'
    //     },
    //     {
    //       label: 'Błażowianka II',
    //       address: 'todo'
    //     }
    //   ]
    // }
    {
      label: 'Documents',
      address: urls.documents
    },
    {
      label: 'OldVersionWebsite',
      // address: 'https://old.blazowianka.pl', // TODO: change to new url when working
      address: 'http://blazowianka.net',
      external: true
    }
  ];
};
