import React, { memo, useCallback, useEffect, useState } from 'react';

import axios from 'config/axios';
import { icons } from 'consts/icons';
import ArticlePlaceholder from 'modules/home/articles/ArticlePlaceholder';
import MatchPlaceholder from 'modules/home/matches/MatchPlaceholder';
import ArticlePlaceholder2 from 'components/ArticlePlaceholder';
import IconButton from 'components/IconButton';
import PlaceholderImage from 'components/PlaceholderImage';

import ToastExample from './ToastExample';

const TestPage = () => {
  const settingsPrefix = 'shared/settings';
  const [settings, setSettings] = useState(null);

  const getSettings = useCallback(
    () =>
      axios.get(`${settingsPrefix}/settings`).then(result => {
        // eslint-disable-next-line no-console
        console.log('settings', result.data);
        setSettings(result.data);
      }),
    []
  );

  useEffect(() => {
    getSettings();
  }, [getSettings]);

  return (
    <>
      <ToastExample />
      <br />
      <br />
      <IconButton icon={icons.chevronRight} to="/error-test-page">
        error-test-page
      </IconButton>
      <br />
      <br />
      ArticlePlaceholder:
      <ArticlePlaceholder />
      <br />
      <br />
      ArticlePlaceholder2:
      <ArticlePlaceholder2 />
      <br />
      <br />
      PlaceholderImage - not used in app?:
      <PlaceholderImage />
      <br />
      <br />
      MatchPlaceholder - not used in app?:
      <MatchPlaceholder />
      <br />
      <br />
      <pre>{JSON.stringify(settings, null, 2)}</pre>
    </>
  );
};

export default memo(TestPage);
