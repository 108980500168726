import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';

import Dropdown from './Dropdown';
import styles from './UserAction.module.scss';

const propTypes = {
  children: PropTypes.any.isRequired,
  renderDropdown: PropTypes.func,
  renderActionContainer: PropTypes.func
};

const UserAction = ({
  children,
  renderDropdown,
  renderActionContainer = (className, children) => <span className={className}>{children}</span>
}) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const handleShowDropdown = () => renderDropdown && setIsDropdownVisible(true);
  const handleHideDropdown = () => renderDropdown && setIsDropdownVisible(false);

  return (
    <span
      className={styles.user_action_container}
      onMouseEnter={handleShowDropdown}
      onMouseLeave={handleHideDropdown}
    >
      {renderActionContainer(styles.user_action, children)}
      {renderDropdown && <Dropdown visible={isDropdownVisible}>{renderDropdown()}</Dropdown>}
    </span>
  );
};

UserAction.propTypes = propTypes;

export default memo(UserAction);
