import PropTypes from 'prop-types';

import { matchPropTypesShape } from './match';
import { simpleTeamPropTypesShape } from './simple-team';

export const queuePropTypes = {
  number: PropTypes.number,
  dateFrom: PropTypes.string,
  dateTo: PropTypes.string,
  roundName: PropTypes.string,
  pauseTeam: simpleTeamPropTypesShape,
  matches: PropTypes.arrayOf(matchPropTypesShape)
};

export const queuePropTypesShape = PropTypes.shape({
  ...queuePropTypes
});
