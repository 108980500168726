import React, { memo } from 'react';
import PropTypes from 'prop-types';

import StyledImage from './StyledImage';

const propTypes = {
  name: PropTypes.string.isRequired,
  logo: PropTypes.string
};

const Slide = ({ logo, name }) => (
  // TODO: Vorbert -> dorobić możliwość przechodzenia pod link sponsora
  // TODO: Vorbert -> dorobić możliwość najeżdżania na obrazek, by "podnosił się", tak jak karta
  <StyledImage src={logo} alt={name} />
);

Slide.propTypes = propTypes;

export default memo(Slide);
