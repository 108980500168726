import axios from 'config/axios';

const prefix = 'client/match';

export default class MatchSrv {
  static get(id) {
    return axios.get(`${prefix}/${id}`).then(result => result.data);
  }

  static getMatchesBetweenTeamsBeforeDate(firstTeamId, secondTeamId, date) {
    return axios
      .get(`${prefix}/${firstTeamId}/${secondTeamId}/${date}`)
      .then(result => result.data);
  }

  static generatePoster(id) {
    return axios
      .get(`${prefix}/${id}/poster`, {
        responseType: 'blob',
        timeout: 30_000
      })
      .then(result => result.data);
  }

  static generateTickets(id) {
    return axios
      .get(`${prefix}/${id}/tickets`, {
        responseType: 'blob',
        timeout: 30_000
      })
      .then(result => result.data);
  }
}
