import actionTypes from './actionTypes';

const initialState = {};

export default function boardMembersReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.STORE_BOARD_MEMBER_DETAILS:
      const { id: boardMemberId, ...boardMember } = action.data;
      return {
        ...state,
        [boardMemberId]: boardMember
      };
    default:
      return state;
  }
}
