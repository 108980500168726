import React, { memo } from 'react';
import isEmpty from 'lodash/isEmpty';
import defaultPhoto from 'assets/images/Default_image.jpg';

import styles from './Article.module.scss';
import ArticleGallery from './ArticleGallery';
import ArticleHeader from './ArticleHeader';
import ArticlePhoto from './ArticlePhoto';

type Props = {
  photo: string;
  photos: string[];
  author: string;
  title: string;
  content: string;
  createdDate: Date;
};

const Article = ({ photo, author, title, createdDate, content, photos }: Readonly<Props>) => (
  <>
    <ArticlePhoto photo={photo || defaultPhoto} />
    <ArticleHeader author={author} title={title} createdDate={createdDate} />
    {/* eslint-disable-next-line react/no-danger */}
    <p className={styles.content} dangerouslySetInnerHTML={{ __html: content }} />
    {!isEmpty(photos) && <ArticleGallery photos={photos} />}
  </>
);

export default memo(Article);
