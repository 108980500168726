import PropTypes from 'prop-types';

import { assistPropTypesShape } from './assist';
import { cardPropTypesShape } from './card';
import { changePropTypesShape } from './change';
import { goalPropTypesShape } from './goal';
import { matchPropTypes } from './match';

export const playerMatchPropTypes = {
  ...matchPropTypes,
  ageGroupName: PropTypes.string,
  isCaptain: PropTypes.bool,
  isGoalkeeper: PropTypes.bool,
  isJunior: PropTypes.bool,
  isPlayerStartedMatchAsReserved: PropTypes.string,
  minutesCount: PropTypes.number,
  goals: PropTypes.arrayOf(goalPropTypesShape),
  assists: PropTypes.arrayOf(assistPropTypesShape),
  changes: PropTypes.arrayOf(changePropTypesShape),
  cards: PropTypes.arrayOf(cardPropTypesShape)
};

export const playerMatchPropTypesShape = PropTypes.shape({ ...playerMatchPropTypes });
