import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';

import { tablePropTypesShape } from 'propTypes';
import tableTypes from 'consts/tableTypes';
import ArrayUtils from 'utils/array.utils';
import Dropdown from 'components/Dropdown';
// import { useIsDesktop } from 'utils/customHooks';
import LoadingPageData from 'components/LoadingPageData';

// import Tab from 'components/Tabs/Tab';
// import Tabs from 'components/Tabs/Tabs';
import Table from './Table';
import styles from './Tables.module.scss';

const propTypes = {
  tableSeasonFull: tablePropTypesShape,
  tableSeasonAsHost: tablePropTypesShape,
  tableSeasonAsGuest: tablePropTypesShape,
  tableAutumnRoundFull: tablePropTypesShape,
  tableAutumnRoundAsHost: tablePropTypesShape,
  tableAutumnRoundAsGuest: tablePropTypesShape,
  tableSpringRoundFull: tablePropTypesShape,
  tableSpringRoundAsHost: tablePropTypesShape,
  tableSpringRoundAsGuest: tablePropTypesShape,
  teamIdContext: PropTypes.string,
  onGetTableData: PropTypes.func
};

const Tables = ({
  tableSeasonFull,
  tableSeasonAsHost,
  tableSeasonAsGuest,
  tableAutumnRoundFull,
  tableAutumnRoundAsHost,
  tableAutumnRoundAsGuest,
  tableSpringRoundFull,
  tableSpringRoundAsHost,
  tableSpringRoundAsGuest,
  teamIdContext,
  onGetTableData
}) => {
  const { t } = useTranslation();
  // const isDesktop = useIsDesktop();

  const [selectedDropdownOptionIndex, setSelectedDropdownOptionIndex] = useState(0);

  const generateTable = table =>
    ArrayUtils.isNotEmpty(table?.teams) ? (
      <Table teams={table.teams} teamIdContext={teamIdContext} />
    ) : (
      <LoadingPageData />
    );

  const tables = [
    generateTable(tableSeasonFull),
    generateTable(tableSeasonAsHost),
    generateTable(tableSeasonAsGuest),
    generateTable(tableAutumnRoundFull),
    generateTable(tableAutumnRoundAsHost),
    generateTable(tableAutumnRoundAsGuest),
    generateTable(tableSpringRoundFull),
    generateTable(tableSpringRoundAsHost),
    generateTable(tableSpringRoundAsGuest)
  ];

  // TODO: maybe on desktop change select to Tabs, TODO is generated tab content after click like in select, but generated tabTitles immediately

  // const generateTableTab = (table, tabTitle) =>
  //   !isEmpty(table) ? (
  //     <Tab title={tabTitle}>
  //       {ArrayUtils.isNotEmpty(table.teams) ? (
  //         <Table teams={table.teams} teamIdContext={teamIdContext} />
  //       ) : undefined}
  //     </Tab>
  //   ) : (
  //     ''
  //   );

  // const tableSeasonFullTab = generateTableTab(tableSeasonFull, t('TableSeasonFull'));
  // const tableSeasonAsHostTab = generateTableTab(tableSeasonAsHost, t('TableSeasonAsHost'));
  // const tableSeasonAsGuestTab = generateTableTab(tableSeasonAsGuest, t('TableSeasonAsGuest'));
  // const tableAutumnRoundFullTab = generateTableTab(tableAutumnRoundFull, t('TableAutumnRoundFull'));
  // const tableAutumnRoundAsHostTab = generateTableTab(
  //   tableAutumnRoundAsHost,
  //   t('TableAutumnRoundAsHost')
  // );
  // const tableAutumnRoundAsGuestTab = generateTableTab(
  //   tableAutumnRoundAsGuest,
  //   t('TableAutumnRoundAsGuest')
  // );
  // const tableSpringRoundFullTab = generateTableTab(tableSpringRoundFull, t('TableSpringRoundFull'));
  // const tableSpringRoundAsHostTab = generateTableTab(
  //   tableSpringRoundAsHost,
  //   t('TableSpringRoundAsHost')
  // );
  // const tableSpringRoundAsGuestTab = generateTableTab(
  //   tableSpringRoundAsGuest,
  //   t('TableSpringRoundAsGuest')
  // );

  const handleDropdownChange = () => {
    const dropdown = document.getElementById('dropdown'); // as HTMLSelectElement;
    setSelectedDropdownOptionIndex(dropdown.selectedIndex);
    onGetTableData(dropdown.selectedIndex);
  };

  const dropdownOptions = [
    {
      label: t('TableSeasonFull'),
      value: tableTypes.tableSeasonFull
    },
    {
      label: t('TableSeasonAsHost'),
      value: tableTypes.tableSeasonAsHost
    },
    {
      label: t('TableSeasonAsGuest'),
      value: tableTypes.tableSeasonAsGuest
    },
    {
      label: t('TableAutumnRoundFull'),
      value: tableTypes.tableAutumnRoundFull
    },
    {
      label: t('TableAutumnRoundAsHost'),
      value: tableTypes.tableAutumnRoundAsHost
    },
    {
      label: t('TableAutumnRoundAsGuest'),
      value: tableTypes.tableAutumnRoundAsGuest
    },
    {
      label: t('TableSpringRoundFull'),
      value: tableTypes.tableSpringRoundFull
    },
    {
      label: t('TableSpringRoundAsHost'),
      value: tableTypes.tableSpringRoundAsHost
    },
    {
      label: t('TableSpringRoundAsGuest'),
      value: tableTypes.tableSpringRoundAsGuest
    }
  ];

  return !isEmpty(tableSeasonFull) ? (
    // isDesktop ? (
    //   <Tabs>
    //     {tableSeasonFullTab}
    //     {tableSeasonAsHostTab}
    //     {tableSeasonAsGuestTab}
    //     {tableAutumnRoundFullTab}
    //     {tableAutumnRoundAsHostTab}
    //     {tableAutumnRoundAsGuestTab}
    //     {tableSpringRoundFullTab}
    //     {tableSpringRoundAsHostTab}
    //     {tableSpringRoundAsGuestTab}
    //   </Tabs>
    // ) : (
    <>
      <Dropdown
        id="dropdown"
        title="dropdown"
        value={selectedDropdownOptionIndex}
        selectedItemIndex={selectedDropdownOptionIndex}
        options={dropdownOptions}
        onChange={handleDropdownChange}
      />

      <div className={styles.table}>{tables[selectedDropdownOptionIndex]}</div>
    </>
  ) : (
    // )
    <LoadingPageData />
  );
};

Tables.propTypes = propTypes;

export default memo(Tables);
