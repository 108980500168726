import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Row from '../Row';
import styles from './DesktopMenu.module.scss';
import MenuLink from './MenuLink';
import withMenuLinks from './withMenuLinks';

const propTypes = {
  linksData: PropTypes.arrayOf(PropTypes.object).isRequired
};

const DesktopMenu = ({ linksData }) => {
  const { t } = useTranslation();

  const menuLinks = linksData.map((linkData, index) => (
    <MenuLink
      key={index}
      label={linkData.shouldNotTranslateLabel ? linkData.label : t(linkData.label)}
      address={linkData.address}
      subLinks={linkData.subLinks}
      external={linkData.external}
    />
  ));

  return (
    <Row className={styles.menu_link_container} as="ul" justifyItems="center" alignSelf="stretch">
      {menuLinks}
    </Row>
  );
};

DesktopMenu.propTypes = propTypes;

export default withMenuLinks(memo(DesktopMenu));
