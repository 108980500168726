import React, { memo, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { icons } from 'consts/icons';
import styles from './MobileSubmenuLink.module.scss';

const propTypes = {
  address: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  external: PropTypes.bool,
  onActive: PropTypes.func
};

const MobileSubmenuLink = ({ address, label, external, onActive, ...others }) => {
  const location = useLocation();
  const linkClassName = cx(styles.link, styles.subLink);
  const activeLinkClassName = cx(styles.link_active);

  useEffect(() => {
    if (location.pathname === address && onActive) {
      onActive(true);
    }
  }, [location.pathname, address, onActive]);

  const content = (
    <>
      <i className={cx(styles.subLink_icon, icons.chevronRight)} />
      {label}
    </>
  );

  return !external ? (
    <NavLink
      {...others}
      to={address}
      title={label}
      className={({ isActive }) => {
        if (isActive && onActive) {
          onActive(true);
        }
        return linkClassName + (isActive ? ` ${activeLinkClassName}` : '');
      }}
    >
      {content}
    </NavLink>
  ) : (
    <a target="_blank" rel="noreferrer" href={address} title={label} className={linkClassName}>
      {content}
    </a>
  );
};

MobileSubmenuLink.propTypes = propTypes;

export default memo(MobileSubmenuLink);
