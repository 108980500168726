import ArrayUtils from 'utils/array.utils';
import ObjectUtils from 'utils/object.utils';

import actionTypes from './actionTypes';
import { TeamSrv } from './service';

// TODO: akcje success są wykorzystywane też w pliku my-team-actions, więc może przenieść je stąd do osobnego pliku actions-success?

export const getTeamDetailsSuccess = (id, details) => dispatch =>
  dispatch({
    type: actionTypes.STORE_TEAM_DETAILS,
    data: {
      id,
      details
    }
  });

export function getDetails(id) {
  return (dispatch, getState) => {
    const { teams } = getState();

    if (ObjectUtils.isEmpty(teams[id]?.details)) {
      return TeamSrv.getDetails(id).then(details => dispatch(getTeamDetailsSuccess(id, details)));
    }
  };
}

export const getSeasonsListSuccess = (id, seasons) => dispatch =>
  dispatch({
    type: actionTypes.STORE_TEAM_SEASONS_LIST,
    data: {
      id,
      seasons
    }
  });

export function getSeasonsList(id) {
  return (dispatch, getState) => {
    const { teams } = getState();

    if (ArrayUtils.isEmpty(teams[id]?.seasons)) {
      return TeamSrv.getSeasonsList(id).then(seasons =>
        dispatch(getSeasonsListSuccess(id, seasons))
      );
    }
  };
}

export function getSeasonsAdvancedList(id) {
  return dispatch =>
    TeamSrv.getSeasonsAdvancedList(id).then(seasons =>
      dispatch(getSeasonsListSuccess(id, seasons))
    );
}

export const getCoachesInCurrentSeasonSuccess = (id, coachesInCurrentSeason) => dispatch =>
  dispatch({
    type: actionTypes.STORE_TEAM_COACHES_IN_CURRENT_SEASON,
    data: {
      id,
      coachesInCurrentSeason
    }
  });

export function getCoachesInCurrentSeason(id) {
  return (dispatch, getState) => {
    const { teams } = getState();

    if (ArrayUtils.isEmpty(teams[id]?.coachesInCurrentSeason)) {
      return TeamSrv.getCoachesInCurrentSeason(id).then(coachesInCurrentSeason =>
        dispatch(getCoachesInCurrentSeasonSuccess(id, coachesInCurrentSeason))
      );
    }
  };
}

export const getPlayersWithMatchesAndEventsInCurrentSeasonSuccess =
  (id, playersWithMatchesAndEventsInCurrentSeason) => dispatch =>
    dispatch({
      type: actionTypes.STORE_TEAM_PLAYERS_WITH_MATCHES_AND_EVENTS_IN_CURRENT_SEASON,
      data: {
        id,
        playersWithMatchesAndEventsInCurrentSeason
      }
    });

export function getPlayersWithMatchesAndEventsInCurrentSeason(id) {
  return (dispatch, getState) => {
    const { teams } = getState();

    if (ObjectUtils.isEmpty(teams[id]?.playersWithMatchesAndEventsInCurrentSeason)) {
      return TeamSrv.getPlayersWithMatchesAndEventsInCurrentSeason(id).then(
        playersWithMatchesAndEventsInCurrentSeason =>
          dispatch(
            getPlayersWithMatchesAndEventsInCurrentSeasonSuccess(
              id,
              playersWithMatchesAndEventsInCurrentSeason
            )
          )
      );
    }
  };
}

export const getMatchesInCurrentSeasonSuccess = (id, matchesInCurrentSeason) => dispatch =>
  dispatch({
    type: actionTypes.STORE_TEAM_MATCHES_IN_CURRENT_SEASON,
    data: {
      id,
      matchesInCurrentSeason
    }
  });

export function getMatchesInCurrentSeason(id) {
  return (dispatch, getState) => {
    const { teams } = getState();

    if (ArrayUtils.isEmpty(teams[id]?.matchesInCurrentSeason)) {
      return TeamSrv.getMatchesInCurrentSeason(id).then(matchesInCurrentSeason =>
        dispatch(getMatchesInCurrentSeasonSuccess(id, matchesInCurrentSeason))
      );
    }
  };
}

// TODO: to remove?

export const getMatchesInSeasonSuccess = (teamId, seasonId, matches) => dispatch =>
  dispatch({
    type: actionTypes.STORE_TEAM_MATCHES_IN_SEASON,
    data: {
      teamId,
      seasonId,
      matches
    }
  });

export function getMatchesInSeason(teamId, seasonId) {
  return (dispatch, getState) => {
    const { teams } = getState();

    const foundedTeamSeasons = teams[teamId]?.seasons?.find(x => x.seasonId === seasonId);

    if (ArrayUtils.isEmpty(foundedTeamSeasons?.matches)) {
      return TeamSrv.getMatchesInSeason(teamId, seasonId).then(matches =>
        dispatch(getMatchesInSeasonSuccess(teamId, seasonId, matches))
      );
    }
  };
}

export const getCoachesInSeasonSuccess = (teamId, seasonId, coaches) => dispatch =>
  dispatch({
    type: actionTypes.STORE_TEAM_COACHES_IN_SEASON,
    data: {
      teamId,
      seasonId,
      coaches
    }
  });

export function getCoachesInSeason(teamId, seasonId) {
  return (dispatch, getState) => {
    const { teams } = getState();

    const foundedTeamSeasons = teams[teamId]?.seasons?.find(x => x.seasonId === seasonId);

    if (ArrayUtils.isEmpty(foundedTeamSeasons?.coaches)) {
      return TeamSrv.getCoachesInSeason(teamId, seasonId).then(coaches =>
        dispatch(getCoachesInSeasonSuccess(teamId, seasonId, coaches))
      );
    }
  };
}

export const getPlayersWithMatchesAndEventsInSeasonSuccess =
  (teamId, seasonId, playersWithMatchesAndEventsInSeason) => dispatch =>
    dispatch({
      type: actionTypes.STORE_TEAM_PLAYERS_WITH_MATCHES_AND_EVENTS_IN_SEASON,
      data: {
        teamId,
        seasonId,
        playersWithMatchesAndEventsInSeason
      }
    });

export function getPlayersWithMatchesAndEventsInSeason(teamId, seasonId) {
  return (dispatch, getState) => {
    const { teams } = getState();

    const foundedTeamSeasons = teams[teamId]?.seasons?.find(x => x.seasonId === seasonId);

    if (ObjectUtils.isEmpty(foundedTeamSeasons?.playersWithMatchesAndEvents)) {
      return TeamSrv.getPlayersWithMatchesAndEventsInSeason(teamId, seasonId).then(
        playersWithMatchesAndEventsInSeason =>
          dispatch(
            getPlayersWithMatchesAndEventsInSeasonSuccess(
              teamId,
              seasonId,
              playersWithMatchesAndEventsInSeason
            )
          )
      );
    }
  };
}

// -------------------------------------------- TO TEST -----------------------------------------

export const getPlayersInCurrentSeasonSuccess = (id, playersInCurrentSeason) => dispatch =>
  dispatch({
    type: actionTypes.STORE_TEAM_PLAYERS_IN_CURRENT_SEASON,
    data: {
      id,
      playersInCurrentSeason
    }
  });

export function getPlayersInCurrentSeason(id) {
  return (dispatch, getState) => {
    const { teams } = getState();

    if (ObjectUtils.isEmpty(teams[id]?.playersInCurrentSeason)) {
      return TeamSrv.getPlayersInCurrentSeason(id).then(
        playersWithMatchesAndEventsInCurrentSeason =>
          dispatch(
            getPlayersWithMatchesAndEventsInCurrentSeasonSuccess(
              id,
              playersWithMatchesAndEventsInCurrentSeason
            )
          )
      );
    }
  };
}

export const getPlayerMatchesAndEventsInCurrentSeasonSuccess =
  (teamId, playerId, matchesAndEvents) => dispatch =>
    dispatch({
      type: actionTypes.STORE_TEAM_PLAYER_MATCHES_AND_EVENTS_IN_CURRENT_SEASON,
      data: {
        teamId,
        playerId,
        matchesAndEvents
      }
    });

export function getPlayerMatchesAndEventsInCurrentSeason(teamId, playerId) {
  return (dispatch, getState) => {
    const { teams } = getState();

    const foundedTeamPlayer = teams[teamId]?.playersInCurrentSeason?.find(x => x.id === playerId);

    // if (ArrayUtils.isEmpty(foundedTeamPlayer?.matches)) {

    if (ObjectUtils.isEmpty(foundedTeamPlayer)) {
      // TODO: po czym sprawdzać czy wykonać call'a? ?.playersInCurrentSeason)) {
      return TeamSrv.getPlayerMatchesAndEventsInCurrentSeason(teamId, playerId).then(
        matchesAndEvents =>
          dispatch(
            getPlayersWithMatchesAndEventsInCurrentSeasonSuccess(teamId, playerId, matchesAndEvents)
          )
      );
    }
  };
}
