import React, { memo } from 'react';
import PropTypes from 'prop-types';

import SetNewPasswordForm from './SetNewPasswordForm';

const propTypes = {
  onSubmit: PropTypes.func.isRequired
};

const SetNewPasswordPage = ({ onSubmit }) => <SetNewPasswordForm onSubmit={onSubmit} />;

SetNewPasswordPage.propTypes = propTypes;

export default memo(SetNewPasswordPage);
