import React, { memo } from 'react';

import styles from './PageHeader.module.scss';

type Props = {
  title: string;
};

const PageHeader = ({ title }: Readonly<Props>) => <h2 className={styles.header}>{title}</h2>;

export default memo(PageHeader);
