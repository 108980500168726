import ArrayUtils from 'utils/array.utils';
import ObjectUtils from 'utils/object.utils';
import { getMyClubId } from 'utils/systemParameterProvider';

import {
  getBoardMembersSuccess,
  getContactSuccess,
  getDetailsSuccess,
  getHistorySuccess,
  getPartnersSuccess,
  getSponsorsSuccess,
  getStadiumDescriptionSuccess,
  getTeamsSuccess
} from './actions';
import ClubSrv from './service';

// TODO: sprawdzić czy zamiast zakomentowanej function działa const poniżej i jak jest z wydajnością, jeśli wszystko jest dobrze to przerobić analogicznie w całej aplikacji

// export function getDetailsForMyClub() {
//   return (dispatch, getState) => {
//     const { clubs } = getState();
//     // TODO: zastanowić się czy tutaj trzeba pobierać cały state
//     const myClubId = getMyClubId(getState());

//     if (myClubId && ObjectUtils.isEmpty(clubs[myClubId]?.details)) {
//       return ClubSrv.getDetailsForMyClub().then(details =>
//         dispatch(getDetailsSuccess(myClubId, details))
//       );
//     }
//   };
// }

export const getDetailsForMyClub = () => (dispatch, getState) => {
  const { clubs } = getState();
  // TODO: zastanowić się czy tutaj trzeba pobierać cały state
  const myClubId = getMyClubId(getState());

  if (myClubId && ObjectUtils.isEmpty(clubs[myClubId]?.details)) {
    return ClubSrv.getDetailsForMyClub().then(details =>
      dispatch(getDetailsSuccess(myClubId, details))
    );
  }
};

export function getContactForMyClub() {
  return (dispatch, getState) => {
    const state = getState();
    const myClubId = getMyClubId(state);

    if (myClubId && ObjectUtils.isEmpty(state.clubs[myClubId]?.contact)) {
      return ClubSrv.getContactForMyClub().then(contact =>
        dispatch(getContactSuccess(myClubId, contact))
      );
    }
  };
}

export function getTeamsForMyClub() {
  return (dispatch, getState) => {
    const state = getState();
    const myClubId = getMyClubId(state);

    if (myClubId && ArrayUtils.isEmpty(state.clubs[myClubId]?.teams)) {
      return ClubSrv.getTeamsForMyClub().then(teams => dispatch(getTeamsSuccess(myClubId, teams)));
    }
  };
}

export function getBoardMembersForMyClub() {
  return (dispatch, getState) => {
    const state = getState();
    const myClubId = getMyClubId(state);

    if (myClubId && ArrayUtils.isEmpty(state.clubs[myClubId]?.boardMembers)) {
      return ClubSrv.getBoardMembersForMyClub().then(boardMembers =>
        dispatch(getBoardMembersSuccess(myClubId, boardMembers))
      );
    }
  };
}

export function getPartnersForMyClub() {
  return (dispatch, getState) => {
    const state = getState();
    const myClubId = getMyClubId(state);

    if (myClubId && ArrayUtils.isEmpty(state.clubs[myClubId]?.partners)) {
      return ClubSrv.getPartnersForMyClub().then(partners =>
        dispatch(getPartnersSuccess(myClubId, partners))
      );
    }
  };
}

export function getSponsorsForMyClub() {
  return (dispatch, getState) => {
    const state = getState();
    const myClubId = getMyClubId(state);

    if (myClubId && ArrayUtils.isEmpty(state.clubs[myClubId]?.sponsors)) {
      return ClubSrv.getSponsorsForMyClub().then(sponsors => {
        dispatch(getSponsorsSuccess(myClubId, sponsors));
      });
    }
  };
}

export function getHistoryForMyClub() {
  return (dispatch, getState) => {
    const state = getState();
    const myClubId = getMyClubId(state);

    if (myClubId && ObjectUtils.isEmpty(state.clubs[myClubId]?.history)) {
      return ClubSrv.getHistoryForMyClub().then(history =>
        dispatch(getHistorySuccess(myClubId, history))
      );
    }
  };
}

export function getStadiumDescriptionForMyClub() {
  return (dispatch, getState) => {
    const state = getState();
    const myClubId = getMyClubId(state);

    if (myClubId && ObjectUtils.isEmpty(state.clubs[myClubId]?.stadiumDescription)) {
      return ClubSrv.getStadiumDescriptionForMyClub().then(stadiumDescription =>
        dispatch(getStadiumDescriptionSuccess(myClubId, stadiumDescription))
      );
    }
  };
}
