export function downloadFile(blob: Blob, fileName: string): void {
  // TODO: przetestować na IE10+
  // if (window.navigator.msSaveOrOpenBlob) {
  //   // IE10+
  //   window.navigator.msSaveOrOpenBlob(blob, fileName);
  // } else {
  const a = document.createElement('a');
  a.setAttribute('style', '"display: none;');
  const fileUrl = URL.createObjectURL(blob); // TODO: or with window prefix like: window.URL.createObjectURL(blob);?
  a.href = fileUrl;
  // TODO: to check if the same as below a.download =
  //a.setAttribute('download', fileName);
  a.download = fileName;
  // TODO: append or appendChild?
  // document.body.append(a);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(fileUrl);
  // TODO: or:
  // setTimeout(() => {
  //   document.body.removeChild(a);
  //   window.URL.revokeObjectURL(fileUrl);
  // }, 0);
  // }
}

export function downloadPdfFromBase64(base64String: string, fileName: string): void {
  downloadFileFromBase64(base64String, fileName, 'application/pdf');
}

export function downloadFileFromBase64(
  base64String: string,
  fileName: string,
  mimeType: string
): void {
  const blob = convertBase64ToBlob(base64String, mimeType);
  downloadFile(blob, fileName);
}

export function convertBase64ToByteArray(base64String: string): Uint8Array {
  const parts = base64String.split(';base64,');
  const contentType = parts[0].replace('data:', '');
  const base64 = parts[1];

  // const byteCharacters = Buffer.from(base64String, 'base64').toString();
  const byteCharacters = atob(base64);

  // const byteNumbers = new Array(byteCharacters.length);

  // for (let i = 0; i < byteCharacters.length; i++) {
  //   byteNumbers[i] = byteCharacters.charCodeAt(i);
  // }

  // const byteArray = new Uint8Array(byteNumbers);

  const byteArray = Uint8Array.from(byteCharacters, c => c.charCodeAt(0));

  return byteArray;
}

export function convertBase64ToBlob(base64String: string, mimeType: string): Blob {
  const byteArray = convertBase64ToByteArray(base64String);
  return convertByteArrayToBlob(byteArray, mimeType);
}

export function convertByteArrayToBlob(byteArray: Uint8Array, mimeType: string): Blob {
  return new Blob([byteArray], { type: mimeType });
}

export function openInNewTab(blob: Blob): void {
  const fileUrl = URL.createObjectURL(blob);
  window.open(fileUrl, '_blank', 'noreferrer');
}

export function openInNewTabFromBase64(base64String: string): void {
  const blob = convertBase64ToBlob(base64String, 'application/pdf');
  openInNewTab(blob);
}
