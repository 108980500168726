import actionTypes from './actionTypes';

const initialState = {
  systemParameters: [],
  youthTeams: []
};

export default function systemParametersPageReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.STORE_SYSTEM_PARAMETERS:
      return { systemParameters: [...action.data], youthTeams: [...state.youthTeams] };
    case actionTypes.STORE_YOUTH_TEAMS:
      return { systemParameters: [...state.systemParameters], youthTeams: [...action.data] };
    default:
      return state;
  }
}
