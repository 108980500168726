import React, { memo } from 'react';
import { useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'store';
import tableTypes from 'consts/tableTypes';
import { useDispatchOnMountWithLocationMultiple } from 'utils/customHooks';

import {
  getCompetitionsInfo,
  getCompetitionsTableAutumnRoundAsGuest,
  getCompetitionsTableAutumnRoundAsHost,
  getCompetitionsTableAutumnRoundFull,
  getCompetitionsTableSeasonAsGuest,
  getCompetitionsTableSeasonAsHost,
  getCompetitionsTableSeasonFull,
  getCompetitionsTableSpringRoundAsGuest,
  getCompetitionsTableSpringRoundAsHost,
  getCompetitionsTableSpringRoundFull
} from '../actions';
import TablePage from './TablePage';

const TablePageContainer = props => {
  const dispatch = useAppDispatch();

  const { leagueId, seasonId } = useParams();

  const competitions = useAppSelector(state =>
    state.competitions?.find(x => x.leagueId === leagueId && x.seasonId === seasonId)
  );

  const leagueName = competitions?.leagueName;
  const seasonName = competitions?.seasonName;

  const tableSeasonFull = competitions?.table?.season?.full;
  const tableSeasonAsHost = competitions?.table?.season?.asHost;
  const tableSeasonAsGuest = competitions?.table?.season?.asGuest;

  const tableAutumnRoundFull = competitions?.table?.autumnRound?.full;
  const tableAutumnRoundAsHost = competitions?.table?.autumnRound?.asHost;
  const tableAutumnRoundAsGuest = competitions?.table?.autumnRound?.asGuest;

  const tableSpringRoundFull = competitions?.table?.springRound?.full;
  const tableSpringRoundAsHost = competitions?.table?.springRound?.asHost;
  const tableSpringRoundAsGuest = competitions?.table?.springRound?.asGuest;

  const handleGetTableData = tableType => {
    switch (tableType) {
      case tableTypes.tableSeasonFull: {
        dispatch(getCompetitionsTableSeasonFull(leagueId, seasonId));
        break;
      }
      case tableTypes.tableSeasonAsHost: {
        dispatch(getCompetitionsTableSeasonAsHost(leagueId, seasonId));
        break;
      }
      case tableTypes.tableSeasonAsGuest: {
        dispatch(getCompetitionsTableSeasonAsGuest(leagueId, seasonId));
        break;
      }
      case tableTypes.tableAutumnRoundFull: {
        dispatch(getCompetitionsTableAutumnRoundFull(leagueId, seasonId));
        break;
      }
      case tableTypes.tableAutumnRoundAsHost: {
        dispatch(getCompetitionsTableAutumnRoundAsHost(leagueId, seasonId));
        break;
      }
      case tableTypes.tableAutumnRoundAsGuest: {
        dispatch(getCompetitionsTableAutumnRoundAsGuest(leagueId, seasonId));
        break;
      }
      case tableTypes.tableSpringRoundFull: {
        dispatch(getCompetitionsTableSpringRoundFull(leagueId, seasonId));
        break;
      }
      case tableTypes.tableSpringRoundAsHost: {
        dispatch(getCompetitionsTableSpringRoundAsHost(leagueId, seasonId));
        break;
      }
      case tableTypes.tableSpringRoundAsGuest: {
        dispatch(getCompetitionsTableSpringRoundAsGuest(leagueId, seasonId));
        break;
      }

      default:
        break;
    }
  };

  useDispatchOnMountWithLocationMultiple([
    getCompetitionsInfo(leagueId, seasonId),
    getCompetitionsTableSeasonFull(leagueId, seasonId)
  ]);

  return (
    <TablePage
      {...props}
      leagueName={leagueName}
      seasonName={seasonName}
      tableSeasonFull={tableSeasonFull}
      tableSeasonAsHost={tableSeasonAsHost}
      tableSeasonAsGuest={tableSeasonAsGuest}
      tableAutumnRoundFull={tableAutumnRoundFull}
      tableAutumnRoundAsHost={tableAutumnRoundAsHost}
      tableAutumnRoundAsGuest={tableAutumnRoundAsGuest}
      tableSpringRoundFull={tableSpringRoundFull}
      tableSpringRoundAsHost={tableSpringRoundAsHost}
      tableSpringRoundAsGuest={tableSpringRoundAsGuest}
      onGetTableData={handleGetTableData}
    />
  );
};

export default memo(TablePageContainer);
