import axios from 'config/axios';

const prefix = 'client/club';

function getUrlWithId(id, suffix) {
  return axios.get(`${prefix}/${id}/${suffix}`).then(result => result.data);
}

function getUrl(suffix) {
  return axios.get(`${prefix}/${suffix}`).then(result => result.data);
}

export default class ClubSrv {
  static getDetailsForMyClub() {
    return axios.get(`${prefix}/details`).then(result => result.data);
  }

  static getDetails(id) {
    return axios.get(`${prefix}/${id}/details`).then(result => result.data);
  }

  static getContactForMyClub() {
    return axios.get(`${prefix}/contact`).then(result => result.data);
  }

  static getContact(id) {
    return axios.get(`${prefix}/${id}/contact`).then(result => result.data);
  }

  static getTeamsForMyClub() {
    return axios.get(`${prefix}/teams`).then(result => result.data);
  }

  static getTeams(id) {
    return axios.get(`${prefix}/${id}/teams`).then(result => result.data);
  }

  static getBoardMembersForMyClub() {
    // return axios.get(`${prefix}/board-members`).then(result => result.data);
    return getUrl('board-members'); // TODO: czy trzeba return? jeśli działa to w innych metodach też podmienić
  }

  static getBoardMembers(id) {
    // return axios.get(`${prefix}/${id}/board-members`).then(result => result.data);
    return getUrlWithId(id, 'board-members'); // TODO: czy trzeba return? jeśli działa to w innych metodach też podmienić
  }

  static getPartnersForMyClub() {
    return axios.get(`${prefix}/partners`).then(result => result.data);
  }

  static getPartners(id) {
    return axios.get(`${prefix}/${id}/partners`).then(result => result.data);
  }

  static getSponsorsForMyClub() {
    return axios.get(`${prefix}/sponsors`).then(result => result.data);
  }

  static getSponsors(id) {
    return axios.get(`${prefix}/${id}/sponsors`).then(result => result.data);
  }

  static getHistoryForMyClub() {
    return axios.get(`${prefix}/history`).then(result => result.data);
  }

  static getHistory(id) {
    return axios.get(`${prefix}/${id}/history`).then(result => result.data);
  }

  static getStadiumDescriptionForMyClub() {
    return axios.get(`${prefix}/stadium-description`).then(result => result.data);
  }

  static getStadiumDescription(id) {
    return axios.get(`${prefix}/${id}/stadium-description`).then(result => result.data);
  }
}
