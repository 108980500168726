import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { icons } from 'consts/icons';
import urls from 'consts/urls';
import { useIsDesktop } from 'utils/customHooks';
import Column from 'components/Column';
import IconButton from 'components/IconButton';
import Row from 'components/Row';

import { ICoachSeasonModel } from '../data/models';
import styles from './CoachSeasons.module.scss';
import LeaguesRows from './LeaguesRows';

type Props = {
  coachId: string;
  seasons?: ICoachSeasonModel[] | null;
  isSelectable?: boolean;
  onSetSelectedSeasonId: (seasonId: string) => void;
};

const CoachSeasons = ({
  coachId,
  seasons,
  isSelectable,
  onSetSelectedSeasonId
}: Readonly<Props>) => {
  const { t } = useTranslation();
  const isDesktop = useIsDesktop();

  const [selectedSeasonId, setSelectedSeasonId] = useState<string | null>(null);

  const rowClassName = cx(styles.row, {
    [styles.row_mobile]: !isDesktop,
    [styles.row_selectable]: isSelectable
  });

  const selectedRowClassName = cx(rowClassName, styles.row_selected);

  const seasonNameColumnClassName = cx(styles.row_seasonName_column, {
    [styles.row_seasonName_column_mobile]: !isDesktop
  });

  const leaguesColumnClassName = cx(styles.row_leagues_column, {
    [styles.row_leagues_column_mobile]: !isDesktop
  });

  const buttonColumnClassName = cx(styles.row_button_column, {
    [styles.row_button_column_mobile]: !isDesktop
  });

  const competitionsLinks = seasons?.map(season => (
    <Row
      key={season.seasonId}
      className={`${selectedSeasonId === season.seasonId ? selectedRowClassName : rowClassName}`}
      onClick={() => {
        if (isSelectable) {
          setSelectedSeasonId(season.seasonId);
          onSetSelectedSeasonId(season.seasonId);
        }
      }}
    >
      <Column className={seasonNameColumnClassName} width={isDesktop ? '6%' : '100%'}>
        {season.seasonName}
      </Column>

      <Column className={leaguesColumnClassName} width={isDesktop ? '75%' : '100%'}>
        <LeaguesRows season={season}></LeaguesRows>
      </Column>

      <Column className={buttonColumnClassName} width={isDesktop ? '17%' : '100%'}>
        <IconButton
          className={styles.details_button}
          icon={icons.arrowRight}
          title={t('CoachSeasonStatistics')}
          to={urls.coachSeasonStatistics(coachId, season.seasonId)}
        >
          {t('CoachSeasonStatistics')}
        </IconButton>
      </Column>
    </Row>
  ));

  return competitionsLinks;
};

export default memo(CoachSeasons);
