import React, { memo } from 'react';
import styled from 'styled-components';

import DateInfo from './DateInfo';
import TimeInfo from './TimeInfo';

const Container = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

type Props = {
  date?: Date;
  withoutIcon?: boolean;
  withDayName?: boolean;
  shouldWrapDayNameToNextLine?: boolean;
};

const DateTimeInfo = ({
  date,
  withoutIcon,
  withDayName,
  shouldWrapDayNameToNextLine
}: Readonly<Props>) => (
  <Container>
    <DateInfo
      date={date}
      withoutIcon={withoutIcon}
      withDayName={withDayName}
      shouldWrapDayNameToNextLine={shouldWrapDayNameToNextLine}
    />
    <TimeInfo date={date} withoutIcon={withoutIcon} />
  </Container>
);

export default memo(DateTimeInfo);
