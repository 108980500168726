import React, { memo } from 'react';

import { useAppSelector } from 'store';
import { useDispatchOnMount } from 'utils/customHooks';
import { useGetMyClubId } from 'utils/systemParameterProvider';

import { getContactForMyClub } from '../my-club-actions';
import ContactPage from './ContactPage';

const ContactPageContainer = props => {
  const myClubId = useGetMyClubId();

  const contact = useAppSelector(state => state.clubs[myClubId]?.contact);

  useDispatchOnMount(getContactForMyClub());

  return <ContactPage {...props} contact={contact} />;
};

export default memo(ContactPageContainer);
