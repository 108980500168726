import PropTypes from 'prop-types';

import { personWithIdAndPhotoPropTypes } from './person';

export const playerInSquadPropTypes = {
  ...personWithIdAndPhotoPropTypes,
  shirtNumber: PropTypes.number,
  isCaptain: PropTypes.bool,
  isGoalkeeper: PropTypes.bool,
  isJunior: PropTypes.bool
};

export const playerInSquadPropTypesShape = PropTypes.shape({
  ...playerInSquadPropTypes
});
