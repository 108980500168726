import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import groupBy from 'lodash/groupBy';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { matchPropTypesShape, playerMatchPropTypesShape } from 'propTypes';
import urls from 'consts/urls';
// TODO: komponent nie może korzystać z modules, sprawdzić w całej aplikacji
import Column from 'components/Column';
// TODO: komponent nie może korzystać z modules, sprawdzić w całej aplikacji
// TODO: komponent nie może korzystać z modules, sprawdzić w całej aplikacji
import ContentRevealer from 'components/ContentRevealer';
import DateInfo from 'components/DateInfo';
import PlayerLink from 'components/Links/PlayerLink';
import TeamLink from 'components/Links/TeamLink';
import MatchStatus from 'components/MatchStatus';
import TimeInfo from 'components/TimeInfo';

import styles from './TeamPlayersMinutes.module.scss';

const propTypes = {
  matches: PropTypes.arrayOf(matchPropTypesShape),
  playersWithMatchesAndEvents: PropTypes.arrayOf(playerMatchPropTypesShape)
};

const TeamPlayersMinutes = ({ matches, playersWithMatchesAndEvents }) => {
  const { t } = useTranslation();

  const monthsDictionary = groupBy(matches, ({ date }) => new Date(date).getMonth());

  const monthNameTranslationKeys = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  let monthColumns = [];
  let matchesInMonthsColumns = [];

  let monthsWithMatches = [];

  for (const monthIndex in monthsDictionary) {
    const matches = monthsDictionary[monthIndex];

    monthsWithMatches.push({ month: monthIndex, matches });

    monthColumns.push(
      <th key={monthIndex} colSpan={matches?.length + 1}>
        {t(monthNameTranslationKeys[monthIndex])}
      </th>
    );

    for (const match of matches) {
      matchesInMonthsColumns.push(
        <th key={match.id} className={styles.table_header}>
          <Column>
            {/* TODO: Warning: validateDOMNesting(...): <a> cannot appear as a descendant of <a>. */}
            <Link to={urls.matchDetails(match.id)}>
              <div className={styles.table_header_dateAndTime}>
                <DateInfo date={match.date} withoutIcon />
                <TimeInfo date={match.date} withoutIcon />
              </div>
              <TeamLink {...match.host} />
              <TeamLink {...match.guest} />
              <div className={styles.table_header_resultAndStatus}>
                {match.hostGoals} - {match.guestGoals}
                <MatchStatus status={match.status} />
              </div>
            </Link>
          </Column>
        </th>
      );
    }

    matchesInMonthsColumns.push(<th>{t('Sum')}</th>);
  }

  let playersRows = [];

  for (const player of window
    .structuredClone(playersWithMatchesAndEvents)
    .sort((a, b) => a.lastName.localeCompare(b.lastName))) {
    for (const match of matches) {
      const playerMatch = player.matches.find(x => x.id === match.id);

      if (!playerMatch) {
        player.matches.push(match);
      }
    }

    let playersMinutesColumns = [];

    const minutesInAllMatchesCount = player.matches
      .map(x => x.minutesCount || 0)
      .reduce((partialSum, a) => partialSum + a, 0);

    playersMinutesColumns.push(
      <td className={styles.table_playerRow_minutesSum}>{minutesInAllMatchesCount}</td>
    );

    for (const matchesInMonth of monthsWithMatches) {
      for (const match of matchesInMonth.matches) {
        const playerMatch = player.matches.find(x => x.id === match.id);

        const minutesCount = playerMatch ? playerMatch.minutesCount : 0;

        playersMinutesColumns.push(<td>{minutesCount}</td>);
      }

      const playerMatchesInMonth = player.matches.filter(x =>
        matchesInMonth.matches.map(y => y.id).includes(x.id)
      );

      const minutesInMonthCount = playerMatchesInMonth
        .map(x => x.minutesCount || 0)
        .reduce((partialSum, a) => partialSum + a, 0);

      playersMinutesColumns.push(
        <td className={styles.table_playerRow_minutesSum}>{minutesInMonthCount}</td>
      );
    }

    playersRows.push(
      <tr key={player.id} className={styles.table_playerRow}>
        <td className={styles.table_playerRow_playerColumn}>
          <PlayerLink {...player} className={styles.table_playerRow_playerColumn_player} />
        </td>

        {playersMinutesColumns}
      </tr>
    );
  }

  const stickyHeaderAndFirstColumnTableClassName = cx(styles.table, 'table--sticky');

  // TODO:

  // const monthsDropdownOptions = months.map(month => ({
  //   ...month,
  //   optionName: `${month.yearFrom}/${month.yearTo}`
  // }));

  // const maxIndex = months.length - 1;

  // const [monthToShowIndex, setMonthToShowIndex] = useState(maxIndex);
  // const [monthToShow, setMonthToShow] = useState(months[monthToShowIndex]);

  return (
    <ContentRevealer isVisible={true} className={styles.table_container}>
      {/* // TODO: maybe only select/dropdown without arrows? */}
      {/* <CarouselHeader
        items={monthsDropdownOptions}
        maxIndex={maxIndex}
        itemToShowIndex={monthToShowIndex}
        setItemToShow={setMonthToShow}
        setItemToShowIndex={setMonthToShowIndex}
      /> */}

      <table className={stickyHeaderAndFirstColumnTableClassName}>
        <thead>
          <tr>
            <th rowSpan={2} className={styles.table_header} />
            <th rowSpan={2}>{t('Sum')}</th>
            {monthColumns}
          </tr>

          <tr>{matchesInMonthsColumns}</tr>
        </thead>

        <tbody>{playersRows}</tbody>
      </table>
    </ContentRevealer>
  );
};

TeamPlayersMinutes.propTypes = propTypes;

export default memo(TeamPlayersMinutes);
