import { memo } from 'react';
import styled from 'styled-components';

const StyledContentWrapper = styled.main`
  padding-top: 3rem;
  min-height: calc(100vh - ${({ theme }) => theme.minNavbarHeight});
  background-color: ${({ theme }) => theme.light};
  box-shadow: 0px 4px 35px rgba(89, 89, 89, 0.35);
  border-radius: 0 0 7px 7px;
  overflow: hidden;
`;

export default memo(StyledContentWrapper);
