import React, { memo, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import cx from 'classnames';

import { useAppSelector } from 'store';
import { icons } from 'consts/icons';
import { isExactCurrentSubRoute } from 'utils/utils';

import styles from './MobileMenuLink.module.scss';
import MobileSubmenu from './MobileSubmenu';

const propTypes = {
  address: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  subLinks: PropTypes.arrayOf(PropTypes.object),
  external: PropTypes.bool
};

const MobileMenuLink = ({ address, label, subLinks, external, ...others }) => {
  const activeLinkUrl = useAppSelector(state => get(state, 'navbar.activeLinkUrl'));
  const [visibleSubmenu, setVisibleSubmenu] = useState(false);
  const [isAnySubLinkActive, setIsAnySubLinkActive] = useState(false);

  const checkIsCurrentSubLinkRoute = (links = subLinks) => {
    if (!links) return false;

    return links.some(link => {
      // Check if current link is active
      if (isExactCurrentSubRoute(activeLinkUrl, link.address)) {
        return true;
      }
      // Recursively check nested subLinks
      if (link.subLinks) {
        return checkIsCurrentSubLinkRoute(link.subLinks);
      }
      return false;
    });
  };

  const linkClassName = cx(styles.link, styles.subLink, {
    [styles.subLink_opened]: visibleSubmenu,
    [styles.link_active]: isAnySubLinkActive && !!activeLinkUrl
  });

  const activeLinkClassName = cx(styles.link_active);

  const iconClassName = cx(
    styles.subLink_icon,
    visibleSubmenu ? icons.chevronUp : icons.chevronDown
  );

  useEffect(() => {
    if (activeLinkUrl) {
      const isCurrentSubLinkRoute = checkIsCurrentSubLinkRoute();
      setIsAnySubLinkActive(isCurrentSubLinkRoute);
      if (isCurrentSubLinkRoute) {
        setVisibleSubmenu(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLinkUrl]);

  const handleClick = e => {
    if (subLinks) {
      e.preventDefault();
      e.stopPropagation();
      setVisibleSubmenu(prev => !prev);
    }
  };

  return (
    <li>
      {!external ? (
        <div className={linkClassName} onClick={handleClick} role="button" tabIndex={0}>
          {subLinks ? (
            <>
              {label}
              <i className={iconClassName} />
            </>
          ) : (
            <NavLink
              {...others}
              to={address}
              title={label}
              className={({ isActive }) => (isActive ? ` ${activeLinkClassName}` : '')}
            >
              {label}
            </NavLink>
          )}
        </div>
      ) : (
        <a target="_blank" rel="noreferrer" href={address} title={label} className={linkClassName}>
          {label}
        </a>
      )}
      {subLinks && visibleSubmenu && <MobileSubmenu subLinksData={subLinks} />}
    </li>
  );
};

MobileMenuLink.propTypes = propTypes;

export default memo(MobileMenuLink);
