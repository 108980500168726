import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { personWithIdAndPhotoPropTypes } from 'propTypes';
import urls from 'consts/urls';

import Avatar from '../Avatar';
import Link from './Link';
import styles from './PlayerLink.module.scss';

const propTypes = {
  ...personWithIdAndPhotoPropTypes,
  className: PropTypes.string,
  reverse: PropTypes.bool
};

const PlayerLink = ({ id, photo, firstName, lastName, className, reverse }) => {
  const { t } = useTranslation();

  const linkClassName = cx(styles.player_link, className, {
    [styles.player_link_reverse]: reverse
  });

  return (
    <Link
      to={urls.playerDetails(id)}
      className={linkClassName}
      title={`${t('GoToThePlayerSubpage')} ${firstName} ${lastName}`}
    >
      <Avatar src={photo} className={styles.player_logo} />
      <span className={styles.player_name}>
        {firstName} {lastName}
      </span>
    </Link>
  );
};

PlayerLink.propTypes = propTypes;

export default memo(PlayerLink);
