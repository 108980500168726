import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import Column from '../Column';

import styles from './CircleInformation.module.scss';

type Props = {
  icon: string;
  messageTranslationKey: string;
};

const CircleInformation = ({ icon, messageTranslationKey }: Readonly<Props>) => {
  const { t } = useTranslation();

  const iconClassName = cx(icon, styles.icon);

  return (
    <Column className={styles.container}>
      <span className={styles.circle} />
      <span className={styles.circle} />
      <span className={styles.circle} />
      <Column className={styles.content}>
        <i className={iconClassName} />
        <div className={styles.text}>{t(messageTranslationKey)}</div>
      </Column>
    </Column>
  );
};

export default memo(CircleInformation);
