import axios from 'config/axios';

import { EFileIds } from 'consts/fileIds';
import { IFileResponse } from './models/fileResponse.models';

const prefix = 'client/file';

export default class FileApi {
  static async getFile(id: EFileIds): Promise<any> {
    const result = await axios.get<IFileResponse>(`${prefix}/${id}`);
    return result.data;
  }
}
