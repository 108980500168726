const formNames = {
  loginForm: 'loginForm',
  registerForm: 'registerForm',
  resetPasswordForm: 'resetPasswordForm',
  setNewPasswordForm: 'setNewPasswordForm',
  userInformationForm: 'userInformationForm',
  myProfileForm: 'myProfileForm',
  userPasswordChangeForm: 'userPasswordChangeForm'
};

export default formNames;
