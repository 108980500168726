import React, { memo } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';

import { competitionsSchedulePropTypes, tablePropTypesShape } from 'propTypes';
import Accordion from 'components/Accordion';
import InnerContentWrapper from 'components/InnerContentWrapper';
import LoadingPageData from 'components/LoadingPageData';
import PageHeader from 'components/PageHeader';

import CompetitionsSchedule from '../competitions-schedule/CompetitionsSchedule';
import Tables from '../table/Tables';

const propTypes = {
  leagueName: PropTypes.string,
  seasonName: PropTypes.string,
  tableSeasonFull: tablePropTypesShape,
  tableSeasonAsHost: tablePropTypesShape,
  tableSeasonAsGuest: tablePropTypesShape,
  tableAutumnRoundFull: tablePropTypesShape,
  tableAutumnRoundAsHost: tablePropTypesShape,
  tableAutumnRoundAsGuest: tablePropTypesShape,
  tableSpringRoundFull: tablePropTypesShape,
  tableSpringRoundAsHost: tablePropTypesShape,
  tableSpringRoundAsGuest: tablePropTypesShape,
  competitionsSchedule: competitionsSchedulePropTypes,
  teamIdContext: PropTypes.string,
  onGetTableData: PropTypes.func
};

const CompetitionsDetailsPage = ({
  leagueName,
  seasonName,
  tableSeasonFull,
  tableSeasonAsHost,
  tableSeasonAsGuest,
  tableAutumnRoundFull,
  tableAutumnRoundAsHost,
  tableAutumnRoundAsGuest,
  tableSpringRoundFull,
  tableSpringRoundAsHost,
  tableSpringRoundAsGuest,
  competitionsSchedule,
  teamIdContext,
  onGetTableData
}) => {
  const { t } = useTranslation();

  return (
    <InnerContentWrapper>
      {!isEmpty(leagueName) && !isEmpty(seasonName) ? (
        <PageHeader title={`${leagueName} ${seasonName}`} />
      ) : (
        <LoadingPageData />
      )}

      {!isEmpty(tableSeasonFull) ? (
        <Accordion title={t('Table')}>
          <Tables
            tableSeasonFull={tableSeasonFull}
            tableSeasonAsHost={tableSeasonAsHost}
            tableSeasonAsGuest={tableSeasonAsGuest}
            tableAutumnRoundFull={tableAutumnRoundFull}
            tableAutumnRoundAsHost={tableAutumnRoundAsHost}
            tableAutumnRoundAsGuest={tableAutumnRoundAsGuest}
            tableSpringRoundFull={tableSpringRoundFull}
            tableSpringRoundAsHost={tableSpringRoundAsHost}
            tableSpringRoundAsGuest={tableSpringRoundAsGuest}
            teamIdContext={teamIdContext}
            onGetTableData={onGetTableData}
          />
        </Accordion>
      ) : (
        <LoadingPageData />
      )}

      {/* // TODO: jeśli teamIdContext to może pobrać też statusy meczy dla drużyny? */}
      {!isEmpty(competitionsSchedule) ? (
        <CompetitionsSchedule
          competitionsSchedule={competitionsSchedule}
          teamIdContext={teamIdContext}
        />
      ) : (
        <LoadingPageData />
      )}
    </InnerContentWrapper>
  );
};

CompetitionsDetailsPage.propTypes = propTypes;

export default memo(CompetitionsDetailsPage);
