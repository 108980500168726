import { getText } from 'utils/getText';

export function formatNumberLength(number: number, length: number): string {
  let formattedNumber = `${number}`;

  while (formattedNumber.length < length) {
    formattedNumber = `0${formattedNumber}`;
  }

  return formattedNumber;
}

// TODO: sprawdzić czy apartmentNumber jest jeśli tak to powinno być npm 24f/5, a jeśli nie to bez '/5'
export function asAddress(address: {
  city: string;
  street: string;
  buildingNumber: number | string;
  apartmentNumber: number | string;
  zipCode: string;
  postOffice: string;
}): string {
  return `${getText(address.city)} ${
    address.street ? /* // TODO: lang: t('StreetAbbreviation() */ 'ul.' : ''
  } ${getText(address.street)} ${getText(address.buildingNumber)} ${getText(
    address.apartmentNumber
  )} ${getText(address.zipCode)} ${getText(address.postOffice)}`;
}
