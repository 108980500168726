import { KeyValuePair } from 'models/common.models';
import { EFileIds } from 'consts/fileIds';

import { FilesState, IFileStateModel } from './models';

export function getExistingFile(
  state: FilesState,
  id: EFileIds
): IFileStateModel | undefined | null {
  return state[id];
}

export function changeFileValuesOrAddNewFile(
  id: EFileIds,
  state: FilesState,
  keyValues: KeyValuePair[]
): void {
  const existingFile = getExistingFile(state, id);

  if (existingFile) {
    for (const keyValue of keyValues) {
      existingFile[keyValue.key] = keyValue.value;
    }
  } else {
    state[id] = {} as IFileStateModel;

    for (const keyValue of keyValues) {
      state[id][keyValue.key] = keyValue.value;
    }
  }
}
