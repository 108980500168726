// code form: https://github.com/fitzmode/use-dynamic-refs/blob/master/src/index.tsx

import * as React from 'react';

const map = new Map<string, React.RefObject<unknown>>();

function setRef<T>(key: string): React.RefObject<T> | void {
  if (!key) {
    return;
  }

  const ref = React.createRef<T>();
  map.set(key, ref);

  return ref;
}

function getRef<T>(key: string): React.RefObject<T> | undefined | void {
  if (!key) {
    return;
  }

  return map.get(key) as React.RefObject<T>;
}

export function useDynamicRefs<T>(): [
  (key: string) => void | React.RefObject<T>,
  (key: string) => void | React.RefObject<T>
] {
  return [getRef, setRef];
}
