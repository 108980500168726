import styled, { css } from 'styled-components';

import Flex from './Flex';

type Props = { width?: string };

const Column = styled(Flex)<Props>`
  flex-direction: column;

  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}
`;

export default Column;
