import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import Column from './Column';
import Loader from './Loader';
import styles from './LoadingPageData.module.scss';

// TODO: Vorbert -> przerobić na styled-components
const LoadingPageData = () => {
  const { t } = useTranslation();

  return (
    <Column className={styles.loader_container}>
      <div className={styles.loader}>
        <Loader />
      </div>
      <p className={styles.loader_description}>{`${t('LoadingData')}...`}</p>
    </Column>
  );
};

export default memo(LoadingPageData);
