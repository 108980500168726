import React, { memo } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import cx from 'classnames';

import { getArrayWithLength } from 'utils/utils';

import Article from './Article';
import ArticlePlaceholder from './ArticlePlaceholder';
import styles from './ArticlesList.module.scss';

const propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      photo: PropTypes.string,
      title: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
      createdDate: PropTypes.string.isRequired,
      author: PropTypes.string.isRequired
    })
  ),
  centerItems: PropTypes.bool
};

const ArticlesList = ({ articles = [], centerItems }) => {
  const articleContainerClassName = cx(styles.article_container, {
    'align-items-center': centerItems
  });

  return (
    <div className={articleContainerClassName}>
      {!isEmpty(articles)
        ? articles.map((article, index) => <Article key={index} {...article} vertical />)
        : getArrayWithLength(3).map((x, index) => <ArticlePlaceholder vertical key={index} />)}
    </div>
  );
};

ArticlesList.propTypes = propTypes;

export default memo(ArticlesList);
