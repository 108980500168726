import React from 'react';

import Skeleton from 'components/Skeleton';

import Avatar from './Avatar';
import styles from './UserCardAvatar.module.scss';

type Props = {
  src?: string | null;
  isLoading?: boolean;
};

const UserCardAvatar = ({ src, isLoading }: Readonly<Props>) => {
  return (
    <div className={styles.avatar_container}>
      {isLoading ? (
        <Skeleton isCircle width="9rem" height="9rem" />
      ) : (
        <Avatar className={styles.avatar} src={src} />
      )}
    </div>
  );
};

export default UserCardAvatar;
