import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { StyledButton, StyledIcon } from './IconButton.styles';

const propTypes = {
  children: PropTypes.any.isRequired,
  icon: PropTypes.string.isRequired
};

const IconButton = ({ children, icon, ...passedProps }) => (
  <StyledButton {...passedProps}>
    {children}
    <StyledIcon icon={icon} />
  </StyledButton>
);

IconButton.propTypes = propTypes;

export default memo(IconButton);
