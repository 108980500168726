import PropTypes from 'prop-types';

import { playerPropTypes } from './player';

export const changePropTypes = {
  minute: PropTypes.number,
  isEnter: PropTypes.bool,
  substitutePlayer: PropTypes.shape({ ...playerPropTypes })
};

export const changePropTypesShape = PropTypes.shape({ ...changePropTypes });
