import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { icons } from 'consts/icons';
import urls from 'consts/urls';
import { toYearFormat } from 'utils/dateFormat';
import { asAddress } from 'utils/format';
import Card from 'components/Card';
import IconButton from 'components/IconButton';
import SingleInformation from 'components/SingleInformation';

import styles from './TeamDetailsCard.module.scss';

const propTypes = {
  logo: PropTypes.string,
  groupPhoto: PropTypes.string,
  name: PropTypes.string,
  creationDate: PropTypes.string,
  address: PropTypes.shape({
    city: PropTypes.string,
    street: PropTypes.string,
    buildingNumber: PropTypes.string,
    apartmentNumber: PropTypes.string,
    zipCode: PropTypes.string,
    postOffice: PropTypes.string
  }),
  email: PropTypes.string,
  websiteUrl: PropTypes.string,
  ageGroupName: PropTypes.string,
  clubId: PropTypes.string
};

const TeamDetailsCard = ({
  logo,
  groupPhoto,
  name,
  creationDate,
  address,
  email,
  websiteUrl,
  ageGroupName,
  clubId
}) => {
  const { t } = useTranslation();

  const getFormattedDate = () => `${toYearFormat(creationDate)}r.`;

  const noLogoIconClassName = cx(icons.image, styles.team_noLogoIcon);

  return (
    <Card className={styles.team_card}>
      {groupPhoto && (
        <div
          className={styles.team_photo}
          style={{
            backgroundImage: ` linear-gradient(0deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), url(${groupPhoto})`
          }}
        />
      )}

      {logo ? (
        <img className={styles.team_logo} src={logo} alt={t('ClubLogo')} />
      ) : (
        <i className={noLogoIconClassName} />
      )}

      <h2 className={styles.team_name}>{name}</h2>
      {ageGroupName && <h4 className={styles.team_ageGroupName}>{ageGroupName}</h4>}
      {/* TODO: przenieść te dane do clubDetails albo dodać tutaj jakiś nagłówek/sekcję typu dane klubu czy coś */}
      {address?.city && <SingleInformation title={t('Address')} value={asAddress(address)} />}
      {email && <SingleInformation title={t('EmailAddress')} value={email} />}
      {creationDate && <SingleInformation title={t('CreationDate')} value={getFormattedDate()} />}

      <IconButton
        className={styles.team_button}
        icon={icons.arrowRight}
        title={t('AboutClub')}
        to={urls.clubDetails(clubId)}
      >
        {t('AboutClub')}
      </IconButton>

      {websiteUrl && (
        <IconButton
          className={styles.team_button}
          icon={icons.arrowRight}
          to={websiteUrl}
          title={`${t('VisitTheClubSite')}: ${name}`}
          target="_blank"
        >
          {t('VisitTheClubSite')}
        </IconButton>
      )}
    </Card>
  );
};

TeamDetailsCard.propTypes = propTypes;

export default memo(TeamDetailsCard);
