import React from 'react';
import { useTranslation } from 'react-i18next';

import SingleInformation from 'components/SingleInformation';

import styles from './SupportInformation.module.scss';

type Props = {
  krs: string;
};

const SupportInformation = ({ krs }: Readonly<Props>) => {
  const { t } = useTranslation();

  return (
    <div className={styles.information}>
      {`${t('ContactSupportInformation')}: `}

      <span className={styles.information_clickable}>
        <SingleInformation title="KRS" value={krs} isCopyEnabled isBiggerText />
      </span>
    </div>
  );
};

export default SupportInformation;
