import actionTypes from './actionTypes';

const initialState = {};

export default function galleryPageReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.STORE_ALBUM_DATA:
      const { id: albumId, ...album } = action.data;
      return {
        ...state,
        [albumId]: album
      };
    default:
      return state;
  }
}
