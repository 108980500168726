import React, { memo, useEffect, useState } from 'react';
import cx from 'classnames';

import cardTypes from 'consts/cardTypes';
import { ESortDirections } from 'consts/ESortDirections';
import ArrayUtils from 'utils/array.utils';
import {
  getCurrentSortingDirection,
  sortNumericValues,
  sortTextValues
} from 'utils/sortingMethods';
import ContentRevealer from 'components/ContentRevealer';

import { columnsToDisplay } from './table.consts';
import TableHeader from './TableHeader';
import TableRow from './TableRow';
import styles from './TeamPlayersStatistics.module.scss';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  playersWithMatchesAndEvents: any[]; // TODO: type
};

const TeamPlayersStatistics = ({ playersWithMatchesAndEvents }: Readonly<Props>) => {
  const [hoveredColumnIndex, setHoveredColumnIndex] = useState<number | null>(null);

  const [sortDirection, setSortDirection] = useState<ESortDirections | null>(null);
  const [sortedColumnIndex, setSortedColumnIndex] = useState<number>(0);

  const getPlayersWithStatistics = () =>
    playersWithMatchesAndEvents.map(player => {
      const getSum = predicate =>
        player.matches.map(predicate).reduce((partialSum, a) => partialSum + a, 0);

      return {
        ...player,
        fullName: `${player.firstName} ${player.lastName}`,
        minutesInAllMatchesCount: getSum(x => x.minutesCount || 0),
        goalsInAllMatchesCount: getSum(x => x.goals?.filter(y => !y.isOwn).length || 0),
        ownGoalsInAllMatchesCount: getSum(x => x.goals?.filter(y => y.isOwn).length || 0),
        assistsInAllMatchesCount: getSum(x => x.assists?.length || 0),
        yellowCardsInAllMatchesCount: getSum(
          x => x.cards?.filter(y => y.cardType === cardTypes.yellow).length || 0
        ),
        secondYellowCardsInAllMatchesCount: getSum(
          x => x.cards?.filter(y => y.cardType === cardTypes.yellow && y.isSecond).length || 0
        ),
        redCardsInAllMatchesCount: getSum(
          x => x.cards?.filter(y => y.cardType === cardTypes.red).length || 0
        )
      };
    });

  const [playersData, setPlayersData] = useState(() => getPlayersWithStatistics());

  const resetPlayersData = () =>
    setPlayersData(sortNumericValues([...playersData], columnsToDisplay[0], true));

  const sortPlayersData = () => {
    const sortFieldName = columnsToDisplay[sortedColumnIndex];
    const sortingType = typeof playersData[0][sortFieldName] === 'number' ? 'numeric' : 'text';
    const ascending = sortDirection === ESortDirections.ascending;

    setPlayersData(
      sortingType === 'numeric'
        ? sortNumericValues(playersData, sortFieldName, ascending)
        : sortTextValues(playersData, sortFieldName, ascending)
    );
  };

  const sortColumn = (columnIndex: number) => {
    const currentSortingDirection = getCurrentSortingDirection(
      sortDirection,
      columnIndex,
      sortedColumnIndex
    );

    setSortDirection(currentSortingDirection);
    setSortedColumnIndex(columnIndex);
  };

  const initializePlayersData = () =>
    ArrayUtils.isNotEmpty(playersWithMatchesAndEvents) &&
    setPlayersData(getPlayersWithStatistics());

  useEffect(
    () => {
      initializePlayersData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [playersWithMatchesAndEvents]
  );

  useEffect(
    () => {
      if (sortDirection) {
        sortPlayersData();
      } else {
        resetPlayersData();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sortDirection, sortedColumnIndex]
  );

  const playerRows = playersData?.map(player => (
    <TableRow key={player.id} player={player} hoveredColumnIndex={hoveredColumnIndex} />
  ));

  const stickyHeaderAndFirstColumnTableClassName = cx(styles.table, 'table--sticky');

  return (
    <ContentRevealer isVisible={true} className={styles.table_container}>
      <table className={stickyHeaderAndFirstColumnTableClassName}>
        <TableHeader
          sortDirection={sortDirection}
          sortedColumnIndex={sortedColumnIndex}
          setHoveredColumnIndex={setHoveredColumnIndex}
          sortColumn={sortColumn}
        />
        <tbody>{playerRows}</tbody>
      </table>
    </ContentRevealer>
  );
};

export default memo(TeamPlayersStatistics);
