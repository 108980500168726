import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { RequestStatus } from 'models/common.models';

import ObjectUtils from 'utils/object.utils';
import Accordion from 'components/Accordion';
import Column from 'components/Column';
import InnerContentWrapper from 'components/InnerContentWrapper';
import MatchesTableResponsive from 'components/MatchesTableResponsive';
import PageHeader from 'components/PageHeader';
import Skeleton from 'components/Skeleton';
import { StyledMatchesNoDataMessage } from 'components/StyledMatchesNoDataMessage';
import ThreeRowsSkeletons from 'components/ThreeRowsSkeletons';

import {
  IPlayerCurrentTeamModel,
  IPlayerDebutMatchModel,
  IPlayerDetailsModel,
  IPlayerMatchModel,
  IPlayerSeasonModel
} from './data/models';
import PlayerDetailsCard from './PlayerDetailsCard';
import styles from './PlayerSeasonStatisticsPage.module.scss';
import LeaguesRows from './seasons/LeaguesRows';

type Props = {
  details?: IPlayerDetailsModel | null;
  photo?: string | null;
  currentTeam?: IPlayerCurrentTeamModel | null;
  debutMatch?: IPlayerDebutMatchModel | null;
  season?: IPlayerSeasonModel | null;
  matchesInSeason?: IPlayerMatchModel[] | null;

  getDetailsRequestStatus?: RequestStatus | null;
  getPhotoRequestStatus?: RequestStatus | null;
  getCurrentTeamRequestStatus?: RequestStatus | null;
  getDebutMatchRequestStatus?: RequestStatus | null;
  getSeasonRequestStatus?: RequestStatus | null;
  getMatchesInSeasonRequestStatus?: RequestStatus | null;
};

const PlayerSeasonStatisticsPage = ({
  details,
  photo,
  currentTeam,
  debutMatch,
  season,
  matchesInSeason,
  getDetailsRequestStatus,
  getPhotoRequestStatus,
  getCurrentTeamRequestStatus,
  getDebutMatchRequestStatus,
  getSeasonRequestStatus,
  getMatchesInSeasonRequestStatus
}: Readonly<Props>) => {
  const { t } = useTranslation();

  let seasonName = '';

  if (season?.seasonName) {
    seasonName = season.seasonName;
  }

  return (
    <InnerContentWrapper centerContent subpageWrap>
      <Column className={styles.header}>
        <PageHeader title={t('PlayerSeasonStatistics')} />
        {getSeasonRequestStatus?.isLoading ? <Skeleton /> : <PageHeader title={seasonName} />}
      </Column>

      <PlayerDetailsCard
        details={details}
        photo={photo}
        currentTeam={currentTeam}
        debutMatch={debutMatch}
        getDetailsRequestStatus={getDetailsRequestStatus}
        getPhotoRequestStatus={getPhotoRequestStatus}
        getCurrentTeamRequestStatus={getCurrentTeamRequestStatus}
        getDebutMatchRequestStatus={getDebutMatchRequestStatus}
      />

      <Accordion
        hoverable
        className="margin-top"
        title={`${t('Statistics')} ${t('InSeason')} ${seasonName}`}
        withoutPadding
        isStyleDisplayContents
      >
        {getSeasonRequestStatus?.isLoading ? (
          <ThreeRowsSkeletons />
        ) : ObjectUtils.isNotEmpty(season) && season?.seasonName ? (
          <div className={styles.leagues_container}>
            <LeaguesRows season={season!}></LeaguesRows>
          </div>
        ) : (
          <StyledMatchesNoDataMessage>{t('NoData')}</StyledMatchesNoDataMessage>
        )}
      </Accordion>

      <Accordion
        hoverable
        className="margin-top"
        title={`${t('Matches')} ${t('InSeason')} ${seasonName}`}
        withoutPadding
        isStyleDisplayContents
      >
        {getMatchesInSeasonRequestStatus?.isLoading ? (
          <ThreeRowsSkeletons />
        ) : matchesInSeason?.length ? (
          <MatchesTableResponsive
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            matches={matchesInSeason as any}
            withStatus
            withStatusAndPlayerStatistics
          />
        ) : (
          <StyledMatchesNoDataMessage>{t('NoData')}</StyledMatchesNoDataMessage>
        )}
      </Accordion>
    </InnerContentWrapper>
  );
};

export default memo(PlayerSeasonStatisticsPage);
