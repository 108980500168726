import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  IPlayerCurrentTeamModel,
  IPlayerDebutMatchModel,
  IPlayerDetailsModel,
  IPlayerMatchModel,
  IPlayerSeasonModel,
  IPlayerSeasonStatisticsModel
} from './models';
import playerApi from './player.api';

export const getDetails = createAsyncThunk<IPlayerDetailsModel, string>(
  'player/getDetails',
  async (id: string) => {
    const response = await playerApi.getDetails(id);
    return response;
  }
);

export const getPhoto = createAsyncThunk<string, string>('player/getPhoto', async (id: string) => {
  const response = await playerApi.getPhoto(id);
  return response;
});

export const getCurrentTeam = createAsyncThunk<IPlayerCurrentTeamModel, string>(
  'player/getCurrentTeam',
  async (id: string) => {
    const response = await playerApi.getCurrentTeam(id);
    return response;
  }
);

export const getDebutMatch = createAsyncThunk<IPlayerDebutMatchModel, string>(
  'player/getDebutMatch',
  async (id: string) => {
    const response = await playerApi.getDebutMatch(id);
    return response;
  }
);

export const getSeasons = createAsyncThunk<IPlayerSeasonModel[], string>(
  'player/getSeasons',
  async (id: string): Promise<IPlayerSeasonModel[]> => {
    const response = await playerApi.getSeasons(id);
    return response as IPlayerSeasonModel[];
  }
);

export const getSeason = createAsyncThunk<IPlayerSeasonModel, { id: string; seasonId: string }>(
  'player/getSeason',
  async ({ id, seasonId }: { id: string; seasonId: string }): Promise<IPlayerSeasonModel> => {
    const response = await playerApi.getSeason(id, seasonId);
    return response as IPlayerSeasonModel;
  }
);

export const getSeasonStatistics = createAsyncThunk<
  IPlayerSeasonStatisticsModel,
  { id: string; seasonId: string; leagueId: string; teamId: string }
>(
  'player/getSeasonStatistics',
  async ({
    id,
    seasonId,
    leagueId,
    teamId
  }: {
    id: string;
    seasonId: string;
    leagueId: string;
    teamId: string;
  }) => {
    const response = await playerApi.getSeasonStatistics(id, seasonId, leagueId, teamId);
    return response;
  }
);

export const getMatchesInCurrentSeason = createAsyncThunk<
  IPlayerMatchModel[],
  { id: string; seasonId: string }
>(
  'player/getMatchesInCurrentSeason',
  // note: seasonId is currentSeasonId get from systemParameters
  // it is used only in the fulfilled case reducer to match seasonId from store and add matches to the season
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  async ({ id, seasonId }: { id: string; seasonId: string }) => {
    const response = await playerApi.getMatchesInCurrentSeason(id);
    return response;
  }
);

export const getMatchesInSeason = createAsyncThunk<
  IPlayerMatchModel[],
  { id: string; seasonId: string }
>('player/getMatchesInSeason', async ({ id, seasonId }: { id: string; seasonId: string }) => {
  const response = await playerApi.getMatchesInSeason(id, seasonId);
  return response;
});
