import PropTypes from 'prop-types';

import { playerStatisticsPropTypes } from './player-statistics';
import { simpleTeamPropTypes } from './simple-team';

export const playerSeasonPropTypes = {
  seasonId: PropTypes.string,
  seasonName: PropTypes.string,
  leagues: PropTypes.arrayOf(
    PropTypes.shape({
      leagueId: PropTypes.string,
      leagueName: PropTypes.string,
      ageGroupName: PropTypes.string,
      teams: PropTypes.arrayOf(
        PropTypes.shape({
          ...simpleTeamPropTypes,
          playerStatistics: playerStatisticsPropTypes
        })
      )
    })
  )
};

export const playerSeasonPropTypesShape = PropTypes.shape({ ...playerSeasonPropTypes });
