import { memo } from 'react';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  margin: 0 auto;
  max-width: ${({ theme }) => theme.maxSiteWidth};
  min-height: 100vh;
`;

export default memo(StyledWrapper);
