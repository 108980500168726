import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './Dropdown.module.scss';

const propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  visible: PropTypes.bool
};

const Dropdown = ({ children, className, visible }) => {
  const dropdownClassName = cx(styles.dropdown_container, className, {
    [styles.visible]: visible
  });

  return <div className={dropdownClassName}>{children}</div>;
};

Dropdown.propTypes = propTypes;

export default memo(Dropdown);
