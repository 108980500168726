import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

import fieldNames from 'consts/fieldNames';
import formNames from 'consts/formNames';
import { isRequired } from 'utils/formValidators';
import AvatarPlaceholder from 'components/AvatarPlaceholder';
import Button from 'components/Button';
import Form from 'components/Form';
import FormField from 'components/FormField';
import MyAvatarEditField from 'components/reduxFormFields/MyAvatarEditField';
import MyInputTextField from 'components/reduxFormFields/MyInputTextField';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  firstName: PropTypes.string.isRequired,
  avatar: PropTypes.string.isRequired
};

const UserInformationForm = ({ handleSubmit, submitting, firstName, avatar }) => {
  const { t } = useTranslation();

  const [editAvatar, setEditAvatar] = useState(false);
  const handleCloseAvatarEdit = () => setEditAvatar(false);

  return (
    <Form handleSubmit={handleSubmit}>
      <fieldset>
        <legend>{t('UserInformations')}</legend>
        <FormField label={t('Avatar')} />
        {editAvatar ? (
          <MyAvatarEditField
            name={fieldNames.myProfileUserInformationForm.avatar}
            placeholder={t('Avatar')}
            onClose={handleCloseAvatarEdit}
          />
        ) : (
          <div>
            {avatar ? (
              <img src={avatar} alt="avatar" />
            ) : (
              <AvatarPlaceholder label={firstName.charAt(0)} />
            )}
            <div onClick={() => setEditAvatar(true)} data-label={t('Edit')} />
          </div>
        )}

        <MyInputTextField
          name={fieldNames.myProfileUserInformationForm.login}
          label={t('Login')}
          placeholder={t('Login')}
        />

        <Button disabled={submitting}>{t('Update')}</Button>
      </fieldset>
    </Form>
  );
};

UserInformationForm.propTypes = propTypes;

const validate = values => {
  const errors = {};

  isRequired(errors, values, fieldNames.myProfileUserInformationForm.login, t('FieldRequired'));

  return errors;
};

export default reduxForm({
  form: formNames.myProfileForm,
  validate,
  enableReinitialize: true
})(memo(UserInformationForm));
