import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';

import DropdownWithArrows from 'components/DropdownWithArrows';

import TeamLink from '../Links/TeamLink';
import styles from './TeamsCarousel.module.scss';

const propTypes = {
  seasons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      yearFrom: PropTypes.number,
      yearTo: PropTypes.number,
      teams: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          logo: PropTypes.string,
          name: PropTypes.string,
          ageGroupName: PropTypes.string,
          league: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string
          })
        })
      )
    })
  )
};

const TeamsCarousel = ({ seasons }) => {
  const seasonsDropdownOptions = window
    .structuredClone(seasons)
    .sort((a, b) => b.yearFrom - a.yearFrom)
    .map(season => ({
      ...season,
      optionName: `${season.yearFrom}/${season.yearTo}`
    }));

  const maxIndex = seasons.length - 1;

  const [seasonToShowIndex, setSeasonToShowIndex] = useState(0);
  const [seasonToShow, setSeasonToShow] = useState(seasons[seasonToShowIndex]);

  return (
    <div className={styles.container}>
      <DropdownWithArrows
        dropdownId="teamsCarousel"
        items={seasonsDropdownOptions}
        lastItemIndex={maxIndex}
        selectedItemIndex={seasonToShowIndex}
        setSelectedItem={setSeasonToShow}
        setSelectedItemIndex={setSeasonToShowIndex}
      />

      <div className={styles.teams}>
        {seasonToShow.teams.map(team => (
          <div key={team.id} className={styles.team}>
            <TeamLink {...team} />
            <span className={styles.team_ageGroupName}>{team.ageGroupName}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

TeamsCarousel.propTypes = propTypes;

export default memo(TeamsCarousel);
