import React, { memo, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAppDispatch } from 'store';
import { shouldMakeRequest } from 'utils/requests.utils';
import { useGetCurrentSeasonId } from 'utils/systemParameterProvider';

import {
  getMatchesInSeason,
  useRefereeData,
  useSelectGetRefereeMatchesInSeasonRequestStatus
} from './data';
import RefereeDetailsPage from './RefereeDetailsPage';

const RefereeDetailsPageContainer = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const {
    details,
    photo,
    debutMatch,
    seasons,

    getDetailsRequestStatus,
    getPhotoRequestStatus,
    getDebutMatchRequestStatus,
    getSeasonsRequestStatus
  } = useRefereeData(id!);

  const currentSeasonId = useGetCurrentSeasonId();

  const currentSeason = seasons?.find(x => x.seasonId === currentSeasonId);

  const matchesInCurrentSeason = currentSeason?.matches;

  const [selectedSeasonId, setSelectedSeasonId] = useState<string>(currentSeasonId);

  const matchesInSeasonRequestStatus = useSelectGetRefereeMatchesInSeasonRequestStatus(
    id,
    selectedSeasonId
  );

  const handleGetMatchesInSeason = (seasonId: string) => setSelectedSeasonId(seasonId);

  useEffect(() => {
    if (selectedSeasonId !== currentSeasonId) {
      if (shouldMakeRequest(matchesInSeasonRequestStatus)) {
        dispatch(getMatchesInSeason({ id: id!, seasonId: selectedSeasonId }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSeasonId]);

  return (
    <RefereeDetailsPage
      refereeId={id!}
      details={details}
      photo={photo}
      debutMatch={debutMatch}
      seasons={seasons}
      matchesInCurrentSeason={matchesInCurrentSeason}
      getDetailsRequestStatus={getDetailsRequestStatus}
      getPhotoRequestStatus={getPhotoRequestStatus}
      getDebutMatchRequestStatus={getDebutMatchRequestStatus}
      getSeasonsRequestStatus={getSeasonsRequestStatus}
      getMatchesInCurrentSeasonRequestStatus={currentSeason?.getMatchesRequestStatus}
      currentSeasonId={currentSeasonId}
      onGetMatchesInSeason={handleGetMatchesInSeason}
    />
  );
};

export default memo(RefereeDetailsPageContainer);
