import PropTypes from 'prop-types';

import { queuePropTypesShape } from './queue';

export const roundPropTypes = {
  name: PropTypes.string,
  queues: PropTypes.arrayOf(queuePropTypesShape)
};

export const roundPropTypesShape = PropTypes.shape({
  ...roundPropTypes
});
