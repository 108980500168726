import React, { memo } from 'react';

import { useAppSelector } from 'store';
import { useGetMyClubId } from 'utils/systemParameterProvider';

// import { useMultipleDispatchOnMount } from 'utils/customHooks';
// import {
//   getArticles,
//   getNextMatch,
//   getPartners,
//   getPreviousMatch,
// } from './actions';
import HomePage from './HomePage';

const HomePageContainer = props => {
  const home = useAppSelector(state => state.home);

  const articles = home?.articles;
  const table = home?.table;
  const isPreviousMatchLoaded = home?.isPreviousMatchLoaded;
  const previousMatch = home?.previousMatch;
  const isCurrentMatchLoaded = home?.isCurrentMatchLoaded;
  const currentMatch = home?.currentMatch;
  const isNextMatchLoaded = home?.isNextMatchLoaded;
  const nextMatch = home?.nextMatch;

  const myClubId = useGetMyClubId();

  const club = useAppSelector(state => state.clubs[myClubId]);

  const partners = club?.partners;
  const sponsors = club?.sponsors;

  //  useEffect(
  //    () => {
  //      // setChildrenHeight(getChildrenHeight());
  //    },
  //    // eslint-disable-next-line react-hooks/exhaustive-deps
  //    [myClubId]
  //  );

  // TODO: dane na stronę główną są pobierane w index.jsx, czy tutaj muszą być?
  // czy zawsze po wejściu na stronę główną będą te dane czy może być przypadek że kod w index.jsx
  // się nie wykona i tych danych nie będzie ?

  // useMultipleDispatchOnMount([
  //   getArticles(),
  //   getTable(),
  //   getPreviousMatch(),
  //   getNextMatch(),
  // ]);

  return (
    <HomePage
      {...props}
      articles={articles}
      table={table}
      isPreviousMatchLoaded={isPreviousMatchLoaded}
      previousMatch={previousMatch}
      isCurrentMatchLoaded={isCurrentMatchLoaded}
      currentMatch={currentMatch}
      isNextMatchLoaded={isNextMatchLoaded}
      nextMatch={nextMatch}
      partners={partners}
      sponsors={sponsors}
    />
  );
};

export default memo(HomePageContainer);

// // note: albo tak zamiast logiki wyżej to logika jest w withReduxData:
// import { memo } from 'react';

// import HomePage from './HomePage';
// import withReduxData from './withReduxData';

// export default withReduxData(memo(HomePage));
