import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Dropdown from './Dropdown';
import SubmenuLink from './SubmenuLink';

const propTypes = {
  subLinksData: PropTypes.arrayOf(PropTypes.object).isRequired,
  visible: PropTypes.bool
};

const Submenu = ({ subLinksData, visible }) => {
  const { t } = useTranslation();

  const submenuLinks = subLinksData.map((linkData, index) => (
    <SubmenuLink
      key={index}
      {...linkData}
      label={linkData.shouldNotTranslateLabel ? linkData.label : t(linkData.label)}
    />
  ));

  return <Dropdown visible={visible}>{submenuLinks}</Dropdown>;
};

Submenu.propTypes = propTypes;

export default memo(Submenu);
