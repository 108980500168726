import React, { memo } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  abbreviation: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

const AbbreviationDescription = ({ abbreviation, description }) => (
  <div>
    <span className="text-bold">{abbreviation}</span> - {description.toLowerCase()}
  </div>
);

AbbreviationDescription.propTypes = propTypes;

export default memo(AbbreviationDescription);
