export type KeyValuePair = {
  key: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
};

// note: https://stackoverflow.com/questions/41285211/overriding-interface-property-type-defined-in-typescript-d-ts-file/74216680#74216680
// note: https://gist.github.com/ackvf/de21847e78083034252961d550963579#file-global-d-ts-L117
export type Modify<T, R> = Omit<T, keyof R> & R;

export const enum ERequestStatus {
  pending = 'pending',
  fulfilled = 'fulfilled',
  rejected = 'rejected'
}

export interface IRequestStatus {}

export type RequestStatus = {
  status: ERequestStatus;
  errorMessage?: string | null;
  isUninitialized: boolean;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
};

export type RequestStatusType = IRequestStatus | undefined | null;
