import React, { memo } from 'react';

import { useAppSelector } from 'store';
import { useDispatchOnMount } from 'utils/customHooks';
import { useGetMyClubId } from 'utils/systemParameterProvider';

import { getHistoryForMyClub } from '../my-club-actions';
import HistoryPage from './HistoryPage';

const HistoryPageContainer = props => {
  const myClubId = useGetMyClubId();

  const history = useAppSelector(state => state.clubs[myClubId]?.history);

  useDispatchOnMount(getHistoryForMyClub());

  return <HistoryPage {...props} history={history} />;
};

export default memo(HistoryPageContainer);
