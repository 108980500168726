import { memo } from 'react';
import styled from 'styled-components';
import { placeholderImageLoading } from 'styling';

// TODO: Vorbert -> ogarnąć sposób na użycie tego w <Carousel.SlidePlaceholder/>

const PlaceholderImage = styled.div`
  background-color: ${({ theme }) => theme.darkPlaceholderColor};
  animation: ${placeholderImageLoading} 1250ms ease-in-out infinite;
  border-radius: 0.5rem;
`;

export default memo(PlaceholderImage);

// TODO: to remove?
