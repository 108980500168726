import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// TODO: person default avatar
import defaultAvatar from 'assets/images/BoardMember_default_avatar.svg';
import cx from 'classnames';

import { personWithIdAndPhotoPropTypes } from 'propTypes';
import urls from 'consts/urls';

import Avatar from '../Avatar';
import styles from './ClubActivistLink.module.scss';
import Link from './Link';

const propTypes = {
  ...personWithIdAndPhotoPropTypes,
  className: PropTypes.string,
  reverse: PropTypes.bool
};

const ClubActivistLink = ({ id, photo, firstName, lastName, className, reverse }) => {
  const { t } = useTranslation();

  const linkClassName = cx(styles.clubActivist_link, className, {
    [styles.clubActivist_link_reverse]: reverse
  });

  return (
    <Link
      to={urls.clubActivistDetails(id)}
      className={linkClassName}
      title={`${t('GoToTheClubActivistSubpage')} ${firstName} ${lastName}`}
    >
      <Avatar src={photo || defaultAvatar} className={styles.clubActivist_logo} />
      <span className={styles.clubActivist_name}>
        {firstName} {lastName}
      </span>
    </Link>
  );
};

ClubActivistLink.propTypes = propTypes;

export default memo(ClubActivistLink);
