import React, { memo } from 'react';
import PropTypes from 'prop-types';
import defaultAvatar from 'assets/images/BoardMember_default_avatar.svg';

import { personWithIdAndPhotoPropTypes } from 'propTypes';
import urls from 'consts/urls';

import PersonTile from '../PersonTile';

const propTypes = {
  ...personWithIdAndPhotoPropTypes,
  stand: PropTypes.string.isRequired
};

const BoardMemberCard = ({ id, photo, firstName, lastName, stand }) => {
  const boardMemberDetailsUrl = urls.boardMemberDetails(id);

  return (
    <PersonTile
      photo={photo || defaultAvatar}
      firstName={firstName}
      lastName={lastName}
      description={stand}
      detailsUrl={boardMemberDetailsUrl}
    />
  );
};

BoardMemberCard.propTypes = propTypes;

export default memo(BoardMemberCard);
