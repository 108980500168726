import axios from 'config/axios';

import {
  IPlayerCurrentTeamResponse,
  IPlayerDebutMatchResponse,
  IPlayerDetailsResponse,
  IPlayerMatchResponse,
  IPlayerSeasonResponse,
  IPlayerSeasonStatisticsResponse
} from './models/playerResponse.models';

const prefix = 'client/player';

export default class PlayerApi {
  static async getDetails(id: string): Promise<IPlayerDetailsResponse> {
    const result = await axios.get<IPlayerDetailsResponse>(`${prefix}/${id}`);
    return result.data;
  }

  static async getPhoto(id: string): Promise<string> {
    const result = await axios.get<string>(`${prefix}/${id}/photo`);
    return result.data;
  }

  static async getCurrentTeam(id: string): Promise<IPlayerCurrentTeamResponse> {
    const result = await axios.get<IPlayerCurrentTeamResponse>(`${prefix}/${id}/currentTeam`);
    return result.data;
  }

  static async getDebutMatch(id: string): Promise<IPlayerDebutMatchResponse> {
    const result = await axios.get<IPlayerDebutMatchResponse>(`${prefix}/${id}/debutMatch`);
    return result.data;
  }

  static async getSeasons(id: string): Promise<IPlayerSeasonResponse[]> {
    const result = await axios.get<IPlayerSeasonResponse[]>(`${prefix}/${id}/seasons`);
    return result.data;
  }

  static async getSeason(id: string, seasonId: string): Promise<IPlayerSeasonResponse> {
    const result = await axios.get<IPlayerSeasonResponse>(`${prefix}/${id}/season/${seasonId}`);
    return result.data;
  }

  static async getMatchesInCurrentSeason(id: string): Promise<IPlayerMatchResponse[]> {
    const result = await axios.get<IPlayerMatchResponse[]>(`${prefix}/${id}/matches`);
    return result.data;
  }

  static async getMatchesInSeason(id: string, seasonId: string): Promise<IPlayerMatchResponse[]> {
    const result = await axios.get<IPlayerMatchResponse[]>(`${prefix}/${id}/${seasonId}/matches`);
    return result.data;
  }

  // note: not used
  static async getMatchesInCompetitions(
    id: string,
    leagueId: string,
    seasonId: string
  ): Promise<IPlayerMatchResponse[]> {
    const result = await axios.get<IPlayerMatchResponse[]>(
      `${prefix}/${id}/${leagueId}/${seasonId}/matches`
    );
    return result.data;
  }

  static async getSeasonStatistics(
    id: string,
    seasonId: string,
    leagueId: string,
    teamId: string
  ): Promise<IPlayerSeasonStatisticsResponse> {
    const result = await axios.get<IPlayerSeasonStatisticsResponse>(
      `${prefix}/${id}/${teamId}/${leagueId}/${seasonId}/seasonsStatistics`
    );
    return result.data;
  }
}
