import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { icons } from 'consts/icons';

import styles from './MinutesIcon.module.scss';

const MinutesIcon = () => {
  const { t } = useTranslation();

  const minutesIconClassName = cx(icons.clock, styles.icon_blue);

  return <i className={minutesIconClassName} title={t('MinutesCount')} />;
};

export default memo(MinutesIcon);
