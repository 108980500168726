/* eslint-disable @typescript-eslint/no-explicit-any */

// TODO: documentation and tests for all methods

import { mergeWith } from 'lodash';

import { isNullOrUndefined } from './utils';

export default class ArrayUtils {
  /**
   * Method will check if the provided array
   * is not empty
   */
  static isNotEmpty(array: any[] | undefined | null): boolean {
    if (array === undefined || array === null || !Array.isArray(array) || array.length <= 0) {
      return false;
    }

    return true;
  }

  /**
   * Method will check if the provided array
   * is empty
   */
  static isEmpty(array: any[]): boolean {
    return !ArrayUtils.isNotEmpty(array);
  }

  static flatten(array: any[], depth = 1): any[] {
    // If depth is 0, return the array as-is
    if (depth < 1) {
      return array.slice();
    }

    // Otherwise, concatenate into the parent array
    return array.reduce(
      (acc, val) => acc.concat(Array.isArray(val) ? ArrayUtils.flatten(val, depth - 1) : val),
      []
    );
  }

  static convertArrayToObjectOfBooleans(array: any[]): any {
    const returnValue = {};

    for (const value of array) {
      returnValue[value] = true;
    }

    return returnValue;
  }

  static moveItemInArray(array: any[], fromIndex: number, toIndex: number): void {
    const element = array[fromIndex];
    array.splice(fromIndex, 1);
    array.splice(toIndex, 0, element);
  }

  static swapElement(array: any[], fromIndex: number, toIndex: number): void {
    const element = array[fromIndex];
    array[fromIndex] = array[toIndex];
    array[toIndex] = element;
  }

  /**
   * @description
   * Takes an Array<V>, and a grouping function,
   * and returns a Map of the array grouped by the grouping function.
   *
   * @param array An array of type V.
   * @param keyGetter A Function that takes the the Array type V as an input, and returns a value of type K.
   *                  K is generally intended to be a property key of V.
   *
   * @returns Map of the array grouped by the grouping function.
   */
  static groupBy<K, V>(array: Array<V>, keyGetter: (input: V) => K): Map<K, Array<V>> {
    const map = new Map<K, Array<V>>();
    array.forEach(item => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });

    return map;
  }

  // note: usage: grouped = groupBy(pets, pet => pet.type);

  static groupBy2(
    array: any[],
    key: string
  ): any /* TODO: type something like {firstKey: [], secondKey: []} */ {
    return array.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  // note: usage: grouped = groupBy2(pets, 'type');

  static mergeArraysById(array1: any[], array2: any[]): any[] {
    const resultArray = array1.map(itemFromArray1 => {
      const matchItemInArray2 = array2.find(
        itemFromArray2 => itemFromArray2.id === itemFromArray1.id && itemFromArray2
      );
      const result = mergeWith({}, itemFromArray1, matchItemInArray2, (o, s) =>
        isNullOrUndefined(o) ? s : o
      );

      return {
        ...itemFromArray1,
        ...result
      };
    });

    const missingItemsInArray1 = array2.filter(x => !array1.map(y => y.id).includes(x.id));

    if (ArrayUtils.isNotEmpty(missingItemsInArray1)) {
      resultArray.push(...missingItemsInArray1);
    }

    return resultArray;
  }

  static updateAndReturnNewArray(array: any[], elementToUpdateIndex: number, element: any): any[] {
    if (!array) {
      return [element];
    }

    return [
      ...array.slice(0, elementToUpdateIndex),
      element,
      ...array.slice(elementToUpdateIndex + 1)
    ];
  }

  static onlyUnique(value: any, index: number, self: any[]): boolean {
    return self.indexOf(value) === index;
  }

  static distinct(array: any[]): any[] {
    return array.filter(ArrayUtils.onlyUnique);
  }

  static distinct2(array: any[]): any[] {
    return array.filter((item, index, array) => array.indexOf(item) === index);
  }

  // TODO: to test
  static sum(array: any[], key: string): number {
    // // eslint-disable-next-line no-console
    // console.log('array', array);

    // // eslint-disable-next-line no-console
    // console.log('key', key);

    return array.reduce((a, b) => {
      // // eslint-disable-next-line no-console
      // console.log('a', a);
      // // eslint-disable-next-line no-console
      // console.log('b', b);
      return a + b[key];
    }, 0);
  }

  static areArraysIdentical(array1: any[], array2: any[]): boolean {
    if (array1 === array2) {
      return true;
    }

    if (array1 === undefined || array1 === null || array2 === undefined || array2 === null) {
      return false;
    }

    if (array1.length !== array2.length) {
      return false;
    }

    return (
      array1.every((value, index) => value === array2[index]) &&
      array2.every((value, index) => value === array1[index])
    );
  }

  static removeSpacesFromFormGroup(formGroup: any): any {
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup?.get(key);
      if (control?.value && !(control?.value instanceof Date)) {
        control.setValue(control.value.trim());
      }
    });

    return formGroup.value;
  }

  static removeDataByIndex(removeIndex: number, data: any[]): any[] {
    return data.filter((_, index) => index !== removeIndex);
  }

  static getUniqueListBy(array: any[], key: string): any[] {
    return [...new Map(array.map(item => [item[key], item])).values()];
  }

  static prettyPrintArray(array: any[]): string {
    return array.toString().replace(/,/g, ', ');
  }
}
