import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './HamburgerButton.module.scss';

const propTypes = {
  isActive: PropTypes.bool,
  onClick: PropTypes.func
};

const HamburgerButton = ({ isActive, onClick = () => {} }) => {
  const hamburgerClassName = cx(styles.hamburger, {
    [styles.hamburger_active]: isActive
  });

  const lineClassName = linePosition =>
    cx(styles.hamburger_line, styles[`hamburger_line_${linePosition}`]);

  return (
    <svg className={hamburgerClassName} viewBox="0 0 100 100" onClick={onClick}>
      <path
        className={lineClassName('top')}
        d="m 70,33 h -40 c 0,0 -8.5,-0.149796 -8.5,8.5 0,8.649796 8.5,8.5 8.5,8.5 h 20 v -20"
      />
      <path className={lineClassName('middle')} d="m 70,50 h -40" />
      <path
        className={lineClassName('bottom')}
        d="m 30,67 h 40 c 0,0 8.5,0.149796 8.5,-8.5 0,-8.649796 -8.5,-8.5 -8.5,-8.5 h -20 v 20"
      />
    </svg>
  );
};

HamburgerButton.propTypes = propTypes;

export default memo(HamburgerButton);
