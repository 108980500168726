import ArrayUtils from 'utils/array.utils';
import ObjectUtils from 'utils/object.utils';

import actionTypes from './actionTypes';
import service from './service';

const getMatchDetailsSuccess = data => dispatch =>
  dispatch({
    type: actionTypes.STORE_MATCH_DETAILS,
    data
  });

export function get(id) {
  return (dispatch, getState) => {
    const { matches } = getState();

    if (ObjectUtils.isEmpty(matches[id]) || ObjectUtils.isEmpty(matches[id].details)) {
      return service.get(id).then(details =>
        dispatch(
          getMatchDetailsSuccess({
            id,
            details
          })
        )
      );
    }
  };
}

const getMatchesBetweenTeamsBeforeDateSuccess = data => dispatch =>
  dispatch({
    type: actionTypes.STORE_MATCHES_BETWEEN_TEAMS_DETAILS,
    data
  });

export function getMatchesBetweenTeamsBeforeDate(id, hostId, guestId, date) {
  return (dispatch, getState) => {
    const { matches } = getState();

    if (ObjectUtils.isEmpty(matches[id]) || ArrayUtils.isEmpty(matches[id].matchesBetweenTeams)) {
      return service
        .getMatchesBetweenTeamsBeforeDate(hostId, guestId, date)
        .then(matchesBetweenTeams =>
          dispatch(
            getMatchesBetweenTeamsBeforeDateSuccess({
              id,
              matchesBetweenTeams
            })
          )
        );
    }
  };
}
