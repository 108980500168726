import React, { memo } from 'react';

import { useAppDispatch } from 'store';

import { resetPasswordAsync } from '../actions';
import ResetPasswordPage from './ResetPasswordPage';

const ResetPasswordPageContainer = props => {
  const dispatch = useAppDispatch();
  const onSubmit = model => dispatch(resetPasswordAsync(model));

  return <ResetPasswordPage {...props} onSubmit={onSubmit} />;
};

export default memo(ResetPasswordPageContainer);
