import React, { memo, useState } from 'react';
import { Lightbox } from 'react-modal-image';
import { useTranslation } from 'react-i18next';

import styles from './ArticlePhoto.module.scss';

type Props = { photo: string };

const ArticlePhoto = ({ photo }: Readonly<Props>) => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);

  const onShowModal = () => setShowModal(true);
  const onCloseModal = () => setShowModal(false);

  return (
    <>
      <img src={photo} alt={t('Article')} className={styles.image} onClick={onShowModal} />

      {showModal && (
        <Lightbox medium={photo} large={photo} onClose={onCloseModal} hideDownload hideZoom />
      )}
    </>
  );
};

export default memo(ArticlePhoto);
