import React, { memo } from 'react';
import PropTypes from 'prop-types';

import CardRedIcon from 'components/Icon/CardRedIcon';

import EventWrapper from './EventWrapper';

const propTypes = {
  minute: PropTypes.number.isRequired,
  count: PropTypes.number
};

const GivenCardRedShortEvent = ({ minute, count }) => (
  <EventWrapper color="red" minute={minute}>
    <CardRedIcon count={count} />
  </EventWrapper>
);

GivenCardRedShortEvent.propTypes = propTypes;

export default memo(GivenCardRedShortEvent);
