import { jwtDecode, JwtDecodeOptions, JwtPayload } from 'jwt-decode';

export function decodeJwt(token: string, options?: JwtDecodeOptions | undefined): JwtPayload {
  return jwtDecode(token, options);
}

function getUnitNowTimeStamp(): number {
  return Math.floor(Date.now() / 1000);
}

export function isExpired(tokenExp: number): boolean {
  return tokenExp < getUnitNowTimeStamp();
}
