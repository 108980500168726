import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import ErrorMessage from './ErrorMessage';

const propTypes = {
  WrappedComponent: PropTypes.func.isRequired,
  options: PropTypes.shape({
    isCheckbox: PropTypes.bool
  })
};

const asReduxFormField = (
  WrappedComponent,
  options = {
    isCheckbox: false
  }
) => {
  const renderComponent = ({ input, meta, ...other }) => (
    <>
      <WrappedComponent
        {...input}
        onChange={event =>
          input.onChange(!options.isCheckbox ? event.target.value : event.target.checked)
        }
        invalid={meta.invalid && (meta.touched || meta.submitFailed)}
        {...other}
        meta={meta}
      />
      <ErrorMessage
        isVisible={meta.invalid && (meta.touched || meta.submitFailed)}
        errorMsg={meta.error}
      />
    </>
  );

  return props => <Field {...props} component={renderComponent} />;
};

asReduxFormField.propTypes = propTypes;

export default asReduxFormField;
