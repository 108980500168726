import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { icons } from 'consts/icons';

const ChangeEnteringIcon = () => {
  const { t } = useTranslation();

  return <i className={icons.shortArrowUp} title={t('EnteringPlayer')} />;
};

export default memo(ChangeEnteringIcon);
