import actionTypes from './actionTypes';

export const displayToastr =
  (message, type, duration = 7000) =>
  dispatch =>
    dispatch({
      type: actionTypes.TOASTR_DISPLAY,
      message,
      toastrType: type,
      duration
    });

export const closeToastr = id => dispatch =>
  dispatch({
    type: actionTypes.TOASTR_CLOSE,
    id
  });
