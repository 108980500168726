import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { playerPropTypes } from 'propTypes';
import { sortByLastNameAscending } from 'utils/sortingMethods';
import CardList from 'components/CardList';
import PlayerCard from 'components/PeopleCards/PlayerCard';

import PlayerPositionCard from './PlayerPositionCard';

const propTypes = {
  title: PropTypes.string,
  players: PropTypes.arrayOf(PropTypes.shape(playerPropTypes)),
  positionName: PropTypes.string // note: only required to hoc withPositionColors in PlayerPositionCard component
};

const PlayerList = ({ title, players, positionName }) => {
  const [areCardsHighlighted, setAreCardsHighlighted] = useState(false);

  const handleMouseEnter = () => setAreCardsHighlighted(true);
  const handleMouseLeave = () => setAreCardsHighlighted(false);

  const playerCards = sortByLastNameAscending(players).map(player => (
    <PlayerCard key={player.id} {...player} isHighlighted={areCardsHighlighted} />
  ));

  return (
    !isEmpty(players) && (
      <CardList>
        <PlayerPositionCard
          title={title}
          position={{ name: positionName }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
        {playerCards}
      </CardList>
    )
  );
};

PlayerList.propTypes = propTypes;

export default memo(PlayerList);
