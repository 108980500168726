import ArrayUtils from 'utils/array.utils';

import actionTypes from './actionTypes';
import service from './service';

const getArticlesSuccess = data => dispatch =>
  dispatch({
    type: actionTypes.STORE_ARTICLES,
    data
  });

export function get() {
  return (dispatch, getState) => {
    const { articles } = getState();

    return (
      ArrayUtils.isEmpty(articles) && service.get().then(data => dispatch(getArticlesSuccess(data)))
    );

    // TODO: czy jest jakaś różnica i jak jest lepiej, warunek z && w return tak jak u góry czy normalny if tak jak zakomentowane?
    // if (ArrayUtils.isEmpty(articles)) {
    //   return service.get().then(data => dispatch(getArticlesSuccess(data)));
    // }
  };
}
