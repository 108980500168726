import styled from 'styled-components';

import Flex from './Flex';

type Props = {
  alignItems?: string;
  justifyContent?: string;
  alignSelf?: string;
};

const Row = styled(Flex)<Props>``;

export default Row;
