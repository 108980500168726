import ArrayUtils from 'utils/array.utils';
import ObjectUtils from 'utils/object.utils';

import actionTypes from './actionTypes';
import ClubSrv from './service';

// TODO: akce success są wykorzystywane też w pliku my-club-actions, więc może przenieść je stąd do osobnego pliku actions-success?

export const getDetailsSuccess = (id, details) => dispatch =>
  dispatch({
    type: actionTypes.STORE_CLUB_DETAILS,
    data: {
      id,
      details
    }
  });

export function getDetails(id) {
  return (dispatch, getState) => {
    const { clubs } = getState();

    if (ObjectUtils.isEmpty(clubs[id]?.details)) {
      return ClubSrv.getDetails(id).then(details => dispatch(getDetailsSuccess(id, details)));
    }
  };
}

export const getContactSuccess = (id, contact) => dispatch =>
  dispatch({
    type: actionTypes.STORE_CLUB_CONTACT,
    data: {
      id,
      contact
    }
  });

export function getContact(id) {
  return (dispatch, getState) => {
    const { clubs } = getState();

    if (ObjectUtils.isEmpty(clubs[id]?.contact)) {
      return ClubSrv.getContact(id).then(contact => dispatch(getContactSuccess(id, contact)));
    }
  };
}

export const getTeamsSuccess = (id, teams) => dispatch =>
  dispatch({
    type: actionTypes.STORE_CLUB_TEAMS,
    data: {
      id,
      teams
    }
  });

export function getTeams(id) {
  return (dispatch, getState) => {
    const { clubs } = getState();

    if (ArrayUtils.isEmpty(clubs[id]?.teams)) {
      return ClubSrv.getTeams(id).then(teams => dispatch(getTeamsSuccess(id, teams)));
    }
  };
}

export const getBoardMembersSuccess = (id, boardMembers) => dispatch =>
  dispatch({
    type: actionTypes.STORE_CLUB_BOARD_MEMBERS,
    data: {
      id,
      boardMembers
    }
  });

export function getBoardMembers(id) {
  return (dispatch, getState) => {
    const { clubs } = getState();

    if (ArrayUtils.isEmpty(clubs[id]?.boardMembers)) {
      return ClubSrv.getBoardMembers(id).then(boardMembers =>
        dispatch(getBoardMembersSuccess(id, boardMembers))
      );
    }
  };
}

export const getPartnersSuccess = (id, partners) => dispatch =>
  dispatch({
    type: actionTypes.STORE_CLUB_PARTNERS,
    data: {
      id,
      partners
    }
  });

export function getPartners(id) {
  return (dispatch, getState) => {
    const { clubs } = getState();

    if (ObjectUtils.isEmpty(clubs[id]?.partners)) {
      return ClubSrv.getPartners(id).then(partners => dispatch(getPartnersSuccess(id, partners)));
    }
  };
}

export const getSponsorsSuccess = (id, sponsors) => dispatch =>
  dispatch({
    type: actionTypes.STORE_CLUB_SPONSORS,
    data: {
      id,
      sponsors
    }
  });

export function getSponsors(id) {
  return (dispatch, getState) => {
    const { clubs } = getState();

    if (ArrayUtils.isEmpty(clubs[id]?.sponsors)) {
      return ClubSrv.getSponsors(id).then(sponsors => dispatch(getSponsorsSuccess(id, sponsors)));
    }
  };
}

export const getHistorySuccess = (id, history) => dispatch =>
  dispatch({
    type: actionTypes.STORE_CLUB_HISTORY,
    data: {
      id,
      history
    }
  });

export function getHistory(id) {
  return (dispatch, getState) => {
    const { clubs } = getState();

    if (ObjectUtils.isEmpty(clubs[id]?.history)) {
      return ClubSrv.getHistory(id).then(history => dispatch(getHistorySuccess(id, history)));
    }
  };
}

export const getStadiumDescriptionSuccess = (id, stadiumDescription) => dispatch =>
  dispatch({
    type: actionTypes.STORE_CLUB_STADIUM_DESCRIPTION,
    data: {
      id,
      stadiumDescription
    }
  });

export function getStadiumDescription(id) {
  return (dispatch, getState) => {
    const { clubs } = getState();

    if (ObjectUtils.isEmpty(clubs[id]?.stadiumDescription)) {
      return ClubSrv.getStadiumDescription(id).then(stadiumDescription =>
        dispatch(getStadiumDescriptionSuccess(id, stadiumDescription))
      );
    }
  };
}
