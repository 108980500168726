import actionTypes from './actionTypes';

const initialState = {};

export default function articleDetailsPageReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.STORE_ARTICLE_DETAILS:
      const { id: articleId, ...article } = action.data;
      return {
        ...state,
        [articleId]: article
      };
    default:
      return state;
  }
}
