import React, { memo } from 'react';
import PropTypes from 'prop-types';

import withColors from 'hoc/withColors';

import Link from '../Links/Link';
import StyledWrapper from './StyledWrapper';

const propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string,
  as: PropTypes.string
};

// TODO: Vorbert -> zastanowić się nad dodaniem propsa "link" jako alternatywe dla "to"
const Button = ({ children, to, as, ...passedProps }) => {
  const buttonTag = to ? Link : as;

  return (
    <StyledWrapper {...passedProps} as={buttonTag} to={to}>
      {children}
    </StyledWrapper>
  );
};

Button.propTypes = propTypes;

export default memo(withColors(Button));
