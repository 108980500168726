import PropTypes from 'prop-types';

import { playerPropTypes } from './player';

export const assistPropTypes = {
  minute: PropTypes.number,
  goalPlayer: PropTypes.shape({ ...playerPropTypes })
};

export const assistPropTypesShape = PropTypes.shape({ ...assistPropTypes });
