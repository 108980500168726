import ObjectUtils from 'utils/object.utils';

import actionTypes from './actionTypes';
import service from './service';

const getArticleDetailsSuccess = data => dispatch =>
  dispatch({
    type: actionTypes.STORE_ARTICLE_DETAILS,
    data
  });

export function get(id) {
  return (dispatch, getState) => {
    const { articleDetails } = getState(); // TODO: zastanowić się czy nie usunąć reducera articleDetails i brać dane z articles

    return (
      ObjectUtils.isEmpty(articleDetails[id]) &&
      service.get(id).then(article =>
        dispatch(
          getArticleDetailsSuccess({
            id,
            ...article
          })
        )
      )
    );
  };
}
