import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { playerPropTypesShape } from 'propTypes';
import { playerPositionNamesEn } from 'consts/playerPositions';
import { mapPlayersToPositionsObject } from 'mappers';
import InnerContentWrapper from 'components/InnerContentWrapper';
import PageHeader from 'components/PageHeader';
import TextReavealer from 'components/TextReavealer';

import PlayerList from './PlayerList';
import PlayerListPlaceholder from './PlayerListPlaceholder';

const propTypes = {
  players: PropTypes.arrayOf(playerPropTypesShape)
};

const PlayersPage = ({ players }) => {
  const { t } = useTranslation();

  const { goalkeepers, defenders, midfielders, strikers, unknowns } =
    mapPlayersToPositionsObject(players);

  return (
    <InnerContentWrapper subpageWrap>
      <PageHeader title={t('Players')} />
      {players !== undefined && players.length ? (
        <>
          <PlayerList
            title={t('GoalkeeperPlural')}
            players={goalkeepers}
            positionName={playerPositionNamesEn.goalkeeper}
          />
          <PlayerList
            title={t('DefenderPlural')}
            players={defenders}
            positionName={playerPositionNamesEn.defender}
          />
          <PlayerList
            title={t('MidfielderPlural')}
            players={midfielders}
            positionName={playerPositionNamesEn.midfielder}
          />
          <PlayerList
            title={t('StrikerPlural')}
            players={strikers}
            positionName={playerPositionNamesEn.striker}
          />
          <PlayerList
            title={t('UnknownPosition')}
            players={unknowns}
            positionName={playerPositionNamesEn.unknown}
          />
        </>
      ) : players === undefined ? (
        <>
          <PlayerListPlaceholder
            positionName={playerPositionNamesEn.goalkeeper}
            placeholderAmount={1}
          />
          <PlayerListPlaceholder
            positionName={playerPositionNamesEn.defender}
            placeholderAmount={3}
          />
          <PlayerListPlaceholder positionName={playerPositionNamesEn.midfielder} />
          <PlayerListPlaceholder positionName={playerPositionNamesEn.striker} />
          <PlayerListPlaceholder positionName={playerPositionNamesEn.unknown} />
        </>
      ) : (
        <TextReavealer text={t('ThereAreNotPlayersInCurrentSeason')} />
      )}
    </InnerContentWrapper>
  );
};

PlayersPage.propTypes = propTypes;

export default memo(PlayersPage);
