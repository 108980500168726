import actionTypes from './actionTypes';

const getLinkData = (offset = 0, width = 0) => ({
  offset,
  width
});

export const setHoveredLinkItem = (offset, width) => ({
  type: actionTypes.SET_HOVERED_LINK_ITEM,
  payload: getLinkData(offset, width)
});

export const setActiveLinkUrl = (linkUrl = '') => ({
  type: actionTypes.SET_ACTIVE_LINK_URL,
  payload: linkUrl
});
