import { memo } from 'react';
import styled from 'styled-components';

import Row from '../Row';

const StyledWrapper = styled(Row)``;

StyledWrapper.defaultProps = {
  alignItems: 'center'
};

export default memo(StyledWrapper);
