import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'store';
import { useDispatchOnMount } from 'utils/customHooks';
import { useGetMyClubId } from 'utils/systemParameterProvider';

import { getPartnersForMyClub } from '../my-club-actions';
import CompaniesPage from './CompaniesPage';

const PartnersPageContainer = props => {
  const { t } = useTranslation();

  const myClubId = useGetMyClubId();

  const partners = useAppSelector(state => state.clubs[myClubId]?.partners);

  useDispatchOnMount(getPartnersForMyClub());

  return <CompaniesPage {...props} companies={partners} title={t('Partners')} />;
};

export default PartnersPageContainer;
