import { createReduxHistoryContext } from 'redux-first-history';
import { reducer as formReducer } from 'redux-form';
import reduxLogger from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers, configureStore, Reducer, UnknownAction } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';

import { axiosMiddleware } from 'config/axios';
import { isNotProductionEnvironment } from 'utils/environments.utils';
import albumsPageReducer from 'modules/album/reducer';
import articleDetailsPageReducer from 'modules/article/reducer';
import articlesPageReducer from 'modules/articles/reducer';
import authReducer from 'modules/auth/reducer';
import boardMembersReducer from 'modules/board-member/reducer';
// TODO:
// import boardMembersReducer from 'modules/club/board-members/reducer';
// import contactReducer from 'modules/club/contact/reducer';
// import historyReducer from 'modules/club/history/reducer';
// import { partnersReducer, sponsorsReducer } from 'modules/club/partners-and-sponsors/reducer';
import clubsReducer from 'modules/club/reducer';
// import { playersApi } from 'modules/player/data';
import coachesSliceReducer from 'modules/coach/data';
// TODO:
// import stadiumDescriptionReducer from 'modules/club/stadium-description/reducer';
import currentCompetitionsReducer from 'modules/competitions/current-competitions-reducer';
import competitionsReducer from 'modules/competitions/reducer';
import galleryPageReducer from 'modules/gallery/reducer';
import homePageReducer from 'modules/home/reducer';
import matchesPageReducer from 'modules/match/reducer';
import playersSliceReducer, { playersApi } from 'modules/player/data';
import refereesSliceReducer from 'modules/referee/data';
import systemParametersPageReducer from 'modules/system-parameters/reducer';
import teamsReducer from 'modules/team/reducer';
import navbarReducer from 'components/Navbar/reducer';
import toastrReducer from 'components/Toastr/reducer';

import filesSliceReducer from 'modules/club/documents/data';

import globalLoaderSlice from '../GlobalLoader/globalLoaderSlice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'userSettings']
};

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory()
});

const rootReducer = combineReducers({
  globalLoader: globalLoaderSlice,
  router: routerReducer,
  form: formReducer,
  toastr: toastrReducer,

  navbar: navbarReducer,
  systemParameters: systemParametersPageReducer,
  auth: authReducer,
  home: homePageReducer,
  articles: articlesPageReducer,
  articleDetails: articleDetailsPageReducer,
  albums: galleryPageReducer,
  album: albumsPageReducer,
  files: filesSliceReducer,

  matches: matchesPageReducer,
  competitions: competitionsReducer,
  currentCompetitions: currentCompetitionsReducer,

  coaches: coachesSliceReducer,
  boardMembers: boardMembersReducer,

  players: playersSliceReducer,
  // [playersApi.reducerPath]: playersApi.reducer,

  referees: refereesSliceReducer,

  clubs: clubsReducer,
  teams: teamsReducer
});

const resettableRootReducer: Reducer = (state: RootState, action: UnknownAction) => {
  if (action.type === 'auth/logout/pending') {
    storage.removeItem('persist:root');
    Object.keys(state).forEach(key => key !== 'router' && delete state[key]);
    return rootReducer(state, action);
  }

  return rootReducer(state, action);
};

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

export const store = configureStore({
  reducer: persistReducer(persistConfig, resettableRootReducer),
  devTools: isNotProductionEnvironment(),
  enhancers: getDefaultEnhancers => getDefaultEnhancers().concat(sentryReduxEnhancer),
  middleware: getDefaultMiddleware => {
    const baseMiddlewares = getDefaultMiddleware({
      serializableCheck: {
        ignoredPaths: ['_persist'],
        ignoredActions: ['persist/PERSIST']
      }
    })
      // .concat(errorMiddleware) // TODO: do I need this? error handling is also in axiosMiddleware
      // .concat(axiosMiddleware)
      // .concat(routerMiddleware)
      // .concat(playersApi.middleware);
      .concat(axiosMiddleware, routerMiddleware, playersApi.middleware);

    const isReduxLoggerEnabled = false;

    return isNotProductionEnvironment() && isReduxLoggerEnabled
      ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (baseMiddlewares.concat(reduxLogger) as any) // TODO: remove any
      : // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (baseMiddlewares as any); // TODO: remove any
  }
});

export const persistor = persistStore(store);
// export type RootState = ReturnType<typeof store.getState>;
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export const history = createReduxHistory(store);
