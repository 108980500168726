import React, { memo } from 'react';
import PropTypes from 'prop-types';

import styles from './FormTitle.module.scss';

const propTypes = {
  children: PropTypes.node.isRequired
};

const FormTitle = ({ children }) => <h2 className={styles.title}>{children}</h2>;

FormTitle.propTypes = propTypes;

export default memo(FormTitle);
