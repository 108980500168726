import { OutputSelector } from '@reduxjs/toolkit';
import { ERequestStatus, RequestStatus } from 'models/common.models';

import { useAppSelector } from 'store';

import ObjectUtils from './object.utils';

export function getRequestStatusMapped(
  status: ERequestStatus,
  errorMessage?: string | null
): RequestStatus {
  const isUninitialized = status === undefined || status === null;
  const isLoading = status === ERequestStatus.pending;
  const isError = status === ERequestStatus.rejected;
  const isSuccess = status === ERequestStatus.fulfilled;

  return { status, errorMessage, isUninitialized, isLoading, isError, isSuccess };
}

export function shouldMakeRequest(requestStatus: RequestStatus): boolean {
  return (
    (ObjectUtils.isEmpty(requestStatus) || requestStatus?.isUninitialized) &&
    !requestStatus?.isLoading
  );
}

export const useGetRequestStatusStatus = (
  id: string | undefined,
  selector: OutputSelector
): RequestStatus => useAppSelector(state => selector(state, id as string)) as RequestStatus;

export const requestStatusPending: RequestStatus = getRequestStatusMapped(
  ERequestStatus.pending,
  null
);

export const requestStatusFulfilled: RequestStatus = getRequestStatusMapped(
  ERequestStatus.fulfilled,
  null
);

export const requestStatusRejected = action =>
  getRequestStatusMapped(ERequestStatus.rejected, action.payload?.message || action.error.message);
