import React, { memo } from 'react';
import Link from 'components/Links/Link';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import styled from 'styled-components';

import { icons } from 'consts/icons';
import urls from 'consts/urls';
import Column from 'components/Column';
import Row from 'components/Row';

import styles from './TeamEntry.module.scss';

export const StyledNoLogoMessage = styled.div`
  color: ${({ theme }) => theme.blackColor};
`;

const propTypes = {
  id: PropTypes.string.isRequired,
  logo: PropTypes.string,
  name: PropTypes.string.isRequired,
  isNameVisible: PropTypes.bool
};

const TeamEntry = ({ id, logo, name, isNameVisible = true }) => {
  const { t } = useTranslation();

  const teamContainerClassName = cx(styles.team_container);

  const noLogoIconClassName = cx(icons.image, styles.team_noLogoIcon);

  return (
    <Row className={teamContainerClassName}>
      <Link to={urls.teamDetails(id)}>
        <Column className={styles.team}>
          {isNameVisible && <h3 className={styles.team_name}>{name}</h3>}
          {logo ? (
            <img src={logo} alt={t('ClubLogo')} className={styles.team_logo} />
          ) : (
            <i className={noLogoIconClassName} />
          )}
        </Column>
      </Link>
    </Row>
  );
};

TeamEntry.propTypes = propTypes;

export default memo(TeamEntry);
