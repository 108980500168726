import { memo, useEffect, useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import type { PDFDocumentProxy } from 'pdfjs-dist';
import { convertBase64ToByteArray } from 'utils/filesHelper';
import { IFileModel } from 'modules/club/documents/data/models';

import styles from './PdfViewer.module.scss';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url
).toString();

const maxWidth = 800;

type Props = {
  file: IFileModel | null;
};

const PdfViewer = ({ file }: Readonly<Props>) => {
  const [byteArray, setByteArray] = useState<Uint8Array>();
  const [numberOfPages, setNumberOfPages] = useState<number>();

  const onDocumentLoadSuccess = ({ numPages: numberOfPages }: PDFDocumentProxy): void =>
    setNumberOfPages(numberOfPages);

  const pages = Array.from(new Array(numberOfPages), (_el, index) => (
    <Page key={`page_${index + 1}`} pageNumber={index + 1} width={maxWidth} />
  ));

  useEffect(() => {
    if (file?.base64Content) {
      setByteArray(convertBase64ToByteArray(file.base64Content));
    }
  }, [file]);

  return (
    <div className={styles.container}>
      <div className={styles.container_document}>
        <Document file={byteArray?.buffer as ArrayBuffer} onLoadSuccess={onDocumentLoadSuccess}>
          {pages}
        </Document>
      </div>
    </div>
  );
};

export default memo(PdfViewer);
