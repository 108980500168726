import React, { memo } from 'react';
import styled from 'styled-components';

import Skeleton from 'components/Skeleton';

export const SkeletonRow = styled.div`
  display: flex;
  padding: 0.5;
`;

const ThreeRowsSkeletons = () => {
  return (
    <>
      <SkeletonRow>
        <Skeleton width="7rem" style={{ marginRight: '1rem' }} />
        <Skeleton width="15rem" style={{ marginRight: '1rem' }} />
        <Skeleton width="10rem" style={{ marginRight: '1rem' }} />
        <Skeleton width="25rem" style={{ marginRight: '1rem' }} />
        <Skeleton width="15rem" style={{ marginRight: '1rem' }} />
      </SkeletonRow>

      <SkeletonRow>
        <Skeleton width="7rem" style={{ marginRight: '1rem' }} />
        <Skeleton width="15rem" style={{ marginRight: '1rem' }} />
        <Skeleton width="10rem" style={{ marginRight: '1rem' }} />
        <Skeleton width="25rem" style={{ marginRight: '1rem' }} />
        <Skeleton width="15rem" style={{ marginRight: '1rem' }} />
      </SkeletonRow>

      <SkeletonRow>
        <Skeleton width="7rem" style={{ marginRight: '1rem' }} />
        <Skeleton width="15rem" style={{ marginRight: '1rem' }} />
        <Skeleton width="10rem" style={{ marginRight: '1rem' }} />
        <Skeleton width="25rem" style={{ marginRight: '1rem' }} />
        <Skeleton width="15rem" style={{ marginRight: '1rem' }} />
      </SkeletonRow>
    </>
  );
};

export default memo(ThreeRowsSkeletons);
