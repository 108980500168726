// Use throughout your app instead of plain `useDispatch` and `useSelector`
import { useDispatch, useSelector } from 'react-redux';

// import { ThunkDispatch, UnknownAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from './store';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
// type TypedDispatch<T> = ThunkDispatch<T, any, UnknownAction>;

// export const useAppDispatch: () => AppDispatch = useDispatch;
// export const useAppDispatch = () => useDispatch<AppDispatch>();
// export const useAppDispatch = () => useDispatch<TypedDispatch<RootState>>();
// export const useAppDispatch = () => useDispatch<TypedDispatch<RootState2>>();
// export const useAppDispatch = () => useDispatch<TypedDispatch<AppDispatch>>();
export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
// export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppSelector = useSelector.withTypes<RootState>();

// export const useAppStore = useStore.withTypes<AppStore>();

// TODO:
// // create a generic type called AppSelector
// export type AppSelector<Return> = (state: RootState) => Return;
// // create a custom `createSelector` that uses the type above
// export const createAppSelector = <R>(selector: AppSelector<R>): AppSelector<R> => selector;

// const selectTitle: AppSelector<string> = state => state.title;
// // Note that using the AppSelector type requires you to always pass the return type argument
// // It's much easier to use the createAppSelector function
// // selectTitle and selectTitle2 accomplish the same thing
// const selectTitle2 = createAppSelector(state => state.title);
