import React, { memo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { icons } from 'consts/icons';
import { useIsCurrentlyOnUrl } from 'utils/customHooks';
import styles from './SubmenuLink.module.scss';
import Submenu from './Submenu';

const propTypes = {
  address: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  subLinks: PropTypes.arrayOf(PropTypes.object),
  external: PropTypes.bool,
  className: PropTypes.string
};

const SubmenuLink = ({ address, label, subLinks, external, className, ...others }) => {
  const isActiveUrl = useIsCurrentlyOnUrl(address);

  const [visibleSubmenu, setVisibleSubmenu] = useState(false);

  const linkClassName = cx(styles.link, className, {
    [styles.link_active]: isActiveUrl,
    [styles.link_with_submenu]: subLinks
  });

  const content = (
    <>
      {label}
      {subLinks && <i className={cx(styles.submenu_icon, icons.chevronRight)} />}
    </>
  );

  return (
    <div
      className={styles.container}
      onMouseEnter={() => setVisibleSubmenu(true)}
      onMouseLeave={() => setVisibleSubmenu(false)}
    >
      {!external ? (
        <NavLink {...others} to={address} className={linkClassName}>
          {content}
        </NavLink>
      ) : (
        <a target="_blank" rel="noreferrer" href={address} className={linkClassName}>
          {content}
        </a>
      )}
      {subLinks && <Submenu subLinksData={subLinks} visible={visibleSubmenu} />}
    </div>
  );
};

SubmenuLink.propTypes = propTypes;

export default memo(SubmenuLink);
