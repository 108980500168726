import React, { memo } from 'react';
import PropTypes from 'prop-types';

import GoalIcon from 'components/Icon/GoalIcon';

import EventWrapper from './EventWrapper';

const propTypes = {
  minute: PropTypes.number.isRequired,
  isOwn: PropTypes.bool.isRequired,
  isPenalty: PropTypes.bool.isRequired
};

const ScoredGoalShortEvent = ({ minute, isOwn, isPenalty }) => (
  <EventWrapper color={!isOwn ? (!isPenalty ? 'green' : 'orange') : 'red'} minute={minute}>
    <GoalIcon isOwn={isOwn} isPenalty={isPenalty} />
  </EventWrapper>
);

ScoredGoalShortEvent.propTypes = propTypes;

export default memo(ScoredGoalShortEvent);
