import { playerPositionNamesEn } from 'consts/playerPositions';

const playerPositionsMapper = {
  Bramkarz: playerPositionNamesEn.goalkeeper,
  Obrońca: playerPositionNamesEn.defender,
  Pomocnik: playerPositionNamesEn.midfielder,
  Napastnik: playerPositionNamesEn.striker
};

export const mapPlayerPositionName = positionName => playerPositionsMapper[positionName];

const getPlayerPositionGroupName = player => {
  let positionName = playerPositionNamesEn.unknown;

  if (player.position) {
    positionName = player.position.name;
  }

  return `${positionName}s`;
};

export const mapPlayersToPositionsObject = (players = []) => {
  const defaultPositionsObject = {
    goalkeepers: [],
    defenders: [],
    midfielders: [],
    strikers: [],
    unknowns: []
  };

  return players.reduce((aggregator, player) => {
    const positionName = getPlayerPositionGroupName(player);
    aggregator[positionName.toLowerCase()].push(player);
    return aggregator;
  }, defaultPositionsObject);
};
