import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { EColors } from 'consts/colors';
import Button from 'components/Button';

const propTypes = {
  children: PropTypes.node.isRequired
};

const SwitchFormsButton = ({ children, ...others }) => (
  <Button {...others} colorType={EColors.opaqueDark}>
    {children}
  </Button>
);

SwitchFormsButton.propTypes = propTypes;

export default memo(SwitchFormsButton);
