import React, { memo } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  errorUndefinedObject: PropTypes.object
};

const ErrorTestPage = ({ errorUndefinedObject }) => (
  <div>{errorUndefinedObject}</div>
  // <div>{errorUndefinedObject.undefinedProperty}</div>
);

ErrorTestPage.propTypes = propTypes;

export default memo(ErrorTestPage);
