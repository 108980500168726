import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Card from 'components/Card';
import Column from 'components/Column';

import styles from './ContactCard.module.scss';
import ContactInformations from './ContactInformations';
import SupportInformation from './SupportInformation';

const propTypes = {
  contact: PropTypes.shape({
    logo: PropTypes.string,
    photo: PropTypes.string,
    name: PropTypes.string,
    address: PropTypes.exact({
      city: PropTypes.string,
      street: PropTypes.string,
      buildingNumber: PropTypes.string,
      apartmentNumber: PropTypes.string,
      zipCode: PropTypes.string,
      postOffice: PropTypes.string
    }),
    nip: PropTypes.string,
    regon: PropTypes.string,
    krs: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
    bankName: PropTypes.string,
    bankAccountNumber: PropTypes.string
  })
};

const ContactCard = ({ contact }) => {
  const { t } = useTranslation();

  return (
    <Card autoWidth alignCenter className={styles.contact_card}>
      <Column className={styles.contact_column}>
        <img src={contact.logo} alt={t('ClubLogo')} className={styles.contact_logo} />
        <h2>{contact.name}</h2>
        <ContactInformations {...contact} />
        {contact.krs && <SupportInformation krs={contact.krs} />}
      </Column>
    </Card>
  );
};

ContactCard.propTypes = propTypes;

export default memo(ContactCard);
