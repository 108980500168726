import PropTypes from 'prop-types';

import { personPropTypes } from './person';

export const playerPropTypes = {
  id: PropTypes.string,
  ...personPropTypes,
  position: PropTypes.exact({
    id: PropTypes.string,
    name: PropTypes.string
  })
};

export const playerPropTypesShape = PropTypes.shape({
  ...playerPropTypes
});
