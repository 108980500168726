// TODO:
/* eslint-disable @typescript-eslint/no-explicit-any */

import { AsyncThunk, createSlice, UnknownAction } from '@reduxjs/toolkit';

export interface GlobalLoaderState {
  active: boolean;
  pendingRequests: string[];
}

const initialState: GlobalLoaderState = {
  active: false,
  pendingRequests: []
};

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;
type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;
type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>;

const isPendingAction = (action: UnknownAction): action is PendingAction =>
  action.type.endsWith('/pending');
const isRejectedAction = (action: UnknownAction): action is RejectedAction =>
  action.type.endsWith('/rejected');
const isFulfilledAction = (action: UnknownAction): action is FulfilledAction =>
  action.type.endsWith('/fulfilled');

const blacklist: any[] = []; // TODO: fill

function isBlackListed(action: PendingAction) {
  return !!blacklist.find(x => x.pending.match(action));
}

const globalLoaderSlice = createSlice({
  name: 'globalLoader',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addMatcher(isPendingAction, (state, action) => {
        if (isBlackListed(action)) {
          return state;
        }

        state.pendingRequests.push(action.meta.requestId);
        state.active = !!state.pendingRequests.length;
      })
      .addMatcher(isRejectedAction, (state, action) => {
        state.pendingRequests = state.pendingRequests.filter(x => x != action.meta.requestId);
        state.active = !!state.pendingRequests.length;
      })
      .addMatcher(isFulfilledAction, (state, action) => {
        state.pendingRequests = state.pendingRequests.filter(x => x != action.meta.requestId);
        state.active = !!state.pendingRequests.length;
      });
  }
});

export default globalLoaderSlice.reducer;
