import React, { memo } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';

import Article from 'components/Article';
import ArticlePlaceholder from 'components/ArticlePlaceholder';
import InnerContentWrapper from 'components/InnerContentWrapper';

const propTypes = {
  history: PropTypes.shape({
    photo: PropTypes.string,
    history: PropTypes.string
  })
};

const HistoryPage = ({ history }) => {
  const { t } = useTranslation();

  return (
    <InnerContentWrapper subpageWrap>
      {!isEmpty(history) ? (
        <Article photo={history.photo} title={t('ClubHistory')} content={history.history} />
      ) : (
        <ArticlePlaceholder />
      )}
    </InnerContentWrapper>
  );
};

HistoryPage.propTypes = propTypes;

export default memo(HistoryPage);
