import { reduxForm } from 'redux-form';
import i18n from 'i18n';

import fieldNames from 'consts/fieldNames';
import formNames from 'consts/formNames';
import {
  areEquals,
  isEmail,
  isRequiredArray,
  isTrue,
  isValidPasswordArray
} from 'utils/formValidators';

import RegisterForm from './RegisterForm';

const validate = values => {
  const errors = {};

  isRequiredArray(
    errors,
    values,
    [
      fieldNames.registerForm.email,
      fieldNames.registerForm.login,
      fieldNames.registerForm.password,
      fieldNames.registerForm.confirmPassword,
      fieldNames.registerForm.acceptRegulations
    ],
    i18n.t('FieldRequired')
  );

  isEmail(errors, values, fieldNames.registerForm.email, i18n.t('FieldMustBeValidEmail'));

  isTrue(errors, values, fieldNames.registerForm.acceptRegulations, i18n.t('FieldRequired'));

  isValidPasswordArray(
    errors,
    values,
    [fieldNames.registerForm.password, fieldNames.registerForm.confirmPassword],
    i18n.t('NotValidPassword')
  );

  areEquals(
    errors,
    values,
    [fieldNames.registerForm.password, fieldNames.registerForm.confirmPassword],
    i18n.t('PasswordsNotEquals')
  );

  return errors;
};

export default reduxForm({
  form: formNames.registerForm,
  validate
})(RegisterForm);
