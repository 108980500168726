import { t } from 'i18next';

import { getUserLabel } from 'utils/getUserLabel';
import { displayToastr } from 'components/Toastr/actions';
import ToastrTypes from 'components/Toastr/ToastTypes';

import actionTypes from './actionTypes';
import AuthSrv from './AuthSrv';

function storeToken(token) {
  localStorage.setItem('accessToken', token);
}

export const registerSuccess = data => dispatch =>
  dispatch({
    type: actionTypes.AUTH_REGISTER_SUCCESS,
    data
  });

export function registerAsync(model) {
  return dispatch =>
    AuthSrv.register(model).then(data => {
      storeToken(data.accessToken);
      dispatch(registerSuccess(data));
      dispatch(displayToastr(t('SuccessfullyCreatedAccount'), ToastrTypes.success));
    });
}

export const loginSuccess = data => dispatch =>
  dispatch({
    type: actionTypes.AUTH_LOGIN_SUCCESS,
    data
  });

export function loginAsync(model) {
  return dispatch =>
    AuthSrv.login(model).then(data => {
      storeToken(data.accessToken);
      dispatch(loginSuccess(data));
      dispatch(
        displayToastr(
          `${t('Welcome')}
          ${getUserLabel(data)}`, // TODO: w data przychodzi tylko token, albo wyslac wiecej danych albo rozkodowac
          ToastrTypes.success
        )
      );

      // TODO: nie działa przekierowanie
      // browserHistory.push('/home');
    });
}

export function setNewPasswordAsync(model) {
  return () => AuthSrv.setNewPasswordAsync(model);
}

export function resetPasswordAsync(model) {
  return dispatch =>
    AuthSrv.resetPassword(model).then(() =>
      dispatch(displayToastr(t('NewPasswordWasSet'), ToastrTypes.success))
    );
}

export function changePasswordFormAsync(model) {
  return dispatch =>
    AuthSrv.changePassword(model).then(() =>
      dispatch(displayToastr(t('PasswordChanged'), ToastrTypes.success))
    );
}

export const logout = () => dispatch => {
  dispatch({
    type: actionTypes.AUTH_LOGOUT
  });
  dispatch(displayToastr(t('YouHaveBeenLoggedOut'), ToastrTypes.info));
};

export const storeUserInformations = data => dispatch =>
  dispatch({
    type: actionTypes.AUTH_STORE_USER_INFORMATIONS,
    data
  });

export function updateUserInformationAsync(model) {
  return dispatch =>
    AuthSrv.updateUserInformation(model).then(data => {
      dispatch(storeUserInformations(data));
      dispatch(displayToastr(t('UserInformationUpdated'), ToastrTypes.success));
    });
}
