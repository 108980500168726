import React, { memo } from 'react';
import { useParams } from 'react-router-dom';

import { useAppSelector } from 'store';
import { useDispatchOnMountWithLocation } from 'utils/customHooks';

import { get } from './actions';
import ArticleDetailsPage from './ArticleDetailsPage';

const ArticleDetailsPageContainer = props => {
  const { id } = useParams();
  const articleDetails = useAppSelector(state => state.articleDetails[id]); // TODO: zastanowić się czy nie usunąć reducera articleDetails i brać dane z articles

  useDispatchOnMountWithLocation(get(id));

  return <ArticleDetailsPage {...props} articleDetails={articleDetails} />;
};

export default memo(ArticleDetailsPageContainer); // TODO: czy w kontenerach powinno być też memo? czy wystarczy że jest w komponentach page? ujednolicić w całej aplikacji
