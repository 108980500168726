import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import MatchesStatusesLegend from 'components/MatchesStatusesLegend';

import AbbreviationDescription from './AbbreviationDescription';
import styles from './TableFooter.module.scss';
import structure from './TableHeaderStructure';

const TableFooter = () => {
  const { t } = useTranslation();

  const columnsCount = structure.length;

  return (
    <tr>
      <td className={styles.footer_row} colSpan={columnsCount}>
        <AbbreviationDescription
          abbreviation={t('PlayedMatchesQuantityAbbreviation')}
          description={t('PlayedMatchesQuantity')}
        />
        <AbbreviationDescription
          abbreviation={t('ScoredPointsQuantityAbbreviation')}
          description={t('ScoredPointsQuantity')}
        />
        <AbbreviationDescription
          abbreviation={t('WinsQuantityAbbreviation')}
          description={t('WinsQuantity')}
        />
        <AbbreviationDescription
          abbreviation={t('DrawsQuantityAbbreviation')}
          description={t('DrawsQuantity')}
        />
        <AbbreviationDescription
          abbreviation={t('LostsQuantityAbbreviation')}
          description={t('LostsQuantity')}
        />
        <AbbreviationDescription
          abbreviation={t('ScoredGoalsQuantityAbbreviation')}
          description={t('ScoredGoalsQuantity')}
        />
        <AbbreviationDescription
          abbreviation={t('LostGoalsQuantityAbbreviation')}
          description={t('LostGoalsQuantity')}
        />
        <AbbreviationDescription
          abbreviation={t('GoalsQuantityDifferenceAbbreviation')}
          description={t('GoalsQuantityDifference')}
        />
        <MatchesStatusesLegend />
      </td>
    </tr>
  );
};

export default memo(TableFooter);
