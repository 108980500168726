export enum EColumnIndexes {
  fullName = 0,
  minutesInAllMatchesCount = 1,
  goalsInAllMatchesCount = 2,
  ownGoalsInAllMatchesCount = 3,
  assistsInAllMatchesCount = 4,
  yellowCardsInAllMatchesCount = 5,
  secondYellowCardsInAllMatchesCount = 6,
  redCardsInAllMatchesCount = 7
}

export enum EColumnNames {
  fullName = 'fullName',
  minutesInAllMatchesCount = 'minutesInAllMatchesCount',
  goalsInAllMatchesCount = 'goalsInAllMatchesCount',
  ownGoalsInAllMatchesCount = 'ownGoalsInAllMatchesCount',
  assistsInAllMatchesCount = 'assistsInAllMatchesCount',
  yellowCardsInAllMatchesCount = 'yellowCardsInAllMatchesCount',
  secondYellowCardsInAllMatchesCount = 'secondYellowCardsInAllMatchesCount',
  redCardsInAllMatchesCount = 'redCardsInAllMatchesCount'
}

export const columnsToDisplay = [
  EColumnNames.fullName,
  EColumnNames.minutesInAllMatchesCount,
  EColumnNames.goalsInAllMatchesCount,
  EColumnNames.ownGoalsInAllMatchesCount,
  EColumnNames.assistsInAllMatchesCount,
  EColumnNames.yellowCardsInAllMatchesCount,
  EColumnNames.secondYellowCardsInAllMatchesCount,
  EColumnNames.redCardsInAllMatchesCount
];
