import { RequestStatus } from 'models/common.models';

import { useAppSelector } from 'store';
import { useGetRequestStatusStatus } from 'utils/requests.utils';

import {
  selectCoach,
  selectCoachCurrentTeam,
  selectCoachDebutMatch,
  selectCoachDetails,
  selectCoachMatchesInSeason,
  selectCoachPhoto,
  selectCoachSeason,
  selectCoachSeasons,
  selectGetCoachCurrentTeamRequestStatus,
  selectGetCoachDebutMatchRequestStatus,
  selectGetCoachDetailsRequestStatus,
  selectGetCoachMatchesInSeasonRequestStatus,
  selectGetCoachPhotoRequestStatus,
  selectGetCoachSeasonRequestStatus,
  selectGetCoachSeasonsRequestStatus
} from './coach.selectors';
import {
  ICoachCurrentTeamModel,
  ICoachDebutMatchModel,
  ICoachDetailsModel,
  ICoachMatchModel,
  ICoachModel,
  ICoachSeasonModel
} from './models';

export const useSelectCoach = (id: string | undefined): ICoachModel | undefined | null =>
  useAppSelector(state => selectCoach(state, id as string));

export const useSelectGetCoachDetailsRequestStatus = (id: string | undefined): RequestStatus =>
  useGetRequestStatusStatus(id, selectGetCoachDetailsRequestStatus);

export const useSelectCoachDetails = (
  id: string | undefined
): ICoachDetailsModel | undefined | null =>
  useAppSelector(state => selectCoachDetails(state, id as string));

export const useSelectGetCoachPhotoRequestStatus = (id: string | undefined): RequestStatus =>
  useGetRequestStatusStatus(id, selectGetCoachPhotoRequestStatus);

export const useSelectCoachPhoto = (id: string | undefined): string | undefined | null =>
  useAppSelector(state => selectCoachPhoto(state, id as string));

export const useSelectGetCoachCurrentTeamRequestStatus = (id: string | undefined): RequestStatus =>
  useGetRequestStatusStatus(id, selectGetCoachCurrentTeamRequestStatus);

export const useSelectCoachCurrentTeam = (
  id: string | undefined
): ICoachCurrentTeamModel | undefined | null =>
  useAppSelector(state => selectCoachCurrentTeam(state, id as string));

export const useSelectGetCoachDebutMatchRequestStatus = (id: string | undefined): RequestStatus =>
  useGetRequestStatusStatus(id, selectGetCoachDebutMatchRequestStatus);

export const useSelectCoachDebutMatch = (
  id: string | undefined
): ICoachDebutMatchModel | undefined | null =>
  useAppSelector(state => selectCoachDebutMatch(state, id as string));

export const useSelectGetCoachSeasonsRequestStatus = (id: string | undefined): RequestStatus =>
  useGetRequestStatusStatus(id, selectGetCoachSeasonsRequestStatus);

export const useSelectCoachSeasons = (
  id: string | undefined
): ICoachSeasonModel[] | undefined | null =>
  useAppSelector(state => selectCoachSeasons(state, id as string));

export const useSelectGetCoachSeasonRequestStatus = (
  id: string | undefined,
  seasonId: string | undefined
): RequestStatus =>
  useAppSelector(state =>
    selectGetCoachSeasonRequestStatus(state, id as string, seasonId as string)
  ) as RequestStatus;

export const useSelectCoachSeason = (
  id: string | undefined,
  seasonId: string | undefined
): ICoachSeasonModel | undefined | null =>
  useAppSelector(state => selectCoachSeason(state, id as string, seasonId as string));

export const useSelectGetCoachMatchesInSeasonRequestStatus = (
  id: string | undefined,
  seasonId: string | undefined
): RequestStatus =>
  useAppSelector(state =>
    selectGetCoachMatchesInSeasonRequestStatus(state, id as string, seasonId as string)
  ) as RequestStatus;

export const useSelectCoachMatchesInSeason = (
  id: string | undefined,
  seasonId: string | undefined
): ICoachMatchModel[] | undefined | null =>
  useAppSelector(state => selectCoachMatchesInSeason(state, id as string, seasonId as string));
