import React, { memo } from 'react';

import { useAppSelector } from 'store';
import { useDispatchOnMountWithLocationMultiple } from 'utils/customHooks';
import { useGetMyTeamId } from 'utils/systemParameterProvider';

import {
  getCoachesForMyTeamInCurrentSeason,
  getDetailsForMyTeam,
  getMatchesForMyTeamInCurrentSeason,
  getPlayersWithMatchesAndEventsForMyTeamInCurrentSeason,
  getSeasonsListForMyTeam
} from './my-team-actions';
import TeamDetailsPage from './TeamDetailsPage';

const MyTeamDetailsPageContainer = props => {
  const myTeamId = useGetMyTeamId();

  const team = useAppSelector(state => state.teams[myTeamId]);

  const details = team?.details;
  const seasons = team?.seasons;
  const coachesInCurrentSeason = team?.coachesInCurrentSeason;
  const matchesInCurrentSeason = team?.matchesInCurrentSeason;
  const playersWithMatchesAndEventsInCurrentSeason =
    team?.playersWithMatchesAndEventsInCurrentSeason;

  useDispatchOnMountWithLocationMultiple([
    getDetailsForMyTeam(),
    getSeasonsListForMyTeam(),
    getCoachesForMyTeamInCurrentSeason(),
    getPlayersWithMatchesAndEventsForMyTeamInCurrentSeason(),
    getMatchesForMyTeamInCurrentSeason()
  ]);

  return (
    <TeamDetailsPage
      {...props}
      teamId={myTeamId}
      details={details}
      seasons={seasons}
      coachesInCurrentSeason={coachesInCurrentSeason}
      matchesInCurrentSeason={matchesInCurrentSeason}
      playersWithMatchesAndEventsInCurrentSeason={playersWithMatchesAndEventsInCurrentSeason}
    />
  );
};

export default memo(MyTeamDetailsPageContainer);
