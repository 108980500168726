import React, { memo } from 'react';
import PropTypes from 'prop-types';

import MyCheckbox from './MyCheckbox';

const propTypes = {
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  invalid: PropTypes.bool
};

const MyCheckboxGroup = ({ value, invalid, ...others }) => (
  <MyCheckbox {...others} invalid={!value || invalid} />
);

MyCheckboxGroup.propTypes = propTypes;

export default memo(MyCheckboxGroup);
