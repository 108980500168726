import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Row from '../../Row';
import styles from './MyCheckbox.module.scss';

const propTypes = {
  className: PropTypes.string,
  render: PropTypes.func,
  invalid: PropTypes.bool,
  required: PropTypes.bool
};

const MyCheckbox = ({ className, invalid, required, render = () => {}, ...others }) => {
  const checkboxContainerClassName = cx(styles.container, className);
  const requiredAsterixClassName = cx(invalid ? 'text-error' : 'text-green');

  return (
    <Row as="label" className={checkboxContainerClassName} alignItems="center">
      <input type="checkbox" {...others} />
      <div className={styles.checkbox} />
      <span className={styles.content}>
        {render()}
        {required && <span className={requiredAsterixClassName}>*</span>}
      </span>
    </Row>
  );
};

MyCheckbox.propTypes = propTypes;

export default memo(MyCheckbox);
