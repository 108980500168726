import React, { memo } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import Article from 'components/Article';
import ArticlePlaceholder from 'components/ArticlePlaceholder';
import InnerContentWrapper from 'components/InnerContentWrapper';

const propTypes = {
  stadiumDescription: PropTypes.shape({
    photos: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string,
    description: PropTypes.string
  })
};

const StadiumDescriptionPage = ({ stadiumDescription }) => (
  <InnerContentWrapper subpageWrap>
    {!isEmpty(stadiumDescription) ? (
      <Article
        photo={stadiumDescription.photos && stadiumDescription.photos[0]}
        title={stadiumDescription.name}
        content={stadiumDescription.description}
        photos={stadiumDescription.photos}
      />
    ) : (
      <ArticlePlaceholder />
    )}
  </InnerContentWrapper>
);

StadiumDescriptionPage.propTypes = propTypes;

export default memo(StadiumDescriptionPage);
