import React, { memo } from 'react';
import PropTypes from 'prop-types';

import CardYellowIcon from 'components/Icon/CardYellowIcon';

import EventWrapper from './EventWrapper';

const propTypes = {
  minute: PropTypes.number.isRequired,
  count: PropTypes.number
};

const GivenCardYellowShortEvent = ({ minute, count }) => (
  <EventWrapper color="yellow" minute={minute}>
    <CardYellowIcon count={count} />
  </EventWrapper>
);

GivenCardYellowShortEvent.propTypes = propTypes;

export default memo(GivenCardYellowShortEvent);
