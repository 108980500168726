import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useAppDispatch } from 'store';
import { useGetCurrentSeasonId, useGetYouthTeams } from 'utils/systemParameterProvider';
import { isExactCurrentRoute, isExactCurrentSubRoute } from 'utils/utils';

import { setActiveLinkUrl } from './actions';
import { getLinksData } from './menuLinksData';

const withMenuLinks = Component => props => {
  const [linksData, setLinksData] = useState(() => getLinksData());
  const [links, setLinks] = useState([]);
  const dispatch = useAppDispatch();
  const currentLocation = useLocation();

  const currentSeasonId = useGetCurrentSeasonId();

  const youthTeams = useGetYouthTeams();

  const getAddress = object => object.address;

  const getLinksAddresses = linkssData =>
    linkssData.flatMap(linkData =>
      linkData.subLinks
        ? linkData.subLinks.map(sublink => getAddress(sublink))
        : getAddress(linkData)
    );

  useEffect(() => {
    const currentLinksData = getLinksData(currentSeasonId, youthTeams);
    setLinksData(currentLinksData);
    setLinks(getLinksAddresses(currentLinksData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSeasonId, youthTeams]);

  useEffect(
    () => {
      dispatch(
        setActiveLinkUrl(
          links.find(
            link =>
              isExactCurrentSubRoute(currentLocation.pathname, link) ||
              isExactCurrentRoute(currentLocation.pathname, link)
          )
        )
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentLocation, dispatch, links]
  );

  return <Component {...props} linksData={linksData} />;
};

export default withMenuLinks;
