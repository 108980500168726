import styles from './TableHeader.module.scss';

// TODO: tytuły i skróty, nie działają translacje

const structure = [
  {
    className: styles.position,
    // content: i18n.t('OrdinalNumberAbbreviation'),
    // title: i18n.t('OrdinaryNumber')
    content: 'Lp.',
    title: 'Liczba porządkowa'
  },
  {
    className: styles.team,
    // content: i18n.t('Team'),
    // title: i18n.t('Team')
    content: 'Drużyna',
    title: 'Drużyna'
  },
  {
    className: styles.numeric,
    // content: i18n.t('PlayedMatchesQuantityAbbreviation')
    // title: i18n.t('PlayedMatchesQuantity')
    content: 'M',
    title: 'Liczba rozegranych meczy'
  },
  {
    className: styles.numeric,
    // content: i18n.t('ScoredPointsQuantityAbbreviation')
    // title: i18n.t('ScoredPointsQuantity')
    content: 'Pkt',
    title: 'Liczba zdobytych punktów'
  },
  {
    className: styles.numeric,
    // content: i18n.t('WinsQuantityAbbreviation'),
    // title: i18n.t('WinsQuantity')
    content: 'W',
    title: 'Liczba zwycięstw'
  },
  {
    className: styles.numeric,
    // content: i18n.t('DrawsQuantityAbbreviation'),
    // title: i18n.t('DrawsQuantity')
    content: 'R',
    title: 'Liczba remisów'
  },
  {
    className: styles.numeric,
    // content: i18n.t('Loss'),
    // title: i18n.t('LostsQuantity')
    content: 'P',
    title: 'Liczba porażek'
  },
  {
    className: styles.numeric,
    // content: i18n.t('ScoredGoalsQuantityAbbreviation'),
    // title: i18n.t('ScoredGoalsQuantity')
    content: 'Bz',
    title: 'Liczba zdobytych bramek'
  },
  {
    className: styles.numeric,
    // content: i18n.t('LostGoalsQuantityAbbreviation'),
    // title: i18n.t('LostGoalsQuantity')
    content: 'Bs',
    title: 'Liczba straconych bramek'
  },
  {
    className: styles.numeric,
    // content: i18n.t('GoalsQuantityDifferenceAbbreviation'),
    // title: i18n.t('GoalsQuantityDifference')
    content: 'RB',
    title: 'Różnica liczby bramek'
  },
  {
    className: styles.form,
    // content: i18n.t('Form'),
    // title: i18n.t('Form'),
    content: 'Forma',
    title: 'Forma',
    withoutSort: true
  }
];

export default structure;
