import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { icons } from 'consts/icons';
import withPositionColors from 'hoc/withPositionColors';
import Icon from 'components/Icon';

import styles from './PlayerPositionCard.module.scss';

const propTypes = {
  title: PropTypes.string,
  // note: only required to hoc withPositionColors
  position: PropTypes.shape({
    name: PropTypes.string
  }),
  className: PropTypes.string
};

const PlayerPositionCard = ({ title, className, ...others }) => {
  const cardClassName = cx(styles.card, className);

  return (
    <div {...others} className={cardClassName}>
      <div className={styles.card_icon_container}>
        <Icon icon={icons.user} className={styles.card_icon} />
      </div>
      <p className={styles.card_name}>{title}</p>
    </div>
  );
};

PlayerPositionCard.propTypes = propTypes;

export default memo(withPositionColors(PlayerPositionCard));
