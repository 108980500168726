import { keyframes } from 'styled-components';

export const rotate = keyframes`
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
`;

export const halfRotate = keyframes`
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(180deg);
    }
`;

export const placeholderImageLoading = keyframes`
  0% {
    opacity: 0.5;
  }

  50% {
    opacity: 0.85;
  }

  100% {
    opacity: 0.5;
  }
`;

export const loadingSkeleton = keyframes`
  100% {
    transform: translateX(100%);
  }`;
