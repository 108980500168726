import styled, { css } from 'styled-components';

import Avatar from '../Avatar';
import Column from '../Column';

const nameAnimationProperties = css`
  opacity: 0.75;
  transform: scaleX(1);
`;

export const StyledAvatar = styled(Avatar)`
  height: 5em;
  width: 5em;
`;

export const StyledTextContent = styled.div`
  margin-top: 0.5em;
  font-weight: 600;
  font-size: 1.15rem;
`;

export const StyledName = styled.p`
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: calc(100% + 0.2em);
    width: 100%;
    height: 0.15em;
    border-radius: 0.35rem;
    background: var(--position-accent);
    transform: scaleX(0.35);
    opacity: 0.5;
    transition: all ${({ theme }) => theme.defaultTransition} ease;
  }

  /* ${({ isHighlighted }) =>
    isHighlighted &&
    css`
      &::after {
        ${nameAnimationProperties}
      }
    `} */
`;

export const StyledDescription = styled.p`
  color: ${({ theme }) => theme.accent};
`;

export const StyledWrapper = styled(Column)`
  min-width: 120px;
  padding: 1rem 0.65rem;
  border-radius: 0.5rem;
  background-color: transparent;
  color: ${({ theme }) => theme.dark};
  transition: all ${({ theme }) => theme.quickTransition};
  text-align: center;

  &:hover {
    transform: scale(1.1);
    box-shadow: rgba(0, 0, 0, 0.22) 0px 19px 40px;

    ${StyledName}::after {
      ${nameAnimationProperties}
    }
  }
`;

StyledWrapper.defaultProps = {
  alignItems: 'center',
  justifyContent: 'flex-start'
};
