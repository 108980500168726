import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Navbar from '../Navbar';
import HeroSection from './HeroSection';

const propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  showLanguagesFlags: PropTypes.bool
};

const Header = () => {
  return (
    <>
      <HeroSection />
      <Navbar />
    </>
  );
};

Header.propTypes = propTypes;

export default memo(Header);
