import axios from 'config/axios';

import {
  IRefereeDebutMatchResponse,
  IRefereeDetailsResponse,
  IRefereeMatchResponse,
  IRefereeSeasonResponse,
  IRefereeSeasonStatisticsResponse
} from './models/refereeResponse.models';

const prefix = 'client/referee';

export default class RefereeApi {
  static async getDetails(id: string): Promise<IRefereeDetailsResponse> {
    const result = await axios.get<IRefereeDetailsResponse>(`${prefix}/${id}`);
    return result.data;
  }

  static async getPhoto(id: string): Promise<string> {
    const result = await axios.get<string>(`${prefix}/${id}/photo`);
    return result.data;
  }

  static async getDebutMatch(id: string): Promise<IRefereeDebutMatchResponse> {
    const result = await axios.get<IRefereeDebutMatchResponse>(`${prefix}/${id}/debutMatch`);
    return result.data;
  }

  static async getSeasons(id: string): Promise<IRefereeSeasonResponse[]> {
    const result = await axios.get<IRefereeSeasonResponse[]>(`${prefix}/${id}/seasons`);
    return result.data;
  }

  static async getSeason(id: string, seasonId: string): Promise<IRefereeSeasonResponse> {
    const result = await axios.get<IRefereeSeasonResponse>(`${prefix}/${id}/season/${seasonId}`);
    return result.data;
  }

  static async getMatchesInCurrentSeason(id: string): Promise<IRefereeMatchResponse[]> {
    const result = await axios.get<IRefereeMatchResponse[]>(`${prefix}/${id}/matches`);
    return result.data;
  }

  static async getMatchesInSeason(id: string, seasonId: string): Promise<IRefereeMatchResponse[]> {
    const result = await axios.get<IRefereeMatchResponse[]>(`${prefix}/${id}/${seasonId}/matches`);
    return result.data;
  }

  // note: not used
  static async getMatchesInCompetitions(
    id: string,
    leagueId: string,
    seasonId: string
  ): Promise<IRefereeMatchResponse[]> {
    const result = await axios.get<IRefereeMatchResponse[]>(
      `${prefix}/${id}/${leagueId}/${seasonId}/matches`
    );
    return result.data;
  }

  static async getSeasonStatistics(
    id: string,
    seasonId: string,
    leagueId: string,
    teamId: string
  ): Promise<IRefereeSeasonStatisticsResponse> {
    const result = await axios.get<IRefereeSeasonStatisticsResponse>(
      `${prefix}/${id}/${teamId}/${leagueId}/${seasonId}/seasonsStatistics`
    );
    return result.data;
  }
}
