import React, { memo } from 'react';

import InnerContentWrapper from 'components/InnerContentWrapper';

import UserInformationFormContainer from './UserInformationFormContainer';
import UserPasswordChangeFormContainer from './UserPasswordChangeFormContainer';

const MyProfilePage = () => (
  <InnerContentWrapper>
    <UserInformationFormContainer />
    <UserPasswordChangeFormContainer />
  </InnerContentWrapper>
);

export default memo(MyProfilePage);
