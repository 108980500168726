import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { personWithIdAndPhotoPropTypes } from 'propTypes';
import urls from 'consts/urls';
import withPositionColors from 'hoc/withPositionColors';

import PersonTile from '../PersonTile';

const propTypes = {
  ...personWithIdAndPhotoPropTypes,
  position: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string
  }).isRequired,
  isHighlighted: PropTypes.bool
};

const PlayerCard = props => {
  const playerDetailsUrl = urls.playerDetails(props.id);

  return <PersonTile {...props} detailsUrl={playerDetailsUrl} />;
};

PlayerCard.propTypes = propTypes;

export default memo(withPositionColors(PlayerCard));
