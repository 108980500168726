import axios from 'config/axios';

const prefix = 'client/system-parameter';

export default class SystemParameterSrv {
  static get() {
    return axios.get(`${prefix}`).then(result => result.data);
  }

  static getYouthTeams() {
    return axios.get(`${prefix}/youthTeams`).then(result => result.data);
  }
}
