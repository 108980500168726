import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { queuePropTypesShape } from 'propTypes';
import Column from 'components/Column';

import CompetitionsScheduleQueue from './CompetitionsScheduleQueue';
import styles from './CompetitionsScheduleQueues.module.scss';

const propTypes = {
  queues: PropTypes.arrayOf(queuePropTypesShape).isRequired,
  roundName: PropTypes.string.isRequired,
  teamIdContext: PropTypes.string,
  filterValue: PropTypes.string
};

const CompetitionsScheduleQueues = ({ queues, roundName, teamIdContext, filterValue }) => {
  const queuesElements = queues
    .map(queue => ({ ...queue, roundName }))
    .sort((a, b) => a.number - b.number)
    .map((queue, index) => (
      <CompetitionsScheduleQueue
        key={index}
        {...queue}
        teamIdContext={teamIdContext}
        filterValue={filterValue}
      />
    ));

  return <Column className={styles.queues_container}>{queuesElements}</Column>;
};

CompetitionsScheduleQueues.propTypes = propTypes;

export default memo(CompetitionsScheduleQueues);
