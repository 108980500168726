import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './MyInputText.module.scss';

const propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  invalid: PropTypes.bool,
  autoFocus: PropTypes.bool,
  type: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string
};

const MyInputText = ({
  value,
  onChange,
  onBlur,
  invalid,
  autoFocus,
  type = 'text',
  required,
  className
}) => {
  const inputClassName = cx(className, {
    [styles.invalid]: invalid
  });

  return (
    <input
      type={type}
      value={value}
      placeholder=" "
      onChange={onChange}
      onBlur={onBlur}
      autoFocus={autoFocus}
      required={required}
      className={inputClassName}
    />
  );
};

MyInputText.propTypes = propTypes;

export default memo(MyInputText);
