import actionTypes from './actionTypes';

// note: structure:

// const initialState = {
//   leagueName: '',
//   seasonName: '',
//   table: {
//     season: {
//       full: {},
//       asHost: {},
//       asGuest: {}
//     },
//     autumnRound: {
//       full: {},
//       asHost: {},
//       asGuest: {}
//     },
//     springRound: {
//       full: {},
//       asHost: {},
//       asGuest: {}
//     }
//   },
//   competitionsSchedule: {}
// };

const initialState = {};

export default function currentCompetitionsReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.STORE_MY_LEAGUE_NAME:
      return {
        ...state,
        leagueName: action.data
      };
    case actionTypes.STORE_CURRENT_SEASON_NAME:
      return {
        ...state,
        seasonName: action.data
      };
    case actionTypes.STORE_CURRENT_COMPETITIONS_INFO:
      return {
        ...state,
        leagueName: action.data.leagueName,
        seasonName: action.data.seasonName
      };
    case actionTypes.STORE_CURRENT_COMPETITIONS_TABLE_SEASON_FULL:
      return {
        ...state,
        table: {
          ...state.table,
          season: {
            ...state.table?.season,
            full: action.data
          }
        }
      };
    case actionTypes.STORE_CURRENT_COMPETITIONS_TABLE_SEASON_AS_HOST:
      return {
        ...state,
        table: {
          ...state.table,
          season: {
            ...state.table?.season,
            asHost: action.data
          }
        }
      };
    case actionTypes.STORE_CURRENT_COMPETITIONS_TABLE_SEASON_AS_GUEST:
      return {
        ...state,
        table: {
          ...state.table,
          season: {
            ...state.table?.season,
            asGuest: action.data
          }
        }
      };
    case actionTypes.STORE_CURRENT_COMPETITIONS_TABLE_AUTUMN_ROUND_FULL:
      return {
        ...state,
        table: {
          ...state.table,
          autumnRound: {
            ...state.table?.autumnRound,
            full: action.data
          }
        }
      };
    case actionTypes.STORE_CURRENT_COMPETITIONS_TABLE_AUTUMN_ROUND_AS_HOST:
      return {
        ...state,
        table: {
          ...state.table,
          autumnRound: {
            ...state.table?.autumnRound,
            asHost: action.data
          }
        }
      };
    case actionTypes.STORE_CURRENT_COMPETITIONS_TABLE_AUTUMN_ROUND_AS_GUEST:
      return {
        ...state,
        table: {
          ...state.table,
          autumnRound: {
            ...state.table?.autumnRound,
            asGuest: action.data
          }
        }
      };
    case actionTypes.STORE_CURRENT_COMPETITIONS_TABLE_SPRING_ROUND_FULL:
      return {
        ...state,
        table: {
          ...state.table,
          springRound: {
            ...state.table?.springRound,
            full: action.data
          }
        }
      };
    case actionTypes.STORE_CURRENT_COMPETITIONS_TABLE_SPRING_ROUND_AS_HOST:
      return {
        ...state,
        table: {
          ...state.table,
          springRound: {
            ...state.table?.springRound,
            asHost: action.data
          }
        }
      };
    case actionTypes.STORE_CURRENT_COMPETITIONS_TABLE_SPRING_ROUND_AS_GUEST:
      return {
        ...state,
        table: {
          ...state.table,
          springRound: {
            ...state.table?.springRound,
            asGuest: action.data
          }
        }
      };
    case actionTypes.STORE_CURRENT_COMPETITIONS_SCHEDULE:
      return {
        ...state,
        competitionsSchedule: action.data
      };
    default:
      return state;
  }
}
