import React, { lazy, memo, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { componentLoader } from 'utils/componentLoader';

import WebsiteWrapper from './WebsiteWrapper';

const ArrowScrollToTopButton = lazy(() =>
  componentLoader(() => import('./ArrowScrollToTopButton'))
);

const DefaultLayoutRoute = () => (
  <WebsiteWrapper>
    <Outlet />
    <Suspense fallback={<div />}>
      <ArrowScrollToTopButton />
    </Suspense>
  </WebsiteWrapper>
);

export default memo(DefaultLayoutRoute);
