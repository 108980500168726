import React, { memo } from 'react';

import matchResultTypes from 'consts/matchResultTypes';
import { getMatchStatusName } from 'utils/utils';

import MatchStatus from './MatchStatus';

const MatchesStatusesLegend = () => {
  const wonMatchTypeName = getMatchStatusName(matchResultTypes.win);
  const drewMatchTypeName = getMatchStatusName(matchResultTypes.draw);
  const lostMatchTypeName = getMatchStatusName(matchResultTypes.loss);
  const nextMatchTypeName = getMatchStatusName(matchResultTypes.next);

  return (
    <>
      <div>
        <MatchStatus status={matchResultTypes.win} />
        {` ${wonMatchTypeName.toLowerCase()}`}
      </div>
      <div>
        <MatchStatus status={matchResultTypes.draw} />
        {` ${drewMatchTypeName.toLowerCase()}`}
      </div>
      <div>
        <MatchStatus status={matchResultTypes.loss} />
        {` ${lostMatchTypeName.toLowerCase()}`}
      </div>
      <div>
        <MatchStatus status={matchResultTypes.next} />
        {` ${nextMatchTypeName.toLowerCase()}`}
      </div>
    </>
  );
};

export default memo(MatchesStatusesLegend);
