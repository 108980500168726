import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { personWithIdAndPhotoPropTypesShape } from 'propTypes';
import cardTypes from 'consts/cardTypes';
import CardRedIcon from 'components/Icon/CardRedIcon';
import CardSecondYellowIcon from 'components/Icon/CardSecondYellowIcon';
import CardYellowIcon from 'components/Icon/CardYellowIcon';

import EventPlayerLink from './EventPlayerLink';
import EventWrapper from './EventWrapper';

const propTypes = {
  teamId: PropTypes.string.isRequired,
  minute: PropTypes.number.isRequired,
  player: personWithIdAndPhotoPropTypesShape.isRequired,
  cardType: PropTypes.number.isRequired,
  isSecond: PropTypes.bool.isRequired,
  reversed: PropTypes.bool.isRequired
};

const GivenCardEvent = ({ minute, player, cardType, isSecond, reversed }) => {
  const cardColor = cardType === cardTypes.red ? 'red' : 'yellow';
  const cardIconComponent =
    cardType === cardTypes.red ? (
      <CardRedIcon />
    ) : !isSecond ? (
      <CardYellowIcon />
    ) : (
      <CardSecondYellowIcon />
    );

  return (
    <EventWrapper color={cardColor} reversed={reversed} minute={minute}>
      <EventPlayerLink {...player} />
      {cardIconComponent}
    </EventWrapper>
  );
};

GivenCardEvent.propTypes = propTypes;

export default memo(GivenCardEvent);
