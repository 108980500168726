import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { getArrayWithLength } from 'utils/utils';
import Card from 'components/Card';

import headerStyles from './CardTableHeaderCell.module.scss';
import placeholderStyles from './CardTablePlaceholder.module.scss';
import cellStyles from './CardTableRow.module.scss';

const propTypes = {
  rowCount: PropTypes.number
};

const CardTablePlaceholder = ({ rowCount = 15 }) => {
  const { t } = useTranslation();

  const headerClassName = headerName =>
    cx(headerStyles.header, headerStyles[`header_${headerName}`]);
  const cellClassName = cellName => cx(cellStyles.cell, cellStyles[`cell_${cellName}`]);

  return (
    <Card renderTitle={() => t('ResultsTable')}>
      <table>
        <thead>
          <tr>
            <th className={headerClassName('index')}>Lp</th>
            <th className={headerClassName('team')}>{t('Team')}</th>
            <th className={headerClassName('matches')}>M</th>
            <th className={headerClassName('points')}>PKT</th>
            <th className={headerClassName('goals')}>BR</th>
            <th className={headerClassName('difference')}>RB</th>
          </tr>
        </thead>
        <tbody>
          {getArrayWithLength(rowCount).map((row, rowIndex) => (
            <tr className={placeholderStyles.row} key={rowIndex}>
              <td className={cellClassName('index')} />
              <td className={cellClassName('team')} />
              <td className={cellClassName('matches')} />
              <td className={cellClassName('points')} />
              <td className={cellClassName('goals')} />
              <td className={cellClassName('difference')} />
            </tr>
          ))}
        </tbody>
      </table>
    </Card>
  );
};

CardTablePlaceholder.propTypes = propTypes;

export default memo(CardTablePlaceholder);
