import React, { memo } from 'react';
import Link from 'components/Links/Link';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { matchPropTypesShape, playerMatchPropTypesShape } from 'propTypes';
import cardTypes from 'consts/cardTypes';
import matchShortEventTypes from 'consts/matchShortEventTypes';
import urls from 'consts/urls';
import { sortByDateAscending, sortByLastNameAscending } from 'utils/sortingMethods';
import { sortEvents } from 'utils/utils';
import displayEventShortData from 'modules/match/displayEventShortData'; // TODO: komponent nie może korzystać z modules, sprawdzić w całej aplikacji
import Column from 'components/Column';
import ContentRevealer from 'components/ContentRevealer';
import DateInfo from 'components/DateInfo';
import PlayerLink from 'components/Links/PlayerLink';
import TeamLink from 'components/Links/TeamLink';
import MatchStatus from 'components/MatchStatus';
import TimeInfo from 'components/TimeInfo';

import styles from './TeamPlayersMatches.module.scss';

const propTypes = {
  matches: PropTypes.arrayOf(matchPropTypesShape),
  playersWithMatchesAndEvents: PropTypes.arrayOf(playerMatchPropTypesShape)
};

const TeamPlayersMatches = ({ matches, playersWithMatchesAndEvents }) => {
  const matchesHeader = matches?.map(match => (
    <th key={match.id} className={styles.table_header}>
      <Column>
        {/* TODO: Warning: validateDOMNesting(...): <a> cannot appear as a descendant of <a>. */}
        <Link to={urls.matchDetails(match.id)}>
          <div className={styles.table_header_dateAndTime}>
            <DateInfo date={match.date} withoutIcon />
            <TimeInfo date={match.date} withoutIcon />
          </div>{' '}
          <TeamLink {...match.host} />
          <TeamLink {...match.guest} />
          <div className={styles.table_header_resultAndStatus}>
            {match.hostGoals} - {match.guestGoals}
            <MatchStatus status={match.status} />
          </div>
        </Link>
      </Column>
    </th>
  ));

  const players = sortByLastNameAscending(playersWithMatchesAndEvents)?.map(player => {
    for (const match of matches) {
      const playerMatch = player.matches.find(x => x.id === match.id);

      if (!playerMatch) {
        player.matches.push(match);
      }
    }

    let yellowCardsCount = 0;
    let redCardsCount = 0;

    return (
      <tr key={player.id} className={styles.table_playerRow}>
        <td className={styles.table_playerRow_playerColumn}>
          <PlayerLink {...player} className={styles.table_playerRow_playerColumn_player} />
        </td>

        {player.matches.sort(sortByDateAscending).map(match => {
          const goals = match.goals
            ? [
                ...match.goals.map(x => ({
                  ...x,
                  shortEventType: matchShortEventTypes.scoredGoal
                }))
              ]
            : [];

          const assists = match.assists
            ? [...match.assists.map(x => ({ ...x, shortEventType: matchShortEventTypes.assist }))]
            : [];

          const changes = match.changes
            ? [
                ...match.changes.map(x => ({
                  ...x,
                  shortEventType: matchShortEventTypes.playerChanged
                }))
              ]
            : [];

          const cards = match.cards
            ? [
                ...match.cards.map(x => ({
                  ...x,
                  shortEventType: matchShortEventTypes.givenCard
                }))
              ]
            : [];

          for (const card of cards) {
            if (card.cardType === cardTypes.yellow) {
              card.count = ++yellowCardsCount;
            }

            if (card.cardType === cardTypes.red) {
              card.count = ++redCardsCount;
            }
          }

          const events = [...goals, ...assists, ...changes, ...cards]
            .sort((previousEvent, nextEvent) => sortEvents(previousEvent, nextEvent))
            .map((event, eventIndex) => (
              <span key={eventIndex} className={styles.table_playerRow_match_event}>
                {displayEventShortData(event, eventIndex)}
              </span>
            ));

          return (
            <td key={match.id} className={styles.table_playerRow_match}>
              <div className={styles.table_playerRow_match_events}>{events}</div>
            </td>
          );
        })}
      </tr>
    );
  });

  const stickyHeaderAndFirstColumnTableClassName = cx(styles.table, 'table--sticky');

  return (
    <ContentRevealer isVisible={true} className={styles.table_container}>
      <table className={stickyHeaderAndFirstColumnTableClassName}>
        <thead>
          <tr>
            <th className={styles.table_header} />
            {matchesHeader}
          </tr>
        </thead>
        <tbody>{players}</tbody>
      </table>
    </ContentRevealer>
  );
};

TeamPlayersMatches.propTypes = propTypes;

export default memo(TeamPlayersMatches);
