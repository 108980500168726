import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { isNullOrUndefined } from 'utils/utils';

import MatchScorePoints from './MatchScorePoints';
import styles from './MatchScoreSection.module.scss';

const propTypes = {
  hostFirstHalfGoals: PropTypes.number,
  guestFirstHalfGoals: PropTypes.number,
  guestGoals: PropTypes.number,
  hostGoals: PropTypes.number,
  hostWon: PropTypes.bool,
  guestWon: PropTypes.bool,
  isWalkover: PropTypes.bool
};

const MatchScoreSection = ({
  hostGoals,
  guestGoals,
  hostFirstHalfGoals,
  guestFirstHalfGoals,
  hostWon,
  guestWon,
  isWalkover
}) => (
  <div className={styles.score_container}>
    <div className={styles.score}>
      <MatchScorePoints points={hostGoals} winner={hostWon} />
      <span> - </span>
      <MatchScorePoints points={guestGoals} winner={guestWon} />
    </div>

    {!isNullOrUndefined(hostFirstHalfGoals) && !isNullOrUndefined(guestFirstHalfGoals) && (
      <div className={styles.score_half}>{`(${hostFirstHalfGoals} - ${guestFirstHalfGoals})`}</div>
    )}

    {isWalkover ? <div className={styles.walkower_shortcut}>(wo)</div> : ''}
  </div>
);

MatchScoreSection.propTypes = propTypes;

export default memo(MatchScoreSection);
