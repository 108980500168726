import React, { memo } from 'react';
import PropTypes from 'prop-types';

import styles from './AvatarPlaceholder.module.scss';

const propTypes = {
  label: PropTypes.string,
  small: PropTypes.bool,
  additionalClassNames: PropTypes.string
};

const AvatarPlaceholder = ({ label = 'Avatar', small, additionalClassNames = '' }) => (
  <span
    data-label={label}
    className={[
      styles.avatar_placeholder,
      small && styles.small_placeholder,
      ...additionalClassNames.split(' ')
    ].join(' ')}
  />
);

AvatarPlaceholder.propTypes = propTypes;

export default memo(AvatarPlaceholder);
