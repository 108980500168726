import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Button from './Button';
import styles from './LoadingButton.module.scss';

const propTypes = {
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  loadingText: PropTypes.string,
  children: PropTypes.node.isRequired
};

// TODO: Vorbert -> przerobić na styled-component
const LoadingButton = ({ isLoading, disabled, children, loadingText, ...others }) => {
  const buttonClassName = cx(styles.button, {
    [styles.button_loading]: isLoading
  });

  return (
    <Button {...others} disabled={disabled || isLoading} className={buttonClassName}>
      {isLoading && !!loadingText ? loadingText : children}

      <span className={styles.button_spinner} />
    </Button>
  );
};

LoadingButton.propTypes = propTypes;

export default memo(LoadingButton);
