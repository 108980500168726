import { memo } from 'react';
import styled, { css } from 'styled-components';
import { mediaValues } from 'styling';

const StyledWrapper = styled.div`
  --vertical-offset: ${({ theme }) => theme.verticalNavbarOffset};
  --horizontal-offset: ${({ theme }) => theme.verticalNavbarOffset};
  position: relative;
  padding: 0 var(--horizontal-offset) var(--vertical-offset);
  min-height: inherit;

  ${({ subpageWrap }) =>
    subpageWrap &&
    css`
      @media screen and ${mediaValues.bigMobileAndUp} {
        --horizontal-offset: 2.9rem;
      }
    `}

  ${({ horizontalOffset }) =>
    horizontalOffset &&
    css`
      @media screen and ${mediaValues.bigMobileAndUp} {
        margin: 0 3rem 0 3.75rem;
      }
    `}

    /* TODO: Vorbert -> przerobić to na osobny komponent, centrujący dzieci */
    ${({ centerContent }) =>
    centerContent &&
    css`
      & > * {
        margin: 0 auto;
      }
    `}
`;

export default memo(StyledWrapper);
