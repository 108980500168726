import React, { memo } from 'react';
import Avatar from 'react-avatar-edit';
import PropTypes from 'prop-types';

const propTypes = {
  onClose: PropTypes.func,
  onChange: PropTypes.func.isRequired
};

const MyAvatarEdit = ({ onClose = () => {}, onChange }) => {
  const crop = cropped => {
    const fakeE = {
      target: {
        value: cropped
      }
    };

    onChange(fakeE);
  };

  return <Avatar width={200} height={200} onCrop={crop} onClose={onClose} />;
};

MyAvatarEdit.propTypes = propTypes;

export default memo(MyAvatarEdit);
