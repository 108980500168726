import React, { memo, ReactElement } from 'react';
import cx from 'classnames';

import Column from 'components/Column';
import Row from 'components/Row';

import styles from './EventCountCell.module.scss';

type Props = {
  icon: ReactElement;
  eventCounts: number | string | undefined;
  columnName: string;
  columnIndex: number;
  hoveredColumnIndex: number | null;
};

const EventCountCell = ({
  icon,
  eventCounts,
  columnName: cellName,
  columnIndex: cellIndex,
  hoveredColumnIndex
}: Readonly<Props>) => {
  const getCellClassName = (columnName: string, columnIndex: number): string =>
    cx(styles.cell, styles[`cell_${columnName}`], {
      [styles.cell_active]: columnIndex === hoveredColumnIndex
    });

  return (
    <td className={getCellClassName(cellName, cellIndex)}>
      <Row>
        <Column>{icon}</Column>

        <Column>
          <span className={styles.cell_count_number}>{eventCounts}</span>
        </Column>
      </Row>
    </td>
  );
};

export default memo(EventCountCell);
