import React, { memo } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { competitionsSchedulePropTypes } from 'propTypes';
import InnerContentWrapper from 'components/InnerContentWrapper';
import LoadingPageData from 'components/LoadingPageData';
import PageHeader from 'components/PageHeader';

import CompetitionsSchedule from './CompetitionsSchedule';

// TODO: rafkan - ujednolicić kolejność propTypes w komponentach, kolejność importów itp.

const propTypes = {
  leagueName: PropTypes.string,
  seasonName: PropTypes.string,
  competitionsSchedule: competitionsSchedulePropTypes
};

const CompetitionsSchedulePage = ({ leagueName, seasonName, competitionsSchedule }) => (
  <InnerContentWrapper>
    {!isEmpty(competitionsSchedule) ? (
      <>
        {/* TODO: przekierowanie do strony szczegółów ligi-sezonu po kliknięciu na nazwę */}
        <PageHeader title={`${leagueName} ${seasonName}`} />
        <CompetitionsSchedule competitionsSchedule={competitionsSchedule} />
      </>
    ) : (
      <LoadingPageData />
    )}
  </InnerContentWrapper>
);

CompetitionsSchedulePage.propTypes = propTypes;

export default memo(CompetitionsSchedulePage);
