import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  IRefereeDebutMatchModel,
  IRefereeDetailsModel,
  IRefereeMatchModel,
  IRefereeSeasonModel,
  IRefereeSeasonStatisticsModel
} from './models';
import refereeApi from './referee.api';

export const getDetails = createAsyncThunk<IRefereeDetailsModel, string>(
  'referee/getDetails',
  async (id: string) => {
    const response = await refereeApi.getDetails(id);
    return response;
  }
);

export const getPhoto = createAsyncThunk<string, string>('referee/getPhoto', async (id: string) => {
  const response = await refereeApi.getPhoto(id);
  return response;
});

export const getDebutMatch = createAsyncThunk<IRefereeDebutMatchModel, string>(
  'referee/getDebutMatch',
  async (id: string) => {
    const response = await refereeApi.getDebutMatch(id);
    return response;
  }
);

export const getSeasons = createAsyncThunk<IRefereeSeasonModel[], string>(
  'referee/getSeasons',
  async (id: string): Promise<IRefereeSeasonModel[]> => {
    const response = await refereeApi.getSeasons(id);
    // TODO: remove unknown conversion first and compare IRefereeSeasonModel with IRefereeSeasonResponse
    return response as unknown as IRefereeSeasonModel[];
  }
);

export const getSeason = createAsyncThunk<IRefereeSeasonModel, { id: string; seasonId: string }>(
  'referee/getSeason',
  async ({ id, seasonId }: { id: string; seasonId: string }): Promise<IRefereeSeasonModel> => {
    const response = await refereeApi.getSeason(id, seasonId);
    // TODO: remove unknown conversion first and compare IRefereeSeasonModel with IRefereeSeasonResponse
    return response as unknown as IRefereeSeasonModel;
  }
);

// TODO: ageGroups instead of teams? thinking
export const getSeasonStatistics = createAsyncThunk<
  IRefereeSeasonStatisticsModel,
  { id: string; seasonId: string; leagueId: string; teamId: string }
>(
  'referee/getSeasonStatistics',
  async ({
    id,
    seasonId,
    leagueId,
    teamId
  }: {
    id: string;
    seasonId: string;
    leagueId: string;
    teamId: string;
  }) => {
    const response = await refereeApi.getSeasonStatistics(id, seasonId, leagueId, teamId);
    return response;
  }
);

export const getMatchesInCurrentSeason = createAsyncThunk<
  IRefereeMatchModel[],
  { id: string; seasonId: string }
>(
  'referee/getMatchesInCurrentSeason',
  // note: seasonId is currentSeasonId get from systemParameters
  // it is used only in the fulfilled case reducer to match seasonId from store and add matches to the season
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  async ({ id, seasonId }: { id: string; seasonId: string }) => {
    const response = await refereeApi.getMatchesInCurrentSeason(id);
    return response;
  }
);

export const getMatchesInSeason = createAsyncThunk<
  IRefereeMatchModel[],
  { id: string; seasonId: string }
>('referee/getMatchesInSeason', async ({ id, seasonId }: { id: string; seasonId: string }) => {
  const response = await refereeApi.getMatchesInSeason(id, seasonId);
  return response;
});
