import { memo } from 'react';
import styled from 'styled-components';

const StyledTextWrapper = styled.div`
  font-family: 'Poppins', sans-serif;
  /* TODO: Vorbert -> zastanowić się nad domyślnym kolorem czcionki w aplikacji */
  color: ${({ theme }) => theme.dark};
  user-select: none;
`;

export default memo(StyledTextWrapper);
