import React, { memo } from 'react';
import { useParams } from 'react-router-dom';

import { useAppSelector } from 'store';
import { useDispatchOnMountWithLocation } from 'utils/customHooks';

import { get } from './actions';
import BoardMemberDetailsPage from './BoardMemberDetailsPage';

const BoardMemberDetailsPageContainer = props => {
  const { id } = useParams();
  const boardMemberDetails = useAppSelector(state => state.boardMembers[id]);

  useDispatchOnMountWithLocation(get(id));

  return <BoardMemberDetailsPage {...props} boardMemberDetails={boardMemberDetails} />;
};

export default memo(BoardMemberDetailsPageContainer);
