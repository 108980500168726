const actionTypes = {
  STORE_TEAM_DETAILS: 'STORE_TEAM_DETAILS',
  STORE_TEAM_SEASONS_LIST: 'STORE_TEAM_SEASONS_LIST',
  STORE_TEAM_SEASONS_ADVANCED_LIST: 'STORE_TEAM_SEASONS_ADVANCED_LIST',
  STORE_TEAM_COACHES_IN_CURRENT_SEASON: 'STORE_TEAM_COACHES_IN_CURRENT_SEASON',
  STORE_TEAM_PLAYERS_WITH_MATCHES_AND_EVENTS_IN_CURRENT_SEASON:
    'STORE_TEAM_PLAYERS_WITH_MATCHES_AND_EVENTS_IN_CURRENT_SEASON',
  STORE_TEAM_MATCHES_IN_CURRENT_SEASON: 'STORE_TEAM_MATCHES_IN_CURRENT_SEASON',
  STORE_TEAM_MATCHES_IN_SEASON: 'STORE_TEAM_MATCHES_IN_SEASON',
  STORE_TEAM_COACHES_IN_SEASON: 'STORE_TEAM_COACHES_IN_SEASON',
  STORE_TEAM_PLAYERS_WITH_MATCHES_AND_EVENTS_IN_SEASON:
    'STORE_TEAM_PLAYERS_WITH_MATCHES_AND_EVENTS_IN_SEASON',

  // -------------------------------------------- TO TEST -----------------------------------------

  STORE_TEAM_PLAYERS_IN_CURRENT_SEASON: 'STORE_TEAM_PLAYERS_IN_CURRENT_SEASON',
  STORE_TEAM_PLAYER_MATCHES_AND_EVENTS_IN_CURRENT_SEASON:
    'STORE_TEAM_PLAYER_MATCHES_AND_EVENTS_IN_CURRENT_SEASON'
};

export default actionTypes;
