// TODO: eslint disabled

/* eslint-disable no-unused-vars */
/* eslint-disable unused-imports/no-unused-imports */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

import { useAppDispatch } from 'store';
import ArrayUtils from 'utils/array.utils';
import ObjectUtils from 'utils/object.utils';
import { shouldMakeRequest } from 'utils/requests.utils';
import { useGetCurrentSeasonId } from 'utils/systemParameterProvider';

import { IRefereeSeasonModel } from './models';
import {
  useSelectGetRefereeDebutMatchRequestStatus,
  useSelectGetRefereeDetailsRequestStatus,
  useSelectGetRefereeMatchesInSeasonRequestStatus,
  useSelectGetRefereePhotoRequestStatus,
  useSelectGetRefereeSeasonRequestStatus,
  useSelectGetRefereeSeasonsRequestStatus,
  useSelectRefereeDebutMatch,
  useSelectRefereeDetails,
  useSelectRefereePhoto,
  useSelectRefereeSeason,
  useSelectRefereeSeasons
} from './referee.selectors.hooks';
import {
  getDebutMatch,
  getDetails,
  getMatchesInSeason,
  getPhoto,
  getSeason,
  getSeasons,
  getSeasonStatistics
} from './referee.thunks';

// TODO: zastanowić się czy trzeba typy
// type GetRefereeDetailsQueryModel = {
//   details: IRefereeDetailsResponse | null | undefined;
// } & RequestStatus;

// TODO: zastanowić się czy trzeba typy
// export const useGetRefereeDetailsQuery = (id: string ): GetRefereeDetailsQueryModel => {
export const useGetRefereeDetailsQuery = (id: string) => {
  const dispatch = useAppDispatch();

  const requestStatus = useSelectGetRefereeDetailsRequestStatus(id);
  const details = useSelectRefereeDetails(id);

  useEffect(() => {
    if (shouldMakeRequest(requestStatus)) {
      dispatch(getDetails(id));
    }
  }, []);

  return { details, requestStatus };
};

export const useGetRefereePhotoQuery = (id: string) => {
  const dispatch = useAppDispatch();

  const requestStatus = useSelectGetRefereePhotoRequestStatus(id);
  const photo = useSelectRefereePhoto(id);

  useEffect(() => {
    if (shouldMakeRequest(requestStatus)) {
      dispatch(getPhoto(id));
    }
  }, []);

  return { photo, requestStatus };
};

export const useGetRefereeDebutMatchQuery = (id: string) => {
  const dispatch = useAppDispatch();

  const requestStatus = useSelectGetRefereeDebutMatchRequestStatus(id);
  const debutMatch = useSelectRefereeDebutMatch(id);

  useEffect(() => {
    if (shouldMakeRequest(requestStatus)) {
      dispatch(getDebutMatch(id));
    }
  }, []);

  return { debutMatch, requestStatus };
};

export const useGetRefereeSeasonsQuery = (id: string) => {
  const dispatch = useAppDispatch();

  const requestStatus = useSelectGetRefereeSeasonsRequestStatus(id);
  const seasons = useSelectRefereeSeasons(id);

  useEffect(() => {
    if (shouldMakeRequest(requestStatus)) {
      dispatch(getSeasons(id)).then(response =>
        (response?.payload as IRefereeSeasonModel[]).forEach(season =>
          callForSeasonStatistics(dispatch, id, season)
        )
      );
    }
  }, []);

  return { seasons, requestStatus };
};

export const useGetRefereeSeasonQuery = (id: string, seasonId: string) => {
  const dispatch = useAppDispatch();

  const requestStatus = useSelectGetRefereeSeasonRequestStatus(id, seasonId);
  const season = useSelectRefereeSeason(id, seasonId);

  useEffect(() => {
    if (shouldMakeRequest(requestStatus) && ObjectUtils.isEmpty(season)) {
      dispatch(getSeason({ id, seasonId })).then(response =>
        callForSeasonStatistics(dispatch, id, response?.payload as IRefereeSeasonModel)
      );
    }
  }, []);

  return { season, requestStatus };
};

export const useGetMatchesInSeasonQuery = (
  id: string,
  seasonId?: string | null,
  isForCurrentSeason?: boolean,
  seasons?: IRefereeSeasonModel[] | null | undefined
) => {
  const dispatch = useAppDispatch();

  const currentSeasonId = useGetCurrentSeasonId();

  const requestStatus = useSelectGetRefereeMatchesInSeasonRequestStatus(id, seasonId!);

  const shouldMakeCallForCurrentSeason = seasons?.map(x => x.seasonId).includes(currentSeasonId);

  useEffect(() => {
    if (shouldMakeRequest(requestStatus) && !isForCurrentSeason) {
      dispatch(getMatchesInSeason({ id, seasonId: seasonId! }));
    }
  }, []);

  useEffect(() => {
    if (
      shouldMakeRequest(requestStatus) &&
      currentSeasonId &&
      isForCurrentSeason &&
      shouldMakeCallForCurrentSeason
    ) {
      dispatch(getMatchesInSeason({ id, seasonId: currentSeasonId }));
    }
  }, [shouldMakeCallForCurrentSeason]);
};

export const useRefereeData = (id: string) => {
  const {
    details,
    photo,
    debutMatch,

    getDetailsRequestStatus,
    getPhotoRequestStatus,
    getDebutMatchRequestStatus
  } = useGetCardRefereeData(id);

  const { seasons, requestStatus: getSeasonsRequestStatus } = useGetRefereeSeasonsQuery(id);

  useGetMatchesInSeasonQuery(id, null, true, seasons);

  return {
    details,
    photo,
    debutMatch,
    seasons,

    getDetailsRequestStatus,
    getPhotoRequestStatus,
    getDebutMatchRequestStatus,
    getSeasonsRequestStatus
  };
};

export const useRefereeDataForStatisticsPage = (id: string, seasonId: string) => {
  const {
    details,
    photo,
    debutMatch,

    getDetailsRequestStatus,
    getPhotoRequestStatus,
    getDebutMatchRequestStatus
  } = useGetCardRefereeData(id);

  const { season, requestStatus: getSeasonRequestStatus } = useGetRefereeSeasonQuery(id, seasonId);

  useGetMatchesInSeasonQuery(id, seasonId);

  return {
    details,
    photo,
    debutMatch,
    season,

    getDetailsRequestStatus,
    getPhotoRequestStatus,
    getDebutMatchRequestStatus,
    getSeasonRequestStatus
  };
};

export const useGetCardRefereeData = (id: string) => {
  const { details, requestStatus: getDetailsRequestStatus } = useGetRefereeDetailsQuery(id);

  const { photo, requestStatus: getPhotoRequestStatus } = useGetRefereePhotoQuery(id);

  const { debutMatch, requestStatus: getDebutMatchRequestStatus } =
    useGetRefereeDebutMatchQuery(id);

  return {
    details,
    photo,
    debutMatch,

    getDetailsRequestStatus,
    getPhotoRequestStatus,
    getDebutMatchRequestStatus
  };
};

// TODO: ageGroups instead of teams? thinking
function callForSeasonStatistics(dispatch, id: string, season: IRefereeSeasonModel) {
  if (ArrayUtils.isNotEmpty(season.leagues)) {
    season.leagues.forEach(league => {
      // if (ArrayUtils.isNotEmpty(league.teams)) {
      //   league.teams.forEach(team => {
      //     dispatch(
      //       getSeasonStatistics({
      //         id,
      //         teamId: team.id,
      //         seasonId: season.seasonId,
      //         leagueId: league.leagueId
      //       })
      //     );
      //   });
      // }
    });
  }
}
