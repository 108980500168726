const config = {
  nodeEnv: import.meta.env.MODE,
  isDev: import.meta.env.DEV,
  isProd: import.meta.env.PROD,
  isSsr: import.meta.env.SSR,
  baseUrl: import.meta.env.BASE_URL,
  apiUrl: import.meta.env.VITE_APP_API_URL,
  sentryDns: import.meta.env.VITE_APP_SENTRY_DNS,
  sentryEnvironment: import.meta.env.VITE_APP_SENTRY_ENVIRONMENT,
  firebaseVapidKey: import.meta.env.VITE_APP_FIREBASE_VAPID_KEY
};

export default config;
