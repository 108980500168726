export enum ELanguageCodes {
  Pl = 'pl-PL',
  En = 'en-US'
}

export const appDefaultLanguageCode = ELanguageCodes.Pl;

export enum ELanguageDisplayNames {
  Polski = 'Polski',
  English = 'English'
}

export interface ILanguage {
  code: string;
  flagCode: string;
  displayName: ELanguageDisplayNames;
}

export const supportedLanguages: ILanguage[] = [
  { code: 'pl-PL', flagCode: 'PL', displayName: ELanguageDisplayNames.Polski },
  { code: 'en-US', flagCode: 'US', displayName: ELanguageDisplayNames.English }
];
