import React, { memo, useRef, useState } from 'react';
import cx from 'classnames';

import {
  useCheckIfSticky,
  useHandleScrollPosition,
  useIsDesktop,
  useRefState
} from 'utils/customHooks';

import DesktopMenu from './DesktopMenu';
import MenuLogoLink from './MenuLogoLink';
import HamburgerMenu from './Mobile/HamburgerMenu';
import styles from './Navbar.module.scss';
import UserSection from './UserSection';

const Navbar = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  const [isSticky, isStickyReference, setIsSticky] = useRefState(false);
  const navbarRef = useRef(undefined);

  useHandleScrollPosition(scrollPosition, setScrollPosition);
  useCheckIfSticky(navbarRef, isStickyReference, setIsSticky);

  const isDesktop = useIsDesktop();

  const navbarClassName = cx(styles.navbar, {
    [styles.mobileNavbar]: !isDesktop
  });

  // TODO: zastanowić się czy tak nie będzie lepiej: isSticky = scrollPosition > 1;

  return (
    <nav className={navbarClassName} ref={navbarRef}>
      {isDesktop ? (
        <>
          <MenuLogoLink smaller={isSticky} />
          <DesktopMenu />
          <UserSection />
        </>
      ) : (
        <>
          <MenuLogoLink smaller />
          <HamburgerMenu />
        </>
      )}
    </nav>
  );
};

export default memo(Navbar);
