import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { icons } from 'consts/icons';
import { withAlternativeSign } from 'utils/utils';

import styles from './MatchScorePoints.module.scss';

const propTypes = {
  points: PropTypes.number,
  winner: PropTypes.bool,
  className: PropTypes.string
};

const MatchScorePoints = ({ points, winner, className }) => {
  const iconClassName = cx(icons.crown, styles.score_icon);
  const scoreClassName = cx(styles.score, className, {
    [styles.score_won]: winner
  });

  return (
    <span className={scoreClassName}>
      {winner && <i className={iconClassName} />}
      {withAlternativeSign(points)}
    </span>
  );
};

MatchScorePoints.propTypes = propTypes;

export default memo(MatchScorePoints);
