import { memo } from 'react';

import FileLine from 'components/Files/FileLine';

import { FileMap, FileRecord } from './data/models';

type Props = {
  files?: FileMap;
};

const DocumentsPage = ({ files }: Readonly<Props>) => {
  if (!files || Object.keys(files).length === 0) {
    return 'No data'; // <StyledMatchesNoDataMessage>{t('NoData')}</StyledMatchesNoDataMessage>
  }

  return Object.values(files).map(({ file, requestStatus, url }: FileRecord) => {
    if (requestStatus?.isLoading) {
      return 'loading'; // <ThreeRowsSkeletons />
    }

    return (
      <FileLine
        key={file.fileName}
        fileName={file.fileName}
        base64Content={file.base64Content}
        url={url}
      />
    );
  });
};

export default memo(DocumentsPage);
