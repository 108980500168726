import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import TeamLink from 'components/Links/TeamLink';

import Matches from './Matches';
import styles from './TableRow.module.scss';

const propTypes = {
  id: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  logo: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  matchesPlayedNumber: PropTypes.number.isRequired,
  points: PropTypes.number.isRequired,
  winsNumber: PropTypes.number.isRequired,
  drawsNumber: PropTypes.number.isRequired,
  lostNumber: PropTypes.number.isRequired,
  goalsScored: PropTypes.number.isRequired,
  goalsLost: PropTypes.number.isRequired,
  goalsDifference: PropTypes.number.isRequired,
  nextMatch: PropTypes.exact({
    id: PropTypes.string.isRequired,
    host: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    }).isRequired,
    guest: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    }),
    date: PropTypes.string
  }),
  lastFiveMatches: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string.isRequired,
      host: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
      }).isRequired,
      guest: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
      }).isRequired,
      hostGoals: PropTypes.number.isRequired,
      guestGoals: PropTypes.number.isRequired,
      date: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired
    })
  ),

  // note: Readonly<Props>y służące do zmiany podświetlenia kolumn w tabeli
  activeCellIndex: PropTypes.number.isRequired,
  setActiveCellIndex: PropTypes.func.isRequired,
  clearActiveIndex: PropTypes.func.isRequired,
  activeTeamId: PropTypes.string,
  setActiveTeamId: PropTypes.func.isRequired,
  clearActiveTeamId: PropTypes.func.isRequired,
  lowestPositionNumber: PropTypes.number.isRequired,
  teamIdContext: PropTypes.string
};

const TableRow = ({
  id,
  logo,
  name,
  position,
  goalsScored,
  goalsLost,
  goalsDifference,
  winsNumber,
  drawsNumber,
  lostNumber,
  matchesPlayedNumber,
  points,
  nextMatch,
  lastFiveMatches,
  activeCellIndex,
  setActiveCellIndex,
  clearActiveIndex,
  activeTeamId,
  setActiveTeamId,
  clearActiveTeamId,
  lowestPositionNumber,
  teamIdContext
}) => {
  const promotedTeamsNumber = 1;
  const relegatedTeamsNumber = 4;

  const getGoalsDifference = () => `${goalsDifference > 0 ? '+' : ''}${goalsDifference}`;

  const isHighlightedBlue = () => position <= promotedTeamsNumber;
  const isHighlightedRed = () => position > lowestPositionNumber - relegatedTeamsNumber;
  const isTeamIdContext = () => id === teamIdContext;

  const rowClassName = cx({
    active: id === activeTeamId,
    [styles['row--blue']]: isHighlightedBlue(),
    [styles['row--red']]: isHighlightedRed(),
    [styles.highlightedRow]: isTeamIdContext()
  });

  const getCurrentClass = cellIndex => (activeCellIndex === cellIndex ? 'active' : '');
  const positionCellClassName = cx(
    'align-text-center',
    getCurrentClass(0) /* // TODO: pierwsze dwie kolumny sticky przy scrollu, styles.sticky */
  );
  const nameCellClassName = cx(
    'align-text-left',
    getCurrentClass(1) /* // TODO: pierwsze dwie kolumny sticky przy scrollu, styles.sticky */
  );

  return (
    <tr onMouseLeave={clearActiveIndex} className={rowClassName}>
      <td className={positionCellClassName} onMouseEnter={() => setActiveCellIndex(0)}>
        <div>{position}</div>
      </td>
      <td className={nameCellClassName} onMouseEnter={() => setActiveCellIndex(1)}>
        <TeamLink id={id} logo={logo} name={name} />
      </td>
      <td className={getCurrentClass(2)} onMouseEnter={() => setActiveCellIndex(2)}>
        {matchesPlayedNumber}
      </td>
      <td className={getCurrentClass(3)} onMouseEnter={() => setActiveCellIndex(3)}>
        {points}
      </td>
      <td className={getCurrentClass(4)} onMouseEnter={() => setActiveCellIndex(4)}>
        {winsNumber}
      </td>
      <td className={getCurrentClass(5)} onMouseEnter={() => setActiveCellIndex(5)}>
        {drawsNumber}
      </td>
      <td className={getCurrentClass(6)} onMouseEnter={() => setActiveCellIndex(6)}>
        {lostNumber}
      </td>
      <td className={getCurrentClass(7)} onMouseEnter={() => setActiveCellIndex(7)}>
        {goalsScored}
      </td>
      <td className={getCurrentClass(8)} onMouseEnter={() => setActiveCellIndex(8)}>
        {goalsLost}
      </td>
      <td className={getCurrentClass(9)} onMouseEnter={() => setActiveCellIndex(9)}>
        {getGoalsDifference()}
      </td>
      <td className={getCurrentClass(10)} onMouseEnter={() => setActiveCellIndex(10)}>
        <Matches
          lastFiveMatches={lastFiveMatches}
          setActiveTeamId={setActiveTeamId}
          clearActiveTeamId={clearActiveTeamId}
          nextMatch={nextMatch}
        />
      </td>
    </tr>
  );
};

TableRow.propTypes = propTypes;

export default memo(TableRow);
