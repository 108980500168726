export const fileIds = {
  policyOnTheProtectionOfChildrenAndMinorsId: '637667c9-1b27-4bbb-bec5-2b4e2b2666e4',
  statementByParentAndChildAboutPolicyOnTheProtectionOfChildrenAndMinorsId:
    '2920c873-166a-4d5c-84ff-99c541ae0730',
  academyMembershipDeclarationId: '5eb16bde-3672-492b-af69-772e8f02180d',
  academyRegulationsId: '3c9738f8-0a22-4f41-8707-9599f4d8015b',
  stadiumRegulationsId: '6fc052ec-d5b4-4734-a229-bc79b85edb02',
  statuteId: 'd8fa8bda-ea06-4191-97aa-7f1fc570feb3'
};

export enum EFileIds {
  PolicyOnTheProtectionOfChildrenAndMinorsId = '637667c9-1b27-4bbb-bec5-2b4e2b2666e4',
  StatementByParentAndChildAboutPolicyOnTheProtectionOfChildrenAndMinorsId = '2920c873-166a-4d5c-84ff-99c541ae0730',
  AcademyMembershipDeclarationId = '5eb16bde-3672-492b-af69-772e8f02180d',
  AcademyRegulationsId = '3c9738f8-0a22-4f41-8707-9599f4d8015b',
  StadiumRegulationsId = '6fc052ec-d5b4-4734-a229-bc79b85edb02',
  StatuteId = 'd8fa8bda-ea06-4191-97aa-7f1fc570feb3'
}

export type FileIdType =
  | EFileIds.PolicyOnTheProtectionOfChildrenAndMinorsId
  | EFileIds.StatementByParentAndChildAboutPolicyOnTheProtectionOfChildrenAndMinorsId
  | EFileIds.AcademyMembershipDeclarationId
  | EFileIds.AcademyRegulationsId
  | EFileIds.StadiumRegulationsId
  | EFileIds.StatuteId;
