import React, { memo } from 'react';

import Row from 'components/Row';

import styles from './MatchPlaceholder.module.scss';

const MatchPlaceholder = () => (
  <>
    <Row className={styles.date_section}>
      <div className={styles.date} />
      <div className={styles.date} />
    </Row>
    <Row className={styles.score_section}>
      <div className={styles.logo} />
      <div className={styles.score} />
      <span className={styles.score_divider}>vs</span>
      <div className={styles.score} />
      <div className={styles.logo} />
    </Row>
  </>
);

export default memo(MatchPlaceholder);
