import PropTypes from 'prop-types';

export const personPropTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string
};

export const personPropTypesShape = PropTypes.shape({
  ...personPropTypes
});

export const personWithIdAndPhotoPropTypes = {
  ...personPropTypes,
  id: PropTypes.string,
  photo: PropTypes.string
};

export const personWithPhotoPropTypes = {
  ...personPropTypes,
  photo: PropTypes.string
};

export const personWithPhotoPropTypesShape = PropTypes.shape({
  ...personWithPhotoPropTypes
});

export const personWithIdAndPhotoPropTypesShape = PropTypes.shape({
  ...personWithIdAndPhotoPropTypes
});
