const actionTypes = {
  STORE_CLUB_DETAILS: 'STORE_CLUB_DETAILS',
  STORE_CLUB_CONTACT: 'STORE_CLUB_CONTACT',
  STORE_CLUB_TEAMS: 'STORE_CLUB_TEAMS',
  STORE_CLUB_BOARD_MEMBERS: 'STORE_CLUB_BOARD_MEMBERS',
  STORE_CLUB_SPONSORS: 'STORE_CLUB_SPONSORS',
  STORE_CLUB_PARTNERS: 'STORE_CLUB_PARTNERS',
  STORE_CLUB_HISTORY: 'STORE_CLUB_HISTORY',
  STORE_CLUB_STADIUM_DESCRIPTION: 'STORE_CLUB_STADIUM_DESCRIPTION'
};

export default actionTypes;
